import { lazy, Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Redirect } from 'presentation/components/global/Redirect';
import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

import {
  CardsRoutes as CardsRoutesPaths,
  RoutesPrefix
} from 'shared/constants/global';
import { AuthenticationMiddleware, FlagMiddleware } from 'shared/middlewares';

const AdminRoutes = lazy(async () => await import('./admin.routes'));
const StoreRoutes = lazy(async () => await import('./store.routes'));
const CardsRoutes = lazy(async () => await import('./cards.routes'));
const TravelRoutes = lazy(async () => await import('./travel.routes'));
const SupportRoutes = lazy(async () => await import('./support.routes'));
const ReportsRoutes = lazy(async () => await import('./reports.routes'));
const SettingsRoutes = lazy(async () => await import('./settings.routes'));
const ExpensesRoutes = lazy(async () => await import('./expenses.routes'));
const AnalyticsRoutes = lazy(async () => await import('./analytics.routes'));
const PublicRoutes = lazy(async () => await import('./public.routes'));

export function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={() => <FallbackPage error='DEFAULT' />}>
        <Suspense fallback={<SplashScreen />}>
          <Routes>
            <Route element={<AuthenticationMiddleware />}>
              <Route element={<FlagMiddleware />}>
                <Route
                  path={`${RoutesPrefix.CARDS}/*`}
                  element={<CardsRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.EXPENSES}/*`}
                  element={<ExpensesRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.REPORTS}/*`}
                  element={<ReportsRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.ADMIN}/*`}
                  element={<AdminRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.TRAVELS}/*`}
                  element={<TravelRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.ANALYSIS}/*`}
                  element={<AnalyticsRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.SETTINGS}/*`}
                  element={<SettingsRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.STORE}/*`}
                  element={<StoreRoutes />}
                />

                <Route
                  path={`${RoutesPrefix.SUPPORT}/*`}
                  element={<SupportRoutes />}
                />

                <Route
                  path={CardsRoutesPaths.CARDS_WEBVIEW}
                  element={
                    <Navigate to={CardsRoutesPaths.CARDS_USER_ONBOARDING} />
                  }
                />

                <Route
                  path='*'
                  element={<Redirect />}
                />
              </Route>
            </Route>

            <Route
              path={`${RoutesPrefix.CARDS}/*`}
              element={<PublicRoutes />}
            />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
