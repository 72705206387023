import { DSIcons, tokens } from 'ds';
import styled from 'styled-components';

import { type FeedbackColorTextType } from './InfoCards.types';
import { type AddPrefixToType } from 'ds/types';

export const StyledWarningOutlineIcon = styled(DSIcons.WarningOutlineIcon)`
  width: 2rem;
  color: ${tokens.feedbackColorWarningMedium};
`;

export const BookingTypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};
`;

export const FeedbackColorText = styled.div<
  AddPrefixToType<FeedbackColorTextType>
>`
  color: ${({ $isSuccess }) =>
    $isSuccess
      ? tokens.feedbackColorSuccessMedium
      : tokens.feedbackColorErrorMedium};
`;
