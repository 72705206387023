import { tokens } from 'ds';
import styled from 'styled-components';

export const Card = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  height: 29.3rem;
  padding: ${tokens.spacingInsetXs};
  background-color: ${tokens.deepColorWhite};
  border-radius: ${tokens.borderRadiusSm};
  border: ${tokens.borderWidthHairline} solid ${tokens.neutralColorHighMedium};
`;

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h3`
  color: ${tokens.neutralColorLowDarkest};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 169.231%;
  letter-spacing: 0.25px;
`;

export const Counter = styled.span`
  color: ${tokens.neutralColorLowMediumLight};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 169.231%;
  letter-spacing: 0.25px;
`;

export const UserList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  overflow: auto;
  flex-grow: 1;

  > li {
    color: ${tokens.neutralColorLowDark};
    font-size: ${tokens.fontSizeXxss};
    line-height: 132%;
    letter-spacing: 0.25px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${tokens.spacingMd};
  margin-top: 0.8rem;
  font-family: ${tokens.fontFamilyPoppins};

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;

    > section {
      height: 20rem;
    }
  }
`;
