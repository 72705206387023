import { tokens } from 'ds';
import { styled } from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingXs};
  padding: ${tokens.spacingXxs};
  background: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
`;
