import { tokens } from 'ds';
import styled from 'styled-components';

import { type CardInfoValueStylesType } from './ActionButtons.types';
import { type AddPrefixToType } from 'ds/types';

export const CardInfoValue = styled.div<
  AddPrefixToType<CardInfoValueStylesType>
>`
  color: ${({ $approved }) =>
    $approved
      ? tokens.feedbackColorSuccessMedium
      : tokens.feedbackColorErrorMedium};
`;
