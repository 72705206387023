import { tokens } from 'ds';
import styled from 'styled-components';

import { FlightTitle, HotelTitle } from 'presentation/pages/travel/components';

export const Container = styled.div`
  display: flex;
  padding: ${tokens.spacingXs};
  flex-direction: column;
  justify-content: center;
  row-gap: ${tokens.spacingXs};
  background: ${tokens.deepColorWhite};
  border-radius: ${tokens.borderRadiusMd};
  border: 1px solid ${tokens.neutralColorHighDark};
`;

export const Booking = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingXs};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${tokens.spacingNano};
  justify-content: space-between;
  align-items: center;
`;

export const StyledHotelTitle = styled(HotelTitle)`
  .text {
    color: ${tokens.neutralColorLowDark};
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightSemibold};
  }

  .bed-icon {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
  }
`;

export const StyledFlightTitle = styled(FlightTitle)`
  .text {
    color: ${tokens.neutralColorLowDark};
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightSemibold};
  }

  .plane-icon {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
  }

  .arrow-icon {
    color: ${tokens.neutralColorLowMedium};
  }
`;

export const PriceText = styled.span`
  color: ${tokens.neutralColorLowDarkest};
  text-align: right;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
  letter-spacing: 0.75px;
`;

export const Divider = styled.div`
  border: 1px solid ${tokens.neutralColorHighMedium};
  border-radius: ${tokens.borderRadiusCircular};
`;

export const InputContainer = styled.div`
  > div > div > div {
    padding: ${tokens.spacingNano} ${tokens.spacingXs};
  }

  textarea {
    padding: 0;
    vertical-align: middle;
    letter-spacing: 0.75px;
  }
`;
