import { tokens } from 'ds';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  padding: ${tokens.spacingXs} ${tokens.spacingLg};
  background: ${tokens.deepColorWhite};
`;
