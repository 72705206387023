import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${tokens.spacingXs};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacingNano};
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  letter-spacing: 0.75px;
`;

export const BigText = styled.div`
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 160%;
`;

export const SmallText = styled.div`
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 169.231%;
`;
