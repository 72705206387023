import { SecondaryButton, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  justify-content: space-between;

  > p {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0.075rem;
  }

  > a {
    flex-shrink: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 2.5rem;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${tokens.spacingXs};
  column-gap: ${tokens.spacingXs};
  align-self: stretch;
  align-items: center;
  border-radius: ${tokens.borderRadiusSm};
  border: 1px solid ${tokens.neutralColorHighMedium};

  > button {
    flex-shrink: 0;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingNano};
  flex-grow: 1;
`;

export const Title = styled.h3`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 169.231%;
  letter-spacing: 0.25px;
`;

export const Subtitle = styled.p`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightMedium};
  line-height: 169.231%;
  letter-spacing: 0.25px;
`;

export const StyledTrashButton = styled(SecondaryButton)`
  border: 2px solid ${tokens.feedbackColorErrorPure};

  > svg {
    color: ${tokens.feedbackColorErrorPure} !important;
  }

  &:hover {
    border-color: ${tokens.feedbackColorErrorPure};

    * {
      color: ${tokens.feedbackColorErrorPure} !important;
    }
  }
`;
