import { MenuTabs, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  padding: ${tokens.spacingXs};
  gap: ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighPure};
  box-shadow: 0 8px 16px 0 rgb(170 170 170 / 16%);
`;

export const TabContainer = styled(MenuTabs.Container)`
  ul {
    padding: 0;
  }
`;

export const TabItem = styled(MenuTabs.Item)`
  width: 50%;
  justify-content: center;
`;
