import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  gap: ${tokens.spacingXs};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
`;

export const MemberCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: ${tokens.spacingNano};
  align-items: center;
  margin-bottom: ${tokens.spacingXs};
`;

export const MembersCount = styled.span`
  color: ${tokens.neutralColorLowLightest};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 1.7rem;
  letter-spacing: 0.25px;
`;

export const LoadingItems = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const NoMembersMessage = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.2rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.spacingXs};
  justify-content: start;

  > .checkbox-default-component {
    display: inline-flex;
    height: 4.1rem;
    justify-content: start;
    gap: ${tokens.spacingXs};
    padding-top: ${tokens.spacingXxs};
    padding-bottom: ${tokens.spacingXxs};
    padding-left: ${tokens.spacingNano};
    padding-right: 0;
    overflow: hidden;
    max-width: 95%;

    span {
      overflow: hidden;
      max-width: 95%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  > .svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .tooltip-container {
    z-index: 1;
  }
`;

export const MemberListContainer = styled.div`
  width: 100%;
  max-height: 36rem;
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 50% 50%;

  .skeleton-loader {
    margin: 0.2rem;
  }

  @media screen and (max-height: 850px) {
    max-height: 17.6rem;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 100%;
  }

  .checkbox-container-select-all {
    grid-column: 1 / -1;

    > div {
      height: 4.1rem;
      gap: 1.6rem;
      display: inline-flex;
      justify-content: start;
      overflow: hidden;
      max-width: 95%;

      span {
        overflow: hidden;
        max-width: 95%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
