import { DefaultTable, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: ${tokens.borderRadiusMd};
`;

export const StyledTh = styled(DefaultTable.Th)`
  svg {
    flex-shrink: 0;
  }
`;

export const TableOverflowContainer = styled.div`
  .attachment-tooltip {
    z-index: 1;
  }

  > table td,
  > table th {
    height: 6.8rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    overflow-x: auto;

    > table {
      table-layout: auto;
    }
  }
`;

export const ActionColumnTh = styled(DefaultTable.Th)`
  width: 10rem;

  > div {
    display: flex;
    justify-content: center;
  }
`;

export const ActionColumnTd = styled(DefaultTable.Td)`
  display: flex;
  justify-content: center;
`;

export const RemoveButton = styled.button`
  all: unset;
  cursor: pointer;
`;

export const NoDataToShowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacingMd};
  margin-bottom: ${tokens.spacingXl};
  margin-top: 6rem;

  svg {
    height: 12rem;
    width: 12rem;
    margin-bottom: ${tokens.spacingNano};
  }

  span {
    margin-left: 0.5rem;
    font-weight: ${tokens.fontWeightSemibold};
  }
`;

export const NoDataToShowMessage = styled.p`
  color: ${tokens.neutralColorLowMedium};
  text-align: center;
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
`;
