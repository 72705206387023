import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};
  min-width: 0;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 140%;
  letter-spacing: 1px;

  svg {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  svg {
    width: 1.2rem;
    min-width: 1.2rem;
    margin: 0 0.3rem;
  }
`;

export const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
