import { useRef } from 'react';

import { parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';
import { es } from 'date-fns/locale/es';
import { ptBR } from 'date-fns/locale/pt-BR';
import { DSIcons } from 'ds';
import { useDsGa4 } from 'ds/hooks/globals';
import { CustomDate } from 'ds/utils/index';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';

import { useControlledDatePicker } from './useControlledDatePicker';

import { type IControlledDatePickerProps } from './ControlledDatePicker.types';
import { type LocaleType } from 'ds/types/globals/language.types';

import {
  Caption,
  Container,
  DatePickerHeader,
  DatePickerMonth,
  DatePickerYear,
  Fieldset,
  InputComponent,
  Label,
  Legend,
  Wrapper
} from './ControlledDatePicker.styles';

registerLocale('en', enUS);
registerLocale('es', es);
registerLocale('pt', ptBR);

export function ControlledDatePicker({
  iconLeft,
  iconRight,
  label,
  disabled,
  variant,
  currentLangKey = 'pt',
  name,
  onChangeValue,
  selectsRange = false,
  onChangeDateRange,
  minDate,
  maxDate,
  tooltipDisabledDayMessage,
  showClearButton = false,
  onClose,
  showAsteriskInPlaceholder,
  ...rest
}: IControlledDatePickerProps): JSX.Element {
  const IconLeft = iconLeft ? DSIcons[iconLeft] : null;
  const IconRight = iconRight ? DSIcons[iconRight] : null;
  const ClearIcon = DSIcons.ClearIcon;
  const { sendDsGaEvent } = useDsGa4();
  const {
    control,
    formState: { errors },
    watch,
    setValue
  } = useFormContext();

  const dateValue = watch(name);

  const { startDate, endDate, handleChangeDate, clearDateRange } =
    useControlledDatePicker({
      selectsRange,
      onChangeValue,
      onChangeDateRange
    });

  const locale: Record<LocaleType, string> = {
    enUS: 'en-US',
    ptBR: 'pt-BR',
    es: 'es-ES'
  };

  const localeComponent = {
    enUS,
    ptBR,
    es
  };

  const defaultDisabledDayText = {
    pt: 'Não é permitido',
    es: 'No permitido',
    en: 'Not allowed'
  };

  const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  const dateRangeMask = [...dateMask, ' ', '-', ' ', ...dateMask];

  const datePickerRef = useRef<ReactDatePicker<boolean, undefined>>(null);

  return (
    <div>
      <Wrapper>
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ field: { onChange, value, name } }) => (
            <Container
              {...rest}
              data-testid='container'
              $disabled={disabled}
              $hasError={!!errors[name]?.message}
              $variant={variant}
              $isTouched={!!(value || startDate || endDate)}
              $tooltipDisabledDayMessage={
                tooltipDisabledDayMessage ??
                defaultDisabledDayText[currentLangKey]
              }
            >
              <Label>{label}</Label>

              <div>
                {IconLeft && (
                  <IconLeft
                    onClick={() => datePickerRef.current?.setOpen(true)}
                  />
                )}

                <ReactDatePicker
                  ref={datePickerRef}
                  name={name}
                  startDate={selectsRange ? startDate : undefined}
                  endDate={selectsRange ? endDate : undefined}
                  selectsRange={selectsRange}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={value => {
                    handleChangeDate(value, onChange);
                  }}
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                  }) => (
                    <DatePickerHeader>
                      <DSIcons.ArrowLeftIcon
                        onClick={
                          prevMonthButtonDisabled
                            ? undefined
                            : () => {
                                sendDsGaEvent('components', 'date_picker', {
                                  description: 'click no dia',
                                  eventAction: 'date_picker_previous',
                                  eventLabel: 'previous_date_picker'
                                });

                                decreaseMonth();
                              }
                        }
                      />

                      <div>
                        <DatePickerMonth>
                          {date.toLocaleString(
                            locale[CustomDate.localeTransform(currentLangKey)],
                            {
                              month: 'long'
                            }
                          )}
                        </DatePickerMonth>

                        <DatePickerYear>{date.getFullYear()}</DatePickerYear>
                      </div>

                      <DSIcons.ArrowRightIcon
                        onClick={
                          nextMonthButtonDisabled
                            ? undefined
                            : () => {
                                sendDsGaEvent('components', 'date_picker', {
                                  description: 'click no dia',
                                  eventAction: 'date_picker_next',
                                  eventLabel: 'next_date_picker'
                                });

                                increaseMonth();
                              }
                        }
                      />
                    </DatePickerHeader>
                  )}
                  autoComplete='off'
                  calendarClassName='customDatePicker'
                  locale={
                    localeComponent[CustomDate.localeTransform(currentLangKey)]
                  }
                  disabled={disabled}
                  dateFormat='dd/MM/yyyy'
                  adjustDateOnChange={false}
                  placeholderText={`${rest.placeholder}${showAsteriskInPlaceholder ? '*' : ''}`}
                  selected={value ? parseISO(value) : null}
                  customInput={
                    <InputComponent
                      type='text'
                      name={name}
                      mask={selectsRange ? dateRangeMask : dateMask}
                      $variant={variant}
                      onBeforeInput={e => {
                        if (selectsRange) {
                          e.preventDefault();
                        }
                      }}
                    />
                  }
                  onCalendarClose={onClose}
                />
              </div>

              {showClearButton && (dateValue || startDate || endDate) && (
                <ClearIcon
                  className='clearIconButton'
                  onClick={() => {
                    if (selectsRange) clearDateRange();
                    if (!selectsRange) setValue(name, null);
                  }}
                />
              )}

              {IconRight && (
                <IconRight
                  onClick={() => datePickerRef.current?.setOpen(true)}
                />
              )}
            </Container>
          )}
        />

        <Fieldset>
          <Legend>
            <p>{label}</p>
          </Legend>
        </Fieldset>
      </Wrapper>

      {errors[name]?.message && (
        <Caption $hasError>{errors[name]?.message as string}</Caption>
      )}
    </div>
  );
}
