import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { Overview } from '../components';

import { type IHeaderCardsProps } from './BookingDetails.types';
import { type AddPrefixToType } from 'ds/types';

const HeaderCardsWithButtons = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: calc(100% - 1.6rem - 25rem);

    > div {
      width: calc(25% - 1.6rem + 0.32rem);
    }
  }
`;

const HeaderCardsWithoutButtons = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mid}px) {
    width: 100%;

    > div {
      width: calc(20% - 1.6rem + 0.32rem);
    }
  }
`;

export const HeaderCards = styled(Overview.HeaderCards)<
  AddPrefixToType<IHeaderCardsProps>
>`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mid}px) {
    div {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${({ $withButtons }) =>
    $withButtons ? HeaderCardsWithButtons : HeaderCardsWithoutButtons}
`;

export const ButtonsOrCardLoaderWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mid}px) {
    min-width: 25rem;
    width: 20%;
  }
`;

export const TitleDetails = styled.h1`
  display: flex;
  align-items: center;
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
`;

export const BottomContainer = styled.div`
  display: flex;
  gap: ${tokens.spacingMd};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
  }
`;

export const HistorySection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  width: 50%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

export const SummarySection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  width: 50%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

export const SummaryCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const InfoIconContainer = styled.div`
  display: flex;
  margin-left: ${tokens.spacingNano};
`;
