import { tokens } from 'ds';
import styled from 'styled-components';

import { FlightTitle } from 'presentation/pages/travel/components';

import { type ITextProps } from './FlightBookingCard.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.section`
  display: grid;
  grid-template-areas: 'title baggage stops';
  grid-template-columns: 1fr 1fr 2fr;
  width: 100%;
  padding: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-areas: 'title baggage' 'stops stops';
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-areas: 'title' 'baggage' 'stops';
    grid-template-columns: 1fr;
  }
`;

export const Text = styled.div<AddPrefixToType<ITextProps>>`
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${({ $semibold }) =>
    $semibold ? tokens.fontWeightSemibold : tokens.fontWeightRegular};
  color: ${({ $lightColor }) =>
    $lightColor ? tokens.neutralColorLowMedium : tokens.neutralColorLowDark};
  line-height: 140%;
`;

export const TitleSection = styled.div`
  grid-area: title;
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  min-width: 0;
  padding-right: ${tokens.spacingXs};
  border-right: 1px solid ${tokens.neutralColorHighDark};

  > div {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    border-right: none;
    padding-right: 0;
    border-bottom: 1px solid ${tokens.neutralColorHighDark};
    padding-bottom: ${tokens.spacingXs};
    margin-bottom: ${tokens.spacingXs};
  }
`;

export const StyledFlightTitle = styled(FlightTitle)`
  color: ${tokens.neutralColorLowDarkest};
  font-weight: ${tokens.fontWeightBold};
  letter-spacing: 0.75px;

  .arrow-icon {
    color: ${tokens.neutralColorLowDarkest};
    margin: 0 0.7rem;
  }
`;

export const AirlineImg = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: ${tokens.spacingNano};
`;

export const BaggageSection = styled.div`
  grid-area: baggage;
  display: flex;
  align-items: center;
  gap: ${tokens.spacingXxs};
  min-width: 0;
  padding: 0 ${tokens.spacingXs};

  svg {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingNano};
    min-width: 0;

    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0;
  }
`;

export const FlightPathContainer = styled.div`
  display: flex;
  grid-area: stops;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-left: ${tokens.spacingXs};
  border-left: 1px solid ${tokens.neutralColorHighDark};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-left: none;
    padding-left: 0;
    border-top: 1px solid ${tokens.neutralColorHighDark};
    padding-top: ${tokens.spacingXs};
    margin-top: ${tokens.spacingXs};
  }
`;

export const Line = styled.div`
  width: 100%;
  min-width: 0;
  border-top: 1px solid ${tokens.neutralColorHighDark};
  margin: 0 ${tokens.spacingNano};

  & + div {
    white-space: nowrap;
  }
`;
