import { tokens } from 'ds';
import styled from 'styled-components';

import { HotelTitle } from 'presentation/pages/travel/components';

import { type ITextProps } from './HotelBookingCard.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.section`
  display: grid;
  grid-template-areas: 'title info stay';
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-areas: 'title info' 'stay stay';
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-areas: 'title' 'info' 'stay';
    grid-template-columns: 1fr;
  }
`;

export const Text = styled.div<AddPrefixToType<ITextProps>>`
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${({ $semibold }) =>
    $semibold ? tokens.fontWeightSemibold : tokens.fontWeightRegular};
  color: ${({ $lightColor }) =>
    $lightColor ? tokens.neutralColorLowMedium : tokens.neutralColorLowDark};
  line-height: 140%;
`;

export const TitleSection = styled.div`
  grid-area: title;
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  min-width: 0;
  padding-right: ${tokens.spacingXs};
  border-right: 1px solid ${tokens.neutralColorHighDark};

  > div {
    display: flex;
    align-items: center;
  }

  ${Text} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    border-right: none;
    padding-right: 0;
    border-bottom: 1px solid ${tokens.neutralColorHighDark};
    padding-bottom: ${tokens.spacingXs};
    margin-bottom: ${tokens.spacingXs};
  }
`;

export const StyledHotelTitle = styled(HotelTitle)`
  color: ${tokens.neutralColorLowDarkest};
  font-weight: ${tokens.fontWeightBold};
  letter-spacing: 0.75px;
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};

  svg {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
  }

  > button {
    all: unset;
    cursor: pointer;
    height: 2rem;
    width: 2rem;

    > svg {
      color: ${tokens.brandColorSecondaryPure};
      rotate: 180deg;
    }
  }
`;

export const Description = styled(Text)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    && {
      white-space: break-spaces;
    }
  }
`;

export const InfoSection = styled.div`
  grid-area: info;
  display: flex;
  align-items: center;
  gap: ${tokens.spacingXxs};
  min-width: 0;
  padding: 0 ${tokens.spacingXs};

  svg {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingNano};
    min-width: 0;

    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0;
  }
`;

export const StaySection = styled.div`
  grid-area: stay;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${tokens.spacingQuarck};
  padding-left: ${tokens.spacingXs};
  border-left: 1px solid ${tokens.neutralColorHighDark};

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${Text} {
    white-space: nowrap;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-left: none;
    padding-left: 0;
    border-top: 1px solid ${tokens.neutralColorHighDark};
    padding-top: ${tokens.spacingXs};
    margin-top: ${tokens.spacingXs};
  }
`;

export const Line = styled.div`
  width: 100%;
  min-width: 0;
  border-top: 1px solid ${tokens.neutralColorHighDark};
  margin: 0 ${tokens.spacingNano};
`;
