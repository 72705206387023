import { tokens } from 'ds';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${tokens.spacingXs};
  column-gap: ${tokens.spacingXs};
  align-self: stretch;
  align-items: center;
  border-radius: ${tokens.borderRadiusSm};
  border: 1px solid ${tokens.neutralColorHighMedium};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingNano};
`;

export const Title = styled.h3`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 169.231%;
  letter-spacing: 0.25px;
`;

export const Subtitle = styled.p`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightMedium};
  line-height: 169.231%;
  letter-spacing: 0.25px;
`;
