import { lazy } from 'react';

const ShieldProtectedCircleIcon = lazy(
  async () =>
    await import('./shield_protected_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageRemoveCrossSquareIcon = lazy(
  async () =>
    await import('./message_remove_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoactionMapDirectionSelectSquareIcon = lazy(
  async () =>
    await import('./loaction_map_direction_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderIconIcon = lazy(
  async () =>
    await import('./finder_icon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FocusSelectIcon = lazy(
  async () =>
    await import('./focus_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditCreateIcon = lazy(
  async () =>
    await import('./pen_edit_create.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuEditPenAddCircleIcon = lazy(
  async () =>
    await import('./menu_edit_pen_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCheckmarkCircleIcon = lazy(
  async () =>
    await import('./warning_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseIcon = lazy(
  async () =>
    await import('./home_house.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CcSquareIcon = lazy(
  async () =>
    await import('./cc_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenLineSquareIcon = lazy(
  async () =>
    await import('./edit_pen_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkRemoveDeleteCrossIcon = lazy(
  async () =>
    await import('./bookmark_remove_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingVerticalCircleIcon = lazy(
  async () =>
    await import('./setting_vertical_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeAddPlusIcon = lazy(
  async () =>
    await import('./home_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxMoreMenuInfoIcon = lazy(
  async () =>
    await import('./checkbox_more_menu_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalednarScheduleCrossIcon = lazy(
  async () =>
    await import('./calednar_schedule_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockOverturningIcon = lazy(
  async () =>
    await import('./lock_overturning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeAlarmCheckmarkSquareIcon = lazy(
  async () =>
    await import('./time_alarm_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagArrowsLeftCircleIcon = lazy(
  async () =>
    await import('./flag_arrows_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkCrossIcon = lazy(
  async () =>
    await import('./bookmark_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardIcon = lazy(
  async () =>
    await import('./dashboard.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardCopyIcon = lazy(
  async () =>
    await import('./dashboard_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeSquareCopyIcon = lazy(
  async () =>
    await import('./clock_time_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FullScreenZoomIcon = lazy(
  async () =>
    await import('./full_screen_zoom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphTextCircleIcon = lazy(
  async () =>
    await import('./paragraph_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBurgerCircleIcon = lazy(
  async () =>
    await import('./menu_burger_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderWindowSearchIcon = lazy(
  async () =>
    await import('./finder_window_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextImageSquareIcon = lazy(
  async () =>
    await import('./item_text_image_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchMoreSquareIcon = lazy(
  async () =>
    await import('./item_search_more_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockMenuSettingRightIcon = lazy(
  async () =>
    await import('./time_clock_menu_setting_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinocularsSearchSquareIcon = lazy(
  async () =>
    await import('./binoculars_search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernCrossRemoveIcon = lazy(
  async () =>
    await import('./message_chat_modern_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebWindowsIcon = lazy(
  async () =>
    await import('./browser_web_windows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowZoomPlusLoupeIcon = lazy(
  async () =>
    await import('./window_zoom_plus_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprinAddPlusIcon = lazy(
  async () =>
    await import('./fingerprin_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListCrossDeleteIcon = lazy(
  async () =>
    await import('./item_list_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWindowCheckMarkIcon = lazy(
  async () =>
    await import('./browser_window_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BatteryEmptyDischargeIcon = lazy(
  async () =>
    await import('./battery_empty_discharge.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarUndoArrowIcon = lazy(
  async () =>
    await import('./calendar_undo_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectWifiMoreDotsIcon = lazy(
  async () =>
    await import('./object_wifi_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkLabelCheckmarkIcon = lazy(
  async () =>
    await import('./bookmark_label_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecMicStyleStopOctagonIcon = lazy(
  async () =>
    await import('./rec_mic_style_stop_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistItemRemoveCrossStyleSquareIcon = lazy(
  async () =>
    await import('./tasklist_item_remove_cross_style_square.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const MessageChartAnalyticsIcon = lazy(
  async () =>
    await import('./message_chart_analytics.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteCircleIcon = lazy(
  async () =>
    await import('./star_favorite_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzleIcon = lazy(
  async () =>
    await import('./puzzle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiMinimaSigmalSelectIcon = lazy(
  async () =>
    await import('./wifi_minima_sigmal_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsBrowserEnterIcon = lazy(
  async () =>
    await import('./windows_browser_enter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenRemoveCrossCircleIcon = lazy(
  async () =>
    await import('./edit_pen_remove_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoSelectIcon = lazy(
  async () =>
    await import('./info_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlusAddIcon = lazy(
  async () =>
    await import('./plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteAddIcon = lazy(
  async () =>
    await import('./bookmark_favorite_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginLogoutArrowRightIcon = lazy(
  async () =>
    await import('./login_logout_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareLinkBigOctagonIcon = lazy(
  async () =>
    await import('./share_link_big_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LanguageTranslateCircleIcon = lazy(
  async () =>
    await import('./language_translate_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCircleIcon = lazy(
  async () =>
    await import('./copy_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectItemCrossDeleateIcon = lazy(
  async () =>
    await import('./select_item_cross_deleate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookOpenTextIcon = lazy(
  async () =>
    await import('./book_open_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartStyleBigIcon = lazy(
  async () =>
    await import('./chart_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditIcon = lazy(
  async () =>
    await import('./pen_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightBulbIdeaSquareIcon = lazy(
  async () =>
    await import('./light_bulb_idea_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageRightBlankIcon = lazy(
  async () =>
    await import('./chat_message_right_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowsCircleArrowsIcon = lazy(
  async () =>
    await import('./rotate_arrows_circle_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchZoomPlusIcon = lazy(
  async () =>
    await import('./item_search_zoom_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingModernSquareIcon = lazy(
  async () =>
    await import('./loading_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShapesObjectsIcon = lazy(
  async () =>
    await import('./shapes_objects.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuModernSquareIcon = lazy(
  async () =>
    await import('./menu_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinSquareIcon = lazy(
  async () =>
    await import('./pin_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListTasksDeleteCrossPlusIcon = lazy(
  async () =>
    await import('./list_tasks_delete_cross_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlignLeftArrowSquareIcon = lazy(
  async () =>
    await import('./align_left_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleBigIcon = lazy(
  async () =>
    await import('./bell_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateDoneIcon = lazy(
  async () =>
    await import('./rotate_done.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotificationStatusCircleIcon = lazy(
  async () =>
    await import('./notification_status_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EnterLoginStyleSquareIcon = lazy(
  async () =>
    await import('./enter_login_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyPasteSelectAddPlusIcon = lazy(
  async () =>
    await import('./copy_paste_select_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BarcodeScanIcon = lazy(
  async () =>
    await import('./barcode_scan.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeShareIcon = lazy(
  async () =>
    await import('./clock_time_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrivacyIcon = lazy(
  async () =>
    await import('./privacy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistBackIcon = lazy(
  async () =>
    await import('./tasklist_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashModernTwoLineIcon = lazy(
  async () =>
    await import('./bin_trash_modern_two_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveTextDeleteCrossIcon = lazy(
  async () =>
    await import('./mailbox_archive_text_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistSquareIcon = lazy(
  async () =>
    await import('./tasklist_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageUploadArrowUpIcon = lazy(
  async () =>
    await import('./picture_image_upload_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagCircleIcon = lazy(
  async () =>
    await import('./flag_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayHorizontalBigIcon = lazy(
  async () =>
    await import('./airplay_horizontal_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FireSquareIcon = lazy(
  async () =>
    await import('./fire_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiOffTurnOffIcon = lazy(
  async () =>
    await import('./wifi_off_turn_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageCheckmarkIcon = lazy(
  async () =>
    await import('./chat_message_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMenuMoreIcon = lazy(
  async () =>
    await import('./printer_menu_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSquareDotsIcon = lazy(
  async () =>
    await import('./menu_square_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimalRemoveDeleteBigIcon = lazy(
  async () =>
    await import('./monitor_minimal_remove_delete_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SynchronizeIcon = lazy(
  async () =>
    await import('./synchronize.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderWindowTextModernCircleIcon = lazy(
  async () =>
    await import('./finder_window_text_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarSearchIcon = lazy(
  async () =>
    await import('./calendar_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlideDisableDeliteIcon = lazy(
  async () =>
    await import('./slide_disable_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareArrowCircleIcon = lazy(
  async () =>
    await import('./share_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraAddPlusSquareIcon = lazy(
  async () =>
    await import('./camera_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockPlayGoIcon = lazy(
  async () =>
    await import('./timer_clock_play_go.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkUnlinkCopyIcon = lazy(
  async () =>
    await import('./broken_link_unlink_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphTextSquareIcon = lazy(
  async () =>
    await import('./paragraph_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardDeleteIcon = lazy(
  async () =>
    await import('./keyboard_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleCircleIcon = lazy(
  async () =>
    await import('./filter_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DivisionMarkIcon = lazy(
  async () =>
    await import('./division_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendrBottomLineIcon = lazy(
  async () =>
    await import('./calendr_bottom_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowResizeRightIcon = lazy(
  async () =>
    await import('./window_resize_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MinusHexagonIcon = lazy(
  async () =>
    await import('./minus_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockAddPlusSquareIcon = lazy(
  async () =>
    await import('./alarm_clock_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditCircleCopyIcon = lazy(
  async () =>
    await import('./pen_edit_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteLaodingCircleIcon = lazy(
  async () =>
    await import('./heart_favorite_laoding_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemsBlocksCircleIcon = lazy(
  async () =>
    await import('./menu_items_blocks_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NftCircleIcon = lazy(
  async () =>
    await import('./nft_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShapesBjectsSquareIcon = lazy(
  async () =>
    await import('./shapes_bjects_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetVerticalMinimalBigIcon = lazy(
  async () =>
    await import('./magnet_vertical_minimal_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphIcon = lazy(
  async () =>
    await import('./paragraph.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseModernCheckmarkIcon = lazy(
  async () =>
    await import('./home_house_modern_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeSimpleIcon = lazy(
  async () =>
    await import('./eye_simple.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeCrossRemoveRightBigIcon = lazy(
  async () =>
    await import('./clock_time_cross_remove_right_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeadphonesSquareIcon = lazy(
  async () =>
    await import('./headphones_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkReadLineSquareIcon = lazy(
  async () =>
    await import('./checkmark_read_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipAttachmentCircleIcon = lazy(
  async () =>
    await import('./clip_attachment_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuoteCircleIcon = lazy(
  async () =>
    await import('./quote_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingHorizontalCircleIcon = lazy(
  async () =>
    await import('./setting_horizontal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBurgerIcon = lazy(
  async () =>
    await import('./menu_burger.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagCircleCopyIcon = lazy(
  async () =>
    await import('./flag_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingCircleIcon = lazy(
  async () =>
    await import('./setting_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveDeleteIcon = lazy(
  async () =>
    await import('./mailbox_archive_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistPinIcon = lazy(
  async () =>
    await import('./tasklist_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipVerticalSelectIcon = lazy(
  async () =>
    await import('./clip_vertical_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AccessibilityIcon = lazy(
  async () =>
    await import('./accessibility.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalRecStyleSquareIcon = lazy(
  async () =>
    await import('./signal_rec_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitLoginLogoutIcon = lazy(
  async () =>
    await import('./exit_login_logout.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkBlankIcon = lazy(
  async () =>
    await import('./bookmark_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockWarningIcon = lazy(
  async () =>
    await import('./lock_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridLayoutIcon = lazy(
  async () =>
    await import('./grid_layout.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadSquareCopyIcon = lazy(
  async () =>
    await import('./download_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkReadStyleCircleIcon = lazy(
  async () =>
    await import('./checkmark_read_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoldSquareIcon = lazy(
  async () =>
    await import('./bold_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonNightModeIcon = lazy(
  async () =>
    await import('./moon_night_mode.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeRefreshIcon = lazy(
  async () =>
    await import('./eye_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeClassicBigIcon = lazy(
  async () =>
    await import('./clock_time_classic_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortCircleDisableDeliteIcon = lazy(
  async () =>
    await import('./filter_sort_circle_disable_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorOctagonIcon = lazy(
  async () =>
    await import('./cursor_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkSquareIcon = lazy(
  async () =>
    await import('./item_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenListCircleIcon = lazy(
  async () =>
    await import('./edit_pen_list_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleIcon = lazy(
  async () =>
    await import('./calendar_schedule.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockMenuCopyIcon = lazy(
  async () =>
    await import('./time_clock_menu_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingRotateSquareIcon = lazy(
  async () =>
    await import('./loading_rotate_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeCrossIcon = lazy(
  async () =>
    await import('./eye_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebDeleteIcon = lazy(
  async () =>
    await import('./browser_web_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleSquareIcon = lazy(
  async () =>
    await import('./calendar_schedule_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageHashtagIcon = lazy(
  async () =>
    await import('./chat_message_hashtag.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshRotateIcon = lazy(
  async () =>
    await import('./refresh_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureCopyLeftIcon = lazy(
  async () =>
    await import('./image_picture_copy_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AwardRatingSquareIcon = lazy(
  async () =>
    await import('./award_rating_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginEjectCircleCircleIcon = lazy(
  async () =>
    await import('./login_eject_circle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteAddPlusSquareIcon = lazy(
  async () =>
    await import('./star_favorite_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowItemMoreInfoIcon = lazy(
  async () =>
    await import('./window_item_more_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartSquareIcon = lazy(
  async () =>
    await import('./chart_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorIcon = lazy(
  async () =>
    await import('./cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingDotsCircleIcon = lazy(
  async () =>
    await import('./loading_dots_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveMenuSettingIcon = lazy(
  async () =>
    await import('./mailbox_archive_menu_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsFinderBrowserIcon = lazy(
  async () =>
    await import('./windows_finder_browser.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderLightningCircleIcon = lazy(
  async () =>
    await import('./thunder_lightning_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatRightTextCommentsIcon = lazy(
  async () =>
    await import('./message_chat_right_text_comments.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceMicSquareIcon = lazy(
  async () =>
    await import('./voice_mic_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarCheckmarkIcon = lazy(
  async () =>
    await import('./star_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListAddIcon = lazy(
  async () =>
    await import('./list_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowRotateDotsIcon = lazy(
  async () =>
    await import('./arrow_rotate_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectPaswordIcon = lazy(
  async () =>
    await import('./cursor_select_pasword.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatRightTextIcon = lazy(
  async () =>
    await import('./message_chat_right_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandRightCursorSquareIcon = lazy(
  async () =>
    await import('./hand_right_cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PhotoImageSquareIcon = lazy(
  async () =>
    await import('./photo_image_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentBookmarkIcon = lazy(
  async () =>
    await import('./document_bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectDeleteLockIcon = lazy(
  async () =>
    await import('./select_object_delete_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputSquareIcon = lazy(
  async () =>
    await import('./input_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessabeChatLinkIcon = lazy(
  async () =>
    await import('./messabe_chat_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureCopyIcon = lazy(
  async () =>
    await import('./image_picture_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyStyleVerticalLineIcon = lazy(
  async () =>
    await import('./floppy_style_vertical_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSearhLoupeSquareIcon = lazy(
  async () =>
    await import('./menu_searh_loupe_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemCursorCircleIcon = lazy(
  async () =>
    await import('./menu_item_cursor_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxWarningIcon = lazy(
  async () =>
    await import('./mailbox_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesWindowsIcon = lazy(
  async () =>
    await import('./slides_windows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSelectBigIcon = lazy(
  async () =>
    await import('./wifi_select_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginCircleCopyIcon = lazy(
  async () =>
    await import('./plugin_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellSquareIcon = lazy(
  async () =>
    await import('./bell_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginEnterArrowLeftSquareIcon = lazy(
  async () =>
    await import('./login_enter_arrow_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardButtonArrowsIcon = lazy(
  async () =>
    await import('./keyboard_button_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleDownloadInfoIcon = lazy(
  async () =>
    await import('./bin_trash_style_download_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenTextCircleIcon = lazy(
  async () =>
    await import('./edit_pen_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeArrowsIcon = lazy(
  async () =>
    await import('./resize_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AsteriskCircleIcon = lazy(
  async () =>
    await import('./asterisk_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkDoneCheckIcon = lazy(
  async () =>
    await import('./checkmark_done_check.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardButtonArrowsSquareIcon = lazy(
  async () =>
    await import('./keyboard_button_arrows_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarGearCircleIcon = lazy(
  async () =>
    await import('./star_gear_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockCheckmarkIcon = lazy(
  async () =>
    await import('./alarm_clock_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TSquareTextIcon = lazy(
  async () =>
    await import('./t_square_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarNotificationRightDownIcon = lazy(
  async () =>
    await import('./calendar_notification_right_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapLoadingIcon = lazy(
  async () =>
    await import('./compass_map_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapMinimalCircleIcon = lazy(
  async () =>
    await import('./map_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockHourSquareIcon = lazy(
  async () =>
    await import('./clock_hour_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraCircleCopyIcon = lazy(
  async () =>
    await import('./camera_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BluetoothSquareIcon = lazy(
  async () =>
    await import('./bluetooth_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudCheckmarkCircleIcon = lazy(
  async () =>
    await import('./cloud_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCircleIcon = lazy(
  async () =>
    await import('./pin_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinCrossDeleteIcon = lazy(
  async () =>
    await import('./marker_pin_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareArrowUpUploadSquareIcon = lazy(
  async () =>
    await import('./share_arrow_up_upload_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemEditPenDotsCirleIcon = lazy(
  async () =>
    await import('./item_edit_pen_dots_cirle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureCircleIcon = lazy(
  async () =>
    await import('./image_picture_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CertificateCheckmarkIcon = lazy(
  async () =>
    await import('./certificate_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionIcon = lazy(
  async () =>
    await import('./question.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardCircleIcon = lazy(
  async () =>
    await import('./dashboard_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenHorizontalDeleteRemoveIcon = lazy(
  async () =>
    await import('./edit_pen_horizontal_delete_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteBinIcon = lazy(
  async () =>
    await import('./trash_delete_bin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkIcon = lazy(
  async () =>
    await import('./attachment_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrownStyleSquareIcon = lazy(
  async () =>
    await import('./crown_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagicWandSquareIcon = lazy(
  async () =>
    await import('./magic_wand_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareArrowsIcon = lazy(
  async () =>
    await import('./share_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockShieldProteIcon = lazy(
  async () =>
    await import('./lock_shield_prote.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyholeLockCircleIcon = lazy(
  async () =>
    await import('./keyhole_lock_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipFileArchiveIcon = lazy(
  async () =>
    await import('./zip_file_archive.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagesPicturesModernStyleCopyIcon = lazy(
  async () =>
    await import('./images_pictures_modern_style_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchCheckmarkCircleIcon = lazy(
  async () =>
    await import('./search_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteDisabledIcon = lazy(
  async () =>
    await import('./delete_disabled.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapLoadingCircleIcon = lazy(
  async () =>
    await import('./search_loap_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxCheckmarkInfoIcon = lazy(
  async () =>
    await import('./checkbox_checkmark_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelTagCheckmarkIcon = lazy(
  async () =>
    await import('./bookmarks_label_tag_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItmeCopyCheckboxCheckmarkTopRightIcon = lazy(
  async () =>
    await import('./itme_copy_checkbox_checkmark_top_right.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const MessageChatSquareIcon = lazy(
  async () =>
    await import('./message_chat_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareSignalIcon = lazy(
  async () =>
    await import('./share_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlockMinusSelectIcon = lazy(
  async () =>
    await import('./block_minus_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextEditSquareIcon = lazy(
  async () =>
    await import('./text_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditSquareIcon = lazy(
  async () =>
    await import('./pen_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernCircleCopyIcon = lazy(
  async () =>
    await import('./image_picture_modern_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailMoreDotsIcon = lazy(
  async () =>
    await import('./email_mail_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockSelectIcon = lazy(
  async () =>
    await import('./lock_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinInfoIcon = lazy(
  async () =>
    await import('./trash_bin_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordRotectionIcon = lazy(
  async () =>
    await import('./input_password_rotection.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListColumnsIcon = lazy(
  async () =>
    await import('./item_list_columns.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchTextTitleItemMiddleIcon = lazy(
  async () =>
    await import('./search_text_title_item_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarNotificationLeftDownIcon = lazy(
  async () =>
    await import('./calendar_notification_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkIcon = lazy(
  async () =>
    await import('./item_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageTextCrossDeleteIcon = lazy(
  async () =>
    await import('./chat_message_text_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesSquareIcon = lazy(
  async () =>
    await import('./settings_switches_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicRecCircleIcon = lazy(
  async () =>
    await import('./microphone_mic_rec_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadCircleIcon = lazy(
  async () =>
    await import('./download_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchDeleteCrossSquareIcon = lazy(
  async () =>
    await import('./item_search_delete_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListAddPlusIcon = lazy(
  async () =>
    await import('./item_list_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputRemoveCrossSquareIcon = lazy(
  async () =>
    await import('./input_remove_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AddPageIcon = lazy(
  async () =>
    await import('./add_page.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsFilterIcon = lazy(
  async () =>
    await import('./settings_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernStyleCircleIcon = lazy(
  async () =>
    await import('./image_picture_modern_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorLogOutInCircleIcon = lazy(
  async () =>
    await import('./door_log_out_in_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleCircleIcon = lazy(
  async () =>
    await import('./setting_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputTextIcon = lazy(
  async () =>
    await import('./input_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeDotsDownIcon = lazy(
  async () =>
    await import('./home_dots_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorStyleSquareCopyIcon = lazy(
  async () =>
    await import('./cursor_style_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalHotspotIcon = lazy(
  async () =>
    await import('./signal_hotspot.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonNightModeNotificationSquareIcon = lazy(
  async () =>
    await import('./moon_night_mode_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LogoutExitCircleIcon = lazy(
  async () =>
    await import('./logout_exit_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CodeTextCircleIcon = lazy(
  async () =>
    await import('./code_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextFilterIcon = lazy(
  async () =>
    await import('./document_text_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DottedLineCrossCutIcon = lazy(
  async () =>
    await import('./dotted_line_cross_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintProtectionOctagonIcon = lazy(
  async () =>
    await import('./fingerprint_protection_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatRightDotsMoreIcon = lazy(
  async () =>
    await import('./message_chat_right_dots_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyLoginCircleIcon = lazy(
  async () =>
    await import('./key_login_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandStopCircleIcon = lazy(
  async () =>
    await import('./hand_stop_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCircleCopyIcon = lazy(
  async () =>
    await import('./pin_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextSearchSquareIcon = lazy(
  async () =>
    await import('./item_text_search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageCopyIcon = lazy(
  async () =>
    await import('./chat_message_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockStyleLineIcon = lazy(
  async () =>
    await import('./lock_style_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyWindowsSquareIcon = lazy(
  async () =>
    await import('./copy_windows_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuEditPenDeleteCrossIcon = lazy(
  async () =>
    await import('./menu_edit_pen_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteButtonIcon = lazy(
  async () =>
    await import('./delete_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornAngleBigIcon = lazy(
  async () =>
    await import('./horn_angle_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageShatEyeTopRightIcon = lazy(
  async () =>
    await import('./message_shat_eye_top_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeSquareIcon = lazy(
  async () =>
    await import('./eye_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoneCheckIcon = lazy(
  async () =>
    await import('./done_check.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksFavoriteCheckMarkIcon = lazy(
  async () =>
    await import('./bookmarks_favorite_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterMailNotificationIcon = lazy(
  async () =>
    await import('./letter_mail_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpacingIcon = lazy(
  async () =>
    await import('./spacing.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassLocationMapOffIcon = lazy(
  async () =>
    await import('./compass_location_map_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteSquareIcon = lazy(
  async () =>
    await import('./heart_favorite_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyIcon = lazy(
  async () =>
    await import('./key.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareArrowSqaureIcon = lazy(
  async () =>
    await import('./share_arrow_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCopyIcon = lazy(
  async () =>
    await import('./pin_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceMicChartSquareIcon = lazy(
  async () =>
    await import('./voice_mic_chart_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneCircleIcon = lazy(
  async () =>
    await import('./microphone_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MaiboxMinimalCircleIcon = lazy(
  async () =>
    await import('./maibox_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCheckmarkIcon = lazy(
  async () =>
    await import('./item_text_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingVerticalIcon = lazy(
  async () =>
    await import('./setting_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesCheckmarkIcon = lazy(
  async () =>
    await import('./chat_messages_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListLineSquareIcon = lazy(
  async () =>
    await import('./task_list_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderLightningSquareIcon = lazy(
  async () =>
    await import('./thunder_lightning_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortIcon = lazy(
  async () =>
    await import('./filter_sort.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuNotificationCircleIcon = lazy(
  async () =>
    await import('./menu_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapFastSqaureIcon = lazy(
  async () =>
    await import('./search_loap_fast_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockIcon = lazy(
  async () =>
    await import('./alarm_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSelectZoomPlusIcon = lazy(
  async () =>
    await import('./item_select_zoom_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiCrossRemoveIcon = lazy(
  async () =>
    await import('./wifi_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsSyncSqaureIcon = lazy(
  async () =>
    await import('./arrows_sync_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudUploadIcon = lazy(
  async () =>
    await import('./cloud_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotesDocumentCrossDeleteIcon = lazy(
  async () =>
    await import('./notes_document_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipCrossRemoveDeleteCircleIcon = lazy(
  async () =>
    await import('./zip_cross_remove_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagRemoveCrossSquareIcon = lazy(
  async () =>
    await import('./flag_remove_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatSmileCopyIcon = lazy(
  async () =>
    await import('./message_chat_smile_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookCiecleIcon = lazy(
  async () =>
    await import('./book_ciecle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./chart_analytics_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemShareSignalAddPlusIcon = lazy(
  async () =>
    await import('./item_share_signal_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AltButtonCircleIcon = lazy(
  async () =>
    await import('./alt_button_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesBubbleCircleIcon = lazy(
  async () =>
    await import('./chat_messages_bubble_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PodcastIcon = lazy(
  async () =>
    await import('./podcast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteCrossDisabledIcon = lazy(
  async () =>
    await import('./star_favorite_cross_disabled.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCrossDeleteLeftIcon = lazy(
  async () =>
    await import('./document_cross_delete_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailStyleBigIcon = lazy(
  async () =>
    await import('./email_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingHexagonIcon = lazy(
  async () =>
    await import('./setting_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSleepModeZSquareIcon = lazy(
  async () =>
    await import('./item_sleep_mode_z_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemWirelessSqaureIcon = lazy(
  async () =>
    await import('./item_wireless_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerMinimalStyleIcon = lazy(
  async () =>
    await import('./timer_minimal_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingArrowsSquareIcon = lazy(
  async () =>
    await import('./loading_arrows_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMinimalLeftCircleIcon = lazy(
  async () =>
    await import('./edit_pen_minimal_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LaodingRingIcon = lazy(
  async () =>
    await import('./laoding_ring.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookNotesIcon = lazy(
  async () =>
    await import('./notebook_notes.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphTextCopyIcon = lazy(
  async () =>
    await import('./paragraph_text_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneStyleRecMicSquareIcon = lazy(
  async () =>
    await import('./microphone_style_rec_mic_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketsIcon = lazy(
  async () =>
    await import('./brackets.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerSquareCopyIcon = lazy(
  async () =>
    await import('./sticker_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshUpdateDisableDeleteIcon = lazy(
  async () =>
    await import('./refresh_update_disable_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingEditFilterGearSquareIcon = lazy(
  async () =>
    await import('./setting_edit_filter_gear_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectsCopyTargetIcon = lazy(
  async () =>
    await import('./objects_copy_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCircleIcon = lazy(
  async () =>
    await import('./fingerprint_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HighvoltageWarningLightningIcon = lazy(
  async () =>
    await import('./highvoltage_warning_lightning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditMoreSquareIcon = lazy(
  async () =>
    await import('./pen_edit_more_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextPlusAddCentreRightIcon = lazy(
  async () =>
    await import('./document_text_plus_add_centre_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldVerticalDotsMenuSettingIcon = lazy(
  async () =>
    await import('./shield_vertical_dots_menu_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleCircleIcon = lazy(
  async () =>
    await import('./email_letter_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSelectIcon = lazy(
  async () =>
    await import('./filter_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesBubbleCheckMarkIcon = lazy(
  async () =>
    await import('./chat_messages_bubble_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshReloadLoadingArrowIcon = lazy(
  async () =>
    await import('./refresh_reload_loading_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditMinimalInputCircleIcon = lazy(
  async () =>
    await import('./pen_edit_minimal_input_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxCheckmarkSquareIcon = lazy(
  async () =>
    await import('./checkbox_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LogoutExitArrowRightCircleIcon = lazy(
  async () =>
    await import('./logout_exit_arrow_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsIcon = lazy(
  async () =>
    await import('./settings.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsRoundIcon = lazy(
  async () =>
    await import('./settings_round.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarModernLineIcon = lazy(
  async () =>
    await import('./calendar_modern_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeCrossCircleIcon = lazy(
  async () =>
    await import('./eye_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsCrossDeleteSquareCopyIcon = lazy(
  async () =>
    await import('./chart_analytics_cross_delete_square_copy.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const CodeTextIcon = lazy(
  async () =>
    await import('./code_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxUploadTopMiddleIcon = lazy(
  async () =>
    await import('./mailbox_upload_top_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalRightCircleIcon = lazy(
  async () =>
    await import('./send_horizontal_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkOctagonSmallIcon = lazy(
  async () =>
    await import('./bookmark_octagon_small.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCheckmarkCircleIcon = lazy(
  async () =>
    await import('./chart_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatWarningIcon = lazy(
  async () =>
    await import('./message_chat_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeCheckmarkIcon = lazy(
  async () =>
    await import('./clock_time_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkUnlinkAttachmentIcon = lazy(
  async () =>
    await import('./broken_link_unlink_attachment.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowBackspaceMoreIcon = lazy(
  async () =>
    await import('./arrow_backspace_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemShareArrowRightSquareIcon = lazy(
  async () =>
    await import('./item_share_arrow_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeSleepModeCircleIcon = lazy(
  async () =>
    await import('./eye_sleep_mode_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadLoadingProcessIcon = lazy(
  async () =>
    await import('./download_loading_process.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeadphonesStyleSquareIcon = lazy(
  async () =>
    await import('./headphones_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeCertificateCheckMarkIcon = lazy(
  async () =>
    await import('./globe_certificate_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchIcon = lazy(
  async () =>
    await import('./item_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinCircleIcon = lazy(
  async () =>
    await import('./marker_pin_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaySignalIcon = lazy(
  async () =>
    await import('./airplay_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BagShoppingCircleIcon = lazy(
  async () =>
    await import('./bag_shopping_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalHotspotCircleIcon = lazy(
  async () =>
    await import('./signal_hotspot_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChekmarkReadIcon = lazy(
  async () =>
    await import('./message_chekmark_read.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncArrowsSquareCopyIcon = lazy(
  async () =>
    await import('./sync_arrows_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageLineMinimalSquareIcon = lazy(
  async () =>
    await import('./message_line_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TildaSquareIcon = lazy(
  async () =>
    await import('./tilda_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareSignalSquareCheckmarkIcon = lazy(
  async () =>
    await import('./share_signal_square_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentCursorIcon = lazy(
  async () =>
    await import('./item_document_cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuMoreCircleIcon = lazy(
  async () =>
    await import('./item_menu_more_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSelectIcon = lazy(
  async () =>
    await import('./settings_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkUnlinkIcon = lazy(
  async () =>
    await import('./broken_link_unlink.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMuteSlashIcon = lazy(
  async () =>
    await import('./microphone_mute_slash.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteRemoveIcon = lazy(
  async () =>
    await import('./bookmark_favorite_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CupSquareIcon = lazy(
  async () =>
    await import('./cup_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorVerticalClickSquareIcon = lazy(
  async () =>
    await import('./cursor_vertical_click_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatEditPenCopyIcon = lazy(
  async () =>
    await import('./message_chat_edit_pen_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCopyAddPlusIcon = lazy(
  async () =>
    await import('./select_copy_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HashtagSquareIcon = lazy(
  async () =>
    await import('./hashtag_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyMoveObjectIcon = lazy(
  async () =>
    await import('./copy_move_object.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingSqaureIcon = lazy(
  async () =>
    await import('./setting_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornTrumpetIcon = lazy(
  async () =>
    await import('./horn_trumpet.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InboxUploadArrowUpCircleIcon = lazy(
  async () =>
    await import('./inbox_upload_arrow_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnalyticsChartRightBigIcon = lazy(
  async () =>
    await import('./analytics_chart_right_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LeftIcon = lazy(
  async () =>
    await import('./left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteRemoveDeleteIcon = lazy(
  async () =>
    await import('./bookmark_favorite_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterMailSquareIcon = lazy(
  async () =>
    await import('./letter_mail_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BulletListTextIcon = lazy(
  async () =>
    await import('./bullet_list_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinAddPlusCircleIcon = lazy(
  async () =>
    await import('./trash_bin_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteDeleteCrossIcon = lazy(
  async () =>
    await import('./star_favorite_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuSquareIcon = lazy(
  async () =>
    await import('./item_menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeCopyIcon = lazy(
  async () =>
    await import('./alarm_clock_time_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookCopyIcon = lazy(
  async () =>
    await import('./book_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionCircleCopyIcon = lazy(
  async () =>
    await import('./arrow_direction_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareArrowIcon = lazy(
  async () =>
    await import('./share_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleIcon = lazy(
  async () =>
    await import('./bell_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorAngleRightSquareIcon = lazy(
  async () =>
    await import('./cursor_angle_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowSquareCopyIcon = lazy(
  async () =>
    await import('./rotate_arrow_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetRemoveDeliteIcon = lazy(
  async () =>
    await import('./target_remove_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinRestoreBackUpArrowIcon = lazy(
  async () =>
    await import('./trash_bin_restore_back_up_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotepadRightAngleIcon = lazy(
  async () =>
    await import('./notepad_right_angle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchArrowUpIcon = lazy(
  async () =>
    await import('./clock_watch_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashbardMinimalSquareIcon = lazy(
  async () =>
    await import('./dashbard_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeSettingEditFilterIcon = lazy(
  async () =>
    await import('./search_loupe_setting_edit_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectPlusAddSquareIcon = lazy(
  async () =>
    await import('./object_plus_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldSettingIcon = lazy(
  async () =>
    await import('./shield_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarModernAddPlusIcon = lazy(
  async () =>
    await import('./calendar_modern_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxIcon = lazy(
  async () =>
    await import('./checkbox.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCheckmarkCopyIcon = lazy(
  async () =>
    await import('./fingerprint_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuHeartIcon = lazy(
  async () =>
    await import('./menu_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalRightBlankBigIcon = lazy(
  async () =>
    await import('./send_horizontal_right_blank_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalCheckmarkCirckeIcon = lazy(
  async () =>
    await import('./wifi_signal_checkmark_circke.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonSleepModeOffIcon = lazy(
  async () =>
    await import('./moon_sleep_mode_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitDoorLogOutIcon = lazy(
  async () =>
    await import('./exit_door_log_out.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassCrossLocationCircleIcon = lazy(
  async () =>
    await import('./compass_cross_location_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteSquareCopyIcon = lazy(
  async () =>
    await import('./heart_favorite_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphQuoteIcon = lazy(
  async () =>
    await import('./paragraph_quote.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShapesOblectTriangleCircleSquareIcon = lazy(
  async () =>
    await import('./shapes_oblect_triangle_circle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellMinusIcon = lazy(
  async () =>
    await import('./bell_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrintDeleteIcon = lazy(
  async () =>
    await import('./printer_print_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageCopyIcon = lazy(
  async () =>
    await import('./picture_image_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesCircleCopyIcon = lazy(
  async () =>
    await import('./settings_switches_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornTrumpetSquareIcon = lazy(
  async () =>
    await import('./horn_trumpet_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AsteriskRotateArrowIcon = lazy(
  async () =>
    await import('./asterisk_rotate_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalShareCrossSquareIcon = lazy(
  async () =>
    await import('./signal_share_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListBulletsIcon = lazy(
  async () =>
    await import('./list_bullets.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldMinusIcon = lazy(
  async () =>
    await import('./shield_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarReturnArrowIcon = lazy(
  async () =>
    await import('./calendar_return_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureUploadArrowIcon = lazy(
  async () =>
    await import('./image_picture_upload_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDateListStyleIcon = lazy(
  async () =>
    await import('./calendar_date_list_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardSquareCopyIcon = lazy(
  async () =>
    await import('./dashboard_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightBulbIcon = lazy(
  async () =>
    await import('./light_bulb.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartsCopyIcon = lazy(
  async () =>
    await import('./charts_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveIcon = lazy(
  async () =>
    await import('./floppy_save.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshReloadLoadingIcon = lazy(
  async () =>
    await import('./refresh_reload_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockWarningIcon = lazy(
  async () =>
    await import('./alarm_clock_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureLoadingIcon = lazy(
  async () =>
    await import('./image_picture_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorButtonIcon = lazy(
  async () =>
    await import('./cursor_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarIcon = lazy(
  async () =>
    await import('./ear.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InboxMailDownloadIcon = lazy(
  async () =>
    await import('./inbox_mail_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorComputerCircleIcon = lazy(
  async () =>
    await import('./monitor_computer_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailSquareIcon = lazy(
  async () =>
    await import('./email_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernStarIcon = lazy(
  async () =>
    await import('./image_picture_modern_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesImagesPapersAddPlusIcon = lazy(
  async () =>
    await import('./slides_images_papers_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSearchIcon = lazy(
  async () =>
    await import('./object_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuAddCircleIcon = lazy(
  async () =>
    await import('./menu_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesIcon = lazy(
  async () =>
    await import('./chat_messages.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackspaceCheckmarkIcon = lazy(
  async () =>
    await import('./backspace_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleAddPlusCircleIcon = lazy(
  async () =>
    await import('./bell_style_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMenuCircleIcon = lazy(
  async () =>
    await import('./edit_pen_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextPinIcon = lazy(
  async () =>
    await import('./item_text_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowCircleLoginIcon = lazy(
  async () =>
    await import('./arrow_circle_login.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingWaitingIcon = lazy(
  async () =>
    await import('./loading_waiting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiNetworkNoSignalAddSquareIcon = lazy(
  async () =>
    await import('./wifi_network_no_signal_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMinimalCircleSquareIcon = lazy(
  async () =>
    await import('./edit_pen_minimal_circle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderMacIconIcon = lazy(
  async () =>
    await import('./finder_mac_icon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCheckmarkItemLeftDownIcon = lazy(
  async () =>
    await import('./copy_checkmark_item_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemBookmarkTextIcon = lazy(
  async () =>
    await import('./item_bookmark_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTuckedCornerZipIcon = lazy(
  async () =>
    await import('./document_tucked_corner_zip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnalyticsChartSquareCopyIcon = lazy(
  async () =>
    await import('./analytics_chart_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardHideIcon = lazy(
  async () =>
    await import('./keyboard_hide.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AppNotificationIcon = lazy(
  async () =>
    await import('./app_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecStyleCircleIcon = lazy(
  async () =>
    await import('./rec_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendMinimalVerticalSquareIcon = lazy(
  async () =>
    await import('./send_minimal_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputTextCircleIcon = lazy(
  async () =>
    await import('./input_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandCursorRightSquareIcon = lazy(
  async () =>
    await import('./hand_cursor_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TriangleWarningIcon = lazy(
  async () =>
    await import('./triangle_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesIcon = lazy(
  async () =>
    await import('./settings_switches.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PositionTargetIcon = lazy(
  async () =>
    await import('./position_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AddPlusMinusZoomZoomOutIcon = lazy(
  async () =>
    await import('./add_plus_minus_zoom_zoom_out.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCrossIcon = lazy(
  async () =>
    await import('./pin_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleRefreshIcon = lazy(
  async () =>
    await import('./calendar_schedule_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxCheckmarkPlusIcon = lazy(
  async () =>
    await import('./checkbox_checkmark_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropDownMenuIcon = lazy(
  async () =>
    await import('./drop_down_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandMinimalCircleIcon = lazy(
  async () =>
    await import('./hand_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorArrowClickCircleIcon = lazy(
  async () =>
    await import('./cursor_arrow_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockIcon = lazy(
  async () =>
    await import('./lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyMiddleCircleIcon = lazy(
  async () =>
    await import('./key_middle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageMicrophoneMicRecIcon = lazy(
  async () =>
    await import('./message_microphone_mic_rec.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListCheckmarkDocumentTextIcon = lazy(
  async () =>
    await import('./task_list_checkmark_document_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetSelectSquareIcon = lazy(
  async () =>
    await import('./target_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationHexagonIcon = lazy(
  async () =>
    await import('./bell_notification_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TriangleDropIcon = lazy(
  async () =>
    await import('./triangle_drop.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectMoreDotsIcon = lazy(
  async () =>
    await import('./object_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleDownloadBlankIcon = lazy(
  async () =>
    await import('./bin_trash_style_download_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrossDeleteCircleIcon = lazy(
  async () =>
    await import('./cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextAButtonSquareIcon = lazy(
  async () =>
    await import('./text_a_button_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockDottedLineIcon = lazy(
  async () =>
    await import('./lock_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListDocumentTextIcon = lazy(
  async () =>
    await import('./task_list_document_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeCircleIcon = lazy(
  async () =>
    await import('./eye_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoneCheckCheckmarkIcon = lazy(
  async () =>
    await import('./done_check_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftCircleIcon = lazy(
  async () =>
    await import('./cursor_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardIcon = lazy(
  async () =>
    await import('./keyboard.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkBrokernModernSquareIcon = lazy(
  async () =>
    await import('./link_brokern_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionsCircleIcon = lazy(
  async () =>
    await import('./questions_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCircleIcon = lazy(
  async () =>
    await import('./loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningRetryAlertIcon = lazy(
  async () =>
    await import('./warning_retry_alert.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotesCrossDeleteIcon = lazy(
  async () =>
    await import('./notes_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RepeatArrowSquareIcon = lazy(
  async () =>
    await import('./repeat_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryOpenLineIcon = lazy(
  async () =>
    await import('./library_open_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldTargetIcon = lazy(
  async () =>
    await import('./shield_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PicturesSquareIcon = lazy(
  async () =>
    await import('./pictures_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortstyleBigIcon = lazy(
  async () =>
    await import('./filter_sortstyle_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemRightTopIcon = lazy(
  async () =>
    await import('./copy_item_right_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextMinusDeleteIcon = lazy(
  async () =>
    await import('./item_text_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListCrossDeleteCopyIcon = lazy(
  async () =>
    await import('./check_list_cross_delete_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorBigClickLoadingIcon = lazy(
  async () =>
    await import('./cursor_big_click_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadAddPlusSquareIcon = lazy(
  async () =>
    await import('./download_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListInfoIcon = lazy(
  async () =>
    await import('./task_list_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCrossIcon = lazy(
  async () =>
    await import('./loading_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendAngelSquareIcon = lazy(
  async () =>
    await import('./send_angel_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepModeMoonDotsIcon = lazy(
  async () =>
    await import('./sleep_mode_moon_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeCheckmarkIcon = lazy(
  async () =>
    await import('./alarm_clock_time_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteDisabledSquareIcon = lazy(
  async () =>
    await import('./delete_disabled_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagStyleCircleIcon = lazy(
  async () =>
    await import('./flag_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckTodoIcon = lazy(
  async () =>
    await import('./check_todo.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingSquareCopyIcon = lazy(
  async () =>
    await import('./setting_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickSquareCopyIcon = lazy(
  async () =>
    await import('./cursor_click_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicRecCircleCopyIcon = lazy(
  async () =>
    await import('./microphone_mic_rec_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCheckmarkCopyIcon = lazy(
  async () =>
    await import('./copy_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageCrossDeleleteIcon = lazy(
  async () =>
    await import('./chat_message_cross_delelete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UbeSphereDIcon = lazy(
  async () =>
    await import('./ube_sphere_d.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardDottedLineCircleIcon = lazy(
  async () =>
    await import('./dashboard_dotted_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopySquareStyleCircleIcon = lazy(
  async () =>
    await import('./copy_square_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrintButtonSquareIcon = lazy(
  async () =>
    await import('./print_button_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowIcon = lazy(
  async () =>
    await import('./arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeTimerFastIcon = lazy(
  async () =>
    await import('./clock_time_timer_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshPlusArrowDotsIcon = lazy(
  async () =>
    await import('./refresh_plus_arrow_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CertificateStarIcon = lazy(
  async () =>
    await import('./certificate_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrinSettingIcon = lazy(
  async () =>
    await import('./printer_prin_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeTimerIcon = lazy(
  async () =>
    await import('./alarm_clock_time_timer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeynoteIcon = lazy(
  async () =>
    await import('./keynote.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartCheckmarkIcon = lazy(
  async () =>
    await import('./heart_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteDeleteCrossSquareIcon = lazy(
  async () =>
    await import('./star_favorite_delete_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListStickerIcon = lazy(
  async () =>
    await import('./check_list_sticker.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarStickerIcon = lazy(
  async () =>
    await import('./calendar_sticker.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCheckmarkIcon = lazy(
  async () =>
    await import('./fingerprint_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateRefreshLoadingIcon = lazy(
  async () =>
    await import('./rotate_refresh_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectWarninngIcon = lazy(
  async () =>
    await import('./cursor_select_warninng.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TranslateRemoveDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./translate_remove_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotesMoreIcon = lazy(
  async () =>
    await import('./notes_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserBigIcon = lazy(
  async () =>
    await import('./user_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipAttachmentIcon = lazy(
  async () =>
    await import('./clip_attachment.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchRefreshReloadLoadingIcon = lazy(
  async () =>
    await import('./clock_watch_refresh_reload_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotepadTitleIcon = lazy(
  async () =>
    await import('./notepad_title.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapMinusSquareIcon = lazy(
  async () =>
    await import('./search_loap_minus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowBrowserModernIcon = lazy(
  async () =>
    await import('./window_browser_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeRefreshIcon = lazy(
  async () =>
    await import('./clock_time_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernRightBlankIcon = lazy(
  async () =>
    await import('./message_chat_modern_right_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionCheckmarkCircleIcon = lazy(
  async () =>
    await import('./question_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputIcon = lazy(
  async () =>
    await import('./input.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudCircleIcon = lazy(
  async () =>
    await import('./cloud_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkSqaureIcon = lazy(
  async () =>
    await import('./attachment_link_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkRemoveDeleteCircleIcon = lazy(
  async () =>
    await import('./link_remove_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsUpSquareIcon = lazy(
  async () =>
    await import('./thumbs_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendVerticalLineBigIcon = lazy(
  async () =>
    await import('./send_vertical_line_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeTimerFastIcon = lazy(
  async () =>
    await import('./alarm_clock_time_timer_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentMoreDotsIcon = lazy(
  async () =>
    await import('./document_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistItemCrossRemoveBigIcon = lazy(
  async () =>
    await import('./tasklist_item_cross_remove_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkCircleIcon = lazy(
  async () =>
    await import('./checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TvCircleMonitorIcon = lazy(
  async () =>
    await import('./tv_circle_monitor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveCollectionsEditIcon = lazy(
  async () =>
    await import('./mailbox_archive_collections_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SubstractGroupCopyIcon = lazy(
  async () =>
    await import('./substract_group_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemsGroupElementsIcon = lazy(
  async () =>
    await import('./items_group_elements.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveCheckmarkIcon = lazy(
  async () =>
    await import('./mailbox_archive_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchItemLeftTopSquareIcon = lazy(
  async () =>
    await import('./search_item_left_top_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListTasksDeleteCrossIcon = lazy(
  async () =>
    await import('./list_tasks_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveSquareIcon = lazy(
  async () =>
    await import('./mailbox_archive_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadSquareArrowIcon = lazy(
  async () =>
    await import('./download_square_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockShieldProtectionIcon = lazy(
  async () =>
    await import('./lock_shield_protection.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectSquareCopyIcon = lazy(
  async () =>
    await import('./cursor_select_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorPlusCheckmarkIcon = lazy(
  async () =>
    await import('./cursor_plus_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalCheckmarkCirceIcon = lazy(
  async () =>
    await import('./wifi_signal_checkmark_circe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NftSquareCopyIcon = lazy(
  async () =>
    await import('./nft_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PopUpInformationCloseMessageIcon = lazy(
  async () =>
    await import('./pop_up_information_close_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeDotsIcon = lazy(
  async () =>
    await import('./search_loupe_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMinimalRightSquareIcon = lazy(
  async () =>
    await import('./setting_minimal_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeBookmarkIcon = lazy(
  async () =>
    await import('./clock_time_bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingStyleIcon = lazy(
  async () =>
    await import('./loading_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarShareArrowIcon = lazy(
  async () =>
    await import('./calendar_share_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfornationInfoIcon = lazy(
  async () =>
    await import('./infornation_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EnterLoginCircleIcon = lazy(
  async () =>
    await import('./enter_login_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxDownloadIcon = lazy(
  async () =>
    await import('./mailbox_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingCircleSmallIcon = lazy(
  async () =>
    await import('./setting_circle_small.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HardDriveStorageCircleIcon = lazy(
  async () =>
    await import('./hard_drive_storage_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagCheckmarkCircleIcon = lazy(
  async () =>
    await import('./flag_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxTextItemIcon = lazy(
  async () =>
    await import('./mailbox_text_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterMailIcon = lazy(
  async () =>
    await import('./letter_mail.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepModeIcon = lazy(
  async () =>
    await import('./sleep_mode.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CodeIcon = lazy(
  async () =>
    await import('./code.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernNotificationIcon = lazy(
  async () =>
    await import('./message_chat_modern_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrossDeleteSquareIcon = lazy(
  async () =>
    await import('./cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleLineDownIcon = lazy(
  async () =>
    await import('./bin_trash_style_line_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendMailStyleCircleIcon = lazy(
  async () =>
    await import('./send_mail_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyIcon = lazy(
  async () =>
    await import('./floppy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickSelectCircleIcon = lazy(
  async () =>
    await import('./cursor_click_select_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxIncomingArrowLeftIcon = lazy(
  async () =>
    await import('./mailbox_incoming_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeMinusIcon = lazy(
  async () =>
    await import('./search_loupe_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditCheckmarkCircleIcon = lazy(
  async () =>
    await import('./pen_edit_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureCenterSquareIcon = lazy(
  async () =>
    await import('./image_picture_center_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingModernSquareCopyIcon = lazy(
  async () =>
    await import('./loading_modern_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadLoadingCircleIcon = lazy(
  async () =>
    await import('./upload_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyholeLoginLoadingCircleIcon = lazy(
  async () =>
    await import('./keyhole_login_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkSqaureCopyIcon = lazy(
  async () =>
    await import('./checkmark_sqaure_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsDownDislikeStyleSquareIcon = lazy(
  async () =>
    await import('./thumbs_down_dislike_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalIcon = lazy(
  async () =>
    await import('./dashboard_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinnedIcon = lazy(
  async () =>
    await import('./pinned.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CommandCmdIcon = lazy(
  async () =>
    await import('./command_cmd.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeMinimalAngleCircleIcon = lazy(
  async () =>
    await import('./resize_minimal_angle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlackboardSquareIcon = lazy(
  async () =>
    await import('./blackboard_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationCircleIcon = lazy(
  async () =>
    await import('./pin_location_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkSmallOctagonIcon = lazy(
  async () =>
    await import('./bookmark_small_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterMinusIcon = lazy(
  async () =>
    await import('./email_letter_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WakeCircleIcon = lazy(
  async () =>
    await import('./wake_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentClipIcon = lazy(
  async () =>
    await import('./document_clip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkModernHorizontalBigIcon = lazy(
  async () =>
    await import('./broken_link_modern_horizontal_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoBulletListTextIcon = lazy(
  async () =>
    await import('./info_bullet_list_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimePauseIcon = lazy(
  async () =>
    await import('./clock_time_pause.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackwardRearwardBackIcon = lazy(
  async () =>
    await import('./backward_rearward_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecMicRecordingCircleIcon = lazy(
  async () =>
    await import('./rec_mic_recording_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshRotateCircleIcon = lazy(
  async () =>
    await import('./refresh_rotate_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleMoreDotsIcon = lazy(
  async () =>
    await import('./calendar_schedule_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMiddleCircleIcon = lazy(
  async () =>
    await import('./dashboard_middle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightningModernOctagonIcon = lazy(
  async () =>
    await import('./lightning_modern_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareSquareIcon = lazy(
  async () =>
    await import('./share_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectTargetArrowSquareIcon = lazy(
  async () =>
    await import('./select_target_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectIcon = lazy(
  async () =>
    await import('./cursor_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarBlankLinkIcon = lazy(
  async () =>
    await import('./calendar_blank_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserOctagonIcon = lazy(
  async () =>
    await import('./user_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerPauseClockSquareIcon = lazy(
  async () =>
    await import('./timer_pause_clock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarsLightSparkleIcon = lazy(
  async () =>
    await import('./stars_light_sparkle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailsLetterMailIcon = lazy(
  async () =>
    await import('./emails_letter_mail.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCircleCopyIcon = lazy(
  async () =>
    await import('./fingerprint_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TerminalIcon = lazy(
  async () =>
    await import('./terminal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendMessageIcon = lazy(
  async () =>
    await import('./send_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCopyCheckmarkIcon = lazy(
  async () =>
    await import('./item_copy_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchLoapIcon = lazy(
  async () =>
    await import('./item_search_loap.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadArrowCircleIcon = lazy(
  async () =>
    await import('./download_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NoticeBoardTextIcon = lazy(
  async () =>
    await import('./notice_board_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintMoreIcon = lazy(
  async () =>
    await import('./fingerprint_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockLoadingIcon = lazy(
  async () =>
    await import('./time_clock_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingIcon = lazy(
  async () =>
    await import('./loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatRemoveDeleteCrossIcon = lazy(
  async () =>
    await import('./message_chat_remove_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemsStyleCircleIcon = lazy(
  async () =>
    await import('./menu_items_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectItemDeleteCrossIcon = lazy(
  async () =>
    await import('./select_item_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleDotsPluginIcon = lazy(
  async () =>
    await import('./circle_dots_plugin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseSquareIcon = lazy(
  async () =>
    await import('./mouse_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingVecticalBigIcon = lazy(
  async () =>
    await import('./setting_vectical_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraAddSquareIcon = lazy(
  async () =>
    await import('./camera_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BluetoothIcon = lazy(
  async () =>
    await import('./bluetooth.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerMenuIcon = lazy(
  async () =>
    await import('./sticker_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxSrarsIcon = lazy(
  async () =>
    await import('./mailbox_srars.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimerFastIcon = lazy(
  async () =>
    await import('./clock_timer_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationMoreDotsIcon = lazy(
  async () =>
    await import('./pin_location_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyVerticalSquareIcon = lazy(
  async () =>
    await import('./copy_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectCircleIcon = lazy(
  async () =>
    await import('./cursor_select_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteBookmarkSquareIcon = lazy(
  async () =>
    await import('./star_favorite_bookmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxCheckmarkCircleIcon = lazy(
  async () =>
    await import('./checkbox_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageMenuIcon = lazy(
  async () =>
    await import('./picture_image_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemFloppyDiskSquareIcon = lazy(
  async () =>
    await import('./item_floppy_disk_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncArrowsCircleIcon = lazy(
  async () =>
    await import('./sync_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailOneIcon = lazy(
  async () =>
    await import('./email_mail_one.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageLoadingIcon = lazy(
  async () =>
    await import('./chat_message_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldMinimalSquareIcon = lazy(
  async () =>
    await import('./shield_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeSquareIcon = lazy(
  async () =>
    await import('./home_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListAddIcon = lazy(
  async () =>
    await import('./task_list_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockDottedLineBigIcon = lazy(
  async () =>
    await import('./clock_dotted_line_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepTurnOffCircleIcon = lazy(
  async () =>
    await import('./sleep_turn_off_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCursorSquareIcon = lazy(
  async () =>
    await import('./item_text_cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BatteryChargingDotsIcon = lazy(
  async () =>
    await import('./battery_charging_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshReloadCheckMarkIcon = lazy(
  async () =>
    await import('./refresh_reload_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailIcon = lazy(
  async () =>
    await import('./email_mail.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMinimalSquareIcon = lazy(
  async () =>
    await import('./printer_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudDownloadIcon = lazy(
  async () =>
    await import('./cloud_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchIcon = lazy(
  async () =>
    await import('./search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotificationCheckmarkDoneIcon = lazy(
  async () =>
    await import('./notification_checkmark_done.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DiamondMinimalSquareIcon = lazy(
  async () =>
    await import('./diamond_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheduleLineIcon = lazy(
  async () =>
    await import('./calendar_chedule_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectClickCircleCopyIcon = lazy(
  async () =>
    await import('./cursor_select_click_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinBigIcon = lazy(
  async () =>
    await import('./pin_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentPlusIcon = lazy(
  async () =>
    await import('./document_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlackboardBusinessChartIcon = lazy(
  async () =>
    await import('./blackboard_business_chart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderRefreshRotateIcon = lazy(
  async () =>
    await import('./thunder_refresh_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeySquareIcon = lazy(
  async () =>
    await import('./key_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCutCrossRemoveDeleteIcon = lazy(
  async () =>
    await import('./item_cut_cross_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemCopyIcon = lazy(
  async () =>
    await import('./menu_item_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetAngleAttractionIcon = lazy(
  async () =>
    await import('./magnet_angle_attraction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemsMenuIcon = lazy(
  async () =>
    await import('./items_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NightModeDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./night_mode_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardSqaureIcon = lazy(
  async () =>
    await import('./dashboard_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentStatusDoneCheckmarkIcon = lazy(
  async () =>
    await import('./document_status_done_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagesPicturesCopyCopyIcon = lazy(
  async () =>
    await import('./images_pictures_copy_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeHiddenIcon = lazy(
  async () =>
    await import('./eye_hidden.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeMinusBoxIcon = lazy(
  async () =>
    await import('./search_loupe_minus_box.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasksRecMicrophoneIcon = lazy(
  async () =>
    await import('./tasks_rec_microphone.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxItemGridIcon = lazy(
  async () =>
    await import('./checkbox_item_grid.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoxCrossRemoveSelectIcon = lazy(
  async () =>
    await import('./box_cross_remove_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortStyleBigIcon = lazy(
  async () =>
    await import('./filter_sort_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectionCheckmarkCircleIcon = lazy(
  async () =>
    await import('./shield_protection_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextIcon = lazy(
  async () =>
    await import('./document_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagIcon = lazy(
  async () =>
    await import('./flag.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernMinusIcon = lazy(
  async () =>
    await import('./home_modern_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebPlayIcon = lazy(
  async () =>
    await import('./browser_web_play.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCircleIcon = lazy(
  async () =>
    await import('./folder_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchFastMinimalCircleIcon = lazy(
  async () =>
    await import('./search_fast_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentFileCurvedCornerIcon = lazy(
  async () =>
    await import('./document_file_curved_corner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectArrowTextEditIcon = lazy(
  async () =>
    await import('./object_arrow_text_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SeachCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./seach_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BatteryChargingMiddleIcon = lazy(
  async () =>
    await import('./battery_charging_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleAddIcon = lazy(
  async () =>
    await import('./calendar_schedule_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePhotoCopyLeftDownIcon = lazy(
  async () =>
    await import('./image_photo_copy_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLightClassicIcon = lazy(
  async () =>
    await import('./lamp_light_classic.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSquareAddIcon = lazy(
  async () =>
    await import('./menu_square_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonNightModeStarIcon = lazy(
  async () =>
    await import('./moon_night_mode_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyArrowIcon = lazy(
  async () =>
    await import('./copy_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonNightModeAddIcon = lazy(
  async () =>
    await import('./moon_night_mode_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListLinkIcon = lazy(
  async () =>
    await import('./check_list_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NoteDocumentClipAttachmentIcon = lazy(
  async () =>
    await import('./note_document_clip_attachment.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyDiskBlankRightDownIcon = lazy(
  async () =>
    await import('./floppy_disk_blank_right_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkSqaureIcon = lazy(
  async () =>
    await import('./checkmark_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipStyleCircleIcon = lazy(
  async () =>
    await import('./clip_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeIcon = lazy(
  async () =>
    await import('./eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LayersCircleIcon = lazy(
  async () =>
    await import('./layers_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashSquareIcon = lazy(
  async () =>
    await import('./bin_trash_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowEnterIcon = lazy(
  async () =>
    await import('./window_enter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediLibraryHorizontalIcon = lazy(
  async () =>
    await import('./medi_library_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageEditPenLineIcon = lazy(
  async () =>
    await import('./message_edit_pen_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChecklistTasksChechmarkSquareIcon = lazy(
  async () =>
    await import('./checklist_tasks_chechmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FullScreenArrowIcon = lazy(
  async () =>
    await import('./full_screen_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectIcon = lazy(
  async () =>
    await import('./select_object.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareCircleIcon = lazy(
  async () =>
    await import('./share_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeIcon = lazy(
  async () =>
    await import('./clock_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalCircleIcon = lazy(
  async () =>
    await import('./wifi_signal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendMessageSendShareIcon = lazy(
  async () =>
    await import('./send_message_send_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadMediaDownSquareIcon = lazy(
  async () =>
    await import('./download_media_down_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockArrowLeftCircleIcon = lazy(
  async () =>
    await import('./lock_arrow_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightbulbShineSquareIcon = lazy(
  async () =>
    await import('./lightbulb_shine_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatAnalyticsIcon = lazy(
  async () =>
    await import('./message_chat_analytics.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UndoArrowLeftCircleIcon = lazy(
  async () =>
    await import('./undo_arrow_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AtasklistStyleCutCrossedIcon = lazy(
  async () =>
    await import('./atasklist_style_cut_crossed.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDIcon = lazy(
  async () =>
    await import('./trash_bin_d.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapRotateArrowIcon = lazy(
  async () =>
    await import('./search_loap_rotate_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentEventIcon = lazy(
  async () =>
    await import('./document_event.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenRectangleIcon = lazy(
  async () =>
    await import('./edit_pen_rectangle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketAddPlusIcon = lazy(
  async () =>
    await import('./bracket_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashnoardCircleIcon = lazy(
  async () =>
    await import('./dashnoard_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectItemSquareIcon = lazy(
  async () =>
    await import('./cursor_select_item_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StatusMenuIcon = lazy(
  async () =>
    await import('./status_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeSqaureIcon = lazy(
  async () =>
    await import('./home_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCircleIcon = lazy(
  async () =>
    await import('./chart_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeNotificationIcon = lazy(
  async () =>
    await import('./alarm_clock_time_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAngleMinimalSquareIcon = lazy(
  async () =>
    await import('./link_angle_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornStyleOctagonIcon = lazy(
  async () =>
    await import('./horn_style_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsResizeIcon = lazy(
  async () =>
    await import('./windows_resize.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeWindowArrowIcon = lazy(
  async () =>
    await import('./resize_window_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraSquareIcon = lazy(
  async () =>
    await import('./camera_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PagesArrowSplitIcon = lazy(
  async () =>
    await import('./pages_arrow_split.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCurvedCornerLeftIcon = lazy(
  async () =>
    await import('./calendar_curved_corner_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeWindowArrowFullScreenCircleIcon = lazy(
  async () =>
    await import('./resize_window_arrow_full_screen_circle.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const MailboxSmallSquareIcon = lazy(
  async () =>
    await import('./mailbox_small_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBurgerSquareIcon = lazy(
  async () =>
    await import('./menu_burger_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CcCircleIcon = lazy(
  async () =>
    await import('./cc_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LayersSquareIcon = lazy(
  async () =>
    await import('./layers_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemArrowRightSquareIcon = lazy(
  async () =>
    await import('./item_arrow_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CropRotateIcon = lazy(
  async () =>
    await import('./crop_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotesSearchIcon = lazy(
  async () =>
    await import('./notes_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshSquareIcon = lazy(
  async () =>
    await import('./refresh_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesImagePicturesHorizontalBlankIcon = lazy(
  async () =>
    await import('./slides_image_pictures_horizontal_blank.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const EyeShowUpArrowIcon = lazy(
  async () =>
    await import('./eye_show_up_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockSqaureIcon = lazy(
  async () =>
    await import('./timer_clock_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockMoreIcon = lazy(
  async () =>
    await import('./time_clock_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoLoadingIcon = lazy(
  async () =>
    await import('./info_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileAddSquareIcon = lazy(
  async () =>
    await import('./user_profile_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerOffCrossRemoveDeletevIcon = lazy(
  async () =>
    await import('./timer_off_cross_remove_deletev.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PowerTurnOnIcon = lazy(
  async () =>
    await import('./power_turn_on.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernMinusDeleteIcon = lazy(
  async () =>
    await import('./message_chat_modern_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionsButtonAddPlusIcon = lazy(
  async () =>
    await import('./questions_button_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextAlignArrowLeftIcon = lazy(
  async () =>
    await import('./text_align_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatVoiceRecIcon = lazy(
  async () =>
    await import('./chat_voice_rec.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeLeftRightSquareIcon = lazy(
  async () =>
    await import('./resize_left_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarSchedulePareviousDateIcon = lazy(
  async () =>
    await import('./calendar_schedule_parevious_date.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyePlusMinusSqaureIcon = lazy(
  async () =>
    await import('./eye_plus_minus_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalednarArrowLeftCentreIcon = lazy(
  async () =>
    await import('./calednar_arrow_left_centre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryUploadIcon = lazy(
  async () =>
    await import('./media_library_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeSquareIcon = lazy(
  async () =>
    await import('./search_loupe_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AdCircleCopyIcon = lazy(
  async () =>
    await import('./ad_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryMinimalMoreIcon = lazy(
  async () =>
    await import('./media_library_minimal_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CertificateCheckMarkCircleIcon = lazy(
  async () =>
    await import('./certificate_check_mark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextImageSquareCopyIcon = lazy(
  async () =>
    await import('./item_text_image_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnchorSquareIcon = lazy(
  async () =>
    await import('./anchor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingOctagonCircleIcon = lazy(
  async () =>
    await import('./setting_octagon_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextItemListAddPlusIcon = lazy(
  async () =>
    await import('./text_item_list_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowItemMoreDotsIcon = lazy(
  async () =>
    await import('./window_item_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemRotateIcon = lazy(
  async () =>
    await import('./item_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistDocumentGroupIcon = lazy(
  async () =>
    await import('./tasklist_document_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CirclesPlusAddIcon = lazy(
  async () =>
    await import('./circles_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistAddIcon = lazy(
  async () =>
    await import('./tasklist_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorBrokenLineIcon = lazy(
  async () =>
    await import('./cursor_broken_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleMenuMoreCircleIcon = lazy(
  async () =>
    await import('./filter_style_menu_more_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketAddCheckmarkIcon = lazy(
  async () =>
    await import('./bracket_add_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrownStyleCircleIcon = lazy(
  async () =>
    await import('./crown_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeCrossIcon = lazy(
  async () =>
    await import('./clock_time_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerIcon = lazy(
  async () =>
    await import('./timer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrownLineCircleIcon = lazy(
  async () =>
    await import('./crown_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditATextIcon = lazy(
  async () =>
    await import('./pen_edit_a_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellBigIcon = lazy(
  async () =>
    await import('./bell_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteRemoveCheckmarkIcon = lazy(
  async () =>
    await import('./heart_favorite_remove_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandHorizontalSquareIcon = lazy(
  async () =>
    await import('./hand_horizontal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ASquareTextButtonEditIcon = lazy(
  async () =>
    await import('./a_square_text_button_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardSquareCopyIcon = lazy(
  async () =>
    await import('./keyboard_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleNotificationSquareIcon = lazy(
  async () =>
    await import('./bell_style_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorExitIcon = lazy(
  async () =>
    await import('./door_exit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarOctagonIcon = lazy(
  async () =>
    await import('./star_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailSelectIcon = lazy(
  async () =>
    await import('./email_mail_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeCheckmarkBoxIcon = lazy(
  async () =>
    await import('./search_loupe_checkmark_box.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneRecMicSqaureIcon = lazy(
  async () =>
    await import('./microphone_rec_mic_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropdownMenuItemCheckmarkIcon = lazy(
  async () =>
    await import('./dropdown_menu_item_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardOptionIcon = lazy(
  async () =>
    await import('./keyboard_option.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupElementsIcon = lazy(
  async () =>
    await import('./group_elements.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesCheckmarkIcon = lazy(
  async () =>
    await import('./settings_switches_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassLocationMapOffSquareIcon = lazy(
  async () =>
    await import('./compass_location_map_off_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendModernCircleIcon = lazy(
  async () =>
    await import('./send_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionModernBigIcon = lazy(
  async () =>
    await import('./arrow_direction_modern_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernStyleSquareIcon = lazy(
  async () =>
    await import('./image_picture_modern_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareMinimalSquareIcon = lazy(
  async () =>
    await import('./share_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldLockSquareIcon = lazy(
  async () =>
    await import('./shield_lock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningAlarmDeleteErrorIcon = lazy(
  async () =>
    await import('./warning_alarm_delete_error.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentImagePictureIcon = lazy(
  async () =>
    await import('./document_image_picture.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldModernOptionLinesIcon = lazy(
  async () =>
    await import('./shield_modern_option_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetArrowsIcon = lazy(
  async () =>
    await import('./target_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IosAppApplicationStarIcon = lazy(
  async () =>
    await import('./ios_app_application_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyArrowLedtDottedLineIcon = lazy(
  async () =>
    await import('./copy_arrow_ledt_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentBookmarkIcon = lazy(
  async () =>
    await import('./item_document_bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyholeLockProtectionSquareIcon = lazy(
  async () =>
    await import('./keyhole_lock_protection_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzlePlugInIcon = lazy(
  async () =>
    await import('./puzzle_plug_in.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderCrossRemoveIcon = lazy(
  async () =>
    await import('./window_finder_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphAlignTextIcon = lazy(
  async () =>
    await import('./paragraph_align_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrintPauseIcon = lazy(
  async () =>
    await import('./printer_print_pause.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchItemLeftSquareIcon = lazy(
  async () =>
    await import('./search_item_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyDisksCopyIcon = lazy(
  async () =>
    await import('./floppy_disks_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingIcon = lazy(
  async () =>
    await import('./setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsStretchArrowsIcon = lazy(
  async () =>
    await import('./windows_stretch_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderMinimizeArrowDownIcon = lazy(
  async () =>
    await import('./window_finder_minimize_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowSidebarLeftBlankIcon = lazy(
  async () =>
    await import('./window_sidebar_left_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicRecSquareIcon = lazy(
  async () =>
    await import('./microphone_mic_rec_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EnterLoginModernSquareIcon = lazy(
  async () =>
    await import('./enter_login_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BluetoothCircleOffIcon = lazy(
  async () =>
    await import('./bluetooth_circle_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageIcon = lazy(
  async () =>
    await import('./chat_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NftSquareIcon = lazy(
  async () =>
    await import('./nft_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsGearIcon = lazy(
  async () =>
    await import('./settings_gear.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageSqaureIcon = lazy(
  async () =>
    await import('./message_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorPlusAddIcon = lazy(
  async () =>
    await import('./cursor_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareLeftItemSquareIcon = lazy(
  async () =>
    await import('./share_left_item_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardStyleIcon = lazy(
  async () =>
    await import('./dashboard_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeShowVisibleIcon = lazy(
  async () =>
    await import('./eye_show_visible.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapLocationRemoveCrossIcon = lazy(
  async () =>
    await import('./compass_map_location_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeArrowCircleIcon = lazy(
  async () =>
    await import('./clock_time_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FeatherPenCircleEditIcon = lazy(
  async () =>
    await import('./feather_pen_circle_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatLeftHeartFavoriteIcon = lazy(
  async () =>
    await import('./message_chat_left_heart_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsUpLikeSquareIcon = lazy(
  async () =>
    await import('./thumbs_up_like_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintDeleteCrossIcon = lazy(
  async () =>
    await import('./fingerprint_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchShiningIcon = lazy(
  async () =>
    await import('./search_shining.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkBoxSquareIcon = lazy(
  async () =>
    await import('./checkmark_box_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentSelectIcon = lazy(
  async () =>
    await import('./document_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemArrowUpSquareIcon = lazy(
  async () =>
    await import('./item_arrow_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageMenuIcon = lazy(
  async () =>
    await import('./chat_message_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowDottedLineIcon = lazy(
  async () =>
    await import('./direction_arrow_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePuctureSelectIcon = lazy(
  async () =>
    await import('./image_pucture_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeTimerSleepSnoozeZzzIcon = lazy(
  async () =>
    await import('./alarm_clock_time_timer_sleep_snooze_zzz.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const TasklistModernLineIcon = lazy(
  async () =>
    await import('./tasklist_modern_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkRemoveCrossIcon = lazy(
  async () =>
    await import('./bookmark_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatClickIcon = lazy(
  async () =>
    await import('./message_chat_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextImageIcon = lazy(
  async () =>
    await import('./item_text_image.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDownloadArrowDownIcon = lazy(
  async () =>
    await import('./calendar_download_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartsBigIcon = lazy(
  async () =>
    await import('./hearts_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateRefreshIcon = lazy(
  async () =>
    await import('./rotate_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiArrowsCircleIcon = lazy(
  async () =>
    await import('./wifi_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveCircleCopyIcon = lazy(
  async () =>
    await import('./zip_archive_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagSqaureIcon = lazy(
  async () =>
    await import('./flag_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteNotificationIcon = lazy(
  async () =>
    await import('./star_favorite_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinEyeIcon = lazy(
  async () =>
    await import('./pin_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkCopyIcon = lazy(
  async () =>
    await import('./bookmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemLockCrossCircleIcon = lazy(
  async () =>
    await import('./item_lock_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrintOutputCheckmarkPlusIcon = lazy(
  async () =>
    await import('./print_output_checkmark_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenLeftTextCircleIcon = lazy(
  async () =>
    await import('./edit_pen_left_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSearchCopyIcon = lazy(
  async () =>
    await import('./object_search_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenubarArrowUpIcon = lazy(
  async () =>
    await import('./menubar_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TriangleSynRoatateArrowsIcon = lazy(
  async () =>
    await import('./triangle_syn_roatate_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageVoiceRecMicIcon = lazy(
  async () =>
    await import('./chat_message_voice_rec_mic.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoCheckmarkCircleIcon = lazy(
  async () =>
    await import('./info_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareArrowSquareIcon = lazy(
  async () =>
    await import('./share_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleNotificationCircleIcon = lazy(
  async () =>
    await import('./bell_style_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsCircleIcon = lazy(
  async () =>
    await import('./settings_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiWarningIcon = lazy(
  async () =>
    await import('./wifi_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TvAddPlusIcon = lazy(
  async () =>
    await import('./tv_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldCheckmarkReadIcon = lazy(
  async () =>
    await import('./shield_checkmark_read.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShrinkVerticalSquareIcon = lazy(
  async () =>
    await import('./shrink_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarBlankDateIcon = lazy(
  async () =>
    await import('./calendar_blank_date.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchMenuIcon = lazy(
  async () =>
    await import('./clock_watch_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeDeleteIcon = lazy(
  async () =>
    await import('./search_loupe_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampBigIcon = lazy(
  async () =>
    await import('./lamp_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureCircleCopyIcon = lazy(
  async () =>
    await import('./image_picture_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionAddPlusCircleIcon = lazy(
  async () =>
    await import('./question_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeQuestionIcon = lazy(
  async () =>
    await import('./search_loupe_question.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistTextIcon = lazy(
  async () =>
    await import('./tasklist_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintNotificationCircleIcon = lazy(
  async () =>
    await import('./fingerprint_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebIcon = lazy(
  async () =>
    await import('./browser_web.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCheckboxIcon = lazy(
  async () =>
    await import('./select_checkbox.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CutCopyIcon = lazy(
  async () =>
    await import('./cut_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheduleBackIcon = lazy(
  async () =>
    await import('./calendar_chedule_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveCircleIcon = lazy(
  async () =>
    await import('./zip_archive_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCenterCircleIcon = lazy(
  async () =>
    await import('./menu_center_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartUpArrowSquareIcon = lazy(
  async () =>
    await import('./chart_up_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileSettingIcon = lazy(
  async () =>
    await import('./user_profile_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectCopyPlusAddIcon = lazy(
  async () =>
    await import('./select_object_copy_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapCircleIcon = lazy(
  async () =>
    await import('./compass_map_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoudStorageCircleIcon = lazy(
  async () =>
    await import('./loud_storage_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheduleClockIcon = lazy(
  async () =>
    await import('./calendar_chedule_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeTimerIcon = lazy(
  async () =>
    await import('./clock_time_timer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LabelTagArrowCircleIcon = lazy(
  async () =>
    await import('./label_tag_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryIcon = lazy(
  async () =>
    await import('./media_library.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FireIcon = lazy(
  async () =>
    await import('./fire.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrintCheckmarkCircleIcon = lazy(
  async () =>
    await import('./print_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveCollectionsIcon = lazy(
  async () =>
    await import('./mailbox_archive_collections.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksAddPlusBottomLeftIcon = lazy(
  async () =>
    await import('./bookmarks_add_plus_bottom_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatMoreDotsIcon = lazy(
  async () =>
    await import('./message_chat_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardClockSquareIcon = lazy(
  async () =>
    await import('./dashboard_clock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareTransmitIcon = lazy(
  async () =>
    await import('./share_transmit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinMoreIcon = lazy(
  async () =>
    await import('./trash_bin_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookIcon = lazy(
  async () =>
    await import('./book.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowCircleDownIcon = lazy(
  async () =>
    await import('./arrow_circle_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareCopyStyleIcon = lazy(
  async () =>
    await import('./share_copy_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockDeleteCrossIcon = lazy(
  async () =>
    await import('./lock_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenCrossDeleteIcon = lazy(
  async () =>
    await import('./pen_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchBrokenLineSquareIcon = lazy(
  async () =>
    await import('./search_broken_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinQuestionIcon = lazy(
  async () =>
    await import('./marker_pin_question.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCursorSquareIcon = lazy(
  async () =>
    await import('./select_cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareNoSignalCircleIcon = lazy(
  async () =>
    await import('./share_no_signal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesEditIcon = lazy(
  async () =>
    await import('./chat_messages_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowDotsIcon = lazy(
  async () =>
    await import('./rotate_arrow_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintSquareIcon = lazy(
  async () =>
    await import('./fingerprint_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationIcon = lazy(
  async () =>
    await import('./bell_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeRemoveIcon = lazy(
  async () =>
    await import('./alarm_clock_time_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkLockCircleIcon = lazy(
  async () =>
    await import('./item_checkmark_lock_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SwitcherSettingUpSquareIcon = lazy(
  async () =>
    await import('./switcher_setting_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeIcon = lazy(
  async () =>
    await import('./alarm_clock_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailCheckmarlIcon = lazy(
  async () =>
    await import('./email_mail_checkmarl.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesCheckmarkPasswordIcon = lazy(
  async () =>
    await import('./chat_messages_checkmark_password.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartArrowRightCircleIcon = lazy(
  async () =>
    await import('./heart_arrow_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveStyleCircleIcon = lazy(
  async () =>
    await import('./zip_archive_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StopSignCircleIcon = lazy(
  async () =>
    await import('./stop_sign_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkHeartDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./bookmark_heart_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailSquareIcon = lazy(
  async () =>
    await import('./email_mail_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalDottedSquareIcon = lazy(
  async () =>
    await import('./dashboard_minimal_dotted_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageCameraIcon = lazy(
  async () =>
    await import('./chat_message_camera.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerIcon = lazy(
  async () =>
    await import('./sticker.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarAddPlusSquareIcon = lazy(
  async () =>
    await import('./star_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLoadingIcon = lazy(
  async () =>
    await import('./pin_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkSelectCopyIcon = lazy(
  async () =>
    await import('./bookmark_select_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterModernIcon = lazy(
  async () =>
    await import('./printer_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingDotsVerticalCircleIcon = lazy(
  async () =>
    await import('./loading_dots_vertical_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowIcon = lazy(
  async () =>
    await import('./rotate_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextSearchIcon = lazy(
  async () =>
    await import('./document_text_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCheckmarkSquareIcon = lazy(
  async () =>
    await import('./menu_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarEditPenIcon = lazy(
  async () =>
    await import('./calendar_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimalBigIcon = lazy(
  async () =>
    await import('./monitor_minimal_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCopyImageIcon = lazy(
  async () =>
    await import('./select_copy_image.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuModernCircleIcon = lazy(
  async () =>
    await import('./menu_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCheckmarkCopyIcon = lazy(
  async () =>
    await import('./document_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropdownMenuItemCheckmarkCopyIcon = lazy(
  async () =>
    await import('./dropdown_menu_item_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkUnlinkAttachmentCircleIcon = lazy(
  async () =>
    await import('./broken_link_unlink_attachment_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickersCopyIcon = lazy(
  async () =>
    await import('./stickers_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMoveFastLeftSquareIcon = lazy(
  async () =>
    await import('./cursor_move_fast_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendAngleCircleIcon = lazy(
  async () =>
    await import('./send_angle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleAddPlusIcon = lazy(
  async () =>
    await import('./email_letter_style_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DisableIcon = lazy(
  async () =>
    await import('./disable.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchTextLoupeSquareIcon = lazy(
  async () =>
    await import('./search_text_loupe_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardCircleCopyIcon = lazy(
  async () =>
    await import('./dashboard_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesSquareCopyIcon = lazy(
  async () =>
    await import('./cookies_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSwipeLeftIcon = lazy(
  async () =>
    await import('./cursor_swipe_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorVerticalCircleIcon = lazy(
  async () =>
    await import('./cursor_vertical_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingEditFilterSwitchesSwitchIcon = lazy(
  async () =>
    await import('./setting_edit_filter_switches_switch.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellCheckmarkIcon = lazy(
  async () =>
    await import('./bell_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernSquareIcon = lazy(
  async () =>
    await import('./image_picture_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListEyeDocumentTextIcon = lazy(
  async () =>
    await import('./task_list_eye_document_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipAddPlusBigIcon = lazy(
  async () =>
    await import('./clip_add_plus_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureIcon = lazy(
  async () =>
    await import('./image_picture.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkDoneCheckSquareIcon = lazy(
  async () =>
    await import('./checkmark_done_check_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InboxMaleIncomingCircleIcon = lazy(
  async () =>
    await import('./inbox_male_incoming_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WatchClockTimeIcon = lazy(
  async () =>
    await import('./watch_clock_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeFastIcon = lazy(
  async () =>
    await import('./clock_time_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingSquareIcon = lazy(
  async () =>
    await import('./setting_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListPaperCheckmarkIcon = lazy(
  async () =>
    await import('./list_paper_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardLoadingIcon = lazy(
  async () =>
    await import('./dashboard_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseCircleIcon = lazy(
  async () =>
    await import('./mouse_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HourglassCircleIcon = lazy(
  async () =>
    await import('./hourglass_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardClockTimeIcon = lazy(
  async () =>
    await import('./dashboard_clock_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCopyDeleteCrossErrorSquareIcon = lazy(
  async () =>
    await import('./item_copy_delete_cross_error_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockPasswordInputIcon = lazy(
  async () =>
    await import('./lock_password_input.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelTagDoubleIcon = lazy(
  async () =>
    await import('./bookmarks_label_tag_double.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkReadStyleSquareIcon = lazy(
  async () =>
    await import('./checkmark_read_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorStyleCircleIcon = lazy(
  async () =>
    await import('./cursor_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleBigSquareIcon = lazy(
  async () =>
    await import('./filter_style_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageTargetIcon = lazy(
  async () =>
    await import('./chat_message_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMinimalLeftCircleIcon = lazy(
  async () =>
    await import('./setting_minimal_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CropEditRefreshIcon = lazy(
  async () =>
    await import('./crop_edit_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyLinkCutIcon = lazy(
  async () =>
    await import('./copy_link_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImageCopySelectIcon = lazy(
  async () =>
    await import('./image_copy_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCurvedCornerIcon = lazy(
  async () =>
    await import('./calendar_curved_corner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapIcon = lazy(
  async () =>
    await import('./search_loap.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReplyLoadingArrowIcon = lazy(
  async () =>
    await import('./reply_loading_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordIcon = lazy(
  async () =>
    await import('./input_password.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectTexListIcon = lazy(
  async () =>
    await import('./object_tex_list.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardCutVerticalIcon = lazy(
  async () =>
    await import('./dashboard_cut_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleArrowRightIcon = lazy(
  async () =>
    await import('./calendar_schedule_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailSendArrowIcon = lazy(
  async () =>
    await import('./email_send_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationSignalSquareIcon = lazy(
  async () =>
    await import('./pin_location_signal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageAddPlusCopyIcon = lazy(
  async () =>
    await import('./chat_message_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectBigIcon = lazy(
  async () =>
    await import('./cursor_select_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoopArrowIcon = lazy(
  async () =>
    await import('./loop_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListMenuMoreIcon = lazy(
  async () =>
    await import('./task_list_menu_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleBigIcon = lazy(
  async () =>
    await import('./setting_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemHeartCircleIcon = lazy(
  async () =>
    await import('./item_heart_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorFastSquareIcon = lazy(
  async () =>
    await import('./cursor_fast_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailWarningIcon = lazy(
  async () =>
    await import('./email_mail_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlockDeleteStopIcon = lazy(
  async () =>
    await import('./block_delete_stop.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionCircleIcon = lazy(
  async () =>
    await import('./question_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCircleIcon = lazy(
  async () =>
    await import('./select_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefeshLoadingArrowsCircleIcon = lazy(
  async () =>
    await import('./refesh_loading_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorArrowAngleSquareIcon = lazy(
  async () =>
    await import('./cursor_arrow_angle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortTopSquareIcon = lazy(
  async () =>
    await import('./filter_sort_top_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBlockCheckmarkIcon = lazy(
  async () =>
    await import('./menu_block_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextLogoIcon = lazy(
  async () =>
    await import('./item_text_logo.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IdSquareIcon = lazy(
  async () =>
    await import('./id_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksFavoriteSqaureIcon = lazy(
  async () =>
    await import('./bookmarks_favorite_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarUndoArrowCenterIcon = lazy(
  async () =>
    await import('./calendar_undo_arrow_center.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserModernStyleStopIcon = lazy(
  async () =>
    await import('./user_modern_style_stop.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatCenterTextIcon = lazy(
  async () =>
    await import('./message_chat_center_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserSignalIcon = lazy(
  async () =>
    await import('./user_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScrollDownCircleIcon = lazy(
  async () =>
    await import('./scroll_down_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorAngleRightCircleIcon = lazy(
  async () =>
    await import('./cursor_angle_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarEditPenDotsIcon = lazy(
  async () =>
    await import('./calendar_edit_pen_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailEmailAddPlusIcon = lazy(
  async () =>
    await import('./mail_email_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenCircleIcon = lazy(
  async () =>
    await import('./edit_pen_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureTextEditIcon = lazy(
  async () =>
    await import('./image_picture_text_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextStarIcon = lazy(
  async () =>
    await import('./item_text_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketsCircleCopyIcon = lazy(
  async () =>
    await import('./brackets_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkSelectDottedLineIcon = lazy(
  async () =>
    await import('./checkmark_select_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchShineCircleCopyIcon = lazy(
  async () =>
    await import('./search_shine_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentArrowUpIcon = lazy(
  async () =>
    await import('./document_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudAddCircleIcon = lazy(
  async () =>
    await import('./cloud_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipAttachmentSquareIcon = lazy(
  async () =>
    await import('./clip_attachment_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCubeIcon = lazy(
  async () =>
    await import('./warning_cube.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsAppPlusIcon = lazy(
  async () =>
    await import('./windows_app_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintSqaureIcon = lazy(
  async () =>
    await import('./fingerprint_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowPopUpIcon = lazy(
  async () =>
    await import('./window_pop_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartMinimalAnalyticsCircleIcon = lazy(
  async () =>
    await import('./chart_minimal_analytics_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchEditCircleIcon = lazy(
  async () =>
    await import('./search_edit_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistArrowLeftCentreIcon = lazy(
  async () =>
    await import('./tasklist_arrow_left_centre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatMoreTextIcon = lazy(
  async () =>
    await import('./message_chat_more_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FileImportIcon = lazy(
  async () =>
    await import('./file_import.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinBoxSqaureIcon = lazy(
  async () =>
    await import('./bin_box_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepSnoozeZzzIcon = lazy(
  async () =>
    await import('./sleep_snooze_zzz.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteBinCircleIcon = lazy(
  async () =>
    await import('./trash_delete_bin_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupElementsTicketIcon = lazy(
  async () =>
    await import('./group_elements_ticket.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatTextLightIcon = lazy(
  async () =>
    await import('./message_chat_text_light.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCircleDottedLineStyleIcon = lazy(
  async () =>
    await import('./loading_circle_dotted_line_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyImageSelectIcon = lazy(
  async () =>
    await import('./copy_image_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSquareIcon = lazy(
  async () =>
    await import('./wifi_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendCursorSquareIcon = lazy(
  async () =>
    await import('./send_cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListRemoveDeleteCrossIcon = lazy(
  async () =>
    await import('./list_remove_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsRoundIcon = lazy(
  async () =>
    await import('./arrows_round.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteAddIcon = lazy(
  async () =>
    await import('./star_favorite_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LifeRingHelpSupportIcon = lazy(
  async () =>
    await import('./life_ring_help_support.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemPointsSquareIcon = lazy(
  async () =>
    await import('./item_points_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowRollUpArrowIcon = lazy(
  async () =>
    await import('./window_roll_up_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendMessageCircleIcon = lazy(
  async () =>
    await import('./send_message_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UnderlineCircleIcon = lazy(
  async () =>
    await import('./underline_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeWarningIcon = lazy(
  async () =>
    await import('./clock_time_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorInfoIcon = lazy(
  async () =>
    await import('./cursor_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DiamondPluginCircleIcon = lazy(
  async () =>
    await import('./diamond_plugin_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCheckmarkSquareIcon = lazy(
  async () =>
    await import('./loading_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpaceObjectSelectIcon = lazy(
  async () =>
    await import('./space_object_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleCircleIcon = lazy(
  async () =>
    await import('./bell_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenListCircleCopyIcon = lazy(
  async () =>
    await import('./edit_pen_list_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellRingIcon = lazy(
  async () =>
    await import('./bell_ring.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SeachMoreMenuSquareIcon = lazy(
  async () =>
    await import('./seach_more_menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordSquareIcon = lazy(
  async () =>
    await import('./input_password_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemLeftTopIcon = lazy(
  async () =>
    await import('./copy_item_left_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinOffSqaureIcon = lazy(
  async () =>
    await import('./marker_pin_off_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernBlankIcon = lazy(
  async () =>
    await import('./home_modern_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemClipCrossSquareIcon = lazy(
  async () =>
    await import('./item_clip_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartCircleModernIcon = lazy(
  async () =>
    await import('./heart_circle_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemEditSquareIcon = lazy(
  async () =>
    await import('./item_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionLoadingIcon = lazy(
  async () =>
    await import('./arrow_direction_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkUnlinkAttachmentSquareIcon = lazy(
  async () =>
    await import('./broken_link_unlink_attachment_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingRingIcon = lazy(
  async () =>
    await import('./loading_ring.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiNetworkSignalArrowLeftIcon = lazy(
  async () =>
    await import('./wifi_network_signal_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextAlignArrowRightIcon = lazy(
  async () =>
    await import('./text_align_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchZoomCheckmarkCircleIcon = lazy(
  async () =>
    await import('./search_zoom_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibrariesModernStyleIcon = lazy(
  async () =>
    await import('./libraries_modern_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextAlignCrossRemoveSquareIcon = lazy(
  async () =>
    await import('./item_text_align_cross_remove_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpaceObjectSelectCheckmarkIcon = lazy(
  async () =>
    await import('./space_object_select_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginCircleIcon = lazy(
  async () =>
    await import('./plugin_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitLoginDeclineIcon = lazy(
  async () =>
    await import('./exit_login_decline.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinSquareCopyIcon = lazy(
  async () =>
    await import('./pin_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeArowLeftIcon = lazy(
  async () =>
    await import('./clock_time_arow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinEditIcon = lazy(
  async () =>
    await import('./marker_pin_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkArrowShareIcon = lazy(
  async () =>
    await import('./bookmark_arrow_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadArrowsBigCircleIcon = lazy(
  async () =>
    await import('./upload_arrows_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSearchMoreCircleIcon = lazy(
  async () =>
    await import('./menu_search_more_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryDownloadIcon = lazy(
  async () =>
    await import('./library_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorTargetIcon = lazy(
  async () =>
    await import('./cursor_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortSquareIcon = lazy(
  async () =>
    await import('./filter_sort_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkCircleIcon = lazy(
  async () =>
    await import('./bookmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinBoxDownloadArrowDownIcon = lazy(
  async () =>
    await import('./bin_box_download_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksCircleIcon = lazy(
  async () =>
    await import('./bookmarks_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardSquareIcon = lazy(
  async () =>
    await import('./dashboard_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleSquareIcon = lazy(
  async () =>
    await import('./bell_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinStyleVerticalIcon = lazy(
  async () =>
    await import('./pin_style_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiNetworkSignalAddCircleIcon = lazy(
  async () =>
    await import('./wifi_network_signal_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleCrossMiddleIcon = lazy(
  async () =>
    await import('./bin_trash_style_cross_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageCircleIcon = lazy(
  async () =>
    await import('./chat_message_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCheckmarkSquareIcon = lazy(
  async () =>
    await import('./item_text_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCircleDottedLineIcon = lazy(
  async () =>
    await import('./loading_circle_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingRotateArrowIcon = lazy(
  async () =>
    await import('./loading_rotate_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessagesChatTextIcon = lazy(
  async () =>
    await import('./messages_chat_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartDiagramCropElementCrossDeleteIcon = lazy(
  async () =>
    await import('./chart_diagram_crop_element_cross_delete.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const ArrowUturnUpCrossIcon = lazy(
  async () =>
    await import('./arrow_uturn_up_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyTimeIcon = lazy(
  async () =>
    await import('./floppy_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseAngleIcon = lazy(
  async () =>
    await import('./mouse_angle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksRemoveIcon = lazy(
  async () =>
    await import('./bookmarks_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchZoomLoupeIcon = lazy(
  async () =>
    await import('./search_zoom_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayStyleCircleIcon = lazy(
  async () =>
    await import('./airplay_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoInfornationIcon = lazy(
  async () =>
    await import('./info_infornation.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignOutIcon = lazy(
  async () =>
    await import('./sign_out.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSquareIcon = lazy(
  async () =>
    await import('./cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarmodernStyleIcon = lazy(
  async () =>
    await import('./calendarmodern_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncSynchronizeCircleIcon = lazy(
  async () =>
    await import('./sync_synchronize_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLightIcon = lazy(
  async () =>
    await import('./lamp_light.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScaleMoveShareIcon = lazy(
  async () =>
    await import('./scale_move_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCheckmarkCircleIcon = lazy(
  async () =>
    await import('./clip_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinShineCircleIcon = lazy(
  async () =>
    await import('./pin_shine_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySquareBigIcon = lazy(
  async () =>
    await import('./floppy_square_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinItemCircleIcon = lazy(
  async () =>
    await import('./pin_item_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenLineCircleCopyIcon = lazy(
  async () =>
    await import('./edit_pen_line_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintSelectIcon = lazy(
  async () =>
    await import('./fingerprint_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleIcon = lazy(
  async () =>
    await import('./bin_trash_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWindowAdsIcon = lazy(
  async () =>
    await import('./browser_window_ads.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageTextCopyIcon = lazy(
  async () =>
    await import('./chat_message_text_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridSelectSquareIcon = lazy(
  async () =>
    await import('./grid_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveDesableIcon = lazy(
  async () =>
    await import('./floppy_save_desable.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkSelectIcon = lazy(
  async () =>
    await import('./bookmark_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrendUpdateLightningCircleIcon = lazy(
  async () =>
    await import('./trend_update_lightning_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageSadSmileIcon = lazy(
  async () =>
    await import('./chat_message_sad_smile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatHeartIcon = lazy(
  async () =>
    await import('./message_chat_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputArrowUpIcon = lazy(
  async () =>
    await import('./input_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalAngleBlankBigIcon = lazy(
  async () =>
    await import('./send_horizontal_angle_blank_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoNotificationIcon = lazy(
  async () =>
    await import('./info_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonNightModeStarCircleIcon = lazy(
  async () =>
    await import('./moon_night_mode_star_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncSynchronizeSquareLoadingIcon = lazy(
  async () =>
    await import('./sync_synchronize_square_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScissorsCutIcon = lazy(
  async () =>
    await import('./scissors_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchKeywordsIcon = lazy(
  async () =>
    await import('./item_search_keywords.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginLogoutSquareIcon = lazy(
  async () =>
    await import('./login_logout_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagSquareIcon = lazy(
  async () =>
    await import('./flag_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortMenuIcon = lazy(
  async () =>
    await import('./filter_sort_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectVerticalSquareIcon = lazy(
  async () =>
    await import('./cursor_select_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlayLoadingIcon = lazy(
  async () =>
    await import('./play_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagicWandIcon = lazy(
  async () =>
    await import('./magic_wand.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphRightIcon = lazy(
  async () =>
    await import('./paragraph_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentTextSquareIcon = lazy(
  async () =>
    await import('./item_document_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterMinimalMenuSquareIcon = lazy(
  async () =>
    await import('./filter_minimal_menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarTuckedCornerIcon = lazy(
  async () =>
    await import('./calendar_tucked_corner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrendUpdateLightningSquareIcon = lazy(
  async () =>
    await import('./trend_update_lightning_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RoadSignIcon = lazy(
  async () =>
    await import('./road_sign.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NightModeMoonMenuCircleIcon = lazy(
  async () =>
    await import('./night_mode_moon_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseLineIcon = lazy(
  async () =>
    await import('./home_house_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeCircleIcon = lazy(
  async () =>
    await import('./home_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditBigSquareIcon = lazy(
  async () =>
    await import('./pen_edit_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenCenterDottedLineIcon = lazy(
  async () =>
    await import('./edit_pen_center_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesCrossCircleIcon = lazy(
  async () =>
    await import('./cookies_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationSleepModeIcon = lazy(
  async () =>
    await import('./bell_notification_sleep_mode.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCopyAddPlusGroupIcon = lazy(
  async () =>
    await import('./document_copy_add_plus_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagRemoveCircleIcon = lazy(
  async () =>
    await import('./flag_remove_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlackboardChartIcon = lazy(
  async () =>
    await import('./blackboard_chart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LabelTagIcon = lazy(
  async () =>
    await import('./label_tag.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyClockIcon = lazy(
  async () =>
    await import('./floppy_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySquareIcon = lazy(
  async () =>
    await import('./floppy_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecRecordingSquareIcon = lazy(
  async () =>
    await import('./rec_recording_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoreBracketsSquareIcon = lazy(
  async () =>
    await import('./more_brackets_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./lock_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistStyleLineUpIcon = lazy(
  async () =>
    await import('./tasklist_style_line_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleSquareIcon = lazy(
  async () =>
    await import('./filter_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumpsUpIcon = lazy(
  async () =>
    await import('./thumps_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageEyeIcon = lazy(
  async () =>
    await import('./chat_message_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashRemoveCrossIcon = lazy(
  async () =>
    await import('./bin_trash_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LogOutCircleIcon = lazy(
  async () =>
    await import('./log_out_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingArrowCircleIcon = lazy(
  async () =>
    await import('./loading_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiCheckmarkIcon = lazy(
  async () =>
    await import('./wifi_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareArrowCircleCopyIcon = lazy(
  async () =>
    await import('./share_arrow_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterCheckmarkCircleCopyIcon = lazy(
  async () =>
    await import('./filter_checkmark_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxCheckmarkTranslateIcon = lazy(
  async () =>
    await import('./checkbox_checkmark_translate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinAddPlusCircleCopyIcon = lazy(
  async () =>
    await import('./pin_add_plus_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenDottedLineSquareIcon = lazy(
  async () =>
    await import('./edit_pen_dotted_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxBlankOctagonIcon = lazy(
  async () =>
    await import('./checkbox_blank_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleVerticalIcon = lazy(
  async () =>
    await import('./setting_style_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadLoadingArrowIcon = lazy(
  async () =>
    await import('./upload_loading_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoxClassicIcon = lazy(
  async () =>
    await import('./box_classic.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeAddPlusSquareIcon = lazy(
  async () =>
    await import('./eye_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCheckmarkTestOkCircleIcon = lazy(
  async () =>
    await import('./warning_checkmark_test_ok_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListStyleAddPlusIcon = lazy(
  async () =>
    await import('./task_list_style_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadSaveUploadIcon = lazy(
  async () =>
    await import('./download_save_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeCheckmarkShareIcon = lazy(
  async () =>
    await import('./resize_checkmark_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkDoneCheckCircleIcon = lazy(
  async () =>
    await import('./checkmark_done_check_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowUpIcon = lazy(
  async () =>
    await import('./arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxCheckmarkMoreIcon = lazy(
  async () =>
    await import('./checkbox_checkmark_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteDisabledRossHexagonIcon = lazy(
  async () =>
    await import('./delete_disabled_ross_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserStyleCircleIcon = lazy(
  async () =>
    await import('./user_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatTextStyleIcon = lazy(
  async () =>
    await import('./message_chat_text_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncArrowsCircleCopyIcon = lazy(
  async () =>
    await import('./sync_arrows_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CommandMinimalSquareIcon = lazy(
  async () =>
    await import('./command_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightbulbShineCircleIcon = lazy(
  async () =>
    await import('./lightbulb_shine_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLighrShineIcon = lazy(
  async () =>
    await import('./lamp_lighr_shine.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchRadarLocationIcon = lazy(
  async () =>
    await import('./search_radar_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemStarsSquareIcon = lazy(
  async () =>
    await import('./item_stars_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LanguageTranslateIcon = lazy(
  async () =>
    await import('./language_translate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectLoadingCopyIcon = lazy(
  async () =>
    await import('./cursor_select_loading_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarMenuIcon = lazy(
  async () =>
    await import('./calendar_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowTextClockTimeIcon = lazy(
  async () =>
    await import('./window_text_clock_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraRecIcon = lazy(
  async () =>
    await import('./camera_rec.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMenuCheckmarkCopyIcon = lazy(
  async () =>
    await import('./setting_menu_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReturnResetGoBackCircleIcon = lazy(
  async () =>
    await import('./return_reset_go_back_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketsSeparationSquareIcon = lazy(
  async () =>
    await import('./brackets_separation_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareIcon = lazy(
  async () =>
    await import('./share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginSelectInputIcon = lazy(
  async () =>
    await import('./plugin_select_input.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveCircleIcon = lazy(
  async () =>
    await import('./mailbox_archive_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSmallCircleIcon = lazy(
  async () =>
    await import('./wifi_small_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarModernShiningFavoriteNotificationSquareIcon = lazy(
  async () =>
    await import('./star_modern_shining_favorite_notification_square.svg').then(
      module => ({ default: module.ReactComponent })
    )
);
const ScanerBarcodeIcon = lazy(
  async () =>
    await import('./scaner_barcode.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleAlarmBigIcon = lazy(
  async () =>
    await import('./bell_style_alarm_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteLabelClockIcon = lazy(
  async () =>
    await import('./bookmark_favorite_label_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteBinCrossIcon = lazy(
  async () =>
    await import('./trash_delete_bin_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SatrCrossCircleIcon = lazy(
  async () =>
    await import('./satr_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BasketDeleteCrossSquareIcon = lazy(
  async () =>
    await import('./basket_delete_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockSquareIcon = lazy(
  async () =>
    await import('./lock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PhoneIcon = lazy(
  async () =>
    await import('./phone.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListTaskCrossDeleteIcon = lazy(
  async () =>
    await import('./check_list_task_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheckmarkCurvedOrnerIcon = lazy(
  async () =>
    await import('./calendar_checkmark_curved_orner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartSqaureIcon = lazy(
  async () =>
    await import('./chart_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockRefreshRotateIcon = lazy(
  async () =>
    await import('./lock_refresh_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarNotificationIcon = lazy(
  async () =>
    await import('./calendar_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentMoreLeftIcon = lazy(
  async () =>
    await import('./document_more_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnalyticsStyleSquareIcon = lazy(
  async () =>
    await import('./analytics_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./question_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareRssSquareIcon = lazy(
  async () =>
    await import('./share_rss_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonIcon = lazy(
  async () =>
    await import('./moon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleSearchIcon = lazy(
  async () =>
    await import('./calendar_schedule_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteMenuIcon = lazy(
  async () =>
    await import('./star_favorite_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlackboardCircleIcon = lazy(
  async () =>
    await import('./blackboard_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LteSignalIcon = lazy(
  async () =>
    await import('./lte_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayShareBigSquareIcon = lazy(
  async () =>
    await import('./airplay_share_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateCirlceAddPlusIcon = lazy(
  async () =>
    await import('./rotate_cirlce_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneRecMicCrossDeleteOffCircleIcon = lazy(
  async () =>
    await import('./microphone_rec_mic_cross_delete_off_circle.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const ArtboardIcon = lazy(
  async () =>
    await import('./artboard.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlashlightIcon = lazy(
  async () =>
    await import('./flashlight.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleClockTimeIcon = lazy(
  async () =>
    await import('./calendar_schedule_clock_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarBlankIcon = lazy(
  async () =>
    await import('./calendar_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCircleIcon = lazy(
  async () =>
    await import('./warning_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagCrossRemoveCircleIcon = lazy(
  async () =>
    await import('./flag_cross_remove_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridCircleIcon = lazy(
  async () =>
    await import('./grid_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteModernCrossSquareIcon = lazy(
  async () =>
    await import('./delete_modern_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalRightSquareIcon = lazy(
  async () =>
    await import('./send_horizontal_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCopyStatusRejectedCrossIcon = lazy(
  async () =>
    await import('./document_copy_status_rejected_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldPinIcon = lazy(
  async () =>
    await import('./shield_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldLoadingModernIcon = lazy(
  async () =>
    await import('./shield_loading_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterOctagonIcon = lazy(
  async () =>
    await import('./printer_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalBigIcon = lazy(
  async () =>
    await import('./send_horizontal_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShrinkVerticalCircleIcon = lazy(
  async () =>
    await import('./shrink_vertical_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardHideMoveIcon = lazy(
  async () =>
    await import('./keyboard_hide_move.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookLaptopCheckmarkCircleIcon = lazy(
  async () =>
    await import('./notebook_laptop_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCrossAddIcon = lazy(
  async () =>
    await import('./clip_cross_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicRecAddPlusSquareIcon = lazy(
  async () =>
    await import('./mic_rec_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxCrossDeleteRemoveIcon = lazy(
  async () =>
    await import('./mailbox_cross_delete_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecSquareIcon = lazy(
  async () =>
    await import('./voice_rec_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderInnerIcon = lazy(
  async () =>
    await import('./border_inner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthGlobeCrossIcon = lazy(
  async () =>
    await import('./earth_globe_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalenadrAddPlusIcon = lazy(
  async () =>
    await import('./calenadr_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashnoardSquareIcon = lazy(
  async () =>
    await import('./dashnoard_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistItemAddPlusSquareIcon = lazy(
  async () =>
    await import('./tasklist_item_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCopyCheckmarkDottedLineIcon = lazy(
  async () =>
    await import('./item_copy_checkmark_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardSquareIcon = lazy(
  async () =>
    await import('./keyboard_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzlePluginLeftIcon = lazy(
  async () =>
    await import('./puzzle_plugin_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterSearchLoupeIcon = lazy(
  async () =>
    await import('./printer_search_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingClassicStyleCircleIcon = lazy(
  async () =>
    await import('./loading_classic_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditDeletePenIcon = lazy(
  async () =>
    await import('./edit_delete_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxInboxCirckeIcon = lazy(
  async () =>
    await import('./mailbox_inbox_circke.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WatchClockTimeLoadingIcon = lazy(
  async () =>
    await import('./watch_clock_time_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalModernSquareIcon = lazy(
  async () =>
    await import('./dashboard_minimal_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsCircleIcon = lazy(
  async () =>
    await import('./arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuDropDownMenuUpIcon = lazy(
  async () =>
    await import('./menu_drop_down_menu_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsSyncCircleIcon = lazy(
  async () =>
    await import('./arrows_sync_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecStyleSquareIcon = lazy(
  async () =>
    await import('./rec_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLightCheckmarkIcon = lazy(
  async () =>
    await import('./lamp_light_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSmallOctagonIcon = lazy(
  async () =>
    await import('./wifi_small_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiOffCrossIcon = lazy(
  async () =>
    await import('./wifi_off_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderTextEnterIcon = lazy(
  async () =>
    await import('./finder_text_enter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrintOutputAddPlusIcon = lazy(
  async () =>
    await import('./print_output_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonSleepModeCheckmarkCircleIcon = lazy(
  async () =>
    await import('./moon_sleep_mode_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzleRightIcon = lazy(
  async () =>
    await import('./puzzle_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldArrowUpUploadIcon = lazy(
  async () =>
    await import('./shield_arrow_up_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinMinusIcon = lazy(
  async () =>
    await import('./pin_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAttachmentCircleIcon = lazy(
  async () =>
    await import('./link_attachment_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSquareCopyIcon = lazy(
  async () =>
    await import('./menu_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCheckmarkIcon = lazy(
  async () =>
    await import('./document_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskSheetIcon = lazy(
  async () =>
    await import('./task_sheet.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatStarFavoriteIcon = lazy(
  async () =>
    await import('./message_chat_star_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornTrumpetCircleIcon = lazy(
  async () =>
    await import('./horn_trumpet_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenOctagonIcon = lazy(
  async () =>
    await import('./edit_pen_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkSquareCopyIcon = lazy(
  async () =>
    await import('./attachment_link_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarCheckmarkCircleIcon = lazy(
  async () =>
    await import('./star_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureShineStarSquareIcon = lazy(
  async () =>
    await import('./image_picture_shine_star_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashDCrossDeleteIcon = lazy(
  async () =>
    await import('./bin_trash_d_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessagesHeartFavoriteLikeStoryIcon = lazy(
  async () =>
    await import('./messages_heart_favorite_like_story.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorUpSquareIcon = lazy(
  async () =>
    await import('./cursor_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryModernDIcon = lazy(
  async () =>
    await import('./library_modern_d.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeMenuSettingIcon = lazy(
  async () =>
    await import('./clock_time_menu_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagCheckmarkIcon = lazy(
  async () =>
    await import('./flag_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionCrossCircleIcon = lazy(
  async () =>
    await import('./arrow_direction_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteBinRefreshIcon = lazy(
  async () =>
    await import('./trash_delete_bin_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarModernIcon = lazy(
  async () =>
    await import('./calendar_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectionDotsTargetIcon = lazy(
  async () =>
    await import('./shield_protection_dots_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxCollectionsArchiveIcon = lazy(
  async () =>
    await import('./mailbox_collections_archive.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsPagesBookmarksIcon = lazy(
  async () =>
    await import('./windows_pages_bookmarks.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpellingCheckTextIcon = lazy(
  async () =>
    await import('./spelling_check_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapHeartCircleIcon = lazy(
  async () =>
    await import('./search_loap_heart_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const OsAppIconIcon = lazy(
  async () =>
    await import('./os_app_icon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IosAppApplicationIcon = lazy(
  async () =>
    await import('./ios_app_application.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticStatisticsIcon = lazy(
  async () =>
    await import('./chart_analytic_statistics.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackwardRearwardDotsIcon = lazy(
  async () =>
    await import('./backward_rearward_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeMinimalSquareIcon = lazy(
  async () =>
    await import('./resize_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EnterCircleIcon = lazy(
  async () =>
    await import('./enter_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteCircleIcon = lazy(
  async () =>
    await import('./delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryOpenModernStyleIcon = lazy(
  async () =>
    await import('./library_open_modern_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentAddPlusCopyIcon = lazy(
  async () =>
    await import('./document_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockSleepModeIcon = lazy(
  async () =>
    await import('./time_clock_sleep_mode.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageCheckamrkReadIcon = lazy(
  async () =>
    await import('./message_checkamrk_read.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintAddPlusCircleIcon = lazy(
  async () =>
    await import('./fingerprint_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShapesCircleSquareIcon = lazy(
  async () =>
    await import('./shapes_circle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowTextCheckmarkIcon = lazy(
  async () =>
    await import('./window_text_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartSelectIcon = lazy(
  async () =>
    await import('./heart_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowLinkIcon = lazy(
  async () =>
    await import('./window_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LeafletIcon = lazy(
  async () =>
    await import('./leaflet.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BluetoothCircleIcon = lazy(
  async () =>
    await import('./bluetooth_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureUploadArrowUpSqaureIcon = lazy(
  async () =>
    await import('./image_picture_upload_arrow_up_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampSparkIcon = lazy(
  async () =>
    await import('./lamp_spark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSearchMoreIcon = lazy(
  async () =>
    await import('./object_search_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernMoreDotsIcon = lazy(
  async () =>
    await import('./message_chat_modern_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayStyleIcon = lazy(
  async () =>
    await import('./airplay_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditLineCircleIcon = lazy(
  async () =>
    await import('./pen_edit_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkReadCircleIcon = lazy(
  async () =>
    await import('./checkmark_read_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowResizeDownIcon = lazy(
  async () =>
    await import('./window_resize_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyMinimalCircleIcon = lazy(
  async () =>
    await import('./copy_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageAddPlusIcon = lazy(
  async () =>
    await import('./chat_message_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextArrowEditSquareIcon = lazy(
  async () =>
    await import('./text_arrow_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoaderSqaureIcon = lazy(
  async () =>
    await import('./loader_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartUpdateIcon = lazy(
  async () =>
    await import('./chart_update.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileAddIcon = lazy(
  async () =>
    await import('./user_profile_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextEditIcon = lazy(
  async () =>
    await import('./document_text_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursroClickCircleIcon = lazy(
  async () =>
    await import('./cursro_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowCircleIcon = lazy(
  async () =>
    await import('./arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeOneIcon = lazy(
  async () =>
    await import('./eye_one.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BasketTrashDLinesIcon = lazy(
  async () =>
    await import('./basket_trash_d_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenItemTextCircleIcon = lazy(
  async () =>
    await import('./edit_pen_item_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitLogOutCopyIcon = lazy(
  async () =>
    await import('./exit_log_out_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListSquareIcon = lazy(
  async () =>
    await import('./task_list_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchMenuIcon = lazy(
  async () =>
    await import('./search_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowRollUpIcon = lazy(
  async () =>
    await import('./window_roll_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectedCrossDeleteIcon = lazy(
  async () =>
    await import('./shield_protected_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadArrowsBigSquareIcon = lazy(
  async () =>
    await import('./upload_arrows_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardUpMinimalCircleIcon = lazy(
  async () =>
    await import('./dashboard_up_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckLustTextIcon = lazy(
  async () =>
    await import('./check_lust_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteCheckmarkCircleIcon = lazy(
  async () =>
    await import('./heart_favorite_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeNineIcon = lazy(
  async () =>
    await import('./clock_time_nine.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropdownMenuItemIcon = lazy(
  async () =>
    await import('./dropdown_menu_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagStopOctagonCopyIcon = lazy(
  async () =>
    await import('./flag_stop_octagon_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTexSearchIcon = lazy(
  async () =>
    await import('./item_tex_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectLoadingIcon = lazy(
  async () =>
    await import('./cursor_select_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UndoArrowCrossIcon = lazy(
  async () =>
    await import('./undo_arrow_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCrossRemoveSquareIcon = lazy(
  async () =>
    await import('./pin_cross_remove_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListMinusDeleteIcon = lazy(
  async () =>
    await import('./check_list_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditDeleteItemIcon = lazy(
  async () =>
    await import('./edit_delete_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningIcon = lazy(
  async () =>
    await import('./warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintMinimalSquareIcon = lazy(
  async () =>
    await import('./fingerprint_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const OptionButtonCircleIcon = lazy(
  async () =>
    await import('./option_button_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupDeliteSelectIcon = lazy(
  async () =>
    await import('./group_delite_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterMailCircleCopyIcon = lazy(
  async () =>
    await import('./letter_mail_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleStarIcon = lazy(
  async () =>
    await import('./calendar_schedule_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuMoreDotsSquareIcon = lazy(
  async () =>
    await import('./item_menu_more_dots_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlusMinusIrcleIcon = lazy(
  async () =>
    await import('./plus_minus_ircle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TashBinVerticalLineIcon = lazy(
  async () =>
    await import('./tash_bin_vertical_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiNetworkSignalIcon = lazy(
  async () =>
    await import('./wifi_network_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageBadSmileIcon = lazy(
  async () =>
    await import('./chat_message_bad_smile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailMoreIcon = lazy(
  async () =>
    await import('./email_mail_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecMicStyleSquareIcon = lazy(
  async () =>
    await import('./voice_rec_mic_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureAddIcon = lazy(
  async () =>
    await import('./image_picture_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicRecCopyIcon = lazy(
  async () =>
    await import('./microphone_mic_rec_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListPageIcon = lazy(
  async () =>
    await import('./task_list_page.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureSelectCopyIcon = lazy(
  async () =>
    await import('./image_picture_select_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingRoundSquareIcon = lazy(
  async () =>
    await import('./setting_round_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PowerAppliancesShutdownIcon = lazy(
  async () =>
    await import('./power_appliances_shutdown.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BBoldCircleIcon = lazy(
  async () =>
    await import('./b_bold_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrivateIncognitoCircleIcon = lazy(
  async () =>
    await import('./private_incognito_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryUploadLineDownIcon = lazy(
  async () =>
    await import('./library_upload_line_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionSelectIcon = lazy(
  async () =>
    await import('./question_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoCheckmarkCircleCopyIcon = lazy(
  async () =>
    await import('./info_checkmark_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendFastCircleIcon = lazy(
  async () =>
    await import('./send_fast_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatWarningCopyIcon = lazy(
  async () =>
    await import('./message_chat_warning_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageModernIcon = lazy(
  async () =>
    await import('./chat_message_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavorieDeleteIcon = lazy(
  async () =>
    await import('./heart_favorie_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingHorizontalStyleSquareIcon = lazy(
  async () =>
    await import('./loading_horizontal_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextImageItemIcon = lazy(
  async () =>
    await import('./text_image_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnchorTriangleSelectIcon = lazy(
  async () =>
    await import('./anchor_triangle_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicRecIcon = lazy(
  async () =>
    await import('./microphone_mic_rec.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeKeyholeLockIcon = lazy(
  async () =>
    await import('./home_keyhole_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorHeartFavoriteBigIcon = lazy(
  async () =>
    await import('./monitor_heart_favorite_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveSquareIcon = lazy(
  async () =>
    await import('./zip_archive_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonCrossSqaureIcon = lazy(
  async () =>
    await import('./moon_cross_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DiamondLineIcon = lazy(
  async () =>
    await import('./diamond_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthGlobeMoreSettingIcon = lazy(
  async () =>
    await import('./earth_globe_more_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuEditMinusCircleIcon = lazy(
  async () =>
    await import('./menu_edit_minus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecVoiceStyleCircleIcon = lazy(
  async () =>
    await import('./rec_voice_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarMinimalStyleIcon = lazy(
  async () =>
    await import('./calendar_minimal_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BarCodeSearchSquareIcon = lazy(
  async () =>
    await import('./bar_code_search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AtMailSquareIcon = lazy(
  async () =>
    await import('./at_mail_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingEditIcon = lazy(
  async () =>
    await import('./setting_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShiledProtectonUploadArrowUpIcon = lazy(
  async () =>
    await import('./shiled_protecton_upload_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemCheckmarkIcon = lazy(
  async () =>
    await import('./copy_item_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarTasksIcon = lazy(
  async () =>
    await import('./calendar_tasks.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemNotificationIcon = lazy(
  async () =>
    await import('./item_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReplyShareCircleIcon = lazy(
  async () =>
    await import('./reply_share_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowUturnDownIcon = lazy(
  async () =>
    await import('./arrow_uturn_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayAudioArrowIcon = lazy(
  async () =>
    await import('./airplay_audio_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemUndoArrowSquareIcon = lazy(
  async () =>
    await import('./item_undo_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadAllArrowIcon = lazy(
  async () =>
    await import('./download_all_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListNumbersIcon = lazy(
  async () =>
    await import('./list_numbers.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortDeleteIcon = lazy(
  async () =>
    await import('./filter_sort_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchTopCircleIcon = lazy(
  async () =>
    await import('./search_top_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EnterLoginSquareIcon = lazy(
  async () =>
    await import('./enter_login_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextMoreDotsIcon = lazy(
  async () =>
    await import('./document_text_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorStyleClickCircleIcon = lazy(
  async () =>
    await import('./cursor_style_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockCircleIcon = lazy(
  async () =>
    await import('./alarm_clock_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteIcon = lazy(
  async () =>
    await import('./heart_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkBlockIcon = lazy(
  async () =>
    await import('./link_block.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphLeftIcon = lazy(
  async () =>
    await import('./paragraph_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCopyIcon = lazy(
  async () =>
    await import('./document_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BluetoothSquareCopyIcon = lazy(
  async () =>
    await import('./bluetooth_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzlePlugInAddIcon = lazy(
  async () =>
    await import('./puzzle_plug_in_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleCrossDeleteIcon = lazy(
  async () =>
    await import('./calendar_schedule_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimerFastCrossDeleteIcon = lazy(
  async () =>
    await import('./clock_timer_fast_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpellingCheckCheckmarkIcon = lazy(
  async () =>
    await import('./spelling_check_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchListLoapIcon = lazy(
  async () =>
    await import('./search_list_loap.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuCheckmarkCircleIcon = lazy(
  async () =>
    await import('./item_menu_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeMenuIcon = lazy(
  async () =>
    await import('./clock_time_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailMessageNotificationIcon = lazy(
  async () =>
    await import('./mail_message_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterMailCrossDeleteIcon = lazy(
  async () =>
    await import('./letter_mail_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentSearchIcon = lazy(
  async () =>
    await import('./document_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RightArrowIcon = lazy(
  async () =>
    await import('./right_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenStopIcon = lazy(
  async () =>
    await import('./pen_stop.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemAddDotsCheckmarkSquareIcon = lazy(
  async () =>
    await import('./item_add_dots_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxMoreMenuTranslateIcon = lazy(
  async () =>
    await import('./checkbox_more_menu_translate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridKeylineIconIcon = lazy(
  async () =>
    await import('./grid_keyline_icon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintSelectCopyIcon = lazy(
  async () =>
    await import('./fingerprint_select_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InterruptedLineCircleIcon = lazy(
  async () =>
    await import('./interrupted_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinlClassicSquareIcon = lazy(
  async () =>
    await import('./linl_classic_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemRefreshAddPlusIcon = lazy(
  async () =>
    await import('./item_refresh_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BombSquareIcon = lazy(
  async () =>
    await import('./bomb_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterOpenIcon = lazy(
  async () =>
    await import('./letter_open.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistCircleIcon = lazy(
  async () =>
    await import('./tasklist_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteBinCrossCircleIcon = lazy(
  async () =>
    await import('./delete_bin_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsCheckmarkLineIcon = lazy(
  async () =>
    await import('./windows_checkmark_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalCircleIcon = lazy(
  async () =>
    await import('./dashboard_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchShineIcon = lazy(
  async () =>
    await import('./search_shine.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackspaceDeleteButtonIcon = lazy(
  async () =>
    await import('./backspace_delete_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlignCenterIcon = lazy(
  async () =>
    await import('./align_center.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowsSquareIcon = lazy(
  async () =>
    await import('./rotate_arrows_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortAZIcon = lazy(
  async () =>
    await import('./filter_sort_a_z.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxInboxArchivePlusIcon = lazy(
  async () =>
    await import('./mailbox_inbox_archive_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowCrossDeleteIcon = lazy(
  async () =>
    await import('./direction_arrow_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowBrowserTabIcon = lazy(
  async () =>
    await import('./window_browser_tab.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArtboardPinIcon = lazy(
  async () =>
    await import('./artboard_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncModernSquareIcon = lazy(
  async () =>
    await import('./sync_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextSquareIcon = lazy(
  async () =>
    await import('./text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalLeftCircleIcon = lazy(
  async () =>
    await import('./dashboard_minimal_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleEditPenIcon = lazy(
  async () =>
    await import('./calendar_schedule_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeCheckmarkCircleIcon = lazy(
  async () =>
    await import('./eye_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagCrossIcon = lazy(
  async () =>
    await import('./flag_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateDIcon = lazy(
  async () =>
    await import('./rotate_d.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchCrossSquareIcon = lazy(
  async () =>
    await import('./search_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentLogoFileIcon = lazy(
  async () =>
    await import('./item_document_logo_file.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImageSquareIcon = lazy(
  async () =>
    await import('./image_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlignRightIcon = lazy(
  async () =>
    await import('./align_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkSmallSquareIcon = lazy(
  async () =>
    await import('./bookmark_small_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailIcon = lazy(
  async () =>
    await import('./email.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleOctagonIcon = lazy(
  async () =>
    await import('./bell_style_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserModernStyleSquareIcon = lazy(
  async () =>
    await import('./user_modern_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingControllerAngleCircleIcon = lazy(
  async () =>
    await import('./setting_controller_angle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NewsPaperIcon = lazy(
  async () =>
    await import('./news_paper.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSquareRoundedIcon = lazy(
  async () =>
    await import('./folder_square_rounded.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StatusCircleIcon = lazy(
  async () =>
    await import('./status_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipMoreIcon = lazy(
  async () =>
    await import('./clip_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteSquareIcon = lazy(
  async () =>
    await import('./bookmark_favorite_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginEnterArrowRightCircleIcon = lazy(
  async () =>
    await import('./login_enter_arrow_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DisableCrossIcon = lazy(
  async () =>
    await import('./disable_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseSignalIcon = lazy(
  async () =>
    await import('./mouse_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrintModernMinimalCircleIcon = lazy(
  async () =>
    await import('./printer_print_modern_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldDeletedCrossIcon = lazy(
  async () =>
    await import('./shield_deleted_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMinimalLeftSquareIcon = lazy(
  async () =>
    await import('./setting_minimal_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PodcastShareIcon = lazy(
  async () =>
    await import('./podcast_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortStyleDBigIcon = lazy(
  async () =>
    await import('./filter_sort_style_d_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarModernCheckmarkIcon = lazy(
  async () =>
    await import('./calendar_modern_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockProtectionCheckmarkIcon = lazy(
  async () =>
    await import('./lock_protection_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemWarningCircleIcon = lazy(
  async () =>
    await import('./item_warning_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ATextCircleIcon = lazy(
  async () =>
    await import('./a_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyCircleIcon = lazy(
  async () =>
    await import('./key_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickSelectIcon = lazy(
  async () =>
    await import('./cursor_click_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirdropIcon = lazy(
  async () =>
    await import('./airdrop.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendMailIcon = lazy(
  async () =>
    await import('./send_mail.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornTrumpetStyleSquareIcon = lazy(
  async () =>
    await import('./horn_trumpet_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSignalShareSquareIcon = lazy(
  async () =>
    await import('./item_signal_share_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageArrowInboxIcon = lazy(
  async () =>
    await import('./message_arrow_inbox.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraRecSqaureIcon = lazy(
  async () =>
    await import('./camera_rec_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCopyReplaceArrowsIcon = lazy(
  async () =>
    await import('./item_copy_replace_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatVoiceRecIcon = lazy(
  async () =>
    await import('./message_chat_voice_rec.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarStyleCircleIcon = lazy(
  async () =>
    await import('./star_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TriangleSquareCircleObjectsIcon = lazy(
  async () =>
    await import('./triangle_square_circle_objects.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentAddPlusIcon = lazy(
  async () =>
    await import('./document_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridLayoutMenuMoreIcon = lazy(
  async () =>
    await import('./grid_layout_menu_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationAlarmCopyIcon = lazy(
  async () =>
    await import('./bell_notification_alarm_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignQuestionIcon = lazy(
  async () =>
    await import('./sign_question.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleMoreDotrsIcon = lazy(
  async () =>
    await import('./calendar_schedule_more_dotrs.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingVerticalSquareCircleIcon = lazy(
  async () =>
    await import('./setting_vertical_square_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListTextCopyIcon = lazy(
  async () =>
    await import('./list_text_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionMenuMoreCircleIcon = lazy(
  async () =>
    await import('./question_menu_more_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsMoreIcon = lazy(
  async () =>
    await import('./windows_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListCrossDeleteIcon = lazy(
  async () =>
    await import('./task_list_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeCheckmarkIcon = lazy(
  async () =>
    await import('./home_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardStyleCircleIcon = lazy(
  async () =>
    await import('./dashboard_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadLineArrowUpCircleIcon = lazy(
  async () =>
    await import('./upload_line_arrow_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginSqaureCopyIcon = lazy(
  async () =>
    await import('./plugin_sqaure_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignInSendShareIcon = lazy(
  async () =>
    await import('./sign_in_send_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyMinimalStyleCircleIcon = lazy(
  async () =>
    await import('./copy_minimal_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellBigRingingIcon = lazy(
  async () =>
    await import('./bell_big_ringing.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCheckmarkIcon = lazy(
  async () =>
    await import('./copy_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecChartVoiceCircleIcon = lazy(
  async () =>
    await import('./rec_chart_voice_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassIcon = lazy(
  async () =>
    await import('./compass.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteIcon = lazy(
  async () =>
    await import('./bookmark_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorTimeIcon = lazy(
  async () =>
    await import('./cursor_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxCheckmarkCrossIcon = lazy(
  async () =>
    await import('./checkbox_checkmark_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserModernCircleIcon = lazy(
  async () =>
    await import('./user_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatLeftTextIcon = lazy(
  async () =>
    await import('./message_chat_left_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuDotsCircleIcon = lazy(
  async () =>
    await import('./menu_dots_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScanCheckSelectIcon = lazy(
  async () =>
    await import('./scan_check_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PowerOffCircleIcon = lazy(
  async () =>
    await import('./power_off_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextCopyIcon = lazy(
  async () =>
    await import('./text_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernOptionLinesIcon = lazy(
  async () =>
    await import('./home_modern_option_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropdownMenuDeleteIcon = lazy(
  async () =>
    await import('./dropdown_menu_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingSquareSquareIcon = lazy(
  async () =>
    await import('./setting_square_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemsWarningSquareIcon = lazy(
  async () =>
    await import('./items_warning_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrilliancePluginDIcon = lazy(
  async () =>
    await import('./brilliance_plugin_d.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelCircleIcon = lazy(
  async () =>
    await import('./bookmarks_label_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalRightBigIcon = lazy(
  async () =>
    await import('./send_horizontal_right_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinCheckmarkIcon = lazy(
  async () =>
    await import('./trash_bin_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectClickCircleIcon = lazy(
  async () =>
    await import('./cursor_select_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadSaveSquareSelectIcon = lazy(
  async () =>
    await import('./download_save_square_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMinimalLeftCircleIcon = lazy(
  async () =>
    await import('./cursor_minimal_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartDiagramCropElementIcon = lazy(
  async () =>
    await import('./chart_diagram_crop_element.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterCheckmarkCircleIcon = lazy(
  async () =>
    await import('./letter_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintIcon = lazy(
  async () =>
    await import('./fingerprint.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilteCrossDeleteIcon = lazy(
  async () =>
    await import('./filte_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationsIcon = lazy(
  async () =>
    await import('./bell_notifications.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PowerSettingsIcon = lazy(
  async () =>
    await import('./power_settings.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchTextTitleMiddleIcon = lazy(
  async () =>
    await import('./search_text_title_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ASquareTextButtonIcon = lazy(
  async () =>
    await import('./a_square_text_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeRemoveHideCrossSquareIcon = lazy(
  async () =>
    await import('./eye_remove_hide_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkLinesIcon = lazy(
  async () =>
    await import('./bookmark_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveUpIcon = lazy(
  async () =>
    await import('./floppy_save_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingStyleCopyIcon = lazy(
  async () =>
    await import('./loading_style_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailFavoriteHeartIcon = lazy(
  async () =>
    await import('./email_favorite_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandCursorSquareIcon = lazy(
  async () =>
    await import('./hand_cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernTextMenuIcon = lazy(
  async () =>
    await import('./message_chat_modern_text_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HelpWheelSquareIcon = lazy(
  async () =>
    await import('./help_wheel_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintSimpleSquareIcon = lazy(
  async () =>
    await import('./fingerprint_simple_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignCrossDeleteIcon = lazy(
  async () =>
    await import('./sign_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemImagePictureResizeUploadIcon = lazy(
  async () =>
    await import('./item_image_picture_resize_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnchorCircleIcon = lazy(
  async () =>
    await import('./anchor_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScaleMoveSlideIcon = lazy(
  async () =>
    await import('./scale_move_slide.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateLoadingSquareIcon = lazy(
  async () =>
    await import('./rotate_loading_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistMinusSquareIcon = lazy(
  async () =>
    await import('./tasklist_minus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureAddPlusIcon = lazy(
  async () =>
    await import('./image_picture_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItalicIcon = lazy(
  async () =>
    await import('./italic.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BagCircleEssentialInterfaceShoppingUiIcon = lazy(
  async () =>
    await import('./bag_circle_essential_interface_shopping_ui.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const PrinterStyleIcon = lazy(
  async () =>
    await import('./printer_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SphereCircleIcon = lazy(
  async () =>
    await import('./sphere_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./question_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeArrowRightIcon = lazy(
  async () =>
    await import('./clock_time_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsRefreshReloadLoadingIcon = lazy(
  async () =>
    await import('./arrows_refresh_reload_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListCheckmarkCircleIcon = lazy(
  async () =>
    await import('./item_list_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerPinIcon = lazy(
  async () =>
    await import('./sticker_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterModernSquareIcon = lazy(
  async () =>
    await import('./filter_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureRightSquareIcon = lazy(
  async () =>
    await import('./image_picture_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListCutDottedLineIcon = lazy(
  async () =>
    await import('./task_list_cut_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PowerOffStopSquareIcon = lazy(
  async () =>
    await import('./power_off_stop_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeadphonesBigIcon = lazy(
  async () =>
    await import('./headphones_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsDisableCloseAllIcon = lazy(
  async () =>
    await import('./windows_disable_close_all.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatEditTextIcon = lazy(
  async () =>
    await import('./message_chat_edit_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBoardSquarePlusAddIcon = lazy(
  async () =>
    await import('./menu_board_square_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeArrowIcon = lazy(
  async () =>
    await import('./clock_time_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationSquareIcon = lazy(
  async () =>
    await import('./bell_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserShareSignalCircleIcon = lazy(
  async () =>
    await import('./user_share_signal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapAddPlusIcon = lazy(
  async () =>
    await import('./compass_map_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesWindowsCircleIcon = lazy(
  async () =>
    await import('./slides_windows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatSmileIcon = lazy(
  async () =>
    await import('./message_chat_smile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenLineSquareCopyIcon = lazy(
  async () =>
    await import('./edit_pen_line_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinocularsSearchCircleIcon = lazy(
  async () =>
    await import('./binoculars_search_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeMoreMenuIcon = lazy(
  async () =>
    await import('./home_more_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UnwrapMobileIphoneIcon = lazy(
  async () =>
    await import('./unwrap_mobile_iphone.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FileDocumentCircleIcon = lazy(
  async () =>
    await import('./file_document_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveDownIcon = lazy(
  async () =>
    await import('./floppy_save_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarCheckMarkIcon = lazy(
  async () =>
    await import('./star_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationsCircleIcon = lazy(
  async () =>
    await import('./bell_notifications_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyLockWindowsIcon = lazy(
  async () =>
    await import('./copy_lock_windows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScanerBarcodeSelectIcon = lazy(
  async () =>
    await import('./scaner_barcode_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectClickIcon = lazy(
  async () =>
    await import('./cursor_select_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitLogOutArrowIcon = lazy(
  async () =>
    await import('./exit_log_out_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzlePlugInCheckmarkIcon = lazy(
  async () =>
    await import('./puzzle_plug_in_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardDottedLineCircleCopyIcon = lazy(
  async () =>
    await import('./dashboard_dotted_line_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeAddPlusShareIcon = lazy(
  async () =>
    await import('./resize_add_plus_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkSquareIcon = lazy(
  async () =>
    await import('./attachment_link_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageRecMicVoiceIcon = lazy(
  async () =>
    await import('./chat_message_rec_mic_voice.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinDotsSquareIcon = lazy(
  async () =>
    await import('./pin_dots_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextInputSquareIcon = lazy(
  async () =>
    await import('./text_input_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorDisableClickIcon = lazy(
  async () =>
    await import('./cursor_disable_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveCopyIcon = lazy(
  async () =>
    await import('./mailbox_archive_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzlePlugInSquareIcon = lazy(
  async () =>
    await import('./puzzle_plug_in_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCurvedCornerCrossDeleteRemoveIcon = lazy(
  async () =>
    await import('./calendar_curved_corner_cross_delete_remove.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const LinkSquareIcon = lazy(
  async () =>
    await import('./link_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectItemCheckmarkIcon = lazy(
  async () =>
    await import('./select_item_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeMenuIcon = lazy(
  async () =>
    await import('./search_loupe_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenItemSquareIcon = lazy(
  async () =>
    await import('./edit_pen_item_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarBackDataArrowLeftIcon = lazy(
  async () =>
    await import('./calendar_back_data_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectMoreDotsIcon = lazy(
  async () =>
    await import('./select_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextCircleIcon = lazy(
  async () =>
    await import('./text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PositionTargetSelectIcon = lazy(
  async () =>
    await import('./position_target_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoxCheckmarkSelectIcon = lazy(
  async () =>
    await import('./box_checkmark_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecSelectIcon = lazy(
  async () =>
    await import('./voice_rec_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchRefreshLoadingIcon = lazy(
  async () =>
    await import('./clock_watch_refresh_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchMoreMiddleIcon = lazy(
  async () =>
    await import('./search_more_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksFavoriteLabelIcon = lazy(
  async () =>
    await import('./bookmarks_favorite_label.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecVideoSquareIcon = lazy(
  async () =>
    await import('./rec_video_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextDeleteCrossCentreRightIcon = lazy(
  async () =>
    await import('./document_text_delete_cross_centre_right.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const KeyboardSqureIcon = lazy(
  async () =>
    await import('./keyboard_squre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookIcon = lazy(
  async () =>
    await import('./notebook.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAddPlusSquareIcon = lazy(
  async () =>
    await import('./chart_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCheckmarkCircleCopyIcon = lazy(
  async () =>
    await import('./warning_checkmark_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListCheckmarkIcon = lazy(
  async () =>
    await import('./check_list_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionAddPlusCircleIcon = lazy(
  async () =>
    await import('./direction_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScreenMirroringRollUpFullScreenIcon = lazy(
  async () =>
    await import('./screen_mirroring_roll_up_full_screen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheckmarkIcon = lazy(
  async () =>
    await import('./calendar_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningNotificationCircleIcon = lazy(
  async () =>
    await import('./warning_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCrossDeleateIcon = lazy(
  async () =>
    await import('./item_text_cross_deleate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagRemoveSquareIcon = lazy(
  async () =>
    await import('./flag_remove_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NoteDocumentClipAttachmentQuestionIcon = lazy(
  async () =>
    await import('./note_document_clip_attachment_question.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const InputPasswordDeleteCrossIcon = lazy(
  async () =>
    await import('./input_password_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMuteSquareIcon = lazy(
  async () =>
    await import('./microphone_mute_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReplyShareSquareIcon = lazy(
  async () =>
    await import('./reply_share_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectFavoriteHeartIcon = lazy(
  async () =>
    await import('./object_favorite_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapAddPlusZoomIcon = lazy(
  async () =>
    await import('./search_loap_add_plus_zoom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningAlarmCircleIcon = lazy(
  async () =>
    await import('./warning_alarm_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinCircleIcon = lazy(
  async () =>
    await import('./trash_bin_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuListSquareIcon = lazy(
  async () =>
    await import('./menu_list_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkRemoveMinusIcon = lazy(
  async () =>
    await import('./bookmark_remove_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageEditPenIcon = lazy(
  async () =>
    await import('./chat_message_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesAddIcon = lazy(
  async () =>
    await import('./chat_messages_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerAlarmClockIcon = lazy(
  async () =>
    await import('./timer_alarm_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShineModernSqaureIcon = lazy(
  async () =>
    await import('./shine_modern_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiIcon = lazy(
  async () =>
    await import('./wifi.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBarSpaceCentreHorizontalIcon = lazy(
  async () =>
    await import('./menu_bar_space_centre_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersCircleIcon = lazy(
  async () =>
    await import('./users_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VideoMoviesIcon = lazy(
  async () =>
    await import('./video_movies.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteLabelIcon = lazy(
  async () =>
    await import('./bookmark_favorite_label.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowsCircleIcon = lazy(
  async () =>
    await import('./rotate_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalAngleLeftHorizontalBlankBigIcon = lazy(
  async () =>
    await import('./send_horizontal_angle_left_horizontal_blank_big.svg').then(
      module => ({ default: module.ReactComponent })
    )
);
const CalendarTextTwoLinesIcon = lazy(
  async () =>
    await import('./calendar_text_two_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeSmileysIcon = lazy(
  async () =>
    await import('./home_smileys.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginEnterArrowRightSquareIcon = lazy(
  async () =>
    await import('./login_enter_arrow_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDeleteIcon = lazy(
  async () =>
    await import('./trash_bin_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkCircleIcon = lazy(
  async () =>
    await import('./link_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinBoxArrowUpIcon = lazy(
  async () =>
    await import('./bin_box_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionSquareArrowsIcon = lazy(
  async () =>
    await import('./direction_square_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageCenterChatStarIcon = lazy(
  async () =>
    await import('./message_center_chat_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteDeleteIcon = lazy(
  async () =>
    await import('./star_favorite_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardGaugeIcon = lazy(
  async () =>
    await import('./dashboard_gauge.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlayRectangleIcon = lazy(
  async () =>
    await import('./play_rectangle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UnderlineSquareIcon = lazy(
  async () =>
    await import('./underline_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarListLineIcon = lazy(
  async () =>
    await import('./calendar_list_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarBackArrowIcon = lazy(
  async () =>
    await import('./calendar_back_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxShiningInboxSquareIcon = lazy(
  async () =>
    await import('./mailbox_shining_inbox_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageEyeIcon = lazy(
  async () =>
    await import('./message_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionSquareIcon = lazy(
  async () =>
    await import('./question_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextItemSquareIcon = lazy(
  async () =>
    await import('./text_item_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsMenuIcon = lazy(
  async () =>
    await import('./settings_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMenuSelectIcon = lazy(
  async () =>
    await import('./cursor_menu_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuButtonSquareIcon = lazy(
  async () =>
    await import('./item_menu_button_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemAMessageIcon = lazy(
  async () =>
    await import('./item_a_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextCopyIcon = lazy(
  async () =>
    await import('./document_text_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBoardCrossRemoveSquareIcon = lazy(
  async () =>
    await import('./menu_board_cross_remove_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSearchListCircleIcon = lazy(
  async () =>
    await import('./menu_search_list_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockMoreCopyIcon = lazy(
  async () =>
    await import('./time_clock_more_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditMinimalPenLineSquareIcon = lazy(
  async () =>
    await import('./edit_minimal_pen_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerSquareIcon = lazy(
  async () =>
    await import('./stiker_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenArrowLeftCircleIcon = lazy(
  async () =>
    await import('./pen_arrow_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PazzlePluginRightIcon = lazy(
  async () =>
    await import('./pazzle_plugin_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayStyleRightIcon = lazy(
  async () =>
    await import('./airplay_style_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyAddPlusIcon = lazy(
  async () =>
    await import('./copy_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareBigCircleIcon = lazy(
  async () =>
    await import('./share_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingMinimalSquareIcon = lazy(
  async () =>
    await import('./loading_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CurcorSelectMenuCircleIcon = lazy(
  async () =>
    await import('./curcor_select_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxTitleTextIcon = lazy(
  async () =>
    await import('./mailbox_title_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextSelectIcon = lazy(
  async () =>
    await import('./text_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectDeleteSquareIcon = lazy(
  async () =>
    await import('./select_object_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyDiskBlankLeftIcon = lazy(
  async () =>
    await import('./floppy_disk_blank_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarMinusIcon = lazy(
  async () =>
    await import('./calendar_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectCopyMoreDotsIcon = lazy(
  async () =>
    await import('./object_copy_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShapesBjectsCircleIcon = lazy(
  async () =>
    await import('./shapes_bjects_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UndoArrowSqaureIcon = lazy(
  async () =>
    await import('./undo_arrow_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsRollUpIcon = lazy(
  async () =>
    await import('./windows_roll_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarAddPlusCurvedCornerIcon = lazy(
  async () =>
    await import('./calendar_add_plus_curved_corner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkSquareBigIcon = lazy(
  async () =>
    await import('./item_checkmark_square_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectInfoSquareIcon = lazy(
  async () =>
    await import('./object_info_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSearchSquareIcon = lazy(
  async () =>
    await import('./menu_search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GraphAnalitycsSquareIcon = lazy(
  async () =>
    await import('./graph_analitycs_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeMinusIcon = lazy(
  async () =>
    await import('./globe_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderLightningCrossIcon = lazy(
  async () =>
    await import('./thunder_lightning_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorHandSelectIcon = lazy(
  async () =>
    await import('./cursor_hand_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsUpCircleIcon = lazy(
  async () =>
    await import('./thumbs_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageTextIcon = lazy(
  async () =>
    await import('./message_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistUploadBottomMiddleIcon = lazy(
  async () =>
    await import('./tasklist_upload_bottom_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CcOctagonIcon = lazy(
  async () =>
    await import('./cc_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorCircleIcon = lazy(
  async () =>
    await import('./monitor_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MultiShotImageSquareIcon = lazy(
  async () =>
    await import('./multi_shot_image_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailEmailRemoveDeliteIcon = lazy(
  async () =>
    await import('./mail_email_remove_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchShineCircleIcon = lazy(
  async () =>
    await import('./search_shine_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowBackspaceAddPlusIcon = lazy(
  async () =>
    await import('./arrow_backspace_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockStyleSquareIcon = lazy(
  async () =>
    await import('./lock_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkDoneCheckSqaureIcon = lazy(
  async () =>
    await import('./checkmark_done_check_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteRemoveAddPlusIcon = lazy(
  async () =>
    await import('./heart_favorite_remove_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveCrossDeleteIcon = lazy(
  async () =>
    await import('./mailbox_archive_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarModernRemoveCrossIcon = lazy(
  async () =>
    await import('./calendar_modern_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksMoreSettingsIcon = lazy(
  async () =>
    await import('./bookmarks_more_settings.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarSearchCopyIcon = lazy(
  async () =>
    await import('./calendar_search_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditDeleteIcon = lazy(
  async () =>
    await import('./pen_edit_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLightOctagonIcon = lazy(
  async () =>
    await import('./lamp_light_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpaceRemoveCrossSquareIcon = lazy(
  async () =>
    await import('./space_remove_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PiegraphIcon = lazy(
  async () =>
    await import('./piegraph.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorDisplayCheckmarkIcon = lazy(
  async () =>
    await import('./monitor_display_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrinWarningErrorIcon = lazy(
  async () =>
    await import('./printer_prin_warning_error.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsbSquareIcon = lazy(
  async () =>
    await import('./usb_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerOffCrossRemoveMinimalIcon = lazy(
  async () =>
    await import('./timer_off_cross_remove_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextBrowserIcon = lazy(
  async () =>
    await import('./item_text_browser.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCheckmarkDotsLineIcon = lazy(
  async () =>
    await import('./copy_checkmark_dots_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeZoomBoxIcon = lazy(
  async () =>
    await import('./search_loupe_zoom_box.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoxPlusAddSelectIcon = lazy(
  async () =>
    await import('./box_plus_add_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryModernLineBottomIcon = lazy(
  async () =>
    await import('./library_modern_line_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleLockIcon = lazy(
  async () =>
    await import('./calendar_schedule_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateRefreshSquareIcon = lazy(
  async () =>
    await import('./rotate_refresh_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortCheckmarkIcon = lazy(
  async () =>
    await import('./filter_sort_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationsSquareIcon = lazy(
  async () =>
    await import('./bell_notifications_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistCheckmarkIcon = lazy(
  async () =>
    await import('./tasklist_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemImageTextCheckmarkIcon = lazy(
  async () =>
    await import('./item_image_text_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MinusIcon = lazy(
  async () =>
    await import('./minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FavoriteIcon = lazy(
  async () =>
    await import('./favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarSchedulePlayIcon = lazy(
  async () =>
    await import('./calendar_schedule_play.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleDottedLineIcon = lazy(
  async () =>
    await import('./circle_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageShatRightInputTextIcon = lazy(
  async () =>
    await import('./message_shat_right_input_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowSidebarLeftIcon = lazy(
  async () =>
    await import('./window_sidebar_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectPlusIcon = lazy(
  async () =>
    await import('./select_object_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteMinusIcon = lazy(
  async () =>
    await import('./bookmark_favorite_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorTextCopyMoveIcon = lazy(
  async () =>
    await import('./cursor_text_copy_move.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarEditPenCopyIcon = lazy(
  async () =>
    await import('./calendar_edit_pen_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterCrossIcon = lazy(
  async () =>
    await import('./email_letter_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockDottedLineIcon = lazy(
  async () =>
    await import('./clock_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendArrowSelectIcon = lazy(
  async () =>
    await import('./send_arrow_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthGlobeModernIcon = lazy(
  async () =>
    await import('./earth_globe_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernCircleSquareIcon = lazy(
  async () =>
    await import('./image_picture_modern_circle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemDeleteAllCrossIcon = lazy(
  async () =>
    await import('./copy_item_delete_all_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandHorizontalCircleIcon = lazy(
  async () =>
    await import('./hand_horizontal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenubarArrowDownIcon = lazy(
  async () =>
    await import('./menubar_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookCrossDeleteIcon = lazy(
  async () =>
    await import('./notebook_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SidebarWindowIcon = lazy(
  async () =>
    await import('./sidebar_window.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleHorizontalIcon = lazy(
  async () =>
    await import('./setting_style_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinIcon = lazy(
  async () =>
    await import('./marker_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatTextCheckmarkIcon = lazy(
  async () =>
    await import('./message_chat_text_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapHexagonIcon = lazy(
  async () =>
    await import('./compass_map_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockUnlockIcon = lazy(
  async () =>
    await import('./lock_unlock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMoreDottedLineSquareIcon = lazy(
  async () =>
    await import('./edit_pen_more_dotted_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSelectCrossDeleteDottedLineIcon = lazy(
  async () =>
    await import('./item_select_cross_delete_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeliteIcon = lazy(
  async () =>
    await import('./delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintMinimalCircleIcon = lazy(
  async () =>
    await import('./fingerprint_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DowloadSaveIcon = lazy(
  async () =>
    await import('./dowload_save.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesIcon = lazy(
  async () =>
    await import('./cookies.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkSquareIcon = lazy(
  async () =>
    await import('./bookmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SwitcherSettingSquareIcon = lazy(
  async () =>
    await import('./switcher_setting_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartUpArrowCircleIcon = lazy(
  async () =>
    await import('./chart_up_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterCopyDottedLineIcon = lazy(
  async () =>
    await import('./filter_copy_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SynchronizeTriangleArrowsRecirculationIcon = lazy(
  async () =>
    await import('./synchronize_triangle_arrows_recirculation.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const MediaLibraryFolderCircleIcon = lazy(
  async () =>
    await import('./media_library_folder_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemIcon = lazy(
  async () =>
    await import('./menu_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimizeWindowIcon = lazy(
  async () =>
    await import('./monitor_minimize_window.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleVerticalLinesIcon = lazy(
  async () =>
    await import('./bin_trash_style_vertical_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarBlankTextIcon = lazy(
  async () =>
    await import('./calendar_blank_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageCrossRejectionIcon = lazy(
  async () =>
    await import('./message_cross_rejection.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectCheckmarkCircleIcon = lazy(
  async () =>
    await import('./object_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LayersIcon = lazy(
  async () =>
    await import('./layers.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageSmileIcon = lazy(
  async () =>
    await import('./chat_message_smile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ToolsSettingsIcon = lazy(
  async () =>
    await import('./tools_settings.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiNetworkSignalAddSquareIcon = lazy(
  async () =>
    await import('./wifi_network_signal_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuSquareCopyIcon = lazy(
  async () =>
    await import('./item_menu_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMiddleCircleIcon = lazy(
  async () =>
    await import('./edit_pen_middle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PositionTargetCircleIcon = lazy(
  async () =>
    await import('./position_target_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyMoveCutIcon = lazy(
  async () =>
    await import('./copy_move_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SquareDeleteCrossIcon = lazy(
  async () =>
    await import('./square_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListPaperSelectIcon = lazy(
  async () =>
    await import('./list_paper_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerCrossDeleteIcon = lazy(
  async () =>
    await import('./sticker_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinCheckmarkIcon = lazy(
  async () =>
    await import('./marker_pin_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowSearchItemDeleteRemoveIcon = lazy(
  async () =>
    await import('./window_search_item_delete_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkBlankBigIcon = lazy(
  async () =>
    await import('./bookmark_blank_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepModeCircleIcon = lazy(
  async () =>
    await import('./sleep_mode_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxCollectionsArchiveShareIcon = lazy(
  async () =>
    await import('./mailbox_collections_archive_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowScaleSquareIcon = lazy(
  async () =>
    await import('./window_scale_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextItemListIcon = lazy(
  async () =>
    await import('./text_item_list.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginsCircleIcon = lazy(
  async () =>
    await import('./plugins_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureCheckmarkSquareIcon = lazy(
  async () =>
    await import('./image_picture_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectArrowLeftCircleIcon = lazy(
  async () =>
    await import('./object_arrow_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CutItemIcon = lazy(
  async () =>
    await import('./cut_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrintDeliteIcon = lazy(
  async () =>
    await import('./printer_print_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayModetnCircleIcon = lazy(
  async () =>
    await import('./airplay_modetn_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickIcon = lazy(
  async () =>
    await import('./cursor_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemLeftAlignIcon = lazy(
  async () =>
    await import('./copy_item_left_align.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitLogOutIcon = lazy(
  async () =>
    await import('./exit_log_out.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureDownloadArrowDownIcon = lazy(
  async () =>
    await import('./image_picture_download_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./zip_archive_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrightnessEditSettingIcon = lazy(
  async () =>
    await import('./brightness_edit_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsDownDislikeStyleBigIcon = lazy(
  async () =>
    await import('./thumbs_down_dislike_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordCopyIcon = lazy(
  async () =>
    await import('./input_password_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetRemoveDeleteIcon = lazy(
  async () =>
    await import('./target_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCheckmarkCircleCopyIcon = lazy(
  async () =>
    await import('./menu_checkmark_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLeftIcon = lazy(
  async () =>
    await import('./bookmarks_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartStyleSquareIcon = lazy(
  async () =>
    await import('./heart_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailCircleIcon = lazy(
  async () =>
    await import('./mail_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagesPictureCopyIcon = lazy(
  async () =>
    await import('./images_picture_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoNotificationCircleIcon = lazy(
  async () =>
    await import('./info_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteSquareIcon = lazy(
  async () =>
    await import('./star_favorite_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerCheckmarkCopyIcon = lazy(
  async () =>
    await import('./sticker_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornTrumpetCopyIcon = lazy(
  async () =>
    await import('./horn_trumpet_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistAddPlusSquareIcon = lazy(
  async () =>
    await import('./tasklist_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectLeftBigIcon = lazy(
  async () =>
    await import('./cursor_select_left_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkCheckmarkIcon = lazy(
  async () =>
    await import('./bookmark_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchQuestionIcon = lazy(
  async () =>
    await import('./clock_watch_question.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepSnoozeZzzSquareIcon = lazy(
  async () =>
    await import('./sleep_snooze_zzz_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphTextIcon = lazy(
  async () =>
    await import('./paragraph_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalHotspotCopyIcon = lazy(
  async () =>
    await import('./signal_hotspot_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LogOutArrowLeftIcon = lazy(
  async () =>
    await import('./log_out_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningAlarmIcon = lazy(
  async () =>
    await import('./warning_alarm.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageEditIcon = lazy(
  async () =>
    await import('./message_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalLineSquareIcon = lazy(
  async () =>
    await import('./wifi_signal_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashDCircleIcon = lazy(
  async () =>
    await import('./bin_trash_d_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlignJustifyIcon = lazy(
  async () =>
    await import('./align_justify.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DelimiterBracketsSpaceCircleIcon = lazy(
  async () =>
    await import('./delimiter_brackets_space_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingHorizontalIcon = lazy(
  async () =>
    await import('./setting_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaySignalAirdropSquareIcon = lazy(
  async () =>
    await import('./airplay_signal_airdrop_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TvCrossRemoveIcon = lazy(
  async () =>
    await import('./tv_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSelectPlusAddIcon = lazy(
  async () =>
    await import('./item_select_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonNightModeNotificationCircleIcon = lazy(
  async () =>
    await import('./moon_night_mode_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalAngleBigIcon = lazy(
  async () =>
    await import('./send_horizontal_angle_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TabsBookmarksPlusIcon = lazy(
  async () =>
    await import('./tabs_bookmarks_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageRemoveCrossIcon = lazy(
  async () =>
    await import('./chat_message_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarStyleIcon = lazy(
  async () =>
    await import('./star_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadSquareIcon = lazy(
  async () =>
    await import('./download_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserModernSquareIcon = lazy(
  async () =>
    await import('./user_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePicturesDownloadArrowDownSquareIcon = lazy(
  async () =>
    await import('./image_pictures_download_arrow_down_square.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const ChatMessageStarShiningIcon = lazy(
  async () =>
    await import('./chat_message_star_shining.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleDownloadArrowDownIcon = lazy(
  async () =>
    await import('./bin_trash_style_download_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingReloadCircleIcon = lazy(
  async () =>
    await import('./loading_reload_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteSearchIcon = lazy(
  async () =>
    await import('./bookmark_favorite_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FileInfoIcon = lazy(
  async () =>
    await import('./file_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalCheckmarkIcon = lazy(
  async () =>
    await import('./wifi_signal_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatSmileysSadIcon = lazy(
  async () =>
    await import('./message_chat_smileys_sad.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemEditPenTextDownRightSquareIcon = lazy(
  async () =>
    await import('./item_edit_pen_text_down_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimalSquareIcon = lazy(
  async () =>
    await import('./monitor_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SiriIcon = lazy(
  async () =>
    await import('./siri.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteSearchIcon = lazy(
  async () =>
    await import('./star_favorite_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectItemIcon = lazy(
  async () =>
    await import('./cursor_select_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureLeftArrowIcon = lazy(
  async () =>
    await import('./image_picture_left_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuLaodingItemIcon = lazy(
  async () =>
    await import('./menu_laoding_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CmdCommandKeyboardIcon = lazy(
  async () =>
    await import('./cmd_command_keyboard.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLeftTopIcon = lazy(
  async () =>
    await import('./bookmarks_left_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginSquareMinimalArrowRightIcon = lazy(
  async () =>
    await import('./login_square_minimal_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentRemoveGroupIcon = lazy(
  async () =>
    await import('./document_remove_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./clip_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockDeleteOffIcon = lazy(
  async () =>
    await import('./time_clock_delete_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StatisticsAnalyticsArrowUpSquareIcon = lazy(
  async () =>
    await import('./statistics_analytics_arrow_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteAddPlusCircleIcon = lazy(
  async () =>
    await import('./heart_favorite_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistLeftCentreArrowRightIcon = lazy(
  async () =>
    await import('./tasklist_left_centre_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PowerTurnOnSquareIcon = lazy(
  async () =>
    await import('./power_turn_on_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksIcon = lazy(
  async () =>
    await import('./bookmarks.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemRightIcon = lazy(
  async () =>
    await import('./copy_item_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLightShineRightIcon = lazy(
  async () =>
    await import('./lamp_light_shine_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatStyleCheckmarkIcon = lazy(
  async () =>
    await import('./chat_style_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationAddCircleIcon = lazy(
  async () =>
    await import('./pin_location_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDownIcon = lazy(
  async () =>
    await import('./arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuMoreEditCircleIcon = lazy(
  async () =>
    await import('./menu_more_edit_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkCheckmarkCopyIcon = lazy(
  async () =>
    await import('./bookmark_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoInformationIcon = lazy(
  async () =>
    await import('./info_information.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinRefreshLoadingIcon = lazy(
  async () =>
    await import('./marker_pin_refresh_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipMenuSquareIcon = lazy(
  async () =>
    await import('./clip_menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryFolderCheckmarkIcon = lazy(
  async () =>
    await import('./media_library_folder_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserMessageCentreTopIcon = lazy(
  async () =>
    await import('./user_message_centre_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerSmileEditIcon = lazy(
  async () =>
    await import('./stiker_smile_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickCircleCopyIcon = lazy(
  async () =>
    await import('./cursor_click_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CockTimeIcon = lazy(
  async () =>
    await import('./cock_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginEnterIcon = lazy(
  async () =>
    await import('./login_enter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsModernCircleIcon = lazy(
  async () =>
    await import('./chart_analytics_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMessagePopUpPlusIcon = lazy(
  async () =>
    await import('./item_message_pop_up_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NewsIcon = lazy(
  async () =>
    await import('./news.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchSelectIcon = lazy(
  async () =>
    await import('./search_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LadingTimeSquareIcon = lazy(
  async () =>
    await import('./lading_time_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoneCheckNotesIcon = lazy(
  async () =>
    await import('./done_check_notes.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCopyPinIcon = lazy(
  async () =>
    await import('./item_copy_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowCircleIcon = lazy(
  async () =>
    await import('./window_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksFavoriteLabelDeleteIcon = lazy(
  async () =>
    await import('./bookmarks_favorite_label_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsSquareIcon = lazy(
  async () =>
    await import('./chart_analytics_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZoomIcon = lazy(
  async () =>
    await import('./zoom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StatisticsAnalyticsSquareIcon = lazy(
  async () =>
    await import('./statistics_analytics_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkMoreSquareIcon = lazy(
  async () =>
    await import('./item_checkmark_more_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinModernTwoLineIcon = lazy(
  async () =>
    await import('./bin_modern_two_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecSquareCopyIcon = lazy(
  async () =>
    await import('./voice_rec_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorFastMoveIcon = lazy(
  async () =>
    await import('./cursor_fast_move.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipModernCircleIcon = lazy(
  async () =>
    await import('./zip_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextEditIcon = lazy(
  async () =>
    await import('./text_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapSquareIcon = lazy(
  async () =>
    await import('./search_loap_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashnoardMinimalIcon = lazy(
  async () =>
    await import('./dashnoard_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleBigNotificationIcon = lazy(
  async () =>
    await import('./bell_style_big_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCursorSquareIcon = lazy(
  async () =>
    await import('./menu_cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./bookmark_favorite_cross_delete_circle.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const ClipArrowDownBigIcon = lazy(
  async () =>
    await import('./clip_arrow_down_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingSquareCopyIcon = lazy(
  async () =>
    await import('./loading_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureDownloadIcon = lazy(
  async () =>
    await import('./image_picture_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeadphonesStyleBigIcon = lazy(
  async () =>
    await import('./headphones_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarsLightSquareIcon = lazy(
  async () =>
    await import('./stars_light_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkCheckmarkTopRightIcon = lazy(
  async () =>
    await import('./bookmark_checkmark_top_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectDisableItemIcon = lazy(
  async () =>
    await import('./select_disable_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListCrossDeleteIcon = lazy(
  async () =>
    await import('./check_list_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxLineUpIcon = lazy(
  async () =>
    await import('./mailbox_line_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagesPicturesCopyIcon = lazy(
  async () =>
    await import('./images_pictures_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridSquareIcon = lazy(
  async () =>
    await import('./grid_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionRhombusIcon = lazy(
  async () =>
    await import('./question_rhombus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowsRefreshIcon = lazy(
  async () =>
    await import('./rotate_arrows_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchMinusDeleteIcon = lazy(
  async () =>
    await import('./item_search_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandCircleIcon = lazy(
  async () =>
    await import('./hand_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartPercentCircleIcon = lazy(
  async () =>
    await import('./chart_percent_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeStyleSquareIcon = lazy(
  async () =>
    await import('./clock_time_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTitleTasklistLeftTopSquareIcon = lazy(
  async () =>
    await import('./item_title_tasklist_left_top_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketsCircleIcon = lazy(
  async () =>
    await import('./brackets_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagLinesIcon = lazy(
  async () =>
    await import('./flag_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextMoreIcon = lazy(
  async () =>
    await import('./item_text_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageMoreDotsIcon = lazy(
  async () =>
    await import('./chat_message_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTitleTasklistIcon = lazy(
  async () =>
    await import('./item_title_tasklist.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteBinSettingIcon = lazy(
  async () =>
    await import('./trash_delete_bin_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkLineSquareIcon = lazy(
  async () =>
    await import('./bookmark_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksFavoriteLabelAddIcon = lazy(
  async () =>
    await import('./bookmarks_favorite_label_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDAddPlusIcon = lazy(
  async () =>
    await import('./trash_bin_d_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipIcon = lazy(
  async () =>
    await import('./clip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareSignalCircleIcon = lazy(
  async () =>
    await import('./share_signal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeFastIcon = lazy(
  async () =>
    await import('./alarm_clock_time_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HashtagAddPlusCircleIcon = lazy(
  async () =>
    await import('./hashtag_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureEditStarsIcon = lazy(
  async () =>
    await import('./image_picture_edit_stars.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserSeelctIcon = lazy(
  async () =>
    await import('./browser_seelct.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerSmileDeleteIcon = lazy(
  async () =>
    await import('./stiker_smile_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashModernIcon = lazy(
  async () =>
    await import('./bin_trash_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeClassicIcon = lazy(
  async () =>
    await import('./home_classic.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursrorClickSquareModernIcon = lazy(
  async () =>
    await import('./cursror_click_square_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockUnlockAddCircleIcon = lazy(
  async () =>
    await import('./lock_unlock_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsFilterMenuIcon = lazy(
  async () =>
    await import('./settings_filter_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendShareIcon = lazy(
  async () =>
    await import('./send_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesNotificationCircleIcon = lazy(
  async () =>
    await import('./cookies_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagPlusAddCircleIcon = lazy(
  async () =>
    await import('./flag_plus_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuoteIcon = lazy(
  async () =>
    await import('./quote.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AsteriskRotateArrowCircleIcon = lazy(
  async () =>
    await import('./asterisk_rotate_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepModeStarCircleIcon = lazy(
  async () =>
    await import('./sleep_mode_star_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagStyleIcon = lazy(
  async () =>
    await import('./flag_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapMinimalSquareIcon = lazy(
  async () =>
    await import('./map_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassLocationLoadingCircleIcon = lazy(
  async () =>
    await import('./compass_location_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagSquareSquareIcon = lazy(
  async () =>
    await import('./flag_square_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortFavouriteIcon = lazy(
  async () =>
    await import('./filter_sort_favourite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleIcon = lazy(
  async () =>
    await import('./circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuRemoveDeleteIcon = lazy(
  async () =>
    await import('./item_menu_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AwardRatingCircleIcon = lazy(
  async () =>
    await import('./award_rating_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellCrossDeleteIcon = lazy(
  async () =>
    await import('./bell_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderVerticalIcon = lazy(
  async () =>
    await import('./border_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalRecStyleCircleIcon = lazy(
  async () =>
    await import('./signal_rec_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AppApplicationIcon = lazy(
  async () =>
    await import('./app_application.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookLaptopCircleIcon = lazy(
  async () =>
    await import('./notebook_laptop_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteCircleCopyIcon = lazy(
  async () =>
    await import('./heart_favorite_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthHomeWorldSelectIcon = lazy(
  async () =>
    await import('./earth_home_world_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyAddPlusIcon = lazy(
  async () =>
    await import('./floppy_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecCircleIcon = lazy(
  async () =>
    await import('./rec_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlignLeftIcon = lazy(
  async () =>
    await import('./align_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingModernCircleIcon = lazy(
  async () =>
    await import('./loading_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BombIcon = lazy(
  async () =>
    await import('./bomb.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterSquareIcon = lazy(
  async () =>
    await import('./email_letter_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsModernCircleCopyIcon = lazy(
  async () =>
    await import('./chart_analytics_modern_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageInputEditIcon = lazy(
  async () =>
    await import('./message_input_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarShiningCircleIcon = lazy(
  async () =>
    await import('./star_shining_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiInfoIcon = lazy(
  async () =>
    await import('./wifi_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FireAlarmCircleIcon = lazy(
  async () =>
    await import('./fire_alarm_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatMinusIcon = lazy(
  async () =>
    await import('./message_chat_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinksCircleIcon = lazy(
  async () =>
    await import('./links_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshLoadingCircleIcon = lazy(
  async () =>
    await import('./refresh_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshRotateRecycleIcon = lazy(
  async () =>
    await import('./refresh_rotate_recycle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingSquareIcon = lazy(
  async () =>
    await import('./loading_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockCheckmarkIcon = lazy(
  async () =>
    await import('./time_clock_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserCircleIcon = lazy(
  async () =>
    await import('./user_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DisplayMonitorSquareIcon = lazy(
  async () =>
    await import('./display_monitor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashUploadIcon = lazy(
  async () =>
    await import('./bin_trash_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMinivalVerticalSquareIcon = lazy(
  async () =>
    await import('./setting_minival_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeCheckmarkCopyIcon = lazy(
  async () =>
    await import('./home_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MinimizeWindowIcon = lazy(
  async () =>
    await import('./minimize_window.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionNotificationCircleIcon = lazy(
  async () =>
    await import('./question_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeModernSquareIcon = lazy(
  async () =>
    await import('./time_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LeftArrowIcon = lazy(
  async () =>
    await import('./left_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseScrollUpCopyIcon = lazy(
  async () =>
    await import('./mouse_scroll_up_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectAddPlusIcon = lazy(
  async () =>
    await import('./select_object_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLoadingWaitingIcon = lazy(
  async () =>
    await import('./cursor_loading_waiting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleWarningIcon = lazy(
  async () =>
    await import('./calendar_schedule_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiCircleIcon = lazy(
  async () =>
    await import('./wifi_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlignLeftCircleIcon = lazy(
  async () =>
    await import('./align_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryUploadIcon = lazy(
  async () =>
    await import('./library_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatMinusDeleteIcon = lazy(
  async () =>
    await import('./message_chat_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkCheckmarkIcon = lazy(
  async () =>
    await import('./attachment_link_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadSaveCircleIcon = lazy(
  async () =>
    await import('./download_save_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistCopyCheckamrkIcon = lazy(
  async () =>
    await import('./tasklist_copy_checkamrk.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryMiddleLinesFileIcon = lazy(
  async () =>
    await import('./media_library_middle_lines_file.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalLineCircleIcon = lazy(
  async () =>
    await import('./signal_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotificationIcon = lazy(
  async () =>
    await import('./notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerOffRemoveCrossBigIcon = lazy(
  async () =>
    await import('./timer_off_remove_cross_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerFastAddPlusIcon = lazy(
  async () =>
    await import('./timer_fast_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FireHotSquareIcon = lazy(
  async () =>
    await import('./fire_hot_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldModernBlankIcon = lazy(
  async () =>
    await import('./shield_modern_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionHexagonIcon = lazy(
  async () =>
    await import('./question_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryModernMoreIcon = lazy(
  async () =>
    await import('./library_modern_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListPapaerCrossDeleteIcon = lazy(
  async () =>
    await import('./list_papaer_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderLeftIcon = lazy(
  async () =>
    await import('./border_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackwardRearwardBackRemoveIcon = lazy(
  async () =>
    await import('./backward_rearward_back_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMinimalOctagonIcon = lazy(
  async () =>
    await import('./setting_minimal_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockPasswordIcon = lazy(
  async () =>
    await import('./lock_password.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorCheckmarkDoneCheckIcon = lazy(
  async () =>
    await import('./cursor_checkmark_done_check.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncModernArrowCircleIcon = lazy(
  async () =>
    await import('./sync_modern_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderHorizontalIcon = lazy(
  async () =>
    await import('./border_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClosedEyeInvisibleOctagonIcon = lazy(
  async () =>
    await import('./closed_eye_invisible_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneRecMicAddPlusSelectIcon = lazy(
  async () =>
    await import('./microphone_rec_mic_add_plus_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinAngleCircleIcon = lazy(
  async () =>
    await import('./pin_angle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchItemIcon = lazy(
  async () =>
    await import('./search_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectMenuIcon = lazy(
  async () =>
    await import('./select_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CityTownBuildingsSquareIcon = lazy(
  async () =>
    await import('./city_town_buildings_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiOffCrossSquareIcon = lazy(
  async () =>
    await import('./wifi_off_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipSquareStyleVerticalIcon = lazy(
  async () =>
    await import('./clip_square_style_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailMinusIcon = lazy(
  async () =>
    await import('./email_mail_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadArrowCircleCopyIcon = lazy(
  async () =>
    await import('./download_arrow_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockSquareIcon = lazy(
  async () =>
    await import('./time_clock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchAddPlusMiddleIcon = lazy(
  async () =>
    await import('./search_add_plus_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsNotificationCircleIcon = lazy(
  async () =>
    await import('./chart_analytics_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSwipeUpIcon = lazy(
  async () =>
    await import('./cursor_swipe_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseBigIcon = lazy(
  async () =>
    await import('./mouse_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectDottedLineSquareCircleIcon = lazy(
  async () =>
    await import('./select_dotted_line_square_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListMenuIcon = lazy(
  async () =>
    await import('./task_list_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuCircleIcon = lazy(
  async () =>
    await import('./item_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatShareArrowIcon = lazy(
  async () =>
    await import('./message_chat_share_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnalyticsChartSquareIcon = lazy(
  async () =>
    await import('./analytics_chart_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleMaskCutIcon = lazy(
  async () =>
    await import('./circle_mask_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerOffCrossRemoveIcon = lazy(
  async () =>
    await import('./timer_off_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockStyleBlankCircleIcon = lazy(
  async () =>
    await import('./lock_style_blank_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppysSaveIcon = lazy(
  async () =>
    await import('./floppys_save.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleNotificationIcon = lazy(
  async () =>
    await import('./email_letter_style_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsbCirceIcon = lazy(
  async () =>
    await import('./usb_circe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorHoleBigSquareIcon = lazy(
  async () =>
    await import('./door_hole_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeFavoriteHeartIcon = lazy(
  async () =>
    await import('./search_loupe_favorite_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowBackspaceCrossDeleteIcon = lazy(
  async () =>
    await import('./arrow_backspace_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchShiningvIcon = lazy(
  async () =>
    await import('./search_shiningv.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionShiningClickCircleIcon = lazy(
  async () =>
    await import('./arrow_direction_shining_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemFavoriteStarIcon = lazy(
  async () =>
    await import('./item_favorite_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeIcon = lazy(
  async () =>
    await import('./search_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartCircleCrossDeleteIcon = lazy(
  async () =>
    await import('./heart_circle_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridLayoutDeliteIcon = lazy(
  async () =>
    await import('./grid_layout_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashDSquareIcon = lazy(
  async () =>
    await import('./bin_trash_d_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendArrowSquareIcon = lazy(
  async () =>
    await import('./send_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AsteriskSquareIcon = lazy(
  async () =>
    await import('./asterisk_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveCollectionsDisableIcon = lazy(
  async () =>
    await import('./mailbox_archive_collections_disable.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCopyIcon = lazy(
  async () =>
    await import('./fingerprint_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RepeatRotateSquareIcon = lazy(
  async () =>
    await import('./repeat_rotate_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemLockWarningIcon = lazy(
  async () =>
    await import('./item_lock_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockAlarmAddPlusIcon = lazy(
  async () =>
    await import('./time_clock_alarm_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoInformaionSquareIcon = lazy(
  async () =>
    await import('./info_informaion_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatTextAddPlusIcon = lazy(
  async () =>
    await import('./message_chat_text_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCopyAddPlusIcon = lazy(
  async () =>
    await import('./document_copy_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeCheckmarkSquareIcon = lazy(
  async () =>
    await import('./eye_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureLeftSquareIcon = lazy(
  async () =>
    await import('./image_picture_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernMoreDotsIcon = lazy(
  async () =>
    await import('./home_modern_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowCircleIcon = lazy(
  async () =>
    await import('./rotate_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveCrossDesableIcon = lazy(
  async () =>
    await import('./floppy_save_cross_desable.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxBrokenCircleIcon = lazy(
  async () =>
    await import('./checkbox_broken_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropdownMenuItemDeleteIcon = lazy(
  async () =>
    await import('./dropdown_menu_item_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseSettingIcon = lazy(
  async () =>
    await import('./home_house_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveCenterSquareIcon = lazy(
  async () =>
    await import('./zip_archive_center_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListCheckmarkIcon = lazy(
  async () =>
    await import('./task_list_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListMinusDeleteIcon = lazy(
  async () =>
    await import('./task_list_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerSmileIcon = lazy(
  async () =>
    await import('./stiker_smile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeTimerAddTurnOnIcon = lazy(
  async () =>
    await import('./clock_time_timer_add_turn_on.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartArrowCircleIcon = lazy(
  async () =>
    await import('./chart_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailInfoCopyIcon = lazy(
  async () =>
    await import('./email_mail_info_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiOffDisabledIcon = lazy(
  async () =>
    await import('./wifi_off_disabled.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectArrowSquareIcon = lazy(
  async () =>
    await import('./object_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCheckmarkCircleCopyIcon = lazy(
  async () =>
    await import('./loading_checkmark_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReloadRepeatArrowsLoadingIcon = lazy(
  async () =>
    await import('./reload_repeat_arrows_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureCenterCircleIcon = lazy(
  async () =>
    await import('./image_picture_center_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderAddPlusIcon = lazy(
  async () =>
    await import('./window_finder_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectCursorIcon = lazy(
  async () =>
    await import('./object_cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemNotePinIcon = lazy(
  async () =>
    await import('./item_note_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipBigArrowDownIcon = lazy(
  async () =>
    await import('./clip_big_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMinimalStyleSquareIcon = lazy(
  async () =>
    await import('./setting_minimal_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareZoomInSqaureIcon = lazy(
  async () =>
    await import('./share_zoom_in_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeSelectIcon = lazy(
  async () =>
    await import('./resize_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReceiptPlusIcon = lazy(
  async () =>
    await import('./receipt_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PasswordLockProtectionDeclineIcon = lazy(
  async () =>
    await import('./password_lock_protection_decline.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkReadOctagonIcon = lazy(
  async () =>
    await import('./checkmark_read_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowZoomOutPlusLoupeIcon = lazy(
  async () =>
    await import('./window_zoom_out_plus_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoactionMapDirectionSquareIcon = lazy(
  async () =>
    await import('./loaction_map_direction_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecIcon = lazy(
  async () =>
    await import('./voice_rec.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenBigSquareCopyIcon = lazy(
  async () =>
    await import('./edit_pen_big_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectMoreSettingIcon = lazy(
  async () =>
    await import('./select_more_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalenadarRemoveCrossCircleIcon = lazy(
  async () =>
    await import('./calenadar_remove_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorRightCircleCopyIcon = lazy(
  async () =>
    await import('./cursor_right_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginsSquareIcon = lazy(
  async () =>
    await import('./plugins_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetVerticalCrossRemoveBigIcon = lazy(
  async () =>
    await import('./magnet_vertical_cross_remove_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailIcon = lazy(
  async () =>
    await import('./mail.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxBrokenSquareIcon = lazy(
  async () =>
    await import('./checkbox_broken_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteRecicleIcon = lazy(
  async () =>
    await import('./trash_delete_recicle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsModernSquareIcon = lazy(
  async () =>
    await import('./chart_analytics_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernTwitterSquareIcon = lazy(
  async () =>
    await import('./home_modern_twitter_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartLineOctagonIcon = lazy(
  async () =>
    await import('./chart_line_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagNotificationStyleSquareIcon = lazy(
  async () =>
    await import('./flag_notification_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrintQuestionIcon = lazy(
  async () =>
    await import('./printer_print_question.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchMinusCopyIcon = lazy(
  async () =>
    await import('./search_minus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecordButtonCircleIcon = lazy(
  async () =>
    await import('./record_button_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EventsListSquareIcon = lazy(
  async () =>
    await import('./events_list_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalOffRemoveCrossIcon = lazy(
  async () =>
    await import('./wifi_signal_off_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldModernLineIcon = lazy(
  async () =>
    await import('./shield_modern_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PazzlePluginMinimalRightIcon = lazy(
  async () =>
    await import('./pazzle_plugin_minimal_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBoardSquareIcon = lazy(
  async () =>
    await import('./menu_board_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleRefreshLadingIcon = lazy(
  async () =>
    await import('./calendar_schedule_refresh_lading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PasswordLockAppSqaureIcon = lazy(
  async () =>
    await import('./password_lock_app_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMenuCheckmarkIcon = lazy(
  async () =>
    await import('./setting_menu_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCheckmarkSqaureIcon = lazy(
  async () =>
    await import('./pin_checkmark_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashDCheckmarkIcon = lazy(
  async () =>
    await import('./bin_trash_d_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleAttachmentLinkIcon = lazy(
  async () =>
    await import('./circle_attachment_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenSquareIcon = lazy(
  async () =>
    await import('./edit_pen_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginRoundCircleIcon = lazy(
  async () =>
    await import('./plugin_round_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackwardRearwardTargetIcon = lazy(
  async () =>
    await import('./backward_rearward_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinSquareIcon = lazy(
  async () =>
    await import('./trash_bin_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyMinimalStyleSquareIcon = lazy(
  async () =>
    await import('./copy_minimal_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleClockIcon = lazy(
  async () =>
    await import('./calendar_schedule_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UmbrellaIcon = lazy(
  async () =>
    await import('./umbrella.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleCrossAddPlusIcon = lazy(
  async () =>
    await import('./bin_trash_style_cross_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiNotificationIcon = lazy(
  async () =>
    await import('./wifi_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonSleepNightModeIcon = lazy(
  async () =>
    await import('./moon_sleep_night_mode.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UnlockCirleIcon = lazy(
  async () =>
    await import('./unlock_cirle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterMailCircleIcon = lazy(
  async () =>
    await import('./letter_mail_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernCircleIcon = lazy(
  async () =>
    await import('./image_picture_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveLinkIcon = lazy(
  async () =>
    await import('./mailbox_archive_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeVerticalArrowsLeftRightCircleIcon = lazy(
  async () =>
    await import('./resize_vertical_arrows_left_right_circle.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const FileDocumentSquareIcon = lazy(
  async () =>
    await import('./file_document_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordProtectionIcon = lazy(
  async () =>
    await import('./input_password_protection.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteIcon = lazy(
  async () =>
    await import('./star_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureSelectIcon = lazy(
  async () =>
    await import('./image_picture_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkModernRightIcon = lazy(
  async () =>
    await import('./broken_link_modern_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BarcodeScanSelectIcon = lazy(
  async () =>
    await import('./barcode_scan_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryModernLineIcon = lazy(
  async () =>
    await import('./library_modern_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockFastIcon = lazy(
  async () =>
    await import('./timer_clock_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketsSqaureCopyIcon = lazy(
  async () =>
    await import('./brackets_sqaure_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchTextLoupeIcon = lazy(
  async () =>
    await import('./search_text_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrivateIncognitoIcon = lazy(
  async () =>
    await import('./private_incognito.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDateRightIcon = lazy(
  async () =>
    await import('./calendar_date_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipVerticalSquareIcon = lazy(
  async () =>
    await import('./clip_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingVerticalSquareIcon = lazy(
  async () =>
    await import('./setting_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchCheckmarkMiddleIcon = lazy(
  async () =>
    await import('./search_checkmark_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IcoIconSqaureIcon = lazy(
  async () =>
    await import('./ico_icon_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchBigIcon = lazy(
  async () =>
    await import('./search_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CodeSearchIcon = lazy(
  async () =>
    await import('./code_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextItemCircleIcon = lazy(
  async () =>
    await import('./text_item_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebAddIcon = lazy(
  async () =>
    await import('./browser_web_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiCheckmarkSquareIcon = lazy(
  async () =>
    await import('./wifi_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScrollHorizontalSyncSquareIcon = lazy(
  async () =>
    await import('./scroll_horizontal_sync_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesCheckmarkIcon = lazy(
  async () =>
    await import('./cookies_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageCrossRemoveDeleteIcon = lazy(
  async () =>
    await import('./chat_message_cross_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCopyPlusAddIcon = lazy(
  async () =>
    await import('./item_copy_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockFastCopyIcon = lazy(
  async () =>
    await import('./timer_clock_fast_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmTimeCrossRemoveIcon = lazy(
  async () =>
    await import('./alarm_time_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmTimerStyleIcon = lazy(
  async () =>
    await import('./alarm_timer_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCircleCutIcon = lazy(
  async () =>
    await import('./copy_circle_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareSignalSquareIcon = lazy(
  async () =>
    await import('./share_signal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageTextIcon = lazy(
  async () =>
    await import('./chat_message_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockIcon = lazy(
  async () =>
    await import('./time_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartBarBargraphIcon = lazy(
  async () =>
    await import('./chart_bar_bargraph.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditMediumSquareIcon = lazy(
  async () =>
    await import('./pen_edit_medium_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SliderWindowsHorizontalModernIcon = lazy(
  async () =>
    await import('./slider_windows_horizontal_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCurvedCornerIcon = lazy(
  async () =>
    await import('./item_text_curved_corner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatModernTextIcon = lazy(
  async () =>
    await import('./chat_modern_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListAddPlusIcon = lazy(
  async () =>
    await import('./task_list_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatAddPlusSquareIcon = lazy(
  async () =>
    await import('./message_chat_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeUndoArrowIcon = lazy(
  async () =>
    await import('./home_undo_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchRightDownSquareIcon = lazy(
  async () =>
    await import('./item_search_right_down_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoStyleCircleIcon = lazy(
  async () =>
    await import('./info_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksFavoriteMinusIcon = lazy(
  async () =>
    await import('./bookmarks_favorite_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DivideIcon = lazy(
  async () =>
    await import('./divide.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PatchIcon = lazy(
  async () =>
    await import('./patch.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarStyleSquareIcon = lazy(
  async () =>
    await import('./star_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PasswordLockProtectionSquareIcon = lazy(
  async () =>
    await import('./password_lock_protection_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserIcon = lazy(
  async () =>
    await import('./user.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuGridCircleIcon = lazy(
  async () =>
    await import('./menu_grid_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookDocumentGroupIcon = lazy(
  async () =>
    await import('./book_document_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageAddPlusIcon = lazy(
  async () =>
    await import('./picture_image_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorArrowAngleCircleIcon = lazy(
  async () =>
    await import('./cursor_arrow_angle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCopyFilesIcon = lazy(
  async () =>
    await import('./select_copy_files.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchZoomInArowRightIcon = lazy(
  async () =>
    await import('./search_zoom_in_arow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudSignalSquareIcon = lazy(
  async () =>
    await import('./cloud_signal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCopyTopRightIcon = lazy(
  async () =>
    await import('./calendar_copy_top_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowLeftBackSquareIcon = lazy(
  async () =>
    await import('./arrow_left_back_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectTargerIcon = lazy(
  async () =>
    await import('./select_targer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphArrowCircleIcon = lazy(
  async () =>
    await import('./paragraph_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const OpenBookCrossDeleteIcon = lazy(
  async () =>
    await import('./open_book_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistArrowLeftIcon = lazy(
  async () =>
    await import('./tasklist_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxCollectionsArchiveMoreDotsIcon = lazy(
  async () =>
    await import('./mailbox_collections_archive_more_dots.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const CopySelectCutIcon = lazy(
  async () =>
    await import('./copy_select_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionsCopyIcon = lazy(
  async () =>
    await import('./questions_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatReplyArrowIcon = lazy(
  async () =>
    await import('./message_chat_reply_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockAlarmRemoveCrossIcon = lazy(
  async () =>
    await import('./time_clock_alarm_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLightShineLeftIcon = lazy(
  async () =>
    await import('./lamp_light_shine_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesArrowIcon = lazy(
  async () =>
    await import('./settings_switches_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageSmileStyleIcon = lazy(
  async () =>
    await import('./message_smile_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistCutLineIcon = lazy(
  async () =>
    await import('./tasklist_cut_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeEarthHomeWorldIcon = lazy(
  async () =>
    await import('./globe_earth_home_world.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareSignalRightSquareIcon = lazy(
  async () =>
    await import('./share_signal_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StatusAddPlusIcon = lazy(
  async () =>
    await import('./status_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchInputSquareIcon = lazy(
  async () =>
    await import('./item_search_input_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecicleIcon = lazy(
  async () =>
    await import('./recicle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipStyleBigIcon = lazy(
  async () =>
    await import('./clip_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleNextArrowIcon = lazy(
  async () =>
    await import('./calendar_schedule_next_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StopMinusOctagonIcon = lazy(
  async () =>
    await import('./stop_minus_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWindowShowSidebarIcon = lazy(
  async () =>
    await import('./browser_window_show_sidebar.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalStyleSquareIcon = lazy(
  async () =>
    await import('./dashboard_minimal_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningErrorAddIcon = lazy(
  async () =>
    await import('./warning_error_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorDisplaySquareIcon = lazy(
  async () =>
    await import('./monitor_display_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReadCircleIcon = lazy(
  async () =>
    await import('./read_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingMinimalStyleSquareIcon = lazy(
  async () =>
    await import('./loading_minimal_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalModernIcon = lazy(
  async () =>
    await import('./dashboard_minimal_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryFolderMoreDotsIcon = lazy(
  async () =>
    await import('./media_library_folder_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendSqaureButtonIcon = lazy(
  async () =>
    await import('./send_sqaure_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeAmericasIcon = lazy(
  async () =>
    await import('./globe_americas.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartLineSquareIcon = lazy(
  async () =>
    await import('./chart_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerCheckmarkIcon = lazy(
  async () =>
    await import('./stiker_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectedCheckmarkIcon = lazy(
  async () =>
    await import('./shield_protected_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockInfoIcon = lazy(
  async () =>
    await import('./time_clock_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentAddPlusTopCentreIcon = lazy(
  async () =>
    await import('./item_document_add_plus_top_centre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectionSecurityMinusSquareIcon = lazy(
  async () =>
    await import('./shield_protection_security_minus_square.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const LinkAngleRightIcon = lazy(
  async () =>
    await import('./link_angle_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardVerticalCircleIcon = lazy(
  async () =>
    await import('./dashboard_vertical_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCropDottedLinesIcon = lazy(
  async () =>
    await import('./item_crop_dotted_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetSelectIcon = lazy(
  async () =>
    await import('./target_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BasketIcon = lazy(
  async () =>
    await import('./basket.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LabelTagCopyIcon = lazy(
  async () =>
    await import('./label_tag_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSmallSquareIcon = lazy(
  async () =>
    await import('./wifi_small_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CaledarDocumentGroupIcon = lazy(
  async () =>
    await import('./caledar_document_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheckmarkCopyIcon = lazy(
  async () =>
    await import('./calendar_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkCircleIcon = lazy(
  async () =>
    await import('./attachment_link_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDateIcon = lazy(
  async () =>
    await import('./calendar_date.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCutDottedLineIcon = lazy(
  async () =>
    await import('./calendar_cut_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadArrowsLoadingIcon = lazy(
  async () =>
    await import('./download_arrows_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagMinusIcon = lazy(
  async () =>
    await import('./flag_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./pen_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatInfoIcon = lazy(
  async () =>
    await import('./message_chat_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorCutSelectDottedLineIcon = lazy(
  async () =>
    await import('./cursor_cut_select_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectionSquareIcon = lazy(
  async () =>
    await import('./shield_protection_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuModernCircleCopyIcon = lazy(
  async () =>
    await import('./menu_modern_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoldIcon = lazy(
  async () =>
    await import('./bold.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadMediaDownSquareIcon = lazy(
  async () =>
    await import('./upload_media_down_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksStyleIcon = lazy(
  async () =>
    await import('./bookmarks_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeMenuLeftIcon = lazy(
  async () =>
    await import('./clock_time_menu_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCrossIcon = lazy(
  async () =>
    await import('./document_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchMoreDotsSqaureIcon = lazy(
  async () =>
    await import('./search_more_dots_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDLineIcon = lazy(
  async () =>
    await import('./trash_bin_d_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadArrowSqaureIcon = lazy(
  async () =>
    await import('./download_arrow_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleLineTopIcon = lazy(
  async () =>
    await import('./bin_trash_style_line_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BatteryChargeIcon = lazy(
  async () =>
    await import('./battery_charge.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationAlarmIcon = lazy(
  async () =>
    await import('./bell_notification_alarm.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowClickDottedLineCircleIcon = lazy(
  async () =>
    await import('./direction_arrow_click_dotted_line_circle.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const MonitorSquareIcon = lazy(
  async () =>
    await import('./monitor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightLampSelectIcon = lazy(
  async () =>
    await import('./light_lamp_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderClearIcon = lazy(
  async () =>
    await import('./border_clear.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageTrendLightningIcon = lazy(
  async () =>
    await import('./chat_message_trend_lightning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AdNotificationCircleIcon = lazy(
  async () =>
    await import('./ad_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleSquareIcon = lazy(
  async () =>
    await import('./email_letter_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterMinimalStyleCircleIcon = lazy(
  async () =>
    await import('./filter_minimal_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignCheckmarkIcon = lazy(
  async () =>
    await import('./sign_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HourglassSquareIcon = lazy(
  async () =>
    await import('./hourglass_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumpsUpCircleIcon = lazy(
  async () =>
    await import('./thumps_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockAlarmMinusIcon = lazy(
  async () =>
    await import('./time_clock_alarm_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerCopyIcon = lazy(
  async () =>
    await import('./sticker_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarAddCircleIcon = lazy(
  async () =>
    await import('./calendar_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyObjectIcon = lazy(
  async () =>
    await import('./copy_object.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMenuSquareIcon = lazy(
  async () =>
    await import('./edit_pen_menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FiledownIcon = lazy(
  async () =>
    await import('./filedown.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageStarsIcon = lazy(
  async () =>
    await import('./picture_image_stars.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatStyleAddPlusIcon = lazy(
  async () =>
    await import('./message_chat_style_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkBigCircleIcon = lazy(
  async () =>
    await import('./checkmark_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatAddPlusIcon = lazy(
  async () =>
    await import('./message_chat_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornStyleBigIcon = lazy(
  async () =>
    await import('./horn_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecMicrophoneMicOctagonIcon = lazy(
  async () =>
    await import('./rec_microphone_mic_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCrossIcon = lazy(
  async () =>
    await import('./fingerprint_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListCheckmarkIcon = lazy(
  async () =>
    await import('./item_list_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectDotsVerticalMoreIcon = lazy(
  async () =>
    await import('./select_dots_vertical_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatWifiIcon = lazy(
  async () =>
    await import('./message_chat_wifi.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowRightIcon = lazy(
  async () =>
    await import('./arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTitleTasklistRightTopIcon = lazy(
  async () =>
    await import('./item_title_tasklist_right_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoHexagonIcon = lazy(
  async () =>
    await import('./info_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockEditIcon = lazy(
  async () =>
    await import('./time_clock_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoSquareIcon = lazy(
  async () =>
    await import('./info_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagePasswordInputIcon = lazy(
  async () =>
    await import('./chat_message_password_input.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SubstractGroupCopyAddIcon = lazy(
  async () =>
    await import('./substract_group_copy_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageEyeCopyIcon = lazy(
  async () =>
    await import('./message_eye_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemAddPinSqaureIcon = lazy(
  async () =>
    await import('./item_add_pin_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadedCheckmarkSquareIcon = lazy(
  async () =>
    await import('./downloaded_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCircleIcon = lazy(
  async () =>
    await import('./clip_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDeleteCrossIcon = lazy(
  async () =>
    await import('./trash_bin_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingRotateArrowSquareIcon = lazy(
  async () =>
    await import('./loading_rotate_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectedDisabledRossIcon = lazy(
  async () =>
    await import('./shield_protected_disabled_ross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListChartLineIcon = lazy(
  async () =>
    await import('./task_list_chart_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListTasksDeliteRemoveIcon = lazy(
  async () =>
    await import('./list_tasks_delite_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlassesInvisibleIcon = lazy(
  async () =>
    await import('./glasses_invisible.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudUploadCopyIcon = lazy(
  async () =>
    await import('./cloud_upload_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTaskListMinusDeleteSquareIcon = lazy(
  async () =>
    await import('./item_task_list_minus_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditRefreshIcon = lazy(
  async () =>
    await import('./pen_edit_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxInfoIcon = lazy(
  async () =>
    await import('./mailbox_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlockCrossRemoveDeleteSquareIcon = lazy(
  async () =>
    await import('./block_cross_remove_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeFastIcon = lazy(
  async () =>
    await import('./time_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningAlarmSquareIcon = lazy(
  async () =>
    await import('./warning_alarm_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningErrorDeleteIcon = lazy(
  async () =>
    await import('./warning_error_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemLeftTopCopyIcon = lazy(
  async () =>
    await import('./copy_item_left_top_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerPauseClockCircleIcon = lazy(
  async () =>
    await import('./timer_pause_clock_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiPortableOffIcon = lazy(
  async () =>
    await import('./wifi_portable_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectDeleteCrossIcon = lazy(
  async () =>
    await import('./select_object_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraIcon = lazy(
  async () =>
    await import('./camera.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureEditStarsCircleIcon = lazy(
  async () =>
    await import('./image_picture_edit_stars_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleEditIcon = lazy(
  async () =>
    await import('./calendar_schedule_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatClipIcon = lazy(
  async () =>
    await import('./message_chat_clip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadArrowUpIcon = lazy(
  async () =>
    await import('./upload_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchIcon = lazy(
  async () =>
    await import('./clock_watch.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextInputCirleIcon = lazy(
  async () =>
    await import('./text_input_cirle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoubleCheckmarkReadSquareIcon = lazy(
  async () =>
    await import('./double_checkmark_read_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldModernDownloadArrowBigCenterIcon = lazy(
  async () =>
    await import('./shield_modern_download_arrow_big_center.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const CursorSelectCopyIcon = lazy(
  async () =>
    await import('./cursor_select_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatLikeHeartIcon = lazy(
  async () =>
    await import('./message_chat_like_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageTextEditIcon = lazy(
  async () =>
    await import('./message_text_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkOctagonIcon = lazy(
  async () =>
    await import('./bookmark_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkHorizontalSquareIcon = lazy(
  async () =>
    await import('./link_horizontal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkLineOctagonIcon = lazy(
  async () =>
    await import('./bookmark_line_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuMoreEditSquareIcon = lazy(
  async () =>
    await import('./menu_more_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveArowIcon = lazy(
  async () =>
    await import('./mailbox_archive_arow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleBigCircleIcon = lazy(
  async () =>
    await import('./filter_style_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleAddPlusIcon = lazy(
  async () =>
    await import('./calendar_schedule_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkLeftArrowCircleIcon = lazy(
  async () =>
    await import('./attachment_link_left_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TvChartStyleIcon = lazy(
  async () =>
    await import('./tv_chart_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FileZipRarCopyIcon = lazy(
  async () =>
    await import('./file_zip_rar_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinVerticalIcon = lazy(
  async () =>
    await import('./pin_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveSquareIcon = lazy(
  async () =>
    await import('./floppy_save_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeEditIcon = lazy(
  async () =>
    await import('./home_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyIcon = lazy(
  async () =>
    await import('./copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseCircleVerticalIcon = lazy(
  async () =>
    await import('./mouse_circle_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatStyleTextIcon = lazy(
  async () =>
    await import('./message_chat_style_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NewsPaperTextIcon = lazy(
  async () =>
    await import('./news_paper_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentMoreIcon = lazy(
  async () =>
    await import('./document_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketsSqaureIcon = lazy(
  async () =>
    await import('./brackets_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowUpDownIcon = lazy(
  async () =>
    await import('./arrow_up_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationOffCircleIcon = lazy(
  async () =>
    await import('./bell_notification_off_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionDottedLineSelectIcon = lazy(
  async () =>
    await import('./question_dotted_line_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowResizeLeftIcon = lazy(
  async () =>
    await import('./window_resize_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeAddTimerIcon = lazy(
  async () =>
    await import('./clock_time_add_timer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebWindowsDeleteIcon = lazy(
  async () =>
    await import('./browser_web_windows_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoubleCheckmarkReadCircleIcon = lazy(
  async () =>
    await import('./double_checkmark_read_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemArrowIcon = lazy(
  async () =>
    await import('./copy_item_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrownLineSquareIcon = lazy(
  async () =>
    await import('./crown_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheduleCopyIcon = lazy(
  async () =>
    await import('./calendar_chedule_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchRefreshIcon = lazy(
  async () =>
    await import('./search_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesSquareIcon = lazy(
  async () =>
    await import('./cookies_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeCircleCopyIcon = lazy(
  async () =>
    await import('./clock_time_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintNotificationSettingIcon = lazy(
  async () =>
    await import('./fingerprint_notification_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeMinimalLineCircleIcon = lazy(
  async () =>
    await import('./home_minimal_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FeatherPenCircleEditSquareIcon = lazy(
  async () =>
    await import('./feather_pen_circle_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalAddPlusIcon = lazy(
  async () =>
    await import('./wifi_signal_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinDotsCircleIcon = lazy(
  async () =>
    await import('./pin_dots_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PaperAddItemIcon = lazy(
  async () =>
    await import('./paper_add_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextPinIcon = lazy(
  async () =>
    await import('./document_text_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksFavoriteIcon = lazy(
  async () =>
    await import('./bookmarks_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnchorIcon = lazy(
  async () =>
    await import('./anchor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklIstIcon = lazy(
  async () =>
    await import('./taskl_ist.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookOpenSquareIcon = lazy(
  async () =>
    await import('./book_open_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchCrossRemoveIcon = lazy(
  async () =>
    await import('./search_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCopyDotsLineIcon = lazy(
  async () =>
    await import('./item_copy_dots_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveTextIcon = lazy(
  async () =>
    await import('./mailbox_archive_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CirclesDeleteCrossIcon = lazy(
  async () =>
    await import('./circles_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeLoadingBackIcon = lazy(
  async () =>
    await import('./time_loading_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureEditIcon = lazy(
  async () =>
    await import('./image_picture_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureMoveLeftArrowIcon = lazy(
  async () =>
    await import('./image_picture_move_left_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatInfoCopyIcon = lazy(
  async () =>
    await import('./message_chat_info_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSearchSquareIcon = lazy(
  async () =>
    await import('./object_search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendArrowCircleIcon = lazy(
  async () =>
    await import('./send_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCheckmarkIcon = lazy(
  async () =>
    await import('./loading_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCirlePlusAddIcon = lazy(
  async () =>
    await import('./menu_cirle_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagMoreMenuIcon = lazy(
  async () =>
    await import('./flag_more_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorInfoCopyIcon = lazy(
  async () =>
    await import('./cursor_info_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerCheckmarkIcon = lazy(
  async () =>
    await import('./sticker_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./eye_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCrossErrorIcon = lazy(
  async () =>
    await import('./copy_cross_error.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftClickCircleIcon = lazy(
  async () =>
    await import('./cursor_left_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnalyticsChartAddPlusCircleIcon = lazy(
  async () =>
    await import('./analytics_chart_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackspaceDeleteButtonCheckmarkIcon = lazy(
  async () =>
    await import('./backspace_delete_button_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingSwitchesIcon = lazy(
  async () =>
    await import('./setting_switches.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MirrorHorizontalIcon = lazy(
  async () =>
    await import('./mirror_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InboxMaleIncomingIcon = lazy(
  async () =>
    await import('./inbox_male_incoming.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCursorItemIcon = lazy(
  async () =>
    await import('./select_cursor_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiAddSquareIcon = lazy(
  async () =>
    await import('./wifi_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecMicrophoneMicCrossRemoveOctagonIcon = lazy(
  async () =>
    await import('./rec_microphone_mic_cross_remove_octagon.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const ClockTimeCheckmarkCopyIcon = lazy(
  async () =>
    await import('./clock_time_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeAlarmCheckmarkIcon = lazy(
  async () =>
    await import('./clock_time_alarm_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CertificateCheckmarkSquareIcon = lazy(
  async () =>
    await import('./certificate_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReadCheckmarkSquareIcon = lazy(
  async () =>
    await import('./read_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextResizeArrowIcon = lazy(
  async () =>
    await import('./text_resize_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageDotsIcon = lazy(
  async () =>
    await import('./chat_message_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelecClickIcon = lazy(
  async () =>
    await import('./cursor_selec_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandStopSignSquareIcon = lazy(
  async () =>
    await import('./hand_stop_sign_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkCircleDeleteCrossIcon = lazy(
  async () =>
    await import('./attachment_link_circle_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionCrossIcon = lazy(
  async () =>
    await import('./arrow_direction_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxCheckmarkIcon = lazy(
  async () =>
    await import('./mailbox_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PhotoCameraAddCircleIcon = lazy(
  async () =>
    await import('./photo_camera_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryAppPlusDownIcon = lazy(
  async () =>
    await import('./media_library_app_plus_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenListSquareCopyIcon = lazy(
  async () =>
    await import('./edit_pen_list_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UnderlineIcon = lazy(
  async () =>
    await import('./underline.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleRingIcon = lazy(
  async () =>
    await import('./bell_style_ring.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkFileAddIcon = lazy(
  async () =>
    await import('./attachment_link_file_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BatteryChargingLightningIcon = lazy(
  async () =>
    await import('./battery_charging_lightning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyFilesSquareIcon = lazy(
  async () =>
    await import('./copy_files_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoSelectCopyIcon = lazy(
  async () =>
    await import('./info_select_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DiamondBlankIcon = lazy(
  async () =>
    await import('./diamond_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectScanMinimalSquareIcon = lazy(
  async () =>
    await import('./select_scan_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftSquareIcon = lazy(
  async () =>
    await import('./cursor_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecycleCircleIcon = lazy(
  async () =>
    await import('./recycle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateModernCircleIcon = lazy(
  async () =>
    await import('./rotate_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotepadPlusAddIcon = lazy(
  async () =>
    await import('./notepad_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StampCircleIcon = lazy(
  async () =>
    await import('./stamp_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneSquareIcon = lazy(
  async () =>
    await import('./microphone_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleDownloadLineBlankIcon = lazy(
  async () =>
    await import('./bin_trash_style_download_line_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AdsAdvertismentPromotionIcon = lazy(
  async () =>
    await import('./ads_advertisment_promotion.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeArrowsSquareIcon = lazy(
  async () =>
    await import('./resize_arrows_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteBinSelectIcon = lazy(
  async () =>
    await import('./trash_delete_bin_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditCircleIcon = lazy(
  async () =>
    await import('./pen_edit_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardRoundIcon = lazy(
  async () =>
    await import('./dashboard_round.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageModernCopyIcon = lazy(
  async () =>
    await import('./chat_message_modern_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemCircleIcon = lazy(
  async () =>
    await import('./menu_item_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LaptopMinimalSquareIcon = lazy(
  async () =>
    await import('./laptop_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectedSquareIcon = lazy(
  async () =>
    await import('./shield_protected_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuShareSquareIcon = lazy(
  async () =>
    await import('./menu_share_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenTextRightDownCircleIcon = lazy(
  async () =>
    await import('./edit_pen_text_right_down_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureLinkIcon = lazy(
  async () =>
    await import('./image_picture_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarArrowRightIcon = lazy(
  async () =>
    await import('./calendar_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardArrowRightCirceIcon = lazy(
  async () =>
    await import('./dashboard_arrow_right_circe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickSquareIcon = lazy(
  async () =>
    await import('./cursor_click_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraSquareCopyIcon = lazy(
  async () =>
    await import('./camera_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoupeCrossDeleteIcon = lazy(
  async () =>
    await import('./loupe_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuHeartLikeSquareIcon = lazy(
  async () =>
    await import('./menu_heart_like_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinAddPlusCircleIcon = lazy(
  async () =>
    await import('./pin_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimerModernSquareIcon = lazy(
  async () =>
    await import('./clock_timer_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupShareIcon = lazy(
  async () =>
    await import('./group_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyRightTopArrowUpIcon = lazy(
  async () =>
    await import('./copy_right_top_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuIcon = lazy(
  async () =>
    await import('./menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCircleCopyIcon = lazy(
  async () =>
    await import('./loading_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectLinesIcon = lazy(
  async () =>
    await import('./select_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoLoadingCircleIcon = lazy(
  async () =>
    await import('./info_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeEarthCheckmarkIcon = lazy(
  async () =>
    await import('./globe_earth_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardShowIcon = lazy(
  async () =>
    await import('./keyboard_show.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleCheckmarkIcon = lazy(
  async () =>
    await import('./email_letter_style_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerAddPlusIcon = lazy(
  async () =>
    await import('./sticker_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterModernMinimalLineIcon = lazy(
  async () =>
    await import('./printer_modern_minimal_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSimpleSignalCircleIcon = lazy(
  async () =>
    await import('./wifi_simple_signal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GifButtonSquareIcon = lazy(
  async () =>
    await import('./gif_button_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiQuestionIcon = lazy(
  async () =>
    await import('./wifi_question.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UnlockStyleBlankIcon = lazy(
  async () =>
    await import('./unlock_style_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuArrowCircleIcon = lazy(
  async () =>
    await import('./menu_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginLogoutArrowIcon = lazy(
  async () =>
    await import('./login_logout_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputTextSquareIcon = lazy(
  async () =>
    await import('./input_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorStyleSquareIcon = lazy(
  async () =>
    await import('./cursor_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMoreSquareIcon = lazy(
  async () =>
    await import('./edit_pen_more_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockAlarmMoreDotsIcon = lazy(
  async () =>
    await import('./clock_alarm_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimalMinusBigIcon = lazy(
  async () =>
    await import('./monitor_minimal_minus_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerMinusRemoveIcon = lazy(
  async () =>
    await import('./timer_minus_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageEditPenDottedLineIcon = lazy(
  async () =>
    await import('./message_edit_pen_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PaperListDocumentLinkIcon = lazy(
  async () =>
    await import('./paper_list_document_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeLockIcon = lazy(
  async () =>
    await import('./home_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCrossDeleteIcon = lazy(
  async () =>
    await import('./menu_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagicWandCircleIcon = lazy(
  async () =>
    await import('./magic_wand_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailCheckmarkIcon = lazy(
  async () =>
    await import('./email_mail_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryMiddleLinesIcon = lazy(
  async () =>
    await import('./media_library_middle_lines.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyDuplicateObjectAddPlusIcon = lazy(
  async () =>
    await import('./copy_duplicate_object_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextInputCircleIcon = lazy(
  async () =>
    await import('./text_input_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBarSpaceVerticalIcon = lazy(
  async () =>
    await import('./menu_bar_space_vertical.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchAddPlusSquareIcon = lazy(
  async () =>
    await import('./search_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemHeartFavoriteCircleIcon = lazy(
  async () =>
    await import('./item_heart_favorite_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeCircleIcon = lazy(
  async () =>
    await import('./search_loupe_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteCircleIcon = lazy(
  async () =>
    await import('./bookmark_favorite_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxInboxArchiveIcon = lazy(
  async () =>
    await import('./mailbox_inbox_archive.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickCopyIcon = lazy(
  async () =>
    await import('./cursor_click_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BatteryChargingMaxIcon = lazy(
  async () =>
    await import('./battery_charging_max.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerFastLeftIcon = lazy(
  async () =>
    await import('./timer_fast_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListRemoveIcon = lazy(
  async () =>
    await import('./list_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkHorizontalLeftBigIcon = lazy(
  async () =>
    await import('./link_horizontal_left_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BluetoothCrossedOffSqureIcon = lazy(
  async () =>
    await import('./bluetooth_crossed_off_squre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningMinimalWarningSquareIcon = lazy(
  async () =>
    await import('./warning_minimal_warning_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BatteryChargingMinimalIcon = lazy(
  async () =>
    await import('./battery_charging_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StrikeIcon = lazy(
  async () =>
    await import('./strike.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapBigMinimalIcon = lazy(
  async () =>
    await import('./map_big_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartAppIconIcon = lazy(
  async () =>
    await import('./heart_app_icon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartSmallSquareIcon = lazy(
  async () =>
    await import('./chart_small_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SmileyFaceMessageIcon = lazy(
  async () =>
    await import('./smiley_face_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatSmileysIcon = lazy(
  async () =>
    await import('./message_chat_smileys.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileSquareIcon = lazy(
  async () =>
    await import('./user_profile_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarEventIcon = lazy(
  async () =>
    await import('./calendar_event.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskSheetCheckMarkIcon = lazy(
  async () =>
    await import('./task_sheet_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBurgeSqaureIcon = lazy(
  async () =>
    await import('./menu_burge_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyArrowRightIcon = lazy(
  async () =>
    await import('./copy_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsScrollHorizontalCircleIcon = lazy(
  async () =>
    await import('./arrows_scroll_horizontal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./wifi_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendEmailFastCircleIcon = lazy(
  async () =>
    await import('./send_email_fast_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentDeleteCrossTopCentreIcon = lazy(
  async () =>
    await import('./item_document_delete_cross_top_centre.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const CalendarAddPlusLineIcon = lazy(
  async () =>
    await import('./calendar_add_plus_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoxIcon = lazy(
  async () =>
    await import('./box.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LeftRightArrowsCircleIcon = lazy(
  async () =>
    await import('./left_right_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeRemovecCrossShareIcon = lazy(
  async () =>
    await import('./resize_removec_cross_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsSearchIcon = lazy(
  async () =>
    await import('./chart_analytics_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpaceSquareIcon = lazy(
  async () =>
    await import('./space_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendHorizontalTopBlankBigIcon = lazy(
  async () =>
    await import('./send_horizontal_top_blank_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraCircleIcon = lazy(
  async () =>
    await import('./camera_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAngleMinimalCircleIcon = lazy(
  async () =>
    await import('./link_angle_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SquareDIcon = lazy(
  async () =>
    await import('./square_d.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkHeartDeleteCrossSquareIcon = lazy(
  async () =>
    await import('./bookmark_heart_delete_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CodeTextSquareIcon = lazy(
  async () =>
    await import('./code_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetMinimalStyleBigIcon = lazy(
  async () =>
    await import('./target_minimal_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsGearSquareIcon = lazy(
  async () =>
    await import('./settings_gear_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditMinimalInputSquareIcon = lazy(
  async () =>
    await import('./pen_edit_minimal_input_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeFastSquareIcon = lazy(
  async () =>
    await import('./search_loupe_fast_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartPercentSquareIcon = lazy(
  async () =>
    await import('./chart_percent_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareCopyIcon = lazy(
  async () =>
    await import('./share_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinMenuSquareIcon = lazy(
  async () =>
    await import('./pin_menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StampSquareIcon = lazy(
  async () =>
    await import('./stamp_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterMailCheckmarkIcon = lazy(
  async () =>
    await import('./letter_mail_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkCircleMenuIcon = lazy(
  async () =>
    await import('./attachment_link_circle_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordNumbersIcon = lazy(
  async () =>
    await import('./input_password_numbers.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiAddPlusCopyIcon = lazy(
  async () =>
    await import('./wifi_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickSelectSquareIcon = lazy(
  async () =>
    await import('./cursor_click_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryFolderIcon = lazy(
  async () =>
    await import('./media_library_folder.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridLayersAddIcon = lazy(
  async () =>
    await import('./grid_layers_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuoteRightBottomSquareIcon = lazy(
  async () =>
    await import('./quote_right_bottom_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const OptionButtonSquareIcon = lazy(
  async () =>
    await import('./option_button_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSquareCircleIcon = lazy(
  async () =>
    await import('./object_square_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxLoadingIcon = lazy(
  async () =>
    await import('./mailbox_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemSelectIcon = lazy(
  async () =>
    await import('./copy_item_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuAddSignalIcon = lazy(
  async () =>
    await import('./menu_add_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListAddCheckmarkIcon = lazy(
  async () =>
    await import('./task_list_add_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveIcon = lazy(
  async () =>
    await import('./mailbox_archive.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsButtonIcon = lazy(
  async () =>
    await import('./windows_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageFavoriteHeartIcon = lazy(
  async () =>
    await import('./picture_image_favorite_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistStyleLineIcon = lazy(
  async () =>
    await import('./tasklist_style_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CaledarScheduleListSearchIcon = lazy(
  async () =>
    await import('./caledar_schedule_list_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleCheckmarkCircleIcon = lazy(
  async () =>
    await import('./filter_style_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CipSquareIcon = lazy(
  async () =>
    await import('./cip_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemAddPlusPinCircleIcon = lazy(
  async () =>
    await import('./item_add_plus_pin_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCheckmarkCircleIcon = lazy(
  async () =>
    await import('./menu_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterIcon = lazy(
  async () =>
    await import('./printer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksCopyIcon = lazy(
  async () =>
    await import('./bookmarks_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintMinusDeleteIcon = lazy(
  async () =>
    await import('./fingerprint_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuDotsIcon = lazy(
  async () =>
    await import('./menu_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerCircleIcon = lazy(
  async () =>
    await import('./sticker_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AddPlusDottedLineCircleIcon = lazy(
  async () =>
    await import('./add_plus_dotted_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrintButtonCircleIcon = lazy(
  async () =>
    await import('./printer_print_button_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderStyleIcon = lazy(
  async () =>
    await import('./border_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditBigCircleIcon = lazy(
  async () =>
    await import('./pen_edit_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicOctagonIcon = lazy(
  async () =>
    await import('./microphone_mic_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsAddPlusIcon = lazy(
  async () =>
    await import('./chart_analytics_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleBrokenLineCutIcon = lazy(
  async () =>
    await import('./circle_broken_line_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernBlankIcon = lazy(
  async () =>
    await import('./message_chat_modern_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeLineModernIcon = lazy(
  async () =>
    await import('./home_line_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellAddPlusRingIcon = lazy(
  async () =>
    await import('./bell_add_plus_ring.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockStyleMiddleSquareIcon = lazy(
  async () =>
    await import('./lock_style_middle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BokmarkSmallCricleIcon = lazy(
  async () =>
    await import('./bokmark_small_cricle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListArrowUpSquareIcon = lazy(
  async () =>
    await import('./item_list_arrow_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsCircleSyncIcon = lazy(
  async () =>
    await import('./arrows_circle_sync.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxChangeCheckmarkDeleteCrossIcon = lazy(
  async () =>
    await import('./checkbox_change_checkmark_delete_cross.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const CopyItemGroupIcon = lazy(
  async () =>
    await import('./copy_item_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadSpaceArrowUpIcon = lazy(
  async () =>
    await import('./upload_space_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningAddPlusSquareIcon = lazy(
  async () =>
    await import('./warning_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveCrossMoreMenuIcon = lazy(
  async () =>
    await import('./floppy_save_cross_more_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCrossDeleteIcon = lazy(
  async () =>
    await import('./calendar_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinRemoveCrossCircleIcon = lazy(
  async () =>
    await import('./pin_remove_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCheckmarkCircleCopyIcon = lazy(
  async () =>
    await import('./chart_checkmark_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirdropShareIcon = lazy(
  async () =>
    await import('./airdrop_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockSqaureIcon = lazy(
  async () =>
    await import('./alarm_clock_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrownCircleIcon = lazy(
  async () =>
    await import('./crown_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlendarIcon = lazy(
  async () =>
    await import('./alendar.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListAddPlusCopyIcon = lazy(
  async () =>
    await import('./check_list_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerAddPlusCopyIcon = lazy(
  async () =>
    await import('./sticker_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebLinkIcon = lazy(
  async () =>
    await import('./browser_web_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextImagePictureIcon = lazy(
  async () =>
    await import('./text_image_picture.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadAllLineCircleIcon = lazy(
  async () =>
    await import('./download_all_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebWindowsFavoriteIcon = lazy(
  async () =>
    await import('./browser_web_windows_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionNotificationSquareIcon = lazy(
  async () =>
    await import('./question_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashCircleIcon = lazy(
  async () =>
    await import('./bin_trash_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncSynchronizeSquareIcon = lazy(
  async () =>
    await import('./sync_synchronize_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCheckmarkSquareCopyIcon = lazy(
  async () =>
    await import('./warning_checkmark_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleSquareIcon = lazy(
  async () =>
    await import('./setting_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinBoxCheckmarkIcon = lazy(
  async () =>
    await import('./bin_box_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupCopyIcon = lazy(
  async () =>
    await import('./group_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkSquareIcon = lazy(
  async () =>
    await import('./checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampSparkCopyIcon = lazy(
  async () =>
    await import('./lamp_spark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMuteIcon = lazy(
  async () =>
    await import('./microphone_mute.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAttachmentStyleSquareIcon = lazy(
  async () =>
    await import('./link_attachment_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowNavigationDirectionIcon = lazy(
  async () =>
    await import('./arrow_navigation_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingEditFilterGearCircleIcon = lazy(
  async () =>
    await import('./setting_edit_filter_gear_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureClassicSquareIcon = lazy(
  async () =>
    await import('./image_picture_classic_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCiecleIcon = lazy(
  async () =>
    await import('./loading_ciecle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MultiShotImageIcon = lazy(
  async () =>
    await import('./multi_shot_image.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchCheckmarkSquareIcon = lazy(
  async () =>
    await import('./search_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarSquareIcon = lazy(
  async () =>
    await import('./star_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleIcon = lazy(
  async () =>
    await import('./setting_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorCircleIcon = lazy(
  async () =>
    await import('./cursor_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsHeartIcon = lazy(
  async () =>
    await import('./windows_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecMicSquareIcon = lazy(
  async () =>
    await import('./rec_mic_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsFilterCircleIcon = lazy(
  async () =>
    await import('./settings_filter_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UxCircleIcon = lazy(
  async () =>
    await import('./ux_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerSquareIcon = lazy(
  async () =>
    await import('./sticker_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowUturnUpSquareIcon = lazy(
  async () =>
    await import('./arrow_uturn_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CaledarDateListRightAlignIcon = lazy(
  async () =>
    await import('./caledar_date_list_right_align.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSettingEditFilterIcon = lazy(
  async () =>
    await import('./cursor_setting_edit_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageEditIcon = lazy(
  async () =>
    await import('./chat_message_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSwipeDownIcon = lazy(
  async () =>
    await import('./cursor_swipe_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteRemoveMoreDotsIcon = lazy(
  async () =>
    await import('./heart_favorite_remove_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSelectIcon = lazy(
  async () =>
    await import('./menu_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimalCircleIcon = lazy(
  async () =>
    await import('./monitor_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleMinimalIcon = lazy(
  async () =>
    await import('./email_letter_style_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleOctagonIcon = lazy(
  async () =>
    await import('./filter_style_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCutCrossCircleIcon = lazy(
  async () =>
    await import('./item_cut_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSpotSquareIcon = lazy(
  async () =>
    await import('./wifi_spot_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignOutRightIcon = lazy(
  async () =>
    await import('./sign_out_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingClassicCircleIcon = lazy(
  async () =>
    await import('./loading_classic_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyLoginProtectionIcon = lazy(
  async () =>
    await import('./key_login_protection.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatStyleCheckmarkIcon = lazy(
  async () =>
    await import('./message_chat_style_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectAddCircleIcon = lazy(
  async () =>
    await import('./object_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintHexagonIcon = lazy(
  async () =>
    await import('./fingerprint_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TvCheckmarkIcon = lazy(
  async () =>
    await import('./tv_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemInputSquareIcon = lazy(
  async () =>
    await import('./item_input_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScrollUpCircleIcon = lazy(
  async () =>
    await import('./scroll_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecSelectCopyIcon = lazy(
  async () =>
    await import('./voice_rec_select_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoubleRewindArrowUploadCircleIcon = lazy(
  async () =>
    await import('./double_rewind_arrow_upload_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveCitcleIcon = lazy(
  async () =>
    await import('./zip_archive_citcle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./star_favorite_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentAddPlusLeftIcon = lazy(
  async () =>
    await import('./document_add_plus_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeCheckmarkIcon = lazy(
  async () =>
    await import('./search_loupe_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonNightModeCopyIcon = lazy(
  async () =>
    await import('./moon_night_mode_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerRemoveMinusIcon = lazy(
  async () =>
    await import('./sticker_remove_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinAngleCrossIcon = lazy(
  async () =>
    await import('./pin_angle_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectTextSquareIcon = lazy(
  async () =>
    await import('./cursor_select_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockCorssDeleteIcon = lazy(
  async () =>
    await import('./lock_corss_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListCrossRemoveCircleIcon = lazy(
  async () =>
    await import('./item_list_cross_remove_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryTimeClockIcon = lazy(
  async () =>
    await import('./media_library_time_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCircleCopyIcon = lazy(
  async () =>
    await import('./chart_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockMinimalOctagonIcon = lazy(
  async () =>
    await import('./lock_minimal_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectCircleMoreDotsIcon = lazy(
  async () =>
    await import('./object_circle_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageTextCrossChecklistIcon = lazy(
  async () =>
    await import('./chat_message_text_cross_checklist.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernAddPlusIcon = lazy(
  async () =>
    await import('./message_chat_modern_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkBigIcon = lazy(
  async () =>
    await import('./link_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeadphonesCircleIcon = lazy(
  async () =>
    await import('./headphones_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginEnterArrowIcon = lazy(
  async () =>
    await import('./login_enter_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistEditPenIcon = lazy(
  async () =>
    await import('./tasklist_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldHeartIcon = lazy(
  async () =>
    await import('./shield_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLineCircleIcon = lazy(
  async () =>
    await import('./pin_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadArrowsBigSquareIcon = lazy(
  async () =>
    await import('./download_arrows_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingMinimalCircleIcon = lazy(
  async () =>
    await import('./loading_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeMinimalCircleIcon = lazy(
  async () =>
    await import('./home_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkEditPenCircleIcon = lazy(
  async () =>
    await import('./item_checkmark_edit_pen_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationCrossOffSquareIcon = lazy(
  async () =>
    await import('./bell_notification_cross_off_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrinWifiIcon = lazy(
  async () =>
    await import('./printer_prin_wifi.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardMinimalCircleIcon = lazy(
  async () =>
    await import('./keyboard_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteBinSquareIcon = lazy(
  async () =>
    await import('./trash_delete_bin_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeMenuFilterIcon = lazy(
  async () =>
    await import('./search_loupe_menu_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailsLetterMailSquareIcon = lazy(
  async () =>
    await import('./emails_letter_mail_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistArrowRightIcon = lazy(
  async () =>
    await import('./tasklist_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentFileZipIcon = lazy(
  async () =>
    await import('./document_file_zip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesDeleteCrossIcon = lazy(
  async () =>
    await import('./settings_switches_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseScrollDownCircleIcon = lazy(
  async () =>
    await import('./mouse_scroll_down_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectionWifiSignalModernIcon = lazy(
  async () =>
    await import('./shield_protection_wifi_signal_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TvMoreIcon = lazy(
  async () =>
    await import('./tv_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StopSignHexagonIcon = lazy(
  async () =>
    await import('./stop_sign_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapSquareIcon = lazy(
  async () =>
    await import('./compass_map_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockUnlockRemoveCircleIcon = lazy(
  async () =>
    await import('./lock_unlock_remove_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorStyleClickSquareIcon = lazy(
  async () =>
    await import('./cursor_style_click_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCutLineIcon = lazy(
  async () =>
    await import('./calendar_cut_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCursorSelectCircleIcon = lazy(
  async () =>
    await import('./menu_cursor_select_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeySignInIcon = lazy(
  async () =>
    await import('./key_sign_in.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshArrowsSquareIcon = lazy(
  async () =>
    await import('./refresh_arrows_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PowerOnIcon = lazy(
  async () =>
    await import('./power_on.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetVerticalDownBigIcon = lazy(
  async () =>
    await import('./magnet_vertical_down_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCheckmarkIcon = lazy(
  async () =>
    await import('./chart_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldCrossIcon = lazy(
  async () =>
    await import('./shield_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimalCheckmarkBigIcon = lazy(
  async () =>
    await import('./monitor_minimal_checkmark_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChecklistCircleIcon = lazy(
  async () =>
    await import('./checklist_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkBigSquareCopyIcon = lazy(
  async () =>
    await import('./checkmark_big_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepModeStarsIcon = lazy(
  async () =>
    await import('./sleep_mode_stars.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectTextTranslateIcon = lazy(
  async () =>
    await import('./object_text_translate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UiDesignSqaureIcon = lazy(
  async () =>
    await import('./ui_design_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListMenuItemIcon = lazy(
  async () =>
    await import('./list_menu_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkIcon = lazy(
  async () =>
    await import('./bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserSquareIcon = lazy(
  async () =>
    await import('./user_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareMinimalCircleIcon = lazy(
  async () =>
    await import('./share_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveStyleSquareIcon = lazy(
  async () =>
    await import('./zip_archive_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalHotspotSquareIcon = lazy(
  async () =>
    await import('./signal_hotspot_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImageCopyCrossIcon = lazy(
  async () =>
    await import('./image_copy_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextItemInputSquareIcon = lazy(
  async () =>
    await import('./text_item_input_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarBokmarkIcon = lazy(
  async () =>
    await import('./calendar_bokmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockStyleBlankIcon = lazy(
  async () =>
    await import('./lock_style_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSquareIcon = lazy(
  async () =>
    await import('./folder_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemEnterArrowRightEditIcon = lazy(
  async () =>
    await import('./item_enter_arrow_right_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UxDesignSqaureIcon = lazy(
  async () =>
    await import('./ux_design_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardStyleLeftCircleIcon = lazy(
  async () =>
    await import('./dashboard_style_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBookmarkCircleIcon = lazy(
  async () =>
    await import('./menu_bookmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesDeleteIcon = lazy(
  async () =>
    await import('./chat_messages_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockCheckmarkCircleIcon = lazy(
  async () =>
    await import('./lock_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardUpCircleIcon = lazy(
  async () =>
    await import('./dashboard_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilesLibraryMediaLibraryIcon = lazy(
  async () =>
    await import('./files_library_media_library.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatEyeCopyIcon = lazy(
  async () =>
    await import('./message_chat_eye_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldCmodernCircleIcon = lazy(
  async () =>
    await import('./shield_cmodern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatEditPenIcon = lazy(
  async () =>
    await import('./message_chat_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintMoreDotsIcon = lazy(
  async () =>
    await import('./fingerprint_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveObjectItemIcon = lazy(
  async () =>
    await import('./floppy_save_object_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterSquareIcon = lazy(
  async () =>
    await import('./printer_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeIcon = lazy(
  async () =>
    await import('./home.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailCheckmarkIcon = lazy(
  async () =>
    await import('./email_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockMenuIcon = lazy(
  async () =>
    await import('./time_clock_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMinimalLeftClickSquareIcon = lazy(
  async () =>
    await import('./cursor_minimal_left_click_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CropSquareIcon = lazy(
  async () =>
    await import('./crop_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LetterOpenCircleIcon = lazy(
  async () =>
    await import('./letter_open_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailMinimalIrcleIcon = lazy(
  async () =>
    await import('./email_mail_minimal_ircle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimetClockAddPlusIcon = lazy(
  async () =>
    await import('./timet_clock_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatTextClickIcon = lazy(
  async () =>
    await import('./message_chat_text_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SphereRotateArrowsDIcon = lazy(
  async () =>
    await import('./sphere_rotate_arrows_d.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockRefreshIcon = lazy(
  async () =>
    await import('./lock_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelecursorVerticalClickCircleIcon = lazy(
  async () =>
    await import('./selecursor_vertical_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryFolderEditMoreIcon = lazy(
  async () =>
    await import('./media_library_folder_edit_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetSpaceObjectSelectIcon = lazy(
  async () =>
    await import('./target_space_object_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistBookmarkIcon = lazy(
  async () =>
    await import('./tasklist_bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectCheckmarkIcon = lazy(
  async () =>
    await import('./object_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncCheckmarkDeleteDottedLineIcon = lazy(
  async () =>
    await import('./sync_checkmark_delete_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RecMicStopOctagonIcon = lazy(
  async () =>
    await import('./rec_mic_stop_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserCheckmarkSquareIcon = lazy(
  async () =>
    await import('./browser_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkCrossRemoveSquareIcon = lazy(
  async () =>
    await import('./link_cross_remove_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveTextCheckmarkIcon = lazy(
  async () =>
    await import('./mailbox_archive_text_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinAngleIcon = lazy(
  async () =>
    await import('./pin_angle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistUndoArrowLeftIcon = lazy(
  async () =>
    await import('./tasklist_undo_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./fingerprint_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeArrowIcon = lazy(
  async () =>
    await import('./resize_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonCircleIcon = lazy(
  async () =>
    await import('./moon_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfinityLoopRepeatCircleIcon = lazy(
  async () =>
    await import('./infinity_loop_repeat_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FavoriteBookmarkLockIcon = lazy(
  async () =>
    await import('./favorite_bookmark_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeWifiIcon = lazy(
  async () =>
    await import('./globe_wifi.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchFastItemmIcon = lazy(
  async () =>
    await import('./search_fast_itemm.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorDeleteIcon = lazy(
  async () =>
    await import('./cursor_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayStyleSquareIcon = lazy(
  async () =>
    await import('./airplay_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatSendArrowBottomRightIcon = lazy(
  async () =>
    await import('./message_chat_send_arrow_bottom_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeSquareIcon = lazy(
  async () =>
    await import('./clock_time_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailpMailTimeClockIcon = lazy(
  async () =>
    await import('./emailp_mail_time_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatLeftInputIcon = lazy(
  async () =>
    await import('./message_chat_left_input.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaySignalAirdropCircleIcon = lazy(
  async () =>
    await import('./airplay_signal_airdrop_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReplyLeftDownIcon = lazy(
  async () =>
    await import('./reply_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoNotificationSquareIcon = lazy(
  async () =>
    await import('./info_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMenuIcon = lazy(
  async () =>
    await import('./cursor_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserNotificationCircleIcon = lazy(
  async () =>
    await import('./user_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetVerticalBigIcon = lazy(
  async () =>
    await import('./magnet_vertical_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatModernIcon = lazy(
  async () =>
    await import('./message_chat_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenLoadingIcon = lazy(
  async () =>
    await import('./pen_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnalyticsMinimalCircleIcon = lazy(
  async () =>
    await import('./analytics_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatSendArrowIcon = lazy(
  async () =>
    await import('./message_chat_send_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessagesPostsStyleTextRightIcon = lazy(
  async () =>
    await import('./messages_posts_style_text_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthGlobeAddPlusIcon = lazy(
  async () =>
    await import('./earth_globe_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkArrowsIcon = lazy(
  async () =>
    await import('./attachment_link_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSearchZoomPlusIcon = lazy(
  async () =>
    await import('./object_search_zoom_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchNotificationIcon = lazy(
  async () =>
    await import('./clock_watch_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarPageIcon = lazy(
  async () =>
    await import('./calendar_page.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectStyleCircleIcon = lazy(
  async () =>
    await import('./cursor_select_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PluginSqaureIcon = lazy(
  async () =>
    await import('./plugin_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoubleRewindArrowRightSquareIcon = lazy(
  async () =>
    await import('./double_rewind_arrow_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingLinesCircleIcon = lazy(
  async () =>
    await import('./loading_lines_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleDateIcon = lazy(
  async () =>
    await import('./calendar_schedule_date.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListDoListCheckmarkIcon = lazy(
  async () =>
    await import('./list_do_list_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginMinimalArrowRightIcon = lazy(
  async () =>
    await import('./login_minimal_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupElementsSquareIcon = lazy(
  async () =>
    await import('./group_elements_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardDeleteIcon = lazy(
  async () =>
    await import('./dashboard_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCursorSelectIcon = lazy(
  async () =>
    await import('./document_cursor_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WebFinderWindowIcon = lazy(
  async () =>
    await import('./web_finder_window.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseSmileIcon = lazy(
  async () =>
    await import('./home_house_smile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMinimalBigCircleIcon = lazy(
  async () =>
    await import('./edit_pen_minimal_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBoardSquareCircleIcon = lazy(
  async () =>
    await import('./menu_board_square_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListAddPlusIcon = lazy(
  async () =>
    await import('./check_list_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleCheckmarkIcon = lazy(
  async () =>
    await import('./calendar_schedule_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ResizeArrowsRightLeftSquareIcon = lazy(
  async () =>
    await import('./resize_arrows_right_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowClickCircleIcon = lazy(
  async () =>
    await import('./direction_arrow_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSettingSquareIcon = lazy(
  async () =>
    await import('./item_setting_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCopyDottedLineIcon = lazy(
  async () =>
    await import('./pin_copy_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuModernSquareCopyIcon = lazy(
  async () =>
    await import('./menu_modern_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySquareBigCopyIcon = lazy(
  async () =>
    await import('./floppy_square_big_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMinimalSquareIcon = lazy(
  async () =>
    await import('./cursor_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AddCirlcePlusIcon = lazy(
  async () =>
    await import('./add_cirlce_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipArrowUpBigIcon = lazy(
  async () =>
    await import('./clip_arrow_up_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadSaveSquareIcon = lazy(
  async () =>
    await import('./download_save_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemArrowDownSquareIcon = lazy(
  async () =>
    await import('./item_arrow_down_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinSelectIcon = lazy(
  async () =>
    await import('./pin_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextInputSmallSquareIcon = lazy(
  async () =>
    await import('./text_input_small_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenListSquareIcon = lazy(
  async () =>
    await import('./edit_pen_list_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornTrumpetCircleCopyIcon = lazy(
  async () =>
    await import('./horn_trumpet_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkSquareCopyIcon = lazy(
  async () =>
    await import('./item_checkmark_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMinimalCircleIcon = lazy(
  async () =>
    await import('./edit_pen_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookCircleIcon = lazy(
  async () =>
    await import('./book_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ConnectArrowsCircleIcon = lazy(
  async () =>
    await import('./connect_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveCrossCheckmarkIcon = lazy(
  async () =>
    await import('./floppy_save_cross_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendVerticalCircleIcon = lazy(
  async () =>
    await import('./send_vertical_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryFolderDeleteCrossIcon = lazy(
  async () =>
    await import('./media_library_folder_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderWindowSqaureIcon = lazy(
  async () =>
    await import('./finder_window_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecOctagonIcon = lazy(
  async () =>
    await import('./voice_rec_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeAddIcon = lazy(
  async () =>
    await import('./alarm_clock_time_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectedAddIcon = lazy(
  async () =>
    await import('./shield_protected_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderLightningNotificationsIcon = lazy(
  async () =>
    await import('./thunder_lightning_notifications.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAttachmentCircleEditIcon = lazy(
  async () =>
    await import('./link_attachment_circle_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerRemoveCrossIcon = lazy(
  async () =>
    await import('./sticker_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimerDotsLineIcon = lazy(
  async () =>
    await import('./clock_timer_dots_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDeleteCopyIcon = lazy(
  async () =>
    await import('./trash_bin_delete_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuPlusAddSquareIcon = lazy(
  async () =>
    await import('./menu_plus_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserModernStyleCircleIcon = lazy(
  async () =>
    await import('./user_modern_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeMinimalOctagonIcon = lazy(
  async () =>
    await import('./home_minimal_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellCircleIcon = lazy(
  async () =>
    await import('./bell_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./pen_edit_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphSquareIcon = lazy(
  async () =>
    await import('./paragraph_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardVerticalModernIcon = lazy(
  async () =>
    await import('./dashboard_vertical_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WallAdPaperIcon = lazy(
  async () =>
    await import('./wall_ad_paper.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortCircleIcon = lazy(
  async () =>
    await import('./filter_sort_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipArrowIcon = lazy(
  async () =>
    await import('./clip_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistNotesItemIcon = lazy(
  async () =>
    await import('./tasklist_notes_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayMediaVideoIcon = lazy(
  async () =>
    await import('./airplay_media_video.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchZoomPlusIcon = lazy(
  async () =>
    await import('./search_zoom_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DotsIcon = lazy(
  async () =>
    await import('./dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentPlusAddIcon = lazy(
  async () =>
    await import('./document_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSwipeRightIcon = lazy(
  async () =>
    await import('./cursor_swipe_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PasswordLockProtectionIcon = lazy(
  async () =>
    await import('./password_lock_protection.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BuildingCityTownIcon = lazy(
  async () =>
    await import('./building_city_town.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkBigSquareIcon = lazy(
  async () =>
    await import('./checkmark_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListMoreMenuIcon = lazy(
  async () =>
    await import('./task_list_more_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPanChatMessageIcon = lazy(
  async () =>
    await import('./edit_pan_chat_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartSqaureIcon = lazy(
  async () =>
    await import('./heart_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatDocumentGroupIcon = lazy(
  async () =>
    await import('./message_chat_document_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttentionFireIcon = lazy(
  async () =>
    await import('./attention_fire.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorRightSquareIcon = lazy(
  async () =>
    await import('./cursor_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemZoomLoapIcon = lazy(
  async () =>
    await import('./item_zoom_loap.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipCopyIcon = lazy(
  async () =>
    await import('./zip_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsAppCheckmarkIcon = lazy(
  async () =>
    await import('./windows_app_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BasketCheckmakIcon = lazy(
  async () =>
    await import('./basket_checkmak.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeySquareCopyIcon = lazy(
  async () =>
    await import('./key_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LaptopComputerIconSquareIcon = lazy(
  async () =>
    await import('./laptop_computer_icon_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockKeystoneSquadCircleIcon = lazy(
  async () =>
    await import('./lock_keystone_squad_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectSquareIcon = lazy(
  async () =>
    await import('./cursor_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AdButtonIcon = lazy(
  async () =>
    await import('./ad_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleCheckmarkCopyIcon = lazy(
  async () =>
    await import('./calendar_schedule_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorHoleSquareIcon = lazy(
  async () =>
    await import('./door_hole_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionAddPlusCircleCopyIcon = lazy(
  async () =>
    await import('./question_add_plus_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkClassicBigIcon = lazy(
  async () =>
    await import('./bookmark_classic_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageIcon = lazy(
  async () =>
    await import('./picture_image.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectAddIcon = lazy(
  async () =>
    await import('./object_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingItemDottedLineIcon = lazy(
  async () =>
    await import('./loading_item_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeCopyIcon = lazy(
  async () =>
    await import('./clock_time_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HandSquareIcon = lazy(
  async () =>
    await import('./hand_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListPrinIcon = lazy(
  async () =>
    await import('./task_list_prin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListTextCheckmarkIcon = lazy(
  async () =>
    await import('./check_list_text_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyAddDottedLineIcon = lazy(
  async () =>
    await import('./copy_add_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailSelectSmallIcon = lazy(
  async () =>
    await import('./email_mail_select_small.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureAddPlusSquareIcon = lazy(
  async () =>
    await import('./image_picture_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockKeystoneSquadSquareIcon = lazy(
  async () =>
    await import('./lock_keystone_squad_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemImagePictureSquareIcon = lazy(
  async () =>
    await import('./item_image_picture_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSleepModeCircleIcon = lazy(
  async () =>
    await import('./menu_sleep_mode_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditAddPlusIcon = lazy(
  async () =>
    await import('./pen_edit_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampCrossCircleIcon = lazy(
  async () =>
    await import('./lamp_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartStyleCricleIcon = lazy(
  async () =>
    await import('./heart_style_cricle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookRefreshIcon = lazy(
  async () =>
    await import('./notebook_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCursorIcon = lazy(
  async () =>
    await import('./document_cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMinimalMinusIcon = lazy(
  async () =>
    await import('./printer_minimal_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotesEditPenIcon = lazy(
  async () =>
    await import('./notes_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemPenTextSquareIcon = lazy(
  async () =>
    await import('./item_pen_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSearchMoreEditIcon = lazy(
  async () =>
    await import('./item_search_more_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernRemoveCrossDeleteIcon = lazy(
  async () =>
    await import('./home_modern_remove_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemFilterSimpleSquareIcon = lazy(
  async () =>
    await import('./item_filter_simple_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseSquareIcon = lazy(
  async () =>
    await import('./home_house_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoadingCircleIcon = lazy(
  async () =>
    await import('./search_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockSquareCopyIcon = lazy(
  async () =>
    await import('./alarm_clock_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderRightIcon = lazy(
  async () =>
    await import('./border_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockArrowUpIcon = lazy(
  async () =>
    await import('./lock_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupDotsSqaureIcon = lazy(
  async () =>
    await import('./group_dots_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TildaCircleIcon = lazy(
  async () =>
    await import('./tilda_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskItemMenuMoreIcon = lazy(
  async () =>
    await import('./task_item_menu_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureArrowIcon = lazy(
  async () =>
    await import('./image_picture_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartSquareCopyIcon = lazy(
  async () =>
    await import('./chart_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HashtagCircleIcon = lazy(
  async () =>
    await import('./hashtag_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyePlusAddSqaureIcon = lazy(
  async () =>
    await import('./eye_plus_add_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveCheckMarkIcon = lazy(
  async () =>
    await import('./floppy_save_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NightModeAddIcon = lazy(
  async () =>
    await import('./night_mode_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadLoadingArrowDownIcon = lazy(
  async () =>
    await import('./download_loading_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkVericalSquareIcon = lazy(
  async () =>
    await import('./link_verical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchOffCrossSqaureIcon = lazy(
  async () =>
    await import('./search_off_cross_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextWidthIcon = lazy(
  async () =>
    await import('./text_width.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipStyleSquareIcon = lazy(
  async () =>
    await import('./clip_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DisconnectCutSquareIcon = lazy(
  async () =>
    await import('./disconnect_cut_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSigmalMinimalSquareIcon = lazy(
  async () =>
    await import('./wifi_sigmal_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksSquareIcon = lazy(
  async () =>
    await import('./bookmarks_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiAddPlusCircleIcon = lazy(
  async () =>
    await import('./wifi_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeArrowLeftDownIcon = lazy(
  async () =>
    await import('./clock_time_arrow_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EjectIcon = lazy(
  async () =>
    await import('./eject.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeArrowIcon = lazy(
  async () =>
    await import('./alarm_clock_time_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureDeleteCrossIcon = lazy(
  async () =>
    await import('./image_picture_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDeleteCheckmarkIcon = lazy(
  async () =>
    await import('./trash_bin_delete_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextHexagonIcon = lazy(
  async () =>
    await import('./text_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmTimerModeDotsIcon = lazy(
  async () =>
    await import('./alarm_timer_mode_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashDeleteRemoveIcon = lazy(
  async () =>
    await import('./trash_delete_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarStopCrossDeleteIcon = lazy(
  async () =>
    await import('./calendar_stop_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowUturnUpIcon = lazy(
  async () =>
    await import('./arrow_uturn_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileHeartIcon = lazy(
  async () =>
    await import('./user_profile_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheduleListIcon = lazy(
  async () =>
    await import('./calendar_chedule_list.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCrossRemoveBigIcon = lazy(
  async () =>
    await import('./clip_cross_remove_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintEnterArrowRightIcon = lazy(
  async () =>
    await import('./fingerprint_enter_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkMoreMenuIcon = lazy(
  async () =>
    await import('./attachment_link_more_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecMoreIcon = lazy(
  async () =>
    await import('./voice_rec_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCheckmarkIcon = lazy(
  async () =>
    await import('./select_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleWarningArrowDownIcon = lazy(
  async () =>
    await import('./bin_trash_style_warning_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldHexagonCheckmarkIcon = lazy(
  async () =>
    await import('./shield_hexagon_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSelectCheckboxIcon = lazy(
  async () =>
    await import('./cursor_select_checkbox.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendMailSquareIcon = lazy(
  async () =>
    await import('./send_mail_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarEnterArrowRightIcon = lazy(
  async () =>
    await import('./calendar_enter_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraStyleLineIcon = lazy(
  async () =>
    await import('./camera_style_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitLoginIcon = lazy(
  async () =>
    await import('./exit_login.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowPopUpCircleIcon = lazy(
  async () =>
    await import('./window_pop_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftDottedLineCircleIcon = lazy(
  async () =>
    await import('./cursor_left_dotted_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentEyeIcon = lazy(
  async () =>
    await import('./document_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMenuOctagonIcon = lazy(
  async () =>
    await import('./setting_menu_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextInputFormIcon = lazy(
  async () =>
    await import('./text_input_form.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserShareIcon = lazy(
  async () =>
    await import('./user_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UiCircleIcon = lazy(
  async () =>
    await import('./ui_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatStyleRemoveCrossDeletedIcon = lazy(
  async () =>
    await import('./message_chat_style_remove_cross_deleted.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const IgtvIcon = lazy(
  async () =>
    await import('./igtv.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemProductAddPlusIcon = lazy(
  async () =>
    await import('./item_product_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IdAccountCopyIcon = lazy(
  async () =>
    await import('./id_account_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrintOutputRemoveDeletePlusIcon = lazy(
  async () =>
    await import('./print_output_remove_delete_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftTopCircleIcon = lazy(
  async () =>
    await import('./cursor_left_top_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AwardRatingIcon = lazy(
  async () =>
    await import('./award_rating.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentFilterIcon = lazy(
  async () =>
    await import('./document_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImageWaveSquareIcon = lazy(
  async () =>
    await import('./image_wave_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderWindowSquareIcon = lazy(
  async () =>
    await import('./finder_window_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NightModeOffIcon = lazy(
  async () =>
    await import('./night_mode_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeCircleIcon = lazy(
  async () =>
    await import('./clock_time_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ElementsObjectCopyIcon = lazy(
  async () =>
    await import('./elements_object_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeOffRmoveIcon = lazy(
  async () =>
    await import('./alarm_clock_time_off_rmove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingMenuCircleIcon = lazy(
  async () =>
    await import('./setting_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickRightTopCircleIcon = lazy(
  async () =>
    await import('./cursor_click_right_top_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PasswordInputSquareIcon = lazy(
  async () =>
    await import('./password_input_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkCircleBigIcon = lazy(
  async () =>
    await import('./item_checkmark_circle_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkVerticalRightBigIcon = lazy(
  async () =>
    await import('./link_vertical_right_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistItemAddPlusStyleSquareIcon = lazy(
  async () =>
    await import('./tasklist_item_add_plus_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorLogOutInSquareIcon = lazy(
  async () =>
    await import('./door_log_out_in_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AnalyticsCircleIcon = lazy(
  async () =>
    await import('./analytics_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendModernSquareIcon = lazy(
  async () =>
    await import('./send_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListBulletsSquareIcon = lazy(
  async () =>
    await import('./list_bullets_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookCheckmarkIcon = lazy(
  async () =>
    await import('./notebook_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageCrossSquareIcon = lazy(
  async () =>
    await import('./chat_message_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectionPenEditLineIcon = lazy(
  async () =>
    await import('./shield_protection_pen_edit_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CaommandButtonIcon = lazy(
  async () =>
    await import('./caommand_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelTagDeleteCrossSquareIcon = lazy(
  async () =>
    await import('./bookmarks_label_tag_delete_cross_square.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const DownloadArrowSquareIcon = lazy(
  async () =>
    await import('./download_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PositionTargetSquareIcon = lazy(
  async () =>
    await import('./position_target_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteCrossDeleteIcon = lazy(
  async () =>
    await import('./heart_favorite_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuPlusAddIcon = lazy(
  async () =>
    await import('./menu_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemPlusAddRightTopIcon = lazy(
  async () =>
    await import('./copy_item_plus_add_right_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterAddPlusCircleIcon = lazy(
  async () =>
    await import('./printer_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftClickDottedLineIcon = lazy(
  async () =>
    await import('./cursor_left_click_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernDoorCircleIcon = lazy(
  async () =>
    await import('./home_modern_door_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveEditIcon = lazy(
  async () =>
    await import('./floppy_save_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckMarkSelectSquareIcon = lazy(
  async () =>
    await import('./check_mark_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarShineSquareIcon = lazy(
  async () =>
    await import('./star_shine_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshIcon = lazy(
  async () =>
    await import('./refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCutBlockIcon = lazy(
  async () =>
    await import('./copy_cut_block.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMinimalBigSquareIcon = lazy(
  async () =>
    await import('./edit_pen_minimal_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCutItemTextIcon = lazy(
  async () =>
    await import('./copy_cut_item_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./menu_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderLightningSqaureIcon = lazy(
  async () =>
    await import('./thunder_lightning_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncSynchronizeSquareCopyIcon = lazy(
  async () =>
    await import('./sync_synchronize_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChangeSwapArrowIcon = lazy(
  async () =>
    await import('./change_swap_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightOctagonIcon = lazy(
  async () =>
    await import('./light_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectTargetIcon = lazy(
  async () =>
    await import('./select_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyGridIcon = lazy(
  async () =>
    await import('./copy_grid.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AdCircleIcon = lazy(
  async () =>
    await import('./ad_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashbinMoreIcon = lazy(
  async () =>
    await import('./trashbin_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BriefcaseIcon = lazy(
  async () =>
    await import('./briefcase.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleSearchBrokenLineIcon = lazy(
  async () =>
    await import('./circle_search_broken_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotesCheckmarkIcon = lazy(
  async () =>
    await import('./notes_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerAddIcon = lazy(
  async () =>
    await import('./sticker_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayStyleHorizontalIcon = lazy(
  async () =>
    await import('./airplay_style_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarMoreDotsIcon = lazy(
  async () =>
    await import('./calendar_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarChartAnalyticsIcon = lazy(
  async () =>
    await import('./calendar_chart_analytics.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputMinimalSquareIcon = lazy(
  async () =>
    await import('./input_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSpaceSquareIcon = lazy(
  async () =>
    await import('./item_space_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkReadSquareIcon = lazy(
  async () =>
    await import('./checkmark_read_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DotsMenuIcon = lazy(
  async () =>
    await import('./dots_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerPauseClockIcon = lazy(
  async () =>
    await import('./timer_pause_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeOctagonIcon = lazy(
  async () =>
    await import('./eye_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemLoadingCircleIcon = lazy(
  async () =>
    await import('./item_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoStyleOctagonIcon = lazy(
  async () =>
    await import('./info_style_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NewsBookmarkIcon = lazy(
  async () =>
    await import('./news_bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserCheckmarkCircleIcon = lazy(
  async () =>
    await import('./browser_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CirclesArrowShareIcon = lazy(
  async () =>
    await import('./circles_arrow_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectsAddPlusIcon = lazy(
  async () =>
    await import('./objects_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderSidebarIcon = lazy(
  async () =>
    await import('./window_finder_sidebar.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LibraryModernCheckmarkCenterIcon = lazy(
  async () =>
    await import('./library_modern_checkmark_center.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListBulletsMoreDotsIcon = lazy(
  async () =>
    await import('./list_bullets_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookSquareCopyIcon = lazy(
  async () =>
    await import('./book_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinAngleSquareIcon = lazy(
  async () =>
    await import('./pin_angle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackwardRearwardBackLockIcon = lazy(
  async () =>
    await import('./backward_rearward_back_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PaperTextCodeIcon = lazy(
  async () =>
    await import('./paper_text_code.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockSleepIcon = lazy(
  async () =>
    await import('./clock_sleep.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMinimakCheckmarkIcon = lazy(
  async () =>
    await import('./printer_minimak_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookSquareIcon = lazy(
  async () =>
    await import('./book_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockAlarmMoreDotsIcon = lazy(
  async () =>
    await import('./timer_clock_alarm_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendFasrPaperPlaneIcon = lazy(
  async () =>
    await import('./send_fasr_paper_plane.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuCircleCheckmarkIcon = lazy(
  async () =>
    await import('./menu_circle_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkAddPlusIcon = lazy(
  async () =>
    await import('./bookmark_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureLeftCircleIcon = lazy(
  async () =>
    await import('./image_picture_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingHorizontalBigIcon = lazy(
  async () =>
    await import('./setting_horizontal_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FavoriteBookmarkCircleIcon = lazy(
  async () =>
    await import('./favorite_bookmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClockIcon = lazy(
  async () =>
    await import('./cursor_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrivateIncognitoSquareIcon = lazy(
  async () =>
    await import('./private_incognito_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernHeartLikeIcon = lazy(
  async () =>
    await import('./home_modern_heart_like.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudDownlaodIcon = lazy(
  async () =>
    await import('./cloud_downlaod.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsOctagonIcon = lazy(
  async () =>
    await import('./chart_analytics_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseCircleIcon = lazy(
  async () =>
    await import('./home_house_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDatesIcon = lazy(
  async () =>
    await import('./calendar_dates.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GifCircleIcon = lazy(
  async () =>
    await import('./gif_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningMinimalWarningCurckeIcon = lazy(
  async () =>
    await import('./warning_minimal_warning_curcke.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemAddPlusCopyIcon = lazy(
  async () =>
    await import('./item_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterDeleteSquareIcon = lazy(
  async () =>
    await import('./filter_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookOpenCircleIcon = lazy(
  async () =>
    await import('./book_open_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageCheckmarkCopyIcon = lazy(
  async () =>
    await import('./message_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserInternetWebNetworkWindowAppIconIcon = lazy(
  async () =>
    await import('./browser_internet_web_network_window_app_icon.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const ClipVerticalCircleCopyIcon = lazy(
  async () =>
    await import('./clip_vertical_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistSearchIcon = lazy(
  async () =>
    await import('./tasklist_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HornTrumpetStyleCircleIcon = lazy(
  async () =>
    await import('./horn_trumpet_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryCheckmarkIcon = lazy(
  async () =>
    await import('./media_library_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchItemLoupeIcon = lazy(
  async () =>
    await import('./search_item_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxDownloadCircleIcon = lazy(
  async () =>
    await import('./mailbox_download_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveSquareCopyIcon = lazy(
  async () =>
    await import('./zip_archive_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyAntalyticChartIcon = lazy(
  async () =>
    await import('./copy_antalytic_chart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsUpDownMenuCircleIcon = lazy(
  async () =>
    await import('./arrows_up_down_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMoreDotsIcon = lazy(
  async () =>
    await import('./cursor_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesSquareIcon = lazy(
  async () =>
    await import('./slides_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncArrowsSquareIcon = lazy(
  async () =>
    await import('./sync_arrows_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistDownloadIcon = lazy(
  async () =>
    await import('./tasklist_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTxtSearchRightDownSquareIcon = lazy(
  async () =>
    await import('./item_txt_search_right_down_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChecklistIcon = lazy(
  async () =>
    await import('./checklist.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenDottedLineCircleIcon = lazy(
  async () =>
    await import('./edit_pen_dotted_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagDeleteCrossIcon = lazy(
  async () =>
    await import('./flag_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const OpenBookAddPlusIcon = lazy(
  async () =>
    await import('./open_book_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TriangleStatusRoundSquareIcon = lazy(
  async () =>
    await import('./triangle_status_round_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NightModeIcon = lazy(
  async () =>
    await import('./night_mode.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintCrossDeleteIcon = lazy(
  async () =>
    await import('./fingerprint_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkRemoveDeleteSquareIcon = lazy(
  async () =>
    await import('./link_remove_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockMoreMenuIcon = lazy(
  async () =>
    await import('./time_clock_more_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropdownMenuTextIcon = lazy(
  async () =>
    await import('./dropdown_menu_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemLeftBottomIcon = lazy(
  async () =>
    await import('./copy_item_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DasboardIcon = lazy(
  async () =>
    await import('./dasboard.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyAddPlusCopyIcon = lazy(
  async () =>
    await import('./copy_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBoardCircleIcon = lazy(
  async () =>
    await import('./menu_board_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NightModeSwitchesIcon = lazy(
  async () =>
    await import('./night_mode_switches.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AsteriskSquareCopyIcon = lazy(
  async () =>
    await import('./asterisk_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAttachmentStyleCircleIcon = lazy(
  async () =>
    await import('./link_attachment_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshRotateSquareIcon = lazy(
  async () =>
    await import('./refresh_rotate_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardDottedLineIcon = lazy(
  async () =>
    await import('./dashboard_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppySaveCircleIcon = lazy(
  async () =>
    await import('./floppy_save_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AdSquareIcon = lazy(
  async () =>
    await import('./ad_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCrossDeleteIcon = lazy(
  async () =>
    await import('./pin_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuEnterArrowIcon = lazy(
  async () =>
    await import('./item_menu_enter_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarLightSparkleCircleIcon = lazy(
  async () =>
    await import('./star_light_sparkle_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrintAddPlusIcon = lazy(
  async () =>
    await import('./printer_print_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuSquareIcon = lazy(
  async () =>
    await import('./menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapMinimalOctagonIcon = lazy(
  async () =>
    await import('./map_minimal_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsCheckmarkSquareIcon = lazy(
  async () =>
    await import('./chart_analytics_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortFavouriteEditIcon = lazy(
  async () =>
    await import('./filter_sort_favourite_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerPlusAddIcon = lazy(
  async () =>
    await import('./stiker_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InforamtionCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./inforamtion_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionCheckmarkCircleCopyIcon = lazy(
  async () =>
    await import('./question_checkmark_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellStyleCopyIcon = lazy(
  async () =>
    await import('./bell_style_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeadphonesSignalIcon = lazy(
  async () =>
    await import('./headphones_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleDownloadFullBlankIcon = lazy(
  async () =>
    await import('./bin_trash_style_download_full_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatEyeIcon = lazy(
  async () =>
    await import('./message_chat_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LogoutLeftArrowIcon = lazy(
  async () =>
    await import('./logout_left_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsUpLikeStyleBigIcon = lazy(
  async () =>
    await import('./thumbs_up_like_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCheckmarkCircleIcon = lazy(
  async () =>
    await import('./loading_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FavoriteBookmarkStarIcon = lazy(
  async () =>
    await import('./favorite_bookmark_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprinWarningIcon = lazy(
  async () =>
    await import('./fingerprin_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDownloadIcon = lazy(
  async () =>
    await import('./calendar_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeLeftSettingIcon = lazy(
  async () =>
    await import('./clock_time_left_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMiddleSquareIcon = lazy(
  async () =>
    await import('./edit_pen_middle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingEditFilterGearIcon = lazy(
  async () =>
    await import('./setting_edit_filter_gear.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleHorizontalRoundIcon = lazy(
  async () =>
    await import('./setting_style_horizontal_round.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerAlarmFastLeftIcon = lazy(
  async () =>
    await import('./timer_alarm_fast_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaySignalSquareIcon = lazy(
  async () =>
    await import('./airplay_signal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ScannerBarCodeSeceltIcon = lazy(
  async () =>
    await import('./scanner_bar_code_secelt.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridLayoutAddIcon = lazy(
  async () =>
    await import('./grid_layout_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxAddPlusIcon = lazy(
  async () =>
    await import('./mailbox_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesWindowsCopyIcon = lazy(
  async () =>
    await import('./slides_windows_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AddItemSearchCircleIcon = lazy(
  async () =>
    await import('./add_item_search_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeEditIcon = lazy(
  async () =>
    await import('./clock_time_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsEditPenSquareIcon = lazy(
  async () =>
    await import('./chart_analytics_edit_pen_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileIcon = lazy(
  async () =>
    await import('./user_profile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyWindowsCircleIcon = lazy(
  async () =>
    await import('./copy_windows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerMinimalSquareIcon = lazy(
  async () =>
    await import('./timer_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorAddIcon = lazy(
  async () =>
    await import('./cursor_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkBrokenModernDottedLineIcon = lazy(
  async () =>
    await import('./link_broken_modern_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./warning_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuFilterCentreIcon = lazy(
  async () =>
    await import('./menu_filter_centre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchButtonAddPlusIcon = lazy(
  async () =>
    await import('./search_button_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassLocationMapOffCircleIcon = lazy(
  async () =>
    await import('./compass_location_map_off_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningRefreshSquareIcon = lazy(
  async () =>
    await import('./warning_refresh_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkArrowCircleIcon = lazy(
  async () =>
    await import('./link_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockIcon = lazy(
  async () =>
    await import('./timer_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LaodingWaitingIcon = lazy(
  async () =>
    await import('./laoding_waiting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemEditSquareCopyIcon = lazy(
  async () =>
    await import('./item_edit_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendRightCircleIcon = lazy(
  async () =>
    await import('./send_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SubstractGroupIcon = lazy(
  async () =>
    await import('./substract_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerSmallCircleIcon = lazy(
  async () =>
    await import('./timer_small_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeAddPlusIcon = lazy(
  async () =>
    await import('./search_loupe_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListChekmarkCircleIcon = lazy(
  async () =>
    await import('./list_chekmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartModernCircleIcon = lazy(
  async () =>
    await import('./chart_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeArrowDownIcon = lazy(
  async () =>
    await import('./clock_time_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderLightningFastIcon = lazy(
  async () =>
    await import('./thunder_lightning_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChromcastCastAirplayIcon = lazy(
  async () =>
    await import('./chromcast_cast_airplay.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoxClassicLineIcon = lazy(
  async () =>
    await import('./box_classic_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EndlessSquareIcon = lazy(
  async () =>
    await import('./endless_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadArrowIcon = lazy(
  async () =>
    await import('./upload_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkPlusAddIcon = lazy(
  async () =>
    await import('./bookmark_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationAddPlusIcon = lazy(
  async () =>
    await import('./bell_notification_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingReloadSquareIcon = lazy(
  async () =>
    await import('./loading_reload_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarCheduleIcon = lazy(
  async () =>
    await import('./calendar_chedule.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoInformationCircleIcon = lazy(
  async () =>
    await import('./info_information_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapDeleteCrossCircleIcon = lazy(
  async () =>
    await import('./compass_map_delete_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignInLoginInArrowIcon = lazy(
  async () =>
    await import('./sign_in_login_in_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMoreDotsIcon = lazy(
  async () =>
    await import('./printer_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FaceIdIcon = lazy(
  async () =>
    await import('./face_id.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkCircleDeleteIcon = lazy(
  async () =>
    await import('./attachment_link_circle_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DotsMenuCircleIcon = lazy(
  async () =>
    await import('./dots_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplayCircleIcon = lazy(
  async () =>
    await import('./airplay_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetVerticalAddPlusBigIcon = lazy(
  async () =>
    await import('./magnet_vertical_add_plus_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicRecIcon = lazy(
  async () =>
    await import('./mic_rec.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendSquareIcon = lazy(
  async () =>
    await import('./send_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBarSpaceHorizontalIcon = lazy(
  async () =>
    await import('./menu_bar_space_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FashboardMinimalLineCircleIcon = lazy(
  async () =>
    await import('./fashboard_minimal_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SerchCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./serch_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextEditAIcon = lazy(
  async () =>
    await import('./text_edit_a.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaySignalAirdropIcon = lazy(
  async () =>
    await import('./airplay_signal_airdrop.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockStarIcon = lazy(
  async () =>
    await import('./time_clock_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumetCopyIcon = lazy(
  async () =>
    await import('./documet_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LanguageTranslateCopyIcon = lazy(
  async () =>
    await import('./language_translate_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleBigCirleIcon = lazy(
  async () =>
    await import('./filter_style_big_cirle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuAddPlusIcon = lazy(
  async () =>
    await import('./menu_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxNotificationIcon = lazy(
  async () =>
    await import('./mailbox_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleNotificationCircleIcon = lazy(
  async () =>
    await import('./filter_style_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListEnterInpuArrowRightIcon = lazy(
  async () =>
    await import('./check_list_enter_inpu_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputEnterArrowRightIcon = lazy(
  async () =>
    await import('./input_enter_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersIcon = lazy(
  async () =>
    await import('./users.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerSmileAddPlusIcon = lazy(
  async () =>
    await import('./stiker_smile_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentStatusAddPlusIcon = lazy(
  async () =>
    await import('./document_status_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessagesBubbleSquareIcon = lazy(
  async () =>
    await import('./chat_messages_bubble_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipVerticalCircleIcon = lazy(
  async () =>
    await import('./clip_vertical_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCopyIcon = lazy(
  async () =>
    await import('./clip_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuDotsSquareIcon = lazy(
  async () =>
    await import('./menu_dots_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingEditFilterIcon = lazy(
  async () =>
    await import('./setting_edit_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareMiddleSquareIcon = lazy(
  async () =>
    await import('./share_middle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowNotificationCircleIcon = lazy(
  async () =>
    await import('./direction_arrow_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkFavoriteLabelMoreSettingIcon = lazy(
  async () =>
    await import('./bookmark_favorite_label_more_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartSyleIcon = lazy(
  async () =>
    await import('./heart_syle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BagShoppingSqaureIcon = lazy(
  async () =>
    await import('./bag_shopping_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoonStarIcon = lazy(
  async () =>
    await import('./moon_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldBlankIcon = lazy(
  async () =>
    await import('./shield_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuVerticalSquareIcon = lazy(
  async () =>
    await import('./menu_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargerArrowLeftRightCircleIcon = lazy(
  async () =>
    await import('./targer_arrow_left_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowClickSquareIcon = lazy(
  async () =>
    await import('./direction_arrow_click_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintAnalysisLoadingIcon = lazy(
  async () =>
    await import('./fingerprint_analysis_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RepeatRorateSquareIcon = lazy(
  async () =>
    await import('./repeat_rorate_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorCheckmarkIcon = lazy(
  async () =>
    await import('./cursor_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebLoadingIcon = lazy(
  async () =>
    await import('./browser_web_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EdtiPenInputDotsIcon = lazy(
  async () =>
    await import('./edti_pen_input_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatStyleDottedLineIcon = lazy(
  async () =>
    await import('./message_chat_style_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectCopyIcon = lazy(
  async () =>
    await import('./select_object_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAttachmentSquareIcon = lazy(
  async () =>
    await import('./link_attachment_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarPinIcon = lazy(
  async () =>
    await import('./calendar_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderOuterIcon = lazy(
  async () =>
    await import('./border_outer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSelectAddPlusIcon = lazy(
  async () =>
    await import('./item_select_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicRecCheckmarkIcon = lazy(
  async () =>
    await import('./microphone_mic_rec_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMinimalStyleIcon = lazy(
  async () =>
    await import('./edit_pen_minimal_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardMinimalStyleCircleIcon = lazy(
  async () =>
    await import('./dashboard_minimal_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarListIcon = lazy(
  async () =>
    await import('./calendar_list.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchMoreVerticalMiddleIcon = lazy(
  async () =>
    await import('./search_more_vertical_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatBlankRightIcon = lazy(
  async () =>
    await import('./message_chat_blank_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticStatisticsCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./chart_analytic_statistics_cross_delete_circle.svg').then(
      module => ({ default: module.ReactComponent })
    )
);
const RotateMoreDotsIcon = lazy(
  async () =>
    await import('./rotate_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectMenuItemIcon = lazy(
  async () =>
    await import('./select_menu_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterUpDownArrowSquareIcon = lazy(
  async () =>
    await import('./filter_up_down_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionSquareIcon = lazy(
  async () =>
    await import('./arrow_direction_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleTimeIcon = lazy(
  async () =>
    await import('./calendar_schedule_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistStyleLineDownIcon = lazy(
  async () =>
    await import('./tasklist_style_line_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowsIcon = lazy(
  async () =>
    await import('./arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateSyncCircleArrowsIcon = lazy(
  async () =>
    await import('./rotate_sync_circle_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListPaperMoreIcon = lazy(
  async () =>
    await import('./list_paper_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCrossDeleteIcon = lazy(
  async () =>
    await import('./clip_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadArrowUpSquareIcon = lazy(
  async () =>
    await import('./upload_arrow_up_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageListIcon = lazy(
  async () =>
    await import('./chat_message_list.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./warning_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneStyleRecMicCircleIcon = lazy(
  async () =>
    await import('./microphone_style_rec_mic_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickStyleCircleIcon = lazy(
  async () =>
    await import('./cursor_click_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleDeleteCrossIcon = lazy(
  async () =>
    await import('./circle_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HttpSquareIcon = lazy(
  async () =>
    await import('./http_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SyncObjectsCheckmarkSquareIcon = lazy(
  async () =>
    await import('./sync_objects_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChecklistSearchIcon = lazy(
  async () =>
    await import('./checklist_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetHorizontalRightBigIcon = lazy(
  async () =>
    await import('./magnet_horizontal_right_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowMoreDotsIcon = lazy(
  async () =>
    await import('./window_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchChartMiddleIcon = lazy(
  async () =>
    await import('./search_chart_middle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChekmarkBigCircleIcon = lazy(
  async () =>
    await import('./chekmark_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoShareCircleIcon = lazy(
  async () =>
    await import('./info_share_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockSignalSquareIcon = lazy(
  async () =>
    await import('./lock_signal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionCrossCircleIcon = lazy(
  async () =>
    await import('./question_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./pin_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorHoleModetnBigIcon = lazy(
  async () =>
    await import('./door_hole_modetn_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleCopyIcon = lazy(
  async () =>
    await import('./calendar_schedule_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextDocumentMoreSettingIcon = lazy(
  async () =>
    await import('./text_document_more_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingDotsSquareIcon = lazy(
  async () =>
    await import('./loading_dots_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeTimerArrowIcon = lazy(
  async () =>
    await import('./clock_time_timer_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeSearchIcon = lazy(
  async () =>
    await import('./globe_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCheckmarkClockIcon = lazy(
  async () =>
    await import('./item_checkmark_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuHeartIcon = lazy(
  async () =>
    await import('./item_menu_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DropdownMenuIcon = lazy(
  async () =>
    await import('./dropdown_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyCircleIcon = lazy(
  async () =>
    await import('./floppy_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotificationDocumentTextIcon = lazy(
  async () =>
    await import('./notification_document_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatRemoveCrossIcon = lazy(
  async () =>
    await import('./message_chat_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeCrossDeleteIcon = lazy(
  async () =>
    await import('./alarm_clock_time_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeSquareCopyIcon = lazy(
  async () =>
    await import('./search_loupe_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsMinimalCircleIcon = lazy(
  async () =>
    await import('./chart_analytics_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneRecMicRemoveDeleteCrossIcon = lazy(
  async () =>
    await import('./microphone_rec_mic_remove_delete_cross.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const StopRemoveDeleteHorizontalSquareIcon = lazy(
  async () =>
    await import('./stop_remove_delete_horizontal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleMenuIcon = lazy(
  async () =>
    await import('./circle_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileRemoveSquareIcon = lazy(
  async () =>
    await import('./user_profile_remove_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DeleteIcon = lazy(
  async () =>
    await import('./delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardAngleDottedLineCircleIcon = lazy(
  async () =>
    await import('./dashboard_angle_dotted_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenCheckmarkIcon = lazy(
  async () =>
    await import('./edit_pen_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemCheckmarkIcon = lazy(
  async () =>
    await import('./menu_item_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockSquareIcon = lazy(
  async () =>
    await import('./timer_clock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagAddPlusSquareIcon = lazy(
  async () =>
    await import('./flag_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCircleIcon = lazy(
  async () =>
    await import('./item_text_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderCircleIcon = lazy(
  async () =>
    await import('./finder_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookOpenIcon = lazy(
  async () =>
    await import('./book_open.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QyestionSquareIcon = lazy(
  async () =>
    await import('./qyestion_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemShareClickArrowIcon = lazy(
  async () =>
    await import('./item_share_click_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListEnterArrowRightIcon = lazy(
  async () =>
    await import('./check_list_enter_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkHorizontalDottedLineIcon = lazy(
  async () =>
    await import('./link_horizontal_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginModernArowCircleIcon = lazy(
  async () =>
    await import('./login_modern_arow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClosedEyeSquareIcon = lazy(
  async () =>
    await import('./closed_eye_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectsTargetDotsIcon = lazy(
  async () =>
    await import('./objects_target_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemEditPenCircleIcon = lazy(
  async () =>
    await import('./item_edit_pen_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellNotificationCircleIcon = lazy(
  async () =>
    await import('./bell_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadArrowIcon = lazy(
  async () =>
    await import('./download_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalWarningIcon = lazy(
  async () =>
    await import('./wifi_signal_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterMenuCircleIcon = lazy(
  async () =>
    await import('./filter_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistEditPenCopyIcon = lazy(
  async () =>
    await import('./tasklist_edit_pen_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarsLightCircleIcon = lazy(
  async () =>
    await import('./stars_light_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesCircleIcon = lazy(
  async () =>
    await import('./settings_switches_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListTasksCheckmarkIcon = lazy(
  async () =>
    await import('./list_tasks_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCrossDeleteCopyIcon = lazy(
  async () =>
    await import('./clip_cross_delete_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListItemNotificationSquareIcon = lazy(
  async () =>
    await import('./list_item_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebSettingEditIcon = lazy(
  async () =>
    await import('./browser_web_setting_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatBubleAddPlusIcon = lazy(
  async () =>
    await import('./chat_buble_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkLineSquareIcon = lazy(
  async () =>
    await import('./checkmark_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectPictureTextEditIcon = lazy(
  async () =>
    await import('./object_picture_text_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeCursorIcon = lazy(
  async () =>
    await import('./globe_cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackwardRearwardMoreDotsIcon = lazy(
  async () =>
    await import('./backward_rearward_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldMoreSquareIcon = lazy(
  async () =>
    await import('./shield_more_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipArrowRightIcon = lazy(
  async () =>
    await import('./clip_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageCheckmarkIcon = lazy(
  async () =>
    await import('./message_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardVerticalMinimalCircleIcon = lazy(
  async () =>
    await import('./dashboard_vertical_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchSquareIcon = lazy(
  async () =>
    await import('./clock_watch_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ExitDoorLoginIcon = lazy(
  async () =>
    await import('./exit_door_login.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveNewOneClickIcon = lazy(
  async () =>
    await import('./mailbox_archive_new_one_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GridLayoutSquareIcon = lazy(
  async () =>
    await import('./grid_layout_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuoteRightBottomCircleIcon = lazy(
  async () =>
    await import('./quote_right_bottom_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSettingsSortIcon = lazy(
  async () =>
    await import('./filter_settings_sort.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentPinIcon = lazy(
  async () =>
    await import('./document_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BackRefreshReloadLoadingCheckmarkIcon = lazy(
  async () =>
    await import('./back_refresh_reload_loading_checkmark.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const TriangleSelectSquareIcon = lazy(
  async () =>
    await import('./triangle_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderTopIcon = lazy(
  async () =>
    await import('./border_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LabelTagCircleIcon = lazy(
  async () =>
    await import('./label_tag_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StartFavoriteAddPlusCircleIcon = lazy(
  async () =>
    await import('./start_favorite_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareSquareCopyIcon = lazy(
  async () =>
    await import('./share_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleMenuSquareIcon = lazy(
  async () =>
    await import('./setting_style_menu_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelTagSquareIcon = lazy(
  async () =>
    await import('./bookmarks_label_tag_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserSidebarRightTextIcon = lazy(
  async () =>
    await import('./browser_sidebar_right_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagStyleSquareIcon = lazy(
  async () =>
    await import('./flag_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelTagIcon = lazy(
  async () =>
    await import('./bookmarks_label_tag.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeLoadingIcon = lazy(
  async () =>
    await import('./alarm_clock_time_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenItemEditIcon = lazy(
  async () =>
    await import('./pen_item_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorWarningIcon = lazy(
  async () =>
    await import('./cursor_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleAddPlusSquareIcon = lazy(
  async () =>
    await import('./filter_style_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardLineIcon = lazy(
  async () =>
    await import('./dashboard_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const QuestionAddCircleIcon = lazy(
  async () =>
    await import('./question_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CmdKeyboardIcon = lazy(
  async () =>
    await import('./cmd_keyboard.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiArrowCircleIcon = lazy(
  async () =>
    await import('./wifi_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeCrossIcon = lazy(
  async () =>
    await import('./alarm_clock_time_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageModernDotsIcon = lazy(
  async () =>
    await import('./message_modern_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCheckamrkSquareIcon = lazy(
  async () =>
    await import('./chart_checkamrk_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendIcon = lazy(
  async () =>
    await import('./send.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FullScreenZoomSquareIcon = lazy(
  async () =>
    await import('./full_screen_zoom_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SmileysCheckmarkIcon = lazy(
  async () =>
    await import('./smileys_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarHeartIcon = lazy(
  async () =>
    await import('./calendar_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectCheckMarkIcon = lazy(
  async () =>
    await import('./select_object_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadCircleIcon = lazy(
  async () =>
    await import('./upload_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditCheckmarkIcon = lazy(
  async () =>
    await import('./pen_edit_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalModernCircleIcon = lazy(
  async () =>
    await import('./wifi_signal_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsUpLikeStyleSquareIcon = lazy(
  async () =>
    await import('./thumbs_up_like_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartNotificationIcon = lazy(
  async () =>
    await import('./chart_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeStarIcon = lazy(
  async () =>
    await import('./search_loupe_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListDeleteIcon = lazy(
  async () =>
    await import('./list_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipCrossRemoveDeleteSquareIcon = lazy(
  async () =>
    await import('./zip_cross_remove_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuBurgerArrowCircleIcon = lazy(
  async () =>
    await import('./menu_burger_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingModernSquareIcon = lazy(
  async () =>
    await import('./setting_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderResizeArrowIcon = lazy(
  async () =>
    await import('./window_finder_resize_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyArrowCircleIcon = lazy(
  async () =>
    await import('./key_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TriangleWarningSquareIcon = lazy(
  async () =>
    await import('./triangle_warning_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectCheckmarkCopyIcon = lazy(
  async () =>
    await import('./select_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesCircleIcon = lazy(
  async () =>
    await import('./cookies_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureModernCrossRemoveDeleteIcon = lazy(
  async () =>
    await import('./image_picture_modern_cross_remove_delete.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const PrinterPrintCheckmarkIcon = lazy(
  async () =>
    await import('./printer_print_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipCrossDeleteSqaureIcon = lazy(
  async () =>
    await import('./clip_cross_delete_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSearchMoreDotsIcon = lazy(
  async () =>
    await import('./object_search_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmTimerStyleRightIcon = lazy(
  async () =>
    await import('./alarm_timer_style_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrownSquareIcon = lazy(
  async () =>
    await import('./crown_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeClassicSquareIcon = lazy(
  async () =>
    await import('./home_classic_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampCheckamrkIcon = lazy(
  async () =>
    await import('./lamp_checkamrk.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSelectAddCrossIcon = lazy(
  async () =>
    await import('./item_select_add_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkPlusAddIcon = lazy(
  async () =>
    await import('./attachment_link_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordTextIcon = lazy(
  async () =>
    await import('./input_password_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteRemoveDeleteIcon = lazy(
  async () =>
    await import('./heart_favorite_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistMinusIcon = lazy(
  async () =>
    await import('./tasklist_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentStatusRejectedCrossIcon = lazy(
  async () =>
    await import('./document_status_rejected_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalRssIcon = lazy(
  async () =>
    await import('./signal_rss.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowScaleTransformIcon = lazy(
  async () =>
    await import('./window_scale_transform.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseSqaureIcon = lazy(
  async () =>
    await import('./mouse_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupSelectCopyDeleteIcon = lazy(
  async () =>
    await import('./group_select_copy_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCheckmarkCurvedCornerIcon = lazy(
  async () =>
    await import('./item_text_checkmark_curved_corner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistStyleDottedLineIcon = lazy(
  async () =>
    await import('./tasklist_style_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LampLightSquareIcon = lazy(
  async () =>
    await import('./lamp_light_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockWatchCircleOldIcon = lazy(
  async () =>
    await import('./clock_watch_circle_old.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeMenuMoreIcon = lazy(
  async () =>
    await import('./home_menu_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StatisticsAnalyticsArrowUpCircleIcon = lazy(
  async () =>
    await import('./statistics_analytics_arrow_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDeleteRemoveHorizontalIcon = lazy(
  async () =>
    await import('./calendar_delete_remove_horizontal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoMinimalCircleIcon = lazy(
  async () =>
    await import('./info_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextAlignArrowUpIcon = lazy(
  async () =>
    await import('./text_align_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReplyCircleIcon = lazy(
  async () =>
    await import('./reply_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistMenuTextIcon = lazy(
  async () =>
    await import('./tasklist_menu_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeAddPlusIcon = lazy(
  async () =>
    await import('./clock_time_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightBulbIdeaIcon = lazy(
  async () =>
    await import('./light_bulb_idea.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthGlobeModernMinimalIcon = lazy(
  async () =>
    await import('./earth_globe_modern_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeEarthSphereModernIcon = lazy(
  async () =>
    await import('./globe_earth_sphere_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldDeletedPlusIcon = lazy(
  async () =>
    await import('./shield_deleted_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatPlusAddIcon = lazy(
  async () =>
    await import('./message_chat_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditLeftTextItemSquareIcon = lazy(
  async () =>
    await import('./pen_edit_left_text_item_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldMorePasswordIcon = lazy(
  async () =>
    await import('./shield_more_password.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartIcon = lazy(
  async () =>
    await import('./heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentInfoIcon = lazy(
  async () =>
    await import('./document_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkReadLineCircleIcon = lazy(
  async () =>
    await import('./checkmark_read_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudSquareIcon = lazy(
  async () =>
    await import('./cloud_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockStopwatchIcon = lazy(
  async () =>
    await import('./clock_stopwatch.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingDotsArrowCircleIcon = lazy(
  async () =>
    await import('./loading_dots_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImageCheckmarkIcon = lazy(
  async () =>
    await import('./image_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorDisplayCircleIcon = lazy(
  async () =>
    await import('./monitor_display_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextCheckmarkCentreRightIcon = lazy(
  async () =>
    await import('./document_text_checkmark_centre_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RhombusSqureIcon = lazy(
  async () =>
    await import('./rhombus_squre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinSignIcon = lazy(
  async () =>
    await import('./pin_sign.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MedalAwardIcon = lazy(
  async () =>
    await import('./medal_award.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FavoriteHeartCheckmarkSquareIcon = lazy(
  async () =>
    await import('./favorite_heart_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelTagDeleteCrossIcon = lazy(
  async () =>
    await import('./bookmarks_label_tag_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextSelectIcon = lazy(
  async () =>
    await import('./item_text_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapCheckmarkCircleIcon = lazy(
  async () =>
    await import('./compass_map_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PictureImageSearchLoupeIcon = lazy(
  async () =>
    await import('./picture_image_search_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginMinimalRightIcon = lazy(
  async () =>
    await import('./login_minimal_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsCrossIcon = lazy(
  async () =>
    await import('./windows_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkModernRightCircleIcon = lazy(
  async () =>
    await import('./broken_link_modern_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernDoorIcon = lazy(
  async () =>
    await import('./home_modern_door.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeSleepModeSquareIcon = lazy(
  async () =>
    await import('./eye_sleep_mode_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarGoBackIcon = lazy(
  async () =>
    await import('./calendar_go_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftSelectSquareIcon = lazy(
  async () =>
    await import('./cursor_left_select_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistAnalyticsIcon = lazy(
  async () =>
    await import('./tasklist_analytics.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoapZoomOutCrossSquareIcon = lazy(
  async () =>
    await import('./loap_zoom_out_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlusMinusCircleIcon = lazy(
  async () =>
    await import('./plus_minus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyGridPlusIcon = lazy(
  async () =>
    await import('./copy_grid_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectSelectCircleIcon = lazy(
  async () =>
    await import('./object_select_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarFavoriteCheckmarkIcon = lazy(
  async () =>
    await import('./star_favorite_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningLoadingIcon = lazy(
  async () =>
    await import('./warning_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowRollUpCopyIcon = lazy(
  async () =>
    await import('./window_roll_up_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDowloadBigIcon = lazy(
  async () =>
    await import('./trash_bin_dowload_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagicWandMiniIcon = lazy(
  async () =>
    await import('./magic_wand_mini.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePictureArrowRightIcon = lazy(
  async () =>
    await import('./image_picture_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageCircleIcon = lazy(
  async () =>
    await import('./message_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LaodingMinimalSquareIcon = lazy(
  async () =>
    await import('./laoding_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudSqaureIcon = lazy(
  async () =>
    await import('./cloud_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuModernLineIcon = lazy(
  async () =>
    await import('./menu_modern_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderMaximizeResizeIcon = lazy(
  async () =>
    await import('./window_finder_maximize_resize.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HardDriveStorageIcon = lazy(
  async () =>
    await import('./hard_drive_storage.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteCircleIcon = lazy(
  async () =>
    await import('./heart_favorite_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloppyCheckmarkIcon = lazy(
  async () =>
    await import('./floppy_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockLineBigIcon = lazy(
  async () =>
    await import('./lock_line_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderTwoColumnsIcon = lazy(
  async () =>
    await import('./window_finder_two_columns.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CertificateMedalIcon = lazy(
  async () =>
    await import('./certificate_medal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLineSquareIcon = lazy(
  async () =>
    await import('./pin_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeLineMinimalCircleIcon = lazy(
  async () =>
    await import('./home_line_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CupCircleIcon = lazy(
  async () =>
    await import('./cup_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsUpLikeBigIcon = lazy(
  async () =>
    await import('./thumbs_up_like_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectMagicWandIcon = lazy(
  async () =>
    await import('./object_magic_wand.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowFinderMinimizeResizeIcon = lazy(
  async () =>
    await import('./window_finder_minimize_resize.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterPrinWarningInfoIcon = lazy(
  async () =>
    await import('./printer_prin_warning_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMoveFastSquareIcon = lazy(
  async () =>
    await import('./cursor_move_fast_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailMenuIcon = lazy(
  async () =>
    await import('./email_mail_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatAnalyticsSearchSquareIcon = lazy(
  async () =>
    await import('./chat_analytics_search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsMinimalSquareIcon = lazy(
  async () =>
    await import('./chart_analytics_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./chart_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateIcon = lazy(
  async () =>
    await import('./rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatCheckmarkSquareIcon = lazy(
  async () =>
    await import('./message_chat_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeAlarmClockMoreDotsIcon = lazy(
  async () =>
    await import('./time_alarm_clock_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionNavigationPlusAddCircleIcon = lazy(
  async () =>
    await import('./arrow_direction_navigation_plus_add_circle.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const MoveArrowRightLeftCircleIcon = lazy(
  async () =>
    await import('./move_arrow_right_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchMediumSquareIcon = lazy(
  async () =>
    await import('./search_medium_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxMinusIcon = lazy(
  async () =>
    await import('./mailbox_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingCircleCopyIcon = lazy(
  async () =>
    await import('./setting_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThumbsUpIcon = lazy(
  async () =>
    await import('./thumbs_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartPlusAddIcon = lazy(
  async () =>
    await import('./chart_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlusMinusSqaureIcon = lazy(
  async () =>
    await import('./plus_minus_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneMicRecSquareCopyIcon = lazy(
  async () =>
    await import('./microphone_mic_rec_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TicketGroupCircleIcon = lazy(
  async () =>
    await import('./ticket_group_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxAddPlusCopyIcon = lazy(
  async () =>
    await import('./mailbox_add_plus_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingHorizontalCircleCopyIcon = lazy(
  async () =>
    await import('./setting_horizontal_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InboxDeleteRemoveSquareIcon = lazy(
  async () =>
    await import('./inbox_delete_remove_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingCheckmarkStatusCircleIcon = lazy(
  async () =>
    await import('./loading_checkmark_status_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IcoIconCircleIcon = lazy(
  async () =>
    await import('./ico_icon_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintInfoIcon = lazy(
  async () =>
    await import('./fingerprint_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartModernSquareIcon = lazy(
  async () =>
    await import('./chart_modern_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShapesOblectTriangleSquareCircleIcon = lazy(
  async () =>
    await import('./shapes_oblect_triangle_square_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentCheckmarkTopCentreIcon = lazy(
  async () =>
    await import('./item_document_checkmark_top_centre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuTextItemIcon = lazy(
  async () =>
    await import('./menu_text_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchSelectLoupeIcon = lazy(
  async () =>
    await import('./search_select_loupe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LightningCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./lightning_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatTextStyleUpIcon = lazy(
  async () =>
    await import('./message_chat_text_style_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingPlusCircleAddIcon = lazy(
  async () =>
    await import('./loading_plus_circle_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellIcon = lazy(
  async () =>
    await import('./bell.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseStyleBigIcon = lazy(
  async () =>
    await import('./mouse_style_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowCheckmarkCircleIcon = lazy(
  async () =>
    await import('./direction_arrow_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StampCheckmarkCircleIcon = lazy(
  async () =>
    await import('./stamp_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemCutRightIcon = lazy(
  async () =>
    await import('./item_cut_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingCheckMarkSqaureIcon = lazy(
  async () =>
    await import('./setting_check_mark_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockArrowAddPlusCircleIcon = lazy(
  async () =>
    await import('./lock_arrow_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MoviesVideoCircleIcon = lazy(
  async () =>
    await import('./movies_video_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinWarningIcon = lazy(
  async () =>
    await import('./trash_bin_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatLightningModernIcon = lazy(
  async () =>
    await import('./message_chat_lightning_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeHideDownArrowIcon = lazy(
  async () =>
    await import('./eye_hide_down_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyStyleRightIcon = lazy(
  async () =>
    await import('./copy_style_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAddPlusCircleIcon = lazy(
  async () =>
    await import('./chart_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchCrossSquareCopyIcon = lazy(
  async () =>
    await import('./search_cross_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsSwitchesSquareVolumeKnobIcon = lazy(
  async () =>
    await import('./settings_switches_square_volume_knob.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDateLeftTopIcon = lazy(
  async () =>
    await import('./calendar_date_left_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalOctagonIcon = lazy(
  async () =>
    await import('./wifi_signal_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorDisplayDeleteRossIcon = lazy(
  async () =>
    await import('./monitor_display_delete_ross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshArrowsCircleIcon = lazy(
  async () =>
    await import('./refresh_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMinimalCrossRemoveIcon = lazy(
  async () =>
    await import('./printer_minimal_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorBrokenLineSquareIcon = lazy(
  async () =>
    await import('./cursor_broken_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeDotsTargetIcon = lazy(
  async () =>
    await import('./eye_dots_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartCircleIcon = lazy(
  async () =>
    await import('./heart_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinAddPlusSquareIcon = lazy(
  async () =>
    await import('./pin_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalStyleSquareIcon = lazy(
  async () =>
    await import('./wifi_signal_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectionSecurityAddPlusSquareIcon = lazy(
  async () =>
    await import('./shield_protection_security_add_plus_square.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const CalendarLineCircleIcon = lazy(
  async () =>
    await import('./calendar_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarLineSquareIcon = lazy(
  async () =>
    await import('./calendar_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesImagesPapersRemoveDeleteIcon = lazy(
  async () =>
    await import('./slides_images_papers_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ListTasksAddPlusIcon = lazy(
  async () =>
    await import('./list_tasks_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RefreshArrowsSquareCopyIcon = lazy(
  async () =>
    await import('./refresh_arrows_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemShineStarRightTopIcon = lazy(
  async () =>
    await import('./item_shine_star_right_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockSquareIcon = lazy(
  async () =>
    await import('./alarm_clock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardCircleIcon = lazy(
  async () =>
    await import('./keyboard_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockDotsIcon = lazy(
  async () =>
    await import('./lock_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StikerSmileCircleIcon = lazy(
  async () =>
    await import('./stiker_smile_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserSignalSquareIcon = lazy(
  async () =>
    await import('./browser_signal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FileZipRarIcon = lazy(
  async () =>
    await import('./file_zip_rar.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShiledProtectonDownloadArrowDownIcon = lazy(
  async () =>
    await import('./shiled_protecton_download_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCrossDeleteIcon = lazy(
  async () =>
    await import('./copy_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldProtectedIcon = lazy(
  async () =>
    await import('./shield_protected.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteAddIcon = lazy(
  async () =>
    await import('./heart_favorite_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenSmallSquarevIcon = lazy(
  async () =>
    await import('./edit_pen_small_squarev.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkBrokenModernIcon = lazy(
  async () =>
    await import('./link_broken_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopySquareDuplicateIcon = lazy(
  async () =>
    await import('./copy_square_duplicate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageMoreIcon = lazy(
  async () =>
    await import('./chat_message_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReloadSquareIcon = lazy(
  async () =>
    await import('./reload_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionSquareCopyIcon = lazy(
  async () =>
    await import('./arrow_direction_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListImageIcon = lazy(
  async () =>
    await import('./check_list_image.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterStyleStopOctagonIcon = lazy(
  async () =>
    await import('./filter_style_stop_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinMenuCircleIcon = lazy(
  async () =>
    await import('./pin_menu_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipAttachmentArrowLeftCircleIcon = lazy(
  async () =>
    await import('./clip_attachment_arrow_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuEditSquareIcon = lazy(
  async () =>
    await import('./menu_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistItemAddPlusBigIcon = lazy(
  async () =>
    await import('./tasklist_item_add_plus_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkAttachmentCircleArrowLeftIcon = lazy(
  async () =>
    await import('./link_attachment_circle_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldRemoveDeleteCircleIcon = lazy(
  async () =>
    await import('./shield_remove_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardButtonArrowsCircleIcon = lazy(
  async () =>
    await import('./keyboard_button_arrows_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleCrossRemoveIcon = lazy(
  async () =>
    await import('./email_letter_style_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenIcon = lazy(
  async () =>
    await import('./edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArtboardsSquareIcon = lazy(
  async () =>
    await import('./artboards_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterSortCheckMarkIcon = lazy(
  async () =>
    await import('./filter_sort_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenLineStopOctagonIcon = lazy(
  async () =>
    await import('./edit_pen_line_stop_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailLetterStyleIcon = lazy(
  async () =>
    await import('./email_letter_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockAlarmSquareIcon = lazy(
  async () =>
    await import('./time_clock_alarm_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoadingDottedLineIcon = lazy(
  async () =>
    await import('./search_loading_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerMoreDotsIcon = lazy(
  async () =>
    await import('./sticker_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorStyleIcon = lazy(
  async () =>
    await import('./cursor_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BracketsMoreCircleIcon = lazy(
  async () =>
    await import('./brackets_more_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextSquareIcon = lazy(
  async () =>
    await import('./item_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IteamSearchRightTopSquareIcon = lazy(
  async () =>
    await import('./iteam_search_right_top_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkCirclePlusIcon = lazy(
  async () =>
    await import('./attachment_link_circle_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMinimalRemoveCrossIcon = lazy(
  async () =>
    await import('./printer_minimal_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClosedEyeCircleIcon = lazy(
  async () =>
    await import('./closed_eye_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecCheckmarkIcon = lazy(
  async () =>
    await import('./voice_rec_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CrownStyleSquareCopyIcon = lazy(
  async () =>
    await import('./crown_style_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkHorizontalCircleIcon = lazy(
  async () =>
    await import('./link_horizontal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorLeftStyleIcon = lazy(
  async () =>
    await import('./cursor_left_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HeartFavoriteLikeStoryIcon = lazy(
  async () =>
    await import('./heart_favorite_like_story.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrianglestatusRoundCircleIcon = lazy(
  async () =>
    await import('./trianglestatus_round_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoStyleSquareIcon = lazy(
  async () =>
    await import('./info_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistItemRemoveCrossSquareIcon = lazy(
  async () =>
    await import('./tasklist_item_remove_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerClipIcon = lazy(
  async () =>
    await import('./sticker_clip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MicrophoneIcon = lazy(
  async () =>
    await import('./microphone.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UploadBigCircleArrowUpIcon = lazy(
  async () =>
    await import('./upload_big_circle_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleCrossCheckmarkIcon = lazy(
  async () =>
    await import('./bin_trash_style_cross_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputTextEditCircleIcon = lazy(
  async () =>
    await import('./input_text_edit_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatStyleIcon = lazy(
  async () =>
    await import('./message_chat_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchOffCrossCircleIcon = lazy(
  async () =>
    await import('./search_off_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FullScreenCircleIcon = lazy(
  async () =>
    await import('./full_screen_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArchiveDocumentsBoxBigIcon = lazy(
  async () =>
    await import('./archive_documents_box_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrokenLinkModernRightSquareIcon = lazy(
  async () =>
    await import('./broken_link_modern_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatRightBlankIcon = lazy(
  async () =>
    await import('./message_chat_right_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockHourIcon = lazy(
  async () =>
    await import('./clock_hour.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldCheckmarkIcon = lazy(
  async () =>
    await import('./shield_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingStyleVerticalSquareIcon = lazy(
  async () =>
    await import('./setting_style_vertical_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeModernDoorSquareIcon = lazy(
  async () =>
    await import('./home_modern_door_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagicWandCircleCopyIcon = lazy(
  async () =>
    await import('./magic_wand_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BlankSpaceCircleIcon = lazy(
  async () =>
    await import('./blank_space_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowUturnDownMoreMenuIcon = lazy(
  async () =>
    await import('./arrow_uturn_down_more_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextFavoriteIcon = lazy(
  async () =>
    await import('./item_text_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadMinimalSquareIcon = lazy(
  async () =>
    await import('./download_minimal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchStopOctagonIcon = lazy(
  async () =>
    await import('./search_stop_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateItemIcon = lazy(
  async () =>
    await import('./rotate_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeShineSquareIcon = lazy(
  async () =>
    await import('./eye_shine_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BoxDeleteSpamCrossIcon = lazy(
  async () =>
    await import('./box_delete_spam_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentTextFloppyDiskIcon = lazy(
  async () =>
    await import('./document_text_floppy_disk.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemBigRightIcon = lazy(
  async () =>
    await import('./copy_item_big_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiRefreshCircleIcon = lazy(
  async () =>
    await import('./wifi_refresh_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemShareMaximizeIcon = lazy(
  async () =>
    await import('./copy_item_share_maximize.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorWellMinimalLoginCircleIcon = lazy(
  async () =>
    await import('./door_well_minimal_login_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ZipArchiveDeleteCrossSquareIcon = lazy(
  async () =>
    await import('./zip_archive_delete_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PazzlePluginLeftDownIcon = lazy(
  async () =>
    await import('./pazzle_plugin_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingSqaureIcon = lazy(
  async () =>
    await import('./loading_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemAddClipSquareIcon = lazy(
  async () =>
    await import('./item_add_clip_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartCheckmarkSquareIcon = lazy(
  async () =>
    await import('./chart_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemRightTopMenuIcon = lazy(
  async () =>
    await import('./copy_item_right_top_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleHashIcon = lazy(
  async () =>
    await import('./circle_hash.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkCheckmarkCopyIcon = lazy(
  async () =>
    await import('./link_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseScrollUpCircleIcon = lazy(
  async () =>
    await import('./mouse_scroll_up_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeTimerAddIcon = lazy(
  async () =>
    await import('./alarm_clock_time_timer_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CookiesStyleIcon = lazy(
  async () =>
    await import('./cookies_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParagraphCircleIcon = lazy(
  async () =>
    await import('./paragraph_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartMinimalSignalSquareIcon = lazy(
  async () =>
    await import('./chart_minimal_signal_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMinusIcon = lazy(
  async () =>
    await import('./cursor_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenCircleCopyIcon = lazy(
  async () =>
    await import('./edit_pen_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LanguageTranslateSquareIcon = lazy(
  async () =>
    await import('./language_translate_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendRightSquareIcon = lazy(
  async () =>
    await import('./send_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchArrowCircleIcon = lazy(
  async () =>
    await import('./search_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyRightTopArrowLeftIcon = lazy(
  async () =>
    await import('./copy_right_top_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingEditFilterSwitchesSwitchCircleIcon = lazy(
  async () =>
    await import('./setting_edit_filter_switches_switch_circle.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const MoonOffCircleIcon = lazy(
  async () =>
    await import('./moon_off_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingFilterIcon = lazy(
  async () =>
    await import('./setting_filter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowCircleButtonIcon = lazy(
  async () =>
    await import('./arrow_circle_button.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadBigArrowDownIcon = lazy(
  async () =>
    await import('./download_big_arrow_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseScrollUpIcon = lazy(
  async () =>
    await import('./mouse_scroll_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeTwitterCircleIcon = lazy(
  async () =>
    await import('./home_twitter_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarTextNoteIcon = lazy(
  async () =>
    await import('./calendar_text_note.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputPasswordCircleIcon = lazy(
  async () =>
    await import('./input_password_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeMoreIcon = lazy(
  async () =>
    await import('./home_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyholeLockSquareIcon = lazy(
  async () =>
    await import('./keyhole_lock_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarDeleteIcon = lazy(
  async () =>
    await import('./star_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoiceRecDeleteCrossIcon = lazy(
  async () =>
    await import('./voice_rec_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ObjectAddPlusSquareIcon = lazy(
  async () =>
    await import('./object_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleBackIcon = lazy(
  async () =>
    await import('./calendar_schedule_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailDisableDeleteIcon = lazy(
  async () =>
    await import('./email_mail_disable_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentsFilesIcon = lazy(
  async () =>
    await import('./item_documents_files.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PuzzlePlugInRemoveIcon = lazy(
  async () =>
    await import('./puzzle_plug_in_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalBarsBargraphIcon = lazy(
  async () =>
    await import('./signal_bars_bargraph.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageSquareIcon = lazy(
  async () =>
    await import('./message_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMinimalAddPlusBigIcon = lazy(
  async () =>
    await import('./monitor_minimal_add_plus_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentIcon = lazy(
  async () =>
    await import('./item_document.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorClickCircleIcon = lazy(
  async () =>
    await import('./cursor_click_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateDeleteArrowIcon = lazy(
  async () =>
    await import('./rotate_delete_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarScheduleClipIcon = lazy(
  async () =>
    await import('./calendar_schedule_clip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SpellingCheckIcon = lazy(
  async () =>
    await import('./spelling_check.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenBigCircleIcon = lazy(
  async () =>
    await import('./edit_pen_big_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReceiptIcon = lazy(
  async () =>
    await import('./receipt.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SelectObjectMoreDotsIcon = lazy(
  async () =>
    await import('./select_object_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyHorizontalCircleIcon = lazy(
  async () =>
    await import('./copy_horizontal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchShineLeftSquareIcon = lazy(
  async () =>
    await import('./search_shine_left_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemDocumentImageCheckmarkIcon = lazy(
  async () =>
    await import('./item_document_image_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TaskListPinIcon = lazy(
  async () =>
    await import('./task_list_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalWifiModernIcon = lazy(
  async () =>
    await import('./signal_wifi_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageTextRightIcon = lazy(
  async () =>
    await import('./chat_message_text_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyFillIcon = lazy(
  async () =>
    await import('./copy_fill.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchRemoveCrossCircleIcon = lazy(
  async () =>
    await import('./search_remove_cross_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchBigLeftIcon = lazy(
  async () =>
    await import('./search_big_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PrinterMinimalAddPlusIcon = lazy(
  async () =>
    await import('./printer_minimal_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchMinusIcon = lazy(
  async () =>
    await import('./search_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipArrowLeftIcon = lazy(
  async () =>
    await import('./clip_arrow_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PasswordInputIcon = lazy(
  async () =>
    await import('./password_input.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextCimpleCircleIcon = lazy(
  async () =>
    await import('./item_text_cimple_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMinimizeWindowIcon = lazy(
  async () =>
    await import('./item_minimize_window.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ImagePicturesMinusSquareIcon = lazy(
  async () =>
    await import('./image_pictures_minus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotebookAddPlusIcon = lazy(
  async () =>
    await import('./notebook_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkLeftIcon = lazy(
  async () =>
    await import('./attachment_link_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterArrowUpDownCircleIcon = lazy(
  async () =>
    await import('./filter_arrow_up_down_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistRemoveCrossIcon = lazy(
  async () =>
    await import('./tasklist_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipArrowLeftBigIcon = lazy(
  async () =>
    await import('./clip_arrow_left_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipVerticalStyleIcon = lazy(
  async () =>
    await import('./clip_vertical_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MouseScrollDownIcon = lazy(
  async () =>
    await import('./mouse_scroll_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorSquarePositionAppIcon = lazy(
  async () =>
    await import('./cursor_square_position_app.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalRightTopSquareIcon = lazy(
  async () =>
    await import('./signal_right_top_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilterCheckmarkCircleIcon = lazy(
  async () =>
    await import('./filter_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextNotificationSquareIcon = lazy(
  async () =>
    await import('./item_text_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentCheckmarkLeftIcon = lazy(
  async () =>
    await import('./document_checkmark_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkShieldIcon = lazy(
  async () =>
    await import('./bookmark_shield.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeSelectIcon = lazy(
  async () =>
    await import('./search_loupe_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimerClockAddSquareIcon = lazy(
  async () =>
    await import('./timer_clock_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartStyleIcon = lazy(
  async () =>
    await import('./chart_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatRecMicDownIcon = lazy(
  async () =>
    await import('./message_chat_rec_mic_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchSquareIcon = lazy(
  async () =>
    await import('./search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingCheckmarkIcon = lazy(
  async () =>
    await import('./setting_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShieldModernIcon = lazy(
  async () =>
    await import('./shield_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LockStyleLineBlankIcon = lazy(
  async () =>
    await import('./lock_style_line_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilleDocumentSquareIcon = lazy(
  async () =>
    await import('./fille_document_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageInfoIcon = lazy(
  async () =>
    await import('./chat_message_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FileZipRarCircleIcon = lazy(
  async () =>
    await import('./file_zip_rar_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListEditIcon = lazy(
  async () =>
    await import('./check_list_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashModernLineIcon = lazy(
  async () =>
    await import('./bin_trash_modern_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardModernStyleCircleIcon = lazy(
  async () =>
    await import('./dashboard_modern_style_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CropArrowSyncIcon = lazy(
  async () =>
    await import('./crop_arrow_sync.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditSquareCopyIcon = lazy(
  async () =>
    await import('./pen_edit_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IdCircleIcon = lazy(
  async () =>
    await import('./id_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CmdCommandKeyboardCircleIcon = lazy(
  async () =>
    await import('./cmd_command_keyboard_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemSelectIcon = lazy(
  async () =>
    await import('./item_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendStyleSelectIcon = lazy(
  async () =>
    await import('./send_style_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudArrowCircleIcon = lazy(
  async () =>
    await import('./cloud_arrow_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CircleCutIcon = lazy(
  async () =>
    await import('./circle_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CommandMinimalCircleIcon = lazy(
  async () =>
    await import('./command_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkCheckmarkIcon = lazy(
  async () =>
    await import('./link_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemShareArrowIcon = lazy(
  async () =>
    await import('./copy_item_share_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginOgoutIcon = lazy(
  async () =>
    await import('./login_ogout.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemBlockCrossSquareIcon = lazy(
  async () =>
    await import('./item_block_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StopMinusIcon = lazy(
  async () =>
    await import('./stop_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TranslateAddCircleIcon = lazy(
  async () =>
    await import('./translate_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchShineSquareIcon = lazy(
  async () =>
    await import('./search_shine_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkMinimalCircleIcon = lazy(
  async () =>
    await import('./link_minimal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarksLabelTagCopyIcon = lazy(
  async () =>
    await import('./bookmarks_label_tag_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatEditPenCommentsIcon = lazy(
  async () =>
    await import('./message_chat_edit_pen_comments.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DashboardLeftCircleIcon = lazy(
  async () =>
    await import('./dashboard_left_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FinderBrowserSidebarLeftTextIcon = lazy(
  async () =>
    await import('./finder_browser_sidebar_left_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinBoxCircleIcon = lazy(
  async () =>
    await import('./bin_box_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchRightSquareIcon = lazy(
  async () =>
    await import('./search_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BluetoothOffIcon = lazy(
  async () =>
    await import('./bluetooth_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageStickerSmileIcon = lazy(
  async () =>
    await import('./chat_message_sticker_smile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkEditPenIcon = lazy(
  async () =>
    await import('./link_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupElementsMoveIcon = lazy(
  async () =>
    await import('./group_elements_move.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeSmallSelectIcon = lazy(
  async () =>
    await import('./globe_small_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MonitorMaximizeWindowIcon = lazy(
  async () =>
    await import('./monitor_maximize_window.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListWarningIcon = lazy(
  async () =>
    await import('./check_list_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailEmailLetterAllMailboxesIcon = lazy(
  async () =>
    await import('./mail_email_letter_all_mailboxes.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoapRefreshReloadIcon = lazy(
  async () =>
    await import('./search_loap_refresh_reload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemMinusRightTopIcon = lazy(
  async () =>
    await import('./copy_item_minus_right_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailInfoIcon = lazy(
  async () =>
    await import('./email_mail_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyboardCircleCopyIcon = lazy(
  async () =>
    await import('./keyboard_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeHouseModernIcon = lazy(
  async () =>
    await import('./home_house_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FingerprintWarningIcon = lazy(
  async () =>
    await import('./fingerprint_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuItemButtonSquareIcon = lazy(
  async () =>
    await import('./menu_item_button_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemFavoriteHeartCircleIcon = lazy(
  async () =>
    await import('./item_favorite_heart_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyCircleCopyIcon = lazy(
  async () =>
    await import('./copy_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeTimerDeleteTurnOffIcon = lazy(
  async () =>
    await import('./clock_time_timer_delete_turn_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemMenuAddPlusCircleIcon = lazy(
  async () =>
    await import('./item_menu_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartStyleBigCopyIcon = lazy(
  async () =>
    await import('./chart_style_big_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessagesTalkLeftIcon = lazy(
  async () =>
    await import('./messages_talk_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BorderBottomIcon = lazy(
  async () =>
    await import('./border_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookTitleIcon = lazy(
  async () =>
    await import('./book_title.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraChatIcon = lazy(
  async () =>
    await import('./camera_chat.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TextEditPenTIcon = lazy(
  async () =>
    await import('./text_edit_pen_t.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./bookmark_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListDownloadArrowDownCornerIcon = lazy(
  async () =>
    await import('./item_list_download_arrow_down_corner.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckmarkTextIcon = lazy(
  async () =>
    await import('./checkmark_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyrightIcon = lazy(
  async () =>
    await import('./copyright.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KeyholeOctagonIcon = lazy(
  async () =>
    await import('./keyhole_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenBigSquareIcon = lazy(
  async () =>
    await import('./edit_pen_big_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepTurnOffSquareIcon = lazy(
  async () =>
    await import('./sleep_turn_off_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkLinesCopyIcon = lazy(
  async () =>
    await import('./bookmark_lines_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibrarySqaureIcon = lazy(
  async () =>
    await import('./media_library_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SendCircleIcon = lazy(
  async () =>
    await import('./send_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RotateArrowSquareIcon = lazy(
  async () =>
    await import('./rotate_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenMoreDottedLineCircleIcon = lazy(
  async () =>
    await import('./edit_pen_more_dotted_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxArchiveReplyIcon = lazy(
  async () =>
    await import('./mailbox_archive_reply.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WindowsMoreCopyIcon = lazy(
  async () =>
    await import('./windows_more_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuArrowSquareIcon = lazy(
  async () =>
    await import('./menu_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FavoriteBookmarkMenuIcon = lazy(
  async () =>
    await import('./favorite_bookmark_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockNotificationIcon = lazy(
  async () =>
    await import('./time_clock_notification.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemHeartFavoriteSquareIcon = lazy(
  async () =>
    await import('./item_heart_favorite_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EmailMailTargetIcon = lazy(
  async () =>
    await import('./email_mail_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopySmallVerticlaSquareIcon = lazy(
  async () =>
    await import('./copy_small_verticla_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BookmarkAddPlusTopRightIcon = lazy(
  async () =>
    await import('./bookmark_add_plus_top_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChecklistClockIcon = lazy(
  async () =>
    await import('./checklist_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LinkBrokernModernCircleIcon = lazy(
  async () =>
    await import('./link_brokern_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClockTimeMoreSettingIcon = lazy(
  async () =>
    await import('./clock_time_more_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditLineSquareIcon = lazy(
  async () =>
    await import('./pen_edit_line_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MarkerPinAddPlusIcon = lazy(
  async () =>
    await import('./marker_pin_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClipSquareIcon = lazy(
  async () =>
    await import('./clip_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MagnetSqaureIcon = lazy(
  async () =>
    await import('./magnet_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WarningCheckmarkSquareIcon = lazy(
  async () =>
    await import('./warning_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemLikeHeartSquareIcon = lazy(
  async () =>
    await import('./item_like_heart_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AlarmClockTimeWarningIcon = lazy(
  async () =>
    await import('./alarm_clock_time_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TasklistCurvedCornerBottomRightIcon = lazy(
  async () =>
    await import('./tasklist_curved_corner_bottom_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckMarkSquareSelectIcon = lazy(
  async () =>
    await import('./check_mark_square_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputCursorIcon = lazy(
  async () =>
    await import('./input_cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenLineCircleIcon = lazy(
  async () =>
    await import('./edit_pen_line_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MailboxInboxShiningCircleIcon = lazy(
  async () =>
    await import('./mailbox_inbox_shining_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserSingleSelectIcon = lazy(
  async () =>
    await import('./user_single_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsCopyIcon = lazy(
  async () =>
    await import('./chart_analytics_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagStopOctagonIcon = lazy(
  async () =>
    await import('./flag_stop_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PhotoCameraAddIcon = lazy(
  async () =>
    await import('./photo_camera_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InputASquareIcon = lazy(
  async () =>
    await import('./input_a_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiAddPlusIcon = lazy(
  async () =>
    await import('./wifi_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemIcon = lazy(
  async () =>
    await import('./copy_item.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatMessageModernCheckmarkIcon = lazy(
  async () =>
    await import('./chat_message_modern_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumentPinCopyIcon = lazy(
  async () =>
    await import('./document_pin_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessagesWarningInformationIcon = lazy(
  async () =>
    await import('./messages_warning_information.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const WifiSignalCheckmarkCircleIcon = lazy(
  async () =>
    await import('./wifi_signal_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockMinutesIcon = lazy(
  async () =>
    await import('./time_clock_minutes.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartGraphIcon = lazy(
  async () =>
    await import('./chart_graph.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotificationDocumentSelectIcon = lazy(
  async () =>
    await import('./notification_document_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerMoreIcon = lazy(
  async () =>
    await import('./sticker_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemListCheckmarkSquareIcon = lazy(
  async () =>
    await import('./item_list_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorRefreshReloadLoadingIcon = lazy(
  async () =>
    await import('./cursor_refresh_reload_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyClipDocumentIcon = lazy(
  async () =>
    await import('./copy_clip_document.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CopyItemCrossRemoveRightTopIcon = lazy(
  async () =>
    await import('./copy_item_cross_remove_right_top.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemArrowDownCircleIcon = lazy(
  async () =>
    await import('./item_arrow_down_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NightModeCircleIcon = lazy(
  async () =>
    await import('./night_mode_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorRightCircleIcon = lazy(
  async () =>
    await import('./cursor_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CursorMoveFastCirclIcon = lazy(
  async () =>
    await import('./cursor_move_fast_circl.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeCircleCopyIcon = lazy(
  async () =>
    await import('./home_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ThunderLightningIcon = lazy(
  async () =>
    await import('./thunder_lightning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlidesImagesPapersBlankIcon = lazy(
  async () =>
    await import('./slides_images_papers_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserWebCheckmarkIcon = lazy(
  async () =>
    await import('./browser_web_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const InfoCiecleSquareIcon = lazy(
  async () =>
    await import('./info_ciecle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoginLogoutCrossDeleteIcon = lazy(
  async () =>
    await import('./login_logout_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PenEditUndoIcon = lazy(
  async () =>
    await import('./pen_edit_undo.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChartAnalyticsStyleIcon = lazy(
  async () =>
    await import('./chart_analytics_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StickerAddRemoveIcon = lazy(
  async () =>
    await import('./sticker_add_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatIcon = lazy(
  async () =>
    await import('./message_chat.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LaptopComputerSelectIcon = lazy(
  async () =>
    await import('./laptop_computer_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AttachmentLinkCrossDeleteIcon = lazy(
  async () =>
    await import('./attachment_link_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckboxChekmarkEqualIcon = lazy(
  async () =>
    await import('./checkbox_chekmark_equal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CameraRecSquareIcon = lazy(
  async () =>
    await import('./camera_rec_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlayVideoCopyIcon = lazy(
  async () =>
    await import('./play_video_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadCircleCopyIcon = lazy(
  async () =>
    await import('./download_circle_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UnderlineTextSquareIcon = lazy(
  async () =>
    await import('./underline_text_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TrashBinDeleteEnterArrowRightIcon = lazy(
  async () =>
    await import('./trash_bin_delete_enter_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SleepModeSquareIcon = lazy(
  async () =>
    await import('./sleep_mode_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FullSignalNetworkSignalSmartPhoneStrengthTelephoneIcon = lazy(
  async () =>
    await import(
      './full_signal_network_signal_smart_phone_strength_telephone.svg'
    ).then(module => ({ default: module.ReactComponent }))
);
const ObjectHeartTextEditIcon = lazy(
  async () =>
    await import('./object_heart_text_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemTextSelectCopyIcon = lazy(
  async () =>
    await import('./item_text_select_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DocumetEditPenIcon = lazy(
  async () =>
    await import('./documet_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserInternetWebNetworkWindowsIcon = lazy(
  async () =>
    await import('./browser_internet_web_network_windows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FavoriteBookmarkCheckmarkIcon = lazy(
  async () =>
    await import('./favorite_bookmark_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SignalRssNetworkSquareIcon = lazy(
  async () =>
    await import('./signal_rss_network_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BellWarningIcon = lazy(
  async () =>
    await import('./bell_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlusNotificationCircleIcon = lazy(
  async () =>
    await import('./plus_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ShareStarSquareIcon = lazy(
  async () =>
    await import('./share_star_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DownloadButtonSqaureIcon = lazy(
  async () =>
    await import('./download_button_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CloudSettingIcon = lazy(
  async () =>
    await import('./cloud_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ClosedCaptioningIcon = lazy(
  async () =>
    await import('./closed_captioning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EditPenRemoveCrossSquareIcon = lazy(
  async () =>
    await import('./edit_pen_remove_cross_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const StarIcon = lazy(
  async () =>
    await import('./star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const NotesCutIcon = lazy(
  async () =>
    await import('./notes_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleUploadArrowUpIcon = lazy(
  async () =>
    await import('./bin_trash_style_upload_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarEditPenStyleIcon = lazy(
  async () =>
    await import('./calendar_edit_pen_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingDeleteCrossIcon = lazy(
  async () =>
    await import('./loading_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MediaLibraryFavoriteHeartIcon = lazy(
  async () =>
    await import('./media_library_favorite_heart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CheckListTaskWarningIcon = lazy(
  async () =>
    await import('./check_list_task_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyeShineCircleIcon = lazy(
  async () =>
    await import('./eye_shine_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SettingsFilterSqureIcon = lazy(
  async () =>
    await import('./settings_filter_squre.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FileupMailIcon = lazy(
  async () =>
    await import('./fileup_mail.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchCrossRemoveOctagonIcon = lazy(
  async () =>
    await import('./search_cross_remove_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BinTrashStyleCrossRemoveIcon = lazy(
  async () =>
    await import('./bin_trash_style_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TimeClockFloppyDiskSaveIcon = lazy(
  async () =>
    await import('./time_clock_floppy_disk_save.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CurvedBoxIcon = lazy(
  async () =>
    await import('./curved_box.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuPinIcon = lazy(
  async () =>
    await import('./menu_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingStyleSquareIcon = lazy(
  async () =>
    await import('./loading_style_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CipIcon = lazy(
  async () =>
    await import('./cip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LoadingClassicSquareIcon = lazy(
  async () =>
    await import('./loading_classic_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);

export const InterfaceEssential = {
  ShieldProtectedCircleIcon,
  MessageRemoveCrossSquareIcon,
  LoactionMapDirectionSelectSquareIcon,
  FinderIconIcon,
  FocusSelectIcon,
  PenEditCreateIcon,
  MenuEditPenAddCircleIcon,
  WarningCheckmarkCircleIcon,
  HomeHouseIcon,
  CcSquareIcon,
  EditPenLineSquareIcon,
  BookmarkRemoveDeleteCrossIcon,
  SettingVerticalCircleIcon,
  HomeAddPlusIcon,
  CheckboxMoreMenuInfoIcon,
  CalednarScheduleCrossIcon,
  LockOverturningIcon,
  TimeAlarmCheckmarkSquareIcon,
  FlagArrowsLeftCircleIcon,
  BookmarkCrossIcon,
  DashboardIcon,
  DashboardCopyIcon,
  ClockTimeSquareCopyIcon,
  FullScreenZoomIcon,
  ParagraphTextCircleIcon,
  MenuBurgerCircleIcon,
  FinderWindowSearchIcon,
  ItemTextImageSquareIcon,
  ItemSearchMoreSquareIcon,
  TimeClockMenuSettingRightIcon,
  BinocularsSearchSquareIcon,
  MessageChatModernCrossRemoveIcon,
  BrowserWebWindowsIcon,
  WindowZoomPlusLoupeIcon,
  FingerprinAddPlusIcon,
  ItemListCrossDeleteIcon,
  BrowserWindowCheckMarkIcon,
  BatteryEmptyDischargeIcon,
  CalendarUndoArrowIcon,
  ObjectWifiMoreDotsIcon,
  BookmarkLabelCheckmarkIcon,
  RecMicStyleStopOctagonIcon,
  TasklistItemRemoveCrossStyleSquareIcon,
  MessageChartAnalyticsIcon,
  StarFavoriteCircleIcon,
  PuzzleIcon,
  WifiMinimaSigmalSelectIcon,
  WindowsBrowserEnterIcon,
  EditPenRemoveCrossCircleIcon,
  InfoSelectIcon,
  PlusAddIcon,
  BookmarkFavoriteAddIcon,
  LoginLogoutArrowRightIcon,
  ShareLinkBigOctagonIcon,
  LanguageTranslateCircleIcon,
  CopyCircleIcon,
  SelectItemCrossDeleateIcon,
  BookOpenTextIcon,
  ChartStyleBigIcon,
  PenEditIcon,
  LightBulbIdeaSquareIcon,
  ChatMessageRightBlankIcon,
  RotateArrowsCircleArrowsIcon,
  ItemSearchZoomPlusIcon,
  LoadingModernSquareIcon,
  ShapesObjectsIcon,
  MenuModernSquareIcon,
  PinSquareIcon,
  ListTasksDeleteCrossPlusIcon,
  AlignLeftArrowSquareIcon,
  BellStyleBigIcon,
  RotateDoneIcon,
  NotificationStatusCircleIcon,
  EnterLoginStyleSquareIcon,
  CopyPasteSelectAddPlusIcon,
  BarcodeScanIcon,
  ClockTimeShareIcon,
  PrivacyIcon,
  TasklistBackIcon,
  BinTrashModernTwoLineIcon,
  MailboxArchiveTextDeleteCrossIcon,
  TasklistSquareIcon,
  PictureImageUploadArrowUpIcon,
  FlagCircleIcon,
  AirplayHorizontalBigIcon,
  FireSquareIcon,
  WifiOffTurnOffIcon,
  ChatMessageCheckmarkIcon,
  PrinterMenuMoreIcon,
  MenuSquareDotsIcon,
  MonitorMinimalRemoveDeleteBigIcon,
  SynchronizeIcon,
  FinderWindowTextModernCircleIcon,
  CalendarSearchIcon,
  SlideDisableDeliteIcon,
  ShareArrowCircleIcon,
  CameraAddPlusSquareIcon,
  TimerClockPlayGoIcon,
  BrokenLinkUnlinkCopyIcon,
  ParagraphTextSquareIcon,
  KeyboardDeleteIcon,
  FilterStyleCircleIcon,
  DivisionMarkIcon,
  CalendrBottomLineIcon,
  WindowResizeRightIcon,
  MinusHexagonIcon,
  AlarmClockAddPlusSquareIcon,
  PenEditCircleCopyIcon,
  HeartFavoriteLaodingCircleIcon,
  MenuItemsBlocksCircleIcon,
  NftCircleIcon,
  ShapesBjectsSquareIcon,
  MagnetVerticalMinimalBigIcon,
  ParagraphIcon,
  HomeHouseModernCheckmarkIcon,
  EyeSimpleIcon,
  ClockTimeCrossRemoveRightBigIcon,
  HeadphonesSquareIcon,
  CheckmarkReadLineSquareIcon,
  ClipAttachmentCircleIcon,
  QuoteCircleIcon,
  SettingHorizontalCircleIcon,
  MenuBurgerIcon,
  FlagCircleCopyIcon,
  SettingCircleIcon,
  MailboxArchiveDeleteIcon,
  TasklistPinIcon,
  ClipVerticalSelectIcon,
  AccessibilityIcon,
  SignalRecStyleSquareIcon,
  ExitLoginLogoutIcon,
  BookmarkBlankIcon,
  LockWarningIcon,
  GridLayoutIcon,
  DownloadSquareCopyIcon,
  CheckmarkReadStyleCircleIcon,
  BoldSquareIcon,
  MoonNightModeIcon,
  EyeRefreshIcon,
  ClockTimeClassicBigIcon,
  FilterSortCircleDisableDeliteIcon,
  CursorOctagonIcon,
  ItemCheckmarkSquareIcon,
  EditPenListCircleIcon,
  CalendarScheduleIcon,
  TimeClockMenuCopyIcon,
  LoadingRotateSquareIcon,
  EyeCrossIcon,
  BrowserWebDeleteIcon,
  CalendarScheduleSquareIcon,
  ChatMessageHashtagIcon,
  RefreshRotateIcon,
  ImagePictureCopyLeftIcon,
  AwardRatingSquareIcon,
  LoginEjectCircleCircleIcon,
  StarFavoriteAddPlusSquareIcon,
  WindowItemMoreInfoIcon,
  ChartSquareIcon,
  CursorIcon,
  LoadingDotsCircleIcon,
  MailboxArchiveMenuSettingIcon,
  WindowsFinderBrowserIcon,
  ThunderLightningCircleIcon,
  MessageChatRightTextCommentsIcon,
  VoiceMicSquareIcon,
  StarCheckmarkIcon,
  ListAddIcon,
  ArrowRotateDotsIcon,
  CursorSelectPaswordIcon,
  MessageChatRightTextIcon,
  HandRightCursorSquareIcon,
  PhotoImageSquareIcon,
  DocumentBookmarkIcon,
  SelectObjectDeleteLockIcon,
  InputSquareIcon,
  MessabeChatLinkIcon,
  ImagePictureCopyIcon,
  FloppyStyleVerticalLineIcon,
  MenuSearhLoupeSquareIcon,
  MenuItemCursorCircleIcon,
  MailboxWarningIcon,
  SlidesWindowsIcon,
  WifiSelectBigIcon,
  PluginCircleCopyIcon,
  BellSquareIcon,
  LoginEnterArrowLeftSquareIcon,
  KeyboardButtonArrowsIcon,
  BinTrashStyleDownloadInfoIcon,
  EditPenTextCircleIcon,
  ResizeArrowsIcon,
  AsteriskCircleIcon,
  CheckmarkDoneCheckIcon,
  KeyboardButtonArrowsSquareIcon,
  StarGearCircleIcon,
  AlarmClockCheckmarkIcon,
  TSquareTextIcon,
  CalendarNotificationRightDownIcon,
  CompassMapLoadingIcon,
  MapMinimalCircleIcon,
  ClockHourSquareIcon,
  CameraCircleCopyIcon,
  BluetoothSquareIcon,
  CloudCheckmarkCircleIcon,
  PinCircleIcon,
  MarkerPinCrossDeleteIcon,
  ShareArrowUpUploadSquareIcon,
  ItemEditPenDotsCirleIcon,
  ImagePictureCircleIcon,
  CertificateCheckmarkIcon,
  QuestionIcon,
  DashboardCircleIcon,
  EditPenHorizontalDeleteRemoveIcon,
  TrashDeleteBinIcon,
  AttachmentLinkIcon,
  CrownStyleSquareIcon,
  MagicWandSquareIcon,
  ShareArrowsIcon,
  LockShieldProteIcon,
  KeyholeLockCircleIcon,
  ZipFileArchiveIcon,
  ImagesPicturesModernStyleCopyIcon,
  SearchCheckmarkCircleIcon,
  DeleteDisabledIcon,
  SearchLoapLoadingCircleIcon,
  CheckboxCheckmarkInfoIcon,
  BookmarksLabelTagCheckmarkIcon,
  ItmeCopyCheckboxCheckmarkTopRightIcon,
  MessageChatSquareIcon,
  ShareSignalIcon,
  BlockMinusSelectIcon,
  TextEditSquareIcon,
  PenEditSquareIcon,
  ImagePictureModernCircleCopyIcon,
  EmailMailMoreDotsIcon,
  LockSelectIcon,
  TrashBinInfoIcon,
  InputPasswordRotectionIcon,
  ItemListColumnsIcon,
  SearchTextTitleItemMiddleIcon,
  CalendarNotificationLeftDownIcon,
  ItemCheckmarkIcon,
  ChatMessageTextCrossDeleteIcon,
  SettingsSwitchesSquareIcon,
  MicrophoneMicRecCircleIcon,
  DownloadCircleIcon,
  ItemSearchDeleteCrossSquareIcon,
  ItemListAddPlusIcon,
  InputRemoveCrossSquareIcon,
  AddPageIcon,
  SettingsFilterIcon,
  ImagePictureModernStyleCircleIcon,
  DoorLogOutInCircleIcon,
  SettingStyleCircleIcon,
  InputTextIcon,
  HomeDotsDownIcon,
  CursorStyleSquareCopyIcon,
  SignalHotspotIcon,
  MoonNightModeNotificationSquareIcon,
  LogoutExitCircleIcon,
  CodeTextCircleIcon,
  DocumentTextFilterIcon,
  DottedLineCrossCutIcon,
  FingerprintProtectionOctagonIcon,
  MessageChatRightDotsMoreIcon,
  KeyLoginCircleIcon,
  HandStopCircleIcon,
  PinCircleCopyIcon,
  ItemTextSearchSquareIcon,
  ChatMessageCopyIcon,
  LockStyleLineIcon,
  CopyWindowsSquareIcon,
  MenuEditPenDeleteCrossIcon,
  DeleteButtonIcon,
  HornAngleBigIcon,
  MessageShatEyeTopRightIcon,
  EyeSquareIcon,
  DoneCheckIcon,
  BookmarksFavoriteCheckMarkIcon,
  LetterMailNotificationIcon,
  SpacingIcon,
  CompassLocationMapOffIcon,
  HeartFavoriteSquareIcon,
  KeyIcon,
  ShareArrowSqaureIcon,
  PinCopyIcon,
  VoiceMicChartSquareIcon,
  MicrophoneCircleIcon,
  MaiboxMinimalCircleIcon,
  ItemTextCheckmarkIcon,
  SettingVerticalIcon,
  ChatMessagesCheckmarkIcon,
  TaskListLineSquareIcon,
  ThunderLightningSquareIcon,
  FilterSortIcon,
  MenuNotificationCircleIcon,
  SearchLoapFastSqaureIcon,
  AlarmClockIcon,
  ItemSelectZoomPlusIcon,
  WifiCrossRemoveIcon,
  ArrowsSyncSqaureIcon,
  CloudUploadIcon,
  NotesDocumentCrossDeleteIcon,
  ZipCrossRemoveDeleteCircleIcon,
  FlagRemoveCrossSquareIcon,
  MessageChatSmileCopyIcon,
  BookCiecleIcon,
  ChartAnalyticsCrossDeleteSquareIcon,
  ItemShareSignalAddPlusIcon,
  AltButtonCircleIcon,
  ChatMessagesBubbleCircleIcon,
  PodcastIcon,
  StarFavoriteCrossDisabledIcon,
  DocumentCrossDeleteLeftIcon,
  EmailStyleBigIcon,
  SettingHexagonIcon,
  ItemSleepModeZSquareIcon,
  ItemWirelessSqaureIcon,
  TimerMinimalStyleIcon,
  LoadingArrowsSquareIcon,
  EditPenMinimalLeftCircleIcon,
  LaodingRingIcon,
  NotebookNotesIcon,
  ParagraphTextCopyIcon,
  MicrophoneStyleRecMicSquareIcon,
  BracketsIcon,
  StickerSquareCopyIcon,
  RefreshUpdateDisableDeleteIcon,
  SettingEditFilterGearSquareIcon,
  ObjectsCopyTargetIcon,
  FingerprintCircleIcon,
  HighvoltageWarningLightningIcon,
  PenEditMoreSquareIcon,
  DocumentTextPlusAddCentreRightIcon,
  ShieldVerticalDotsMenuSettingIcon,
  EmailLetterStyleCircleIcon,
  FilterSelectIcon,
  ChatMessagesBubbleCheckMarkIcon,
  RefreshReloadLoadingArrowIcon,
  PenEditMinimalInputCircleIcon,
  CheckboxCheckmarkSquareIcon,
  LogoutExitArrowRightCircleIcon,
  SettingsIcon,
  SettingsRoundIcon,
  CalendarModernLineIcon,
  EyeCrossCircleIcon,
  ChartAnalyticsCrossDeleteSquareCopyIcon,
  CodeTextIcon,
  MailboxUploadTopMiddleIcon,
  SendHorizontalRightCircleIcon,
  BookmarkOctagonSmallIcon,
  ChartCheckmarkCircleIcon,
  MessageChatWarningIcon,
  ClockTimeCheckmarkIcon,
  BrokenLinkUnlinkAttachmentIcon,
  ArrowBackspaceMoreIcon,
  ItemShareArrowRightSquareIcon,
  EyeSleepModeCircleIcon,
  DownloadLoadingProcessIcon,
  HeadphonesStyleSquareIcon,
  GlobeCertificateCheckMarkIcon,
  ItemSearchIcon,
  MarkerPinCircleIcon,
  AirplaySignalIcon,
  BagShoppingCircleIcon,
  SignalHotspotCircleIcon,
  MessageChekmarkReadIcon,
  SyncArrowsSquareCopyIcon,
  MessageLineMinimalSquareIcon,
  TildaSquareIcon,
  ShareSignalSquareCheckmarkIcon,
  ItemDocumentCursorIcon,
  ItemMenuMoreCircleIcon,
  SettingsSelectIcon,
  BrokenLinkUnlinkIcon,
  MicrophoneMuteSlashIcon,
  BookmarkFavoriteRemoveIcon,
  CupSquareIcon,
  CursorVerticalClickSquareIcon,
  MessageChatEditPenCopyIcon,
  SelectCopyAddPlusIcon,
  HashtagSquareIcon,
  CopyMoveObjectIcon,
  SettingSqaureIcon,
  HornTrumpetIcon,
  InboxUploadArrowUpCircleIcon,
  AnalyticsChartRightBigIcon,
  LeftIcon,
  BookmarkFavoriteRemoveDeleteIcon,
  LetterMailSquareIcon,
  BulletListTextIcon,
  TrashBinAddPlusCircleIcon,
  StarFavoriteDeleteCrossIcon,
  ItemMenuSquareIcon,
  AlarmClockTimeCopyIcon,
  BookCopyIcon,
  ArrowDirectionCircleCopyIcon,
  ShareArrowIcon,
  BellStyleIcon,
  CursorAngleRightSquareIcon,
  RotateArrowSquareCopyIcon,
  TargetRemoveDeliteIcon,
  TrashBinRestoreBackUpArrowIcon,
  NotepadRightAngleIcon,
  ClockWatchArrowUpIcon,
  DashbardMinimalSquareIcon,
  SearchLoupeSettingEditFilterIcon,
  ObjectPlusAddSquareIcon,
  ShieldSettingIcon,
  CalendarModernAddPlusIcon,
  CheckboxIcon,
  FingerprintCheckmarkCopyIcon,
  MenuHeartIcon,
  SendHorizontalRightBlankBigIcon,
  WifiSignalCheckmarkCirckeIcon,
  MoonSleepModeOffIcon,
  ExitDoorLogOutIcon,
  CompassCrossLocationCircleIcon,
  HeartFavoriteSquareCopyIcon,
  ParagraphQuoteIcon,
  ShapesOblectTriangleCircleSquareIcon,
  BellMinusIcon,
  PrinterPrintDeleteIcon,
  PictureImageCopyIcon,
  SettingsSwitchesCircleCopyIcon,
  HornTrumpetSquareIcon,
  AsteriskRotateArrowIcon,
  SignalShareCrossSquareIcon,
  ListBulletsIcon,
  ShieldMinusIcon,
  CalendarReturnArrowIcon,
  ImagePictureUploadArrowIcon,
  CalendarDateListStyleIcon,
  DashboardSquareCopyIcon,
  LightBulbIcon,
  ChartsCopyIcon,
  FloppySaveIcon,
  RefreshReloadLoadingIcon,
  AlarmClockWarningIcon,
  ImagePictureLoadingIcon,
  CursorButtonIcon,
  EarIcon,
  InboxMailDownloadIcon,
  MonitorComputerCircleIcon,
  EmailSquareIcon,
  ImagePictureModernStarIcon,
  SlidesImagesPapersAddPlusIcon,
  ObjectSearchIcon,
  MenuAddCircleIcon,
  ChatMessagesIcon,
  BackspaceCheckmarkIcon,
  BellStyleAddPlusCircleIcon,
  EditPenMenuCircleIcon,
  ItemTextPinIcon,
  ArrowCircleLoginIcon,
  LoadingWaitingIcon,
  WifiNetworkNoSignalAddSquareIcon,
  EditPenMinimalCircleSquareIcon,
  FinderMacIconIcon,
  CopyCheckmarkItemLeftDownIcon,
  ItemBookmarkTextIcon,
  DocumentTuckedCornerZipIcon,
  AnalyticsChartSquareCopyIcon,
  KeyboardHideIcon,
  AppNotificationIcon,
  RecStyleCircleIcon,
  SendMinimalVerticalSquareIcon,
  InputTextCircleIcon,
  HandCursorRightSquareIcon,
  TriangleWarningIcon,
  SettingsSwitchesIcon,
  PositionTargetIcon,
  AddPlusMinusZoomZoomOutIcon,
  PinCrossIcon,
  CalendarScheduleRefreshIcon,
  CheckboxCheckmarkPlusIcon,
  DropDownMenuIcon,
  HandMinimalCircleIcon,
  CursorArrowClickCircleIcon,
  LockIcon,
  KeyMiddleCircleIcon,
  MessageMicrophoneMicRecIcon,
  TaskListCheckmarkDocumentTextIcon,
  TargetSelectSquareIcon,
  BellNotificationHexagonIcon,
  TriangleDropIcon,
  ObjectMoreDotsIcon,
  BinTrashStyleDownloadBlankIcon,
  CrossDeleteCircleIcon,
  TextAButtonSquareIcon,
  LockDottedLineIcon,
  TaskListDocumentTextIcon,
  EyeCircleIcon,
  DoneCheckCheckmarkIcon,
  CursorLeftCircleIcon,
  KeyboardIcon,
  LinkBrokernModernSquareIcon,
  QuestionsCircleIcon,
  LoadingCircleIcon,
  WarningRetryAlertIcon,
  NotesCrossDeleteIcon,
  RepeatArrowSquareIcon,
  LibraryOpenLineIcon,
  ShieldTargetIcon,
  PicturesSquareIcon,
  FilterSortstyleBigIcon,
  CopyItemRightTopIcon,
  ItemTextMinusDeleteIcon,
  CheckListCrossDeleteCopyIcon,
  CursorBigClickLoadingIcon,
  DownloadAddPlusSquareIcon,
  TaskListInfoIcon,
  LoadingCrossIcon,
  SendAngelSquareIcon,
  SleepModeMoonDotsIcon,
  AlarmClockTimeCheckmarkIcon,
  DeleteDisabledSquareIcon,
  FlagStyleCircleIcon,
  CheckTodoIcon,
  SettingSquareCopyIcon,
  CursorClickSquareCopyIcon,
  MicrophoneMicRecCircleCopyIcon,
  CopyCheckmarkCopyIcon,
  ChatMessageCrossDeleleteIcon,
  UbeSphereDIcon,
  DashboardDottedLineCircleIcon,
  CopySquareStyleCircleIcon,
  PrintButtonSquareIcon,
  ArrowIcon,
  ClockTimeTimerFastIcon,
  RefreshPlusArrowDotsIcon,
  CertificateStarIcon,
  PrinterPrinSettingIcon,
  AlarmClockTimeTimerIcon,
  KeynoteIcon,
  HeartCheckmarkIcon,
  StarFavoriteDeleteCrossSquareIcon,
  CheckListStickerIcon,
  CalendarStickerIcon,
  FingerprintCheckmarkIcon,
  RotateRefreshLoadingIcon,
  CursorSelectWarninngIcon,
  TranslateRemoveDeleteCrossCircleIcon,
  NotesMoreIcon,
  UserBigIcon,
  ClipAttachmentIcon,
  ClockWatchRefreshReloadLoadingIcon,
  NotepadTitleIcon,
  SearchLoapMinusSquareIcon,
  WindowBrowserModernIcon,
  ClockTimeRefreshIcon,
  MessageChatModernRightBlankIcon,
  QuestionCheckmarkCircleIcon,
  InputIcon,
  CloudCircleIcon,
  AttachmentLinkSqaureIcon,
  LinkRemoveDeleteCircleIcon,
  ThumbsUpSquareIcon,
  SendVerticalLineBigIcon,
  AlarmClockTimeTimerFastIcon,
  DocumentMoreDotsIcon,
  TasklistItemCrossRemoveBigIcon,
  CheckmarkCircleIcon,
  TvCircleMonitorIcon,
  MailboxArchiveCollectionsEditIcon,
  SubstractGroupCopyIcon,
  ItemsGroupElementsIcon,
  MailboxArchiveCheckmarkIcon,
  SearchItemLeftTopSquareIcon,
  ListTasksDeleteCrossIcon,
  MailboxArchiveSquareIcon,
  DownloadSquareArrowIcon,
  LockShieldProtectionIcon,
  CursorSelectSquareCopyIcon,
  CursorPlusCheckmarkIcon,
  WifiSignalCheckmarkCirceIcon,
  NftSquareCopyIcon,
  PopUpInformationCloseMessageIcon,
  SearchLoupeDotsIcon,
  SettingMinimalRightSquareIcon,
  ClockTimeBookmarkIcon,
  LoadingStyleIcon,
  CalendarShareArrowIcon,
  InfornationInfoIcon,
  EnterLoginCircleIcon,
  MailboxDownloadIcon,
  SettingCircleSmallIcon,
  HardDriveStorageCircleIcon,
  FlagCheckmarkCircleIcon,
  MailboxTextItemIcon,
  LetterMailIcon,
  SleepModeIcon,
  CodeIcon,
  MessageChatModernNotificationIcon,
  CrossDeleteSquareIcon,
  BinTrashStyleLineDownIcon,
  SendMailStyleCircleIcon,
  FloppyIcon,
  CursorClickSelectCircleIcon,
  MailboxIncomingArrowLeftIcon,
  SearchLoupeMinusIcon,
  PenEditCheckmarkCircleIcon,
  ImagePictureCenterSquareIcon,
  LoadingModernSquareCopyIcon,
  UploadLoadingCircleIcon,
  KeyholeLoginLoadingCircleIcon,
  CheckmarkSqaureCopyIcon,
  ThumbsDownDislikeStyleSquareIcon,
  DashboardMinimalIcon,
  PinnedIcon,
  CommandCmdIcon,
  ResizeMinimalAngleCircleIcon,
  BlackboardSquareIcon,
  PinLocationCircleIcon,
  BookmarkSmallOctagonIcon,
  EmailLetterMinusIcon,
  WakeCircleIcon,
  DocumentClipIcon,
  BrokenLinkModernHorizontalBigIcon,
  InfoBulletListTextIcon,
  ClockTimePauseIcon,
  BackwardRearwardBackIcon,
  RecMicRecordingCircleIcon,
  RefreshRotateCircleIcon,
  CalendarScheduleMoreDotsIcon,
  DashboardMiddleCircleIcon,
  LightningModernOctagonIcon,
  ShareSquareIcon,
  SelectTargetArrowSquareIcon,
  CursorSelectIcon,
  CalendarBlankLinkIcon,
  UserOctagonIcon,
  TimerPauseClockSquareIcon,
  StarsLightSparkleIcon,
  EmailsLetterMailIcon,
  FingerprintCircleCopyIcon,
  TerminalIcon,
  SendMessageIcon,
  ItemCopyCheckmarkIcon,
  ItemSearchLoapIcon,
  DownloadArrowCircleIcon,
  NoticeBoardTextIcon,
  FingerprintMoreIcon,
  TimeClockLoadingIcon,
  LoadingIcon,
  MessageChatRemoveDeleteCrossIcon,
  MenuItemsStyleCircleIcon,
  SelectItemDeleteCrossIcon,
  CircleDotsPluginIcon,
  MouseSquareIcon,
  SettingVecticalBigIcon,
  CameraAddSquareIcon,
  BluetoothIcon,
  StickerMenuIcon,
  MailboxSrarsIcon,
  ClockTimerFastIcon,
  PinLocationMoreDotsIcon,
  CopyVerticalSquareIcon,
  CursorSelectCircleIcon,
  StarFavoriteBookmarkSquareIcon,
  CheckboxCheckmarkCircleIcon,
  PictureImageMenuIcon,
  ItemFloppyDiskSquareIcon,
  SyncArrowsCircleIcon,
  EmailMailOneIcon,
  ChatMessageLoadingIcon,
  ShieldMinimalSquareIcon,
  HomeSquareIcon,
  TaskListAddIcon,
  ClockDottedLineBigIcon,
  SleepTurnOffCircleIcon,
  ItemTextCursorSquareIcon,
  BatteryChargingDotsIcon,
  RefreshReloadCheckMarkIcon,
  EmailMailIcon,
  PrinterMinimalSquareIcon,
  CloudDownloadIcon,
  SearchIcon,
  NotificationCheckmarkDoneIcon,
  DiamondMinimalSquareIcon,
  CalendarCheduleLineIcon,
  CursorSelectClickCircleCopyIcon,
  PinBigIcon,
  DocumentPlusIcon,
  BlackboardBusinessChartIcon,
  ThunderRefreshRotateIcon,
  KeySquareIcon,
  ItemCutCrossRemoveDeleteIcon,
  MenuItemCopyIcon,
  MagnetAngleAttractionIcon,
  ItemsMenuIcon,
  NightModeDeleteCrossCircleIcon,
  DashboardSqaureIcon,
  DocumentStatusDoneCheckmarkIcon,
  ImagesPicturesCopyCopyIcon,
  EyeHiddenIcon,
  SearchLoupeMinusBoxIcon,
  TasksRecMicrophoneIcon,
  CheckboxItemGridIcon,
  BoxCrossRemoveSelectIcon,
  FilterSortStyleBigIcon,
  ShieldProtectionCheckmarkCircleIcon,
  DocumentTextIcon,
  FlagIcon,
  HomeModernMinusIcon,
  BrowserWebPlayIcon,
  FolderCircleIcon,
  SearchFastMinimalCircleIcon,
  DocumentFileCurvedCornerIcon,
  ObjectArrowTextEditIcon,
  SeachCrossDeleteSquareIcon,
  BatteryChargingMiddleIcon,
  CalendarScheduleAddIcon,
  ImagePhotoCopyLeftDownIcon,
  LampLightClassicIcon,
  MenuSquareAddIcon,
  MoonNightModeStarIcon,
  CopyArrowIcon,
  MoonNightModeAddIcon,
  CheckListLinkIcon,
  NoteDocumentClipAttachmentIcon,
  FloppyDiskBlankRightDownIcon,
  CheckmarkSqaureIcon,
  ClipStyleCircleIcon,
  EyeIcon,
  LayersCircleIcon,
  BinTrashSquareIcon,
  WindowEnterIcon,
  MediLibraryHorizontalIcon,
  MessageEditPenLineIcon,
  ChecklistTasksChechmarkSquareIcon,
  FullScreenArrowIcon,
  SelectObjectIcon,
  ShareCircleIcon,
  ClockTimeIcon,
  WifiSignalCircleIcon,
  SendMessageSendShareIcon,
  DownloadMediaDownSquareIcon,
  LockArrowLeftCircleIcon,
  LightbulbShineSquareIcon,
  MessageChatAnalyticsIcon,
  UndoArrowLeftCircleIcon,
  AtasklistStyleCutCrossedIcon,
  TrashBinDIcon,
  SearchLoapRotateArrowIcon,
  DocumentEventIcon,
  EditPenRectangleIcon,
  BracketAddPlusIcon,
  DashnoardCircleIcon,
  CursorSelectItemSquareIcon,
  StatusMenuIcon,
  HomeSqaureIcon,
  ChartCircleIcon,
  AlarmClockTimeNotificationIcon,
  LinkAngleMinimalSquareIcon,
  HornStyleOctagonIcon,
  WindowsResizeIcon,
  ResizeWindowArrowIcon,
  CameraSquareIcon,
  PagesArrowSplitIcon,
  CalendarCurvedCornerLeftIcon,
  ResizeWindowArrowFullScreenCircleIcon,
  MailboxSmallSquareIcon,
  MenuBurgerSquareIcon,
  CcCircleIcon,
  LayersSquareIcon,
  ItemArrowRightSquareIcon,
  CropRotateIcon,
  NotesSearchIcon,
  RefreshSquareIcon,
  SlidesImagePicturesHorizontalBlankIcon,
  EyeShowUpArrowIcon,
  TimerClockSqaureIcon,
  TimeClockMoreIcon,
  InfoLoadingIcon,
  UserProfileAddSquareIcon,
  TimerOffCrossRemoveDeletevIcon,
  PowerTurnOnIcon,
  MessageChatModernMinusDeleteIcon,
  QuestionsButtonAddPlusIcon,
  TextAlignArrowLeftIcon,
  ChatVoiceRecIcon,
  ResizeLeftRightSquareIcon,
  CalendarSchedulePareviousDateIcon,
  EyePlusMinusSqaureIcon,
  CalednarArrowLeftCentreIcon,
  MediaLibraryUploadIcon,
  SearchLoupeSquareIcon,
  AdCircleCopyIcon,
  MediaLibraryMinimalMoreIcon,
  CertificateCheckMarkCircleIcon,
  ItemTextImageSquareCopyIcon,
  AnchorSquareIcon,
  SettingOctagonCircleIcon,
  TextItemListAddPlusIcon,
  WindowItemMoreDotsIcon,
  ItemRotateIcon,
  TasklistDocumentGroupIcon,
  CirclesPlusAddIcon,
  TasklistAddIcon,
  CursorBrokenLineIcon,
  FilterStyleMenuMoreCircleIcon,
  BracketAddCheckmarkIcon,
  CrownStyleCircleIcon,
  ClockTimeCrossIcon,
  TimerIcon,
  CrownLineCircleIcon,
  PenEditATextIcon,
  BellBigIcon,
  HeartFavoriteRemoveCheckmarkIcon,
  HandHorizontalSquareIcon,
  ASquareTextButtonEditIcon,
  KeyboardSquareCopyIcon,
  BellStyleNotificationSquareIcon,
  DoorExitIcon,
  StarOctagonIcon,
  EmailMailSelectIcon,
  SearchLoupeCheckmarkBoxIcon,
  MicrophoneRecMicSqaureIcon,
  DropdownMenuItemCheckmarkIcon,
  KeyboardOptionIcon,
  GroupElementsIcon,
  SettingsSwitchesCheckmarkIcon,
  CompassLocationMapOffSquareIcon,
  SendModernCircleIcon,
  ArrowDirectionModernBigIcon,
  ImagePictureModernStyleSquareIcon,
  ShareMinimalSquareIcon,
  ShieldLockSquareIcon,
  WarningAlarmDeleteErrorIcon,
  DocumentImagePictureIcon,
  ShieldModernOptionLinesIcon,
  TargetArrowsIcon,
  IosAppApplicationStarIcon,
  CopyArrowLedtDottedLineIcon,
  ItemDocumentBookmarkIcon,
  KeyholeLockProtectionSquareIcon,
  PuzzlePlugInIcon,
  WindowFinderCrossRemoveIcon,
  ParagraphAlignTextIcon,
  PrinterPrintPauseIcon,
  SearchItemLeftSquareIcon,
  FloppyDisksCopyIcon,
  SettingIcon,
  WindowsStretchArrowsIcon,
  WindowFinderMinimizeArrowDownIcon,
  WindowSidebarLeftBlankIcon,
  MicrophoneMicRecSquareIcon,
  EnterLoginModernSquareIcon,
  BluetoothCircleOffIcon,
  ChatMessageIcon,
  NftSquareIcon,
  SettingsGearIcon,
  MessageSqaureIcon,
  CursorPlusAddIcon,
  ShareLeftItemSquareIcon,
  DashboardStyleIcon,
  EyeShowVisibleIcon,
  CompassMapLocationRemoveCrossIcon,
  ClockTimeArrowCircleIcon,
  FeatherPenCircleEditIcon,
  MessageChatLeftHeartFavoriteIcon,
  ThumbsUpLikeSquareIcon,
  FingerprintDeleteCrossIcon,
  SearchShiningIcon,
  CheckmarkBoxSquareIcon,
  DocumentSelectIcon,
  ItemArrowUpSquareIcon,
  ChatMessageMenuIcon,
  DirectionArrowDottedLineIcon,
  ImagePuctureSelectIcon,
  AlarmClockTimeTimerSleepSnoozeZzzIcon,
  TasklistModernLineIcon,
  BookmarkRemoveCrossIcon,
  MessageChatClickIcon,
  ItemTextImageIcon,
  CalendarDownloadArrowDownIcon,
  HeartsBigIcon,
  RotateRefreshIcon,
  WifiArrowsCircleIcon,
  ZipArchiveCircleCopyIcon,
  FlagSqaureIcon,
  StarFavoriteNotificationIcon,
  PinEyeIcon,
  BookmarkCopyIcon,
  ItemLockCrossCircleIcon,
  PrintOutputCheckmarkPlusIcon,
  EditPenLeftTextCircleIcon,
  ObjectSearchCopyIcon,
  MenubarArrowUpIcon,
  TriangleSynRoatateArrowsIcon,
  ChatMessageVoiceRecMicIcon,
  InfoCheckmarkCircleIcon,
  ShareArrowSquareIcon,
  BellStyleNotificationCircleIcon,
  SettingsCircleIcon,
  WifiWarningIcon,
  TvAddPlusIcon,
  ShieldCheckmarkReadIcon,
  ShrinkVerticalSquareIcon,
  CalendarBlankDateIcon,
  ClockWatchMenuIcon,
  SearchLoupeDeleteIcon,
  LampBigIcon,
  ImagePictureCircleCopyIcon,
  QuestionAddPlusCircleIcon,
  SearchLoupeQuestionIcon,
  TasklistTextIcon,
  FingerprintNotificationCircleIcon,
  BrowserWebIcon,
  SelectCheckboxIcon,
  CutCopyIcon,
  CalendarCheduleBackIcon,
  ZipArchiveCircleIcon,
  MenuCenterCircleIcon,
  ChartUpArrowSquareIcon,
  UserProfileSettingIcon,
  SelectObjectCopyPlusAddIcon,
  CompassMapCircleIcon,
  LoudStorageCircleIcon,
  CalendarCheduleClockIcon,
  ClockTimeTimerIcon,
  LabelTagArrowCircleIcon,
  MediaLibraryIcon,
  FireIcon,
  PrintCheckmarkCircleIcon,
  MailboxArchiveCollectionsIcon,
  BookmarksAddPlusBottomLeftIcon,
  MessageChatMoreDotsIcon,
  DashboardClockSquareIcon,
  ShareTransmitIcon,
  TrashBinMoreIcon,
  BookIcon,
  ArrowCircleDownIcon,
  ShareCopyStyleIcon,
  LockDeleteCrossIcon,
  PenCrossDeleteIcon,
  SearchBrokenLineSquareIcon,
  MarkerPinQuestionIcon,
  SelectCursorSquareIcon,
  ShareNoSignalCircleIcon,
  ChatMessagesEditIcon,
  RotateArrowDotsIcon,
  FingerprintSquareIcon,
  BellNotificationIcon,
  AlarmClockTimeRemoveIcon,
  ItemCheckmarkLockCircleIcon,
  SwitcherSettingUpSquareIcon,
  AlarmClockTimeIcon,
  EmailMailCheckmarlIcon,
  ChatMessagesCheckmarkPasswordIcon,
  HeartArrowRightCircleIcon,
  ZipArchiveStyleCircleIcon,
  StopSignCircleIcon,
  BookmarkHeartDeleteCrossCircleIcon,
  EmailMailSquareIcon,
  DashboardMinimalDottedSquareIcon,
  ChatMessageCameraIcon,
  StickerIcon,
  StarAddPlusSquareIcon,
  PinLoadingIcon,
  BookmarkSelectCopyIcon,
  PrinterModernIcon,
  LoadingDotsVerticalCircleIcon,
  RotateArrowIcon,
  DocumentTextSearchIcon,
  MenuCheckmarkSquareIcon,
  CalendarEditPenIcon,
  MonitorMinimalBigIcon,
  SelectCopyImageIcon,
  MenuModernCircleIcon,
  DocumentCheckmarkCopyIcon,
  DropdownMenuItemCheckmarkCopyIcon,
  BrokenLinkUnlinkAttachmentCircleIcon,
  StickersCopyIcon,
  CursorMoveFastLeftSquareIcon,
  SendAngleCircleIcon,
  EmailLetterStyleAddPlusIcon,
  DisableIcon,
  SearchTextLoupeSquareIcon,
  DashboardCircleCopyIcon,
  CookiesSquareCopyIcon,
  CursorSwipeLeftIcon,
  CursorVerticalCircleIcon,
  SettingEditFilterSwitchesSwitchIcon,
  BellCheckmarkIcon,
  ImagePictureModernSquareIcon,
  TaskListEyeDocumentTextIcon,
  ClipAddPlusBigIcon,
  ImagePictureIcon,
  CheckmarkDoneCheckSquareIcon,
  InboxMaleIncomingCircleIcon,
  WatchClockTimeIcon,
  ClockTimeFastIcon,
  SettingSquareIcon,
  ListPaperCheckmarkIcon,
  DashboardLoadingIcon,
  MouseCircleIcon,
  HourglassCircleIcon,
  DashboardClockTimeIcon,
  ItemCopyDeleteCrossErrorSquareIcon,
  LockPasswordInputIcon,
  BookmarksLabelTagDoubleIcon,
  CheckmarkReadStyleSquareIcon,
  CursorStyleCircleIcon,
  FilterStyleBigSquareIcon,
  ChatMessageTargetIcon,
  SettingMinimalLeftCircleIcon,
  CropEditRefreshIcon,
  CopyLinkCutIcon,
  ImageCopySelectIcon,
  CalendarCurvedCornerIcon,
  SearchLoapIcon,
  ReplyLoadingArrowIcon,
  InputPasswordIcon,
  ObjectTexListIcon,
  DashboardCutVerticalIcon,
  CalendarScheduleArrowRightIcon,
  EmailSendArrowIcon,
  PinLocationSignalSquareIcon,
  ChatMessageAddPlusCopyIcon,
  CursorSelectBigIcon,
  LoopArrowIcon,
  TaskListMenuMoreIcon,
  SettingStyleBigIcon,
  ItemHeartCircleIcon,
  CursorFastSquareIcon,
  EmailMailWarningIcon,
  BlockDeleteStopIcon,
  QuestionCircleIcon,
  SelectCircleIcon,
  RefeshLoadingArrowsCircleIcon,
  CursorArrowAngleSquareIcon,
  FilterSortTopSquareIcon,
  MenuBlockCheckmarkIcon,
  ItemTextLogoIcon,
  IdSquareIcon,
  BookmarksFavoriteSqaureIcon,
  CalendarUndoArrowCenterIcon,
  UserModernStyleStopIcon,
  MessageChatCenterTextIcon,
  UserSignalIcon,
  ScrollDownCircleIcon,
  CursorAngleRightCircleIcon,
  CalendarEditPenDotsIcon,
  MailEmailAddPlusIcon,
  EditPenCircleIcon,
  ImagePictureTextEditIcon,
  ItemTextStarIcon,
  BracketsCircleCopyIcon,
  CheckmarkSelectDottedLineIcon,
  SearchShineCircleCopyIcon,
  DocumentArrowUpIcon,
  CloudAddCircleIcon,
  ClipAttachmentSquareIcon,
  WarningCubeIcon,
  WindowsAppPlusIcon,
  FingerprintSqaureIcon,
  WindowPopUpIcon,
  ChartMinimalAnalyticsCircleIcon,
  SearchEditCircleIcon,
  TasklistArrowLeftCentreIcon,
  MessageChatMoreTextIcon,
  FileImportIcon,
  BinBoxSqaureIcon,
  SleepSnoozeZzzIcon,
  TrashDeleteBinCircleIcon,
  GroupElementsTicketIcon,
  MessageChatTextLightIcon,
  LoadingCircleDottedLineStyleIcon,
  CopyImageSelectIcon,
  WifiSquareIcon,
  SendCursorSquareIcon,
  ListRemoveDeleteCrossIcon,
  ArrowsRoundIcon,
  StarFavoriteAddIcon,
  LifeRingHelpSupportIcon,
  ItemPointsSquareIcon,
  WindowRollUpArrowIcon,
  SendMessageCircleIcon,
  UnderlineCircleIcon,
  ClockTimeWarningIcon,
  CursorInfoIcon,
  DiamondPluginCircleIcon,
  LoadingCheckmarkSquareIcon,
  SpaceObjectSelectIcon,
  BellStyleCircleIcon,
  EditPenListCircleCopyIcon,
  BellRingIcon,
  SeachMoreMenuSquareIcon,
  InputPasswordSquareIcon,
  CopyItemLeftTopIcon,
  MarkerPinOffSqaureIcon,
  HomeModernBlankIcon,
  ItemClipCrossSquareIcon,
  HeartCircleModernIcon,
  ItemEditSquareIcon,
  ArrowDirectionLoadingIcon,
  BrokenLinkUnlinkAttachmentSquareIcon,
  LoadingRingIcon,
  WifiNetworkSignalArrowLeftIcon,
  TextAlignArrowRightIcon,
  SearchZoomCheckmarkCircleIcon,
  LibrariesModernStyleIcon,
  ItemTextAlignCrossRemoveSquareIcon,
  SpaceObjectSelectCheckmarkIcon,
  PluginCircleIcon,
  ExitLoginDeclineIcon,
  PinSquareCopyIcon,
  ClockTimeArowLeftIcon,
  MarkerPinEditIcon,
  BookmarkArrowShareIcon,
  UploadArrowsBigCircleIcon,
  MenuSearchMoreCircleIcon,
  LibraryDownloadIcon,
  CursorTargetIcon,
  FilterSortSquareIcon,
  BookmarkCircleIcon,
  BinBoxDownloadArrowDownIcon,
  BookmarksCircleIcon,
  DashboardSquareIcon,
  BellStyleSquareIcon,
  PinStyleVerticalIcon,
  WifiNetworkSignalAddCircleIcon,
  BinTrashStyleCrossMiddleIcon,
  ChatMessageCircleIcon,
  ItemTextCheckmarkSquareIcon,
  LoadingCircleDottedLineIcon,
  LoadingRotateArrowIcon,
  MessagesChatTextIcon,
  ChartDiagramCropElementCrossDeleteIcon,
  ArrowUturnUpCrossIcon,
  FloppyTimeIcon,
  MouseAngleIcon,
  BookmarksRemoveIcon,
  SearchZoomLoupeIcon,
  AirplayStyleCircleIcon,
  InfoInfornationIcon,
  SignOutIcon,
  CursorSquareIcon,
  CalendarmodernStyleIcon,
  SyncSynchronizeCircleIcon,
  LampLightIcon,
  ScaleMoveShareIcon,
  ClipCheckmarkCircleIcon,
  PinShineCircleIcon,
  FloppySquareBigIcon,
  PinItemCircleIcon,
  EditPenLineCircleCopyIcon,
  FingerprintSelectIcon,
  BinTrashStyleIcon,
  BrowserWindowAdsIcon,
  ChatMessageTextCopyIcon,
  GridSelectSquareIcon,
  FloppySaveDesableIcon,
  BookmarkSelectIcon,
  TrendUpdateLightningCircleIcon,
  ChatMessageSadSmileIcon,
  MessageChatHeartIcon,
  InputArrowUpIcon,
  SendHorizontalAngleBlankBigIcon,
  InfoNotificationIcon,
  MoonNightModeStarCircleIcon,
  SyncSynchronizeSquareLoadingIcon,
  ScissorsCutIcon,
  ItemSearchKeywordsIcon,
  LoginLogoutSquareIcon,
  FlagSquareIcon,
  FilterSortMenuIcon,
  CursorSelectVerticalSquareIcon,
  PlayLoadingIcon,
  MagicWandIcon,
  ParagraphRightIcon,
  ItemDocumentTextSquareIcon,
  FilterMinimalMenuSquareIcon,
  CalendarTuckedCornerIcon,
  TrendUpdateLightningSquareIcon,
  RoadSignIcon,
  NightModeMoonMenuCircleIcon,
  HomeHouseLineIcon,
  HomeCircleIcon,
  PenEditBigSquareIcon,
  EditPenCenterDottedLineIcon,
  CookiesCrossCircleIcon,
  BellNotificationSleepModeIcon,
  DocumentCopyAddPlusGroupIcon,
  FlagRemoveCircleIcon,
  BlackboardChartIcon,
  LabelTagIcon,
  FloppyClockIcon,
  FloppySquareIcon,
  RecRecordingSquareIcon,
  MoreBracketsSquareIcon,
  LockDeleteCrossCircleIcon,
  TasklistStyleLineUpIcon,
  FilterStyleSquareIcon,
  ThumpsUpIcon,
  ChatMessageEyeIcon,
  BinTrashRemoveCrossIcon,
  LogOutCircleIcon,
  LoadingArrowCircleIcon,
  WifiCheckmarkIcon,
  ShareArrowCircleCopyIcon,
  FilterCheckmarkCircleCopyIcon,
  CheckboxCheckmarkTranslateIcon,
  PinAddPlusCircleCopyIcon,
  EditPenDottedLineSquareIcon,
  CheckboxBlankOctagonIcon,
  SettingStyleVerticalIcon,
  UploadLoadingArrowIcon,
  BoxClassicIcon,
  EyeAddPlusSquareIcon,
  WarningCheckmarkTestOkCircleIcon,
  TaskListStyleAddPlusIcon,
  DownloadSaveUploadIcon,
  ResizeCheckmarkShareIcon,
  CheckmarkDoneCheckCircleIcon,
  ArrowUpIcon,
  CheckboxCheckmarkMoreIcon,
  DeleteDisabledRossHexagonIcon,
  UserStyleCircleIcon,
  MessageChatTextStyleIcon,
  SyncArrowsCircleCopyIcon,
  CommandMinimalSquareIcon,
  LightbulbShineCircleIcon,
  LampLighrShineIcon,
  SearchRadarLocationIcon,
  ItemStarsSquareIcon,
  LanguageTranslateIcon,
  CursorSelectLoadingCopyIcon,
  CalendarMenuIcon,
  WindowTextClockTimeIcon,
  CameraRecIcon,
  SettingMenuCheckmarkCopyIcon,
  ReturnResetGoBackCircleIcon,
  BracketsSeparationSquareIcon,
  ShareIcon,
  PluginSelectInputIcon,
  MailboxArchiveCircleIcon,
  WifiSmallCircleIcon,
  StarModernShiningFavoriteNotificationSquareIcon,
  ScanerBarcodeIcon,
  BellStyleAlarmBigIcon,
  BookmarkFavoriteLabelClockIcon,
  TrashDeleteBinCrossIcon,
  SatrCrossCircleIcon,
  BasketDeleteCrossSquareIcon,
  LockSquareIcon,
  PhoneIcon,
  CheckListTaskCrossDeleteIcon,
  CalendarCheckmarkCurvedOrnerIcon,
  ChartSqaureIcon,
  LockRefreshRotateIcon,
  CalendarNotificationIcon,
  DocumentMoreLeftIcon,
  AnalyticsStyleSquareIcon,
  QuestionCrossDeleteCircleIcon,
  ShareRssSquareIcon,
  MoonIcon,
  CalendarScheduleSearchIcon,
  StarFavoriteMenuIcon,
  BlackboardCircleIcon,
  LteSignalIcon,
  AirplayShareBigSquareIcon,
  RotateCirlceAddPlusIcon,
  MicrophoneRecMicCrossDeleteOffCircleIcon,
  ArtboardIcon,
  FlashlightIcon,
  CalendarScheduleClockTimeIcon,
  CalendarBlankIcon,
  WarningCircleIcon,
  FlagCrossRemoveCircleIcon,
  GridCircleIcon,
  DeleteModernCrossSquareIcon,
  SendHorizontalRightSquareIcon,
  DocumentCopyStatusRejectedCrossIcon,
  ShieldPinIcon,
  ShieldLoadingModernIcon,
  PrinterOctagonIcon,
  SendHorizontalBigIcon,
  ShrinkVerticalCircleIcon,
  KeyboardHideMoveIcon,
  NotebookLaptopCheckmarkCircleIcon,
  ClipCrossAddIcon,
  MicRecAddPlusSquareIcon,
  MailboxCrossDeleteRemoveIcon,
  VoiceRecSquareIcon,
  BorderInnerIcon,
  EarthGlobeCrossIcon,
  CalenadrAddPlusIcon,
  DashnoardSquareIcon,
  TasklistItemAddPlusSquareIcon,
  ItemCopyCheckmarkDottedLineIcon,
  KeyboardSquareIcon,
  PuzzlePluginLeftIcon,
  PrinterSearchLoupeIcon,
  LoadingClassicStyleCircleIcon,
  EditDeletePenIcon,
  MailboxInboxCirckeIcon,
  WatchClockTimeLoadingIcon,
  DashboardMinimalModernSquareIcon,
  ArrowsCircleIcon,
  MenuDropDownMenuUpIcon,
  ArrowsSyncCircleIcon,
  RecStyleSquareIcon,
  LampLightCheckmarkIcon,
  WifiSmallOctagonIcon,
  WifiOffCrossIcon,
  FinderTextEnterIcon,
  PrintOutputAddPlusIcon,
  MoonSleepModeCheckmarkCircleIcon,
  PuzzleRightIcon,
  ShieldArrowUpUploadIcon,
  PinMinusIcon,
  LinkAttachmentCircleIcon,
  MenuSquareCopyIcon,
  DocumentCheckmarkIcon,
  TaskSheetIcon,
  MessageChatStarFavoriteIcon,
  HornTrumpetCircleIcon,
  EditPenOctagonIcon,
  AttachmentLinkSquareCopyIcon,
  StarCheckmarkCircleIcon,
  ImagePictureShineStarSquareIcon,
  BinTrashDCrossDeleteIcon,
  MessagesHeartFavoriteLikeStoryIcon,
  CursorUpSquareIcon,
  LibraryModernDIcon,
  ClockTimeMenuSettingIcon,
  FlagCheckmarkIcon,
  ArrowDirectionCrossCircleIcon,
  TrashDeleteBinRefreshIcon,
  CalendarModernIcon,
  ShieldProtectionDotsTargetIcon,
  MailboxCollectionsArchiveIcon,
  WindowsPagesBookmarksIcon,
  SpellingCheckTextIcon,
  SearchLoapHeartCircleIcon,
  OsAppIconIcon,
  IosAppApplicationIcon,
  ChartAnalyticStatisticsIcon,
  BackwardRearwardDotsIcon,
  ResizeMinimalSquareIcon,
  EnterCircleIcon,
  DeleteCircleIcon,
  LibraryOpenModernStyleIcon,
  DocumentAddPlusCopyIcon,
  TimeClockSleepModeIcon,
  MessageCheckamrkReadIcon,
  FingerprintAddPlusCircleIcon,
  ShapesCircleSquareIcon,
  WindowTextCheckmarkIcon,
  HeartSelectIcon,
  WindowLinkIcon,
  LeafletIcon,
  BluetoothCircleIcon,
  ImagePictureUploadArrowUpSqaureIcon,
  LampSparkIcon,
  ObjectSearchMoreIcon,
  MessageChatModernMoreDotsIcon,
  AirplayStyleIcon,
  PenEditLineCircleIcon,
  CheckmarkReadCircleIcon,
  WindowResizeDownIcon,
  CopyMinimalCircleIcon,
  ChatMessageAddPlusIcon,
  TextArrowEditSquareIcon,
  LoaderSqaureIcon,
  ChartUpdateIcon,
  UserProfileAddIcon,
  DocumentTextEditIcon,
  CursroClickCircleIcon,
  ArrowCircleIcon,
  EyeOneIcon,
  BasketTrashDLinesIcon,
  EditPenItemTextCircleIcon,
  ExitLogOutCopyIcon,
  TaskListSquareIcon,
  SearchMenuIcon,
  WindowRollUpIcon,
  ShieldProtectedCrossDeleteIcon,
  UploadArrowsBigSquareIcon,
  DashboardUpMinimalCircleIcon,
  CheckLustTextIcon,
  HeartFavoriteCheckmarkCircleIcon,
  ClockTimeNineIcon,
  DropdownMenuItemIcon,
  FlagStopOctagonCopyIcon,
  ItemTexSearchIcon,
  CursorSelectLoadingIcon,
  UndoArrowCrossIcon,
  PinCrossRemoveSquareIcon,
  CheckListMinusDeleteIcon,
  EditDeleteItemIcon,
  WarningIcon,
  FingerprintMinimalSquareIcon,
  OptionButtonCircleIcon,
  GroupDeliteSelectIcon,
  LetterMailCircleCopyIcon,
  CalendarScheduleStarIcon,
  ItemMenuMoreDotsSquareIcon,
  PlusMinusIrcleIcon,
  TashBinVerticalLineIcon,
  WifiNetworkSignalIcon,
  ChatMessageBadSmileIcon,
  EmailMailMoreIcon,
  VoiceRecMicStyleSquareIcon,
  ImagePictureAddIcon,
  MicrophoneMicRecCopyIcon,
  TaskListPageIcon,
  ImagePictureSelectCopyIcon,
  SettingRoundSquareIcon,
  PowerAppliancesShutdownIcon,
  BBoldCircleIcon,
  PrivateIncognitoCircleIcon,
  LibraryUploadLineDownIcon,
  QuestionSelectIcon,
  InfoCheckmarkCircleCopyIcon,
  SendFastCircleIcon,
  MessageChatWarningCopyIcon,
  ChatMessageModernIcon,
  HeartFavorieDeleteIcon,
  LoadingHorizontalStyleSquareIcon,
  TextImageItemIcon,
  AnchorTriangleSelectIcon,
  MicrophoneMicRecIcon,
  HomeKeyholeLockIcon,
  MonitorHeartFavoriteBigIcon,
  ZipArchiveSquareIcon,
  MoonCrossSqaureIcon,
  DiamondLineIcon,
  EarthGlobeMoreSettingIcon,
  MenuEditMinusCircleIcon,
  RecVoiceStyleCircleIcon,
  CalendarMinimalStyleIcon,
  BarCodeSearchSquareIcon,
  AtMailSquareIcon,
  SettingEditIcon,
  ShiledProtectonUploadArrowUpIcon,
  CopyItemCheckmarkIcon,
  CalendarTasksIcon,
  ItemNotificationIcon,
  ReplyShareCircleIcon,
  ArrowUturnDownIcon,
  AirplayAudioArrowIcon,
  ItemUndoArrowSquareIcon,
  DownloadAllArrowIcon,
  ListNumbersIcon,
  FilterSortDeleteIcon,
  SearchTopCircleIcon,
  EnterLoginSquareIcon,
  DocumentTextMoreDotsIcon,
  CursorStyleClickCircleIcon,
  AlarmClockCircleIcon,
  HeartFavoriteIcon,
  LinkBlockIcon,
  ParagraphLeftIcon,
  DocumentCopyIcon,
  BluetoothSquareCopyIcon,
  PuzzlePlugInAddIcon,
  CalendarScheduleCrossDeleteIcon,
  ClockTimerFastCrossDeleteIcon,
  SpellingCheckCheckmarkIcon,
  SearchListLoapIcon,
  ItemMenuCheckmarkCircleIcon,
  ClockTimeMenuIcon,
  MailMessageNotificationIcon,
  LetterMailCrossDeleteIcon,
  DocumentSearchIcon,
  RightArrowIcon,
  PenStopIcon,
  ItemAddDotsCheckmarkSquareIcon,
  CheckboxMoreMenuTranslateIcon,
  GridKeylineIconIcon,
  FingerprintSelectCopyIcon,
  InterruptedLineCircleIcon,
  LinlClassicSquareIcon,
  ItemRefreshAddPlusIcon,
  BombSquareIcon,
  LetterOpenIcon,
  TasklistCircleIcon,
  DeleteBinCrossCircleIcon,
  WindowsCheckmarkLineIcon,
  DashboardMinimalCircleIcon,
  SearchShineIcon,
  BackspaceDeleteButtonIcon,
  AlignCenterIcon,
  RotateArrowsSquareIcon,
  FilterSortAZIcon,
  MailboxInboxArchivePlusIcon,
  DirectionArrowCrossDeleteIcon,
  WindowBrowserTabIcon,
  ArtboardPinIcon,
  SyncModernSquareIcon,
  TextSquareIcon,
  DashboardMinimalLeftCircleIcon,
  CalendarScheduleEditPenIcon,
  EyeCheckmarkCircleIcon,
  FlagCrossIcon,
  RotateDIcon,
  SearchCrossSquareIcon,
  ItemDocumentLogoFileIcon,
  ImageSquareIcon,
  AlignRightIcon,
  BookmarkSmallSquareIcon,
  EmailIcon,
  BellStyleOctagonIcon,
  UserModernStyleSquareIcon,
  SettingControllerAngleCircleIcon,
  NewsPaperIcon,
  FolderSquareRoundedIcon,
  StatusCircleIcon,
  ClipMoreIcon,
  BookmarkFavoriteSquareIcon,
  LoginEnterArrowRightCircleIcon,
  DisableCrossIcon,
  MouseSignalIcon,
  PrinterPrintModernMinimalCircleIcon,
  ShieldDeletedCrossIcon,
  SettingMinimalLeftSquareIcon,
  PodcastShareIcon,
  FilterSortStyleDBigIcon,
  CalendarModernCheckmarkIcon,
  LockProtectionCheckmarkIcon,
  ItemWarningCircleIcon,
  ATextCircleIcon,
  KeyCircleIcon,
  CursorClickSelectIcon,
  AirdropIcon,
  SendMailIcon,
  HornTrumpetStyleSquareIcon,
  ItemSignalShareSquareIcon,
  MessageArrowInboxIcon,
  CameraRecSqaureIcon,
  ItemCopyReplaceArrowsIcon,
  MessageChatVoiceRecIcon,
  StarStyleCircleIcon,
  TriangleSquareCircleObjectsIcon,
  DocumentAddPlusIcon,
  GridLayoutMenuMoreIcon,
  BellNotificationAlarmCopyIcon,
  SignQuestionIcon,
  CalendarScheduleMoreDotrsIcon,
  SettingVerticalSquareCircleIcon,
  ListTextCopyIcon,
  QuestionMenuMoreCircleIcon,
  WindowsMoreIcon,
  TaskListCrossDeleteIcon,
  HomeCheckmarkIcon,
  DashboardStyleCircleIcon,
  UploadLineArrowUpCircleIcon,
  PluginSqaureCopyIcon,
  SignInSendShareIcon,
  CopyMinimalStyleCircleIcon,
  BellBigRingingIcon,
  CopyCheckmarkIcon,
  RecChartVoiceCircleIcon,
  CompassIcon,
  BookmarkFavoriteIcon,
  CursorTimeIcon,
  CheckboxCheckmarkCrossIcon,
  UserModernCircleIcon,
  MessageChatLeftTextIcon,
  MenuDotsCircleIcon,
  ScanCheckSelectIcon,
  PowerOffCircleIcon,
  TextCopyIcon,
  HomeModernOptionLinesIcon,
  DropdownMenuDeleteIcon,
  SettingSquareSquareIcon,
  ItemsWarningSquareIcon,
  BrilliancePluginDIcon,
  BookmarksLabelCircleIcon,
  SendHorizontalRightBigIcon,
  TrashBinCheckmarkIcon,
  CursorSelectClickCircleIcon,
  DownloadSaveSquareSelectIcon,
  CursorMinimalLeftCircleIcon,
  ChartDiagramCropElementIcon,
  LetterCheckmarkCircleIcon,
  FingerprintIcon,
  FilteCrossDeleteIcon,
  BellNotificationsIcon,
  PowerSettingsIcon,
  SearchTextTitleMiddleIcon,
  ASquareTextButtonIcon,
  EyeRemoveHideCrossSquareIcon,
  BookmarkLinesIcon,
  FloppySaveUpIcon,
  LoadingStyleCopyIcon,
  EmailFavoriteHeartIcon,
  HandCursorSquareIcon,
  MessageChatModernTextMenuIcon,
  HelpWheelSquareIcon,
  FingerprintSimpleSquareIcon,
  SignCrossDeleteIcon,
  ItemImagePictureResizeUploadIcon,
  AnchorCircleIcon,
  ScaleMoveSlideIcon,
  RotateLoadingSquareIcon,
  TasklistMinusSquareIcon,
  ImagePictureAddPlusIcon,
  ItalicIcon,
  BagCircleEssentialInterfaceShoppingUiIcon,
  PrinterStyleIcon,
  SphereCircleIcon,
  QuestionDeleteCrossCircleIcon,
  ClockTimeArrowRightIcon,
  ArrowsRefreshReloadLoadingIcon,
  ItemListCheckmarkCircleIcon,
  StickerPinIcon,
  FilterModernSquareIcon,
  ImagePictureRightSquareIcon,
  TaskListCutDottedLineIcon,
  PowerOffStopSquareIcon,
  HeadphonesBigIcon,
  WindowsDisableCloseAllIcon,
  MessageChatEditTextIcon,
  MenuBoardSquarePlusAddIcon,
  ClockTimeArrowIcon,
  BellNotificationSquareIcon,
  UserShareSignalCircleIcon,
  CompassMapAddPlusIcon,
  SlidesWindowsCircleIcon,
  MessageChatSmileIcon,
  EditPenLineSquareCopyIcon,
  BinocularsSearchCircleIcon,
  HomeMoreMenuIcon,
  UnwrapMobileIphoneIcon,
  FileDocumentCircleIcon,
  FloppySaveDownIcon,
  StarCheckMarkIcon,
  BellNotificationsCircleIcon,
  CopyLockWindowsIcon,
  ScanerBarcodeSelectIcon,
  CursorSelectClickIcon,
  ExitLogOutArrowIcon,
  PuzzlePlugInCheckmarkIcon,
  DashboardDottedLineCircleCopyIcon,
  ResizeAddPlusShareIcon,
  AttachmentLinkSquareIcon,
  ChatMessageRecMicVoiceIcon,
  PinDotsSquareIcon,
  TextInputSquareIcon,
  CursorDisableClickIcon,
  MailboxArchiveCopyIcon,
  PuzzlePlugInSquareIcon,
  CalendarCurvedCornerCrossDeleteRemoveIcon,
  LinkSquareIcon,
  SelectItemCheckmarkIcon,
  SearchLoupeMenuIcon,
  EditPenItemSquareIcon,
  CalendarBackDataArrowLeftIcon,
  SelectMoreDotsIcon,
  TextCircleIcon,
  PositionTargetSelectIcon,
  BoxCheckmarkSelectIcon,
  VoiceRecSelectIcon,
  ClockWatchRefreshLoadingIcon,
  SearchMoreMiddleIcon,
  BookmarksFavoriteLabelIcon,
  RecVideoSquareIcon,
  DocumentTextDeleteCrossCentreRightIcon,
  KeyboardSqureIcon,
  NotebookIcon,
  ChartAddPlusSquareIcon,
  WarningCheckmarkCircleCopyIcon,
  CheckListCheckmarkIcon,
  DirectionAddPlusCircleIcon,
  ScreenMirroringRollUpFullScreenIcon,
  CalendarCheckmarkIcon,
  WarningNotificationCircleIcon,
  ItemTextCrossDeleateIcon,
  FlagRemoveSquareIcon,
  NoteDocumentClipAttachmentQuestionIcon,
  InputPasswordDeleteCrossIcon,
  MicrophoneMuteSquareIcon,
  ReplyShareSquareIcon,
  ObjectFavoriteHeartIcon,
  SearchLoapAddPlusZoomIcon,
  WarningAlarmCircleIcon,
  TrashBinCircleIcon,
  MenuListSquareIcon,
  BookmarkRemoveMinusIcon,
  ChatMessageEditPenIcon,
  ChatMessagesAddIcon,
  TimerAlarmClockIcon,
  ShineModernSqaureIcon,
  WifiIcon,
  MenuBarSpaceCentreHorizontalIcon,
  UsersCircleIcon,
  VideoMoviesIcon,
  BookmarkFavoriteLabelIcon,
  RotateArrowsCircleIcon,
  SendHorizontalAngleLeftHorizontalBlankBigIcon,
  CalendarTextTwoLinesIcon,
  HomeSmileysIcon,
  LoginEnterArrowRightSquareIcon,
  TrashBinDeleteIcon,
  LinkCircleIcon,
  BinBoxArrowUpIcon,
  DirectionSquareArrowsIcon,
  MessageCenterChatStarIcon,
  StarFavoriteDeleteIcon,
  DashboardGaugeIcon,
  PlayRectangleIcon,
  UnderlineSquareIcon,
  CalendarListLineIcon,
  CalendarBackArrowIcon,
  MailboxShiningInboxSquareIcon,
  MessageEyeIcon,
  QuestionSquareIcon,
  TextItemSquareIcon,
  SettingsMenuIcon,
  CursorMenuSelectIcon,
  ItemMenuButtonSquareIcon,
  ItemAMessageIcon,
  DocumentTextCopyIcon,
  MenuBoardCrossRemoveSquareIcon,
  MenuSearchListCircleIcon,
  TimeClockMoreCopyIcon,
  EditMinimalPenLineSquareIcon,
  StikerSquareIcon,
  PenArrowLeftCircleIcon,
  PazzlePluginRightIcon,
  AirplayStyleRightIcon,
  CopyAddPlusIcon,
  ShareBigCircleIcon,
  LoadingMinimalSquareIcon,
  CurcorSelectMenuCircleIcon,
  MailboxTitleTextIcon,
  TextSelectIcon,
  SelectObjectDeleteSquareIcon,
  FloppyDiskBlankLeftIcon,
  CalendarMinusIcon,
  ObjectCopyMoreDotsIcon,
  ShapesBjectsCircleIcon,
  UndoArrowSqaureIcon,
  WindowsRollUpIcon,
  CalendarAddPlusCurvedCornerIcon,
  ItemCheckmarkSquareBigIcon,
  ObjectInfoSquareIcon,
  MenuSearchSquareIcon,
  GraphAnalitycsSquareIcon,
  GlobeMinusIcon,
  ThunderLightningCrossIcon,
  CursorHandSelectIcon,
  ThumbsUpCircleIcon,
  MessageTextIcon,
  TasklistUploadBottomMiddleIcon,
  CcOctagonIcon,
  MonitorCircleIcon,
  MultiShotImageSquareIcon,
  MailEmailRemoveDeliteIcon,
  SearchShineCircleIcon,
  ArrowBackspaceAddPlusIcon,
  LockStyleSquareIcon,
  CheckmarkDoneCheckSqaureIcon,
  HeartFavoriteRemoveAddPlusIcon,
  MailboxArchiveCrossDeleteIcon,
  CalendarModernRemoveCrossIcon,
  BookmarksMoreSettingsIcon,
  CalendarSearchCopyIcon,
  PenEditDeleteIcon,
  LampLightOctagonIcon,
  SpaceRemoveCrossSquareIcon,
  PiegraphIcon,
  MonitorDisplayCheckmarkIcon,
  PrinterPrinWarningErrorIcon,
  UsbSquareIcon,
  TimerOffCrossRemoveMinimalIcon,
  ItemTextBrowserIcon,
  CopyCheckmarkDotsLineIcon,
  SearchLoupeZoomBoxIcon,
  BoxPlusAddSelectIcon,
  LibraryModernLineBottomIcon,
  CalendarScheduleLockIcon,
  RotateRefreshSquareIcon,
  FilterSortCheckmarkIcon,
  BellNotificationsSquareIcon,
  TasklistCheckmarkIcon,
  ItemImageTextCheckmarkIcon,
  MinusIcon,
  FavoriteIcon,
  CalendarSchedulePlayIcon,
  CircleDottedLineIcon,
  MessageShatRightInputTextIcon,
  WindowSidebarLeftIcon,
  SelectObjectPlusIcon,
  BookmarkFavoriteMinusIcon,
  CursorTextCopyMoveIcon,
  CalendarEditPenCopyIcon,
  EmailLetterCrossIcon,
  ClockDottedLineIcon,
  SendArrowSelectIcon,
  EarthGlobeModernIcon,
  ImagePictureModernCircleSquareIcon,
  CopyItemDeleteAllCrossIcon,
  HandHorizontalCircleIcon,
  MenubarArrowDownIcon,
  NotebookCrossDeleteIcon,
  SidebarWindowIcon,
  SettingStyleHorizontalIcon,
  MarkerPinIcon,
  MessageChatTextCheckmarkIcon,
  CompassMapHexagonIcon,
  LockUnlockIcon,
  EditPenMoreDottedLineSquareIcon,
  ItemSelectCrossDeleteDottedLineIcon,
  DeliteIcon,
  FingerprintMinimalCircleIcon,
  DowloadSaveIcon,
  CookiesIcon,
  BookmarkSquareIcon,
  SwitcherSettingSquareIcon,
  ChartUpArrowCircleIcon,
  FilterCopyDottedLineIcon,
  SynchronizeTriangleArrowsRecirculationIcon,
  MediaLibraryFolderCircleIcon,
  MenuItemIcon,
  MonitorMinimizeWindowIcon,
  BinTrashStyleVerticalLinesIcon,
  CalendarBlankTextIcon,
  MessageCrossRejectionIcon,
  ObjectCheckmarkCircleIcon,
  LayersIcon,
  ChatMessageSmileIcon,
  ToolsSettingsIcon,
  WifiNetworkSignalAddSquareIcon,
  ItemMenuSquareCopyIcon,
  EditPenMiddleCircleIcon,
  PositionTargetCircleIcon,
  CopyMoveCutIcon,
  SquareDeleteCrossIcon,
  ListPaperSelectIcon,
  StickerCrossDeleteIcon,
  MarkerPinCheckmarkIcon,
  WindowSearchItemDeleteRemoveIcon,
  BookmarkBlankBigIcon,
  SleepModeCircleIcon,
  MailboxCollectionsArchiveShareIcon,
  WindowScaleSquareIcon,
  TextItemListIcon,
  PluginsCircleIcon,
  ImagePictureCheckmarkSquareIcon,
  ObjectArrowLeftCircleIcon,
  CutItemIcon,
  PrinterPrintDeliteIcon,
  AirplayModetnCircleIcon,
  CursorClickIcon,
  CopyItemLeftAlignIcon,
  ExitLogOutIcon,
  ImagePictureDownloadArrowDownIcon,
  ZipArchiveDeleteCrossCircleIcon,
  BrightnessEditSettingIcon,
  ThumbsDownDislikeStyleBigIcon,
  InputPasswordCopyIcon,
  TargetRemoveDeleteIcon,
  MenuCheckmarkCircleCopyIcon,
  BookmarksLeftIcon,
  HeartStyleSquareIcon,
  MailCircleIcon,
  ImagesPictureCopyIcon,
  InfoNotificationCircleIcon,
  StarFavoriteSquareIcon,
  StickerCheckmarkCopyIcon,
  HornTrumpetCopyIcon,
  TasklistAddPlusSquareIcon,
  CursorSelectLeftBigIcon,
  BookmarkCheckmarkIcon,
  ClockWatchQuestionIcon,
  SleepSnoozeZzzSquareIcon,
  ParagraphTextIcon,
  SignalHotspotCopyIcon,
  LogOutArrowLeftIcon,
  WarningAlarmIcon,
  MessageEditIcon,
  WifiSignalLineSquareIcon,
  BinTrashDCircleIcon,
  AlignJustifyIcon,
  DelimiterBracketsSpaceCircleIcon,
  SettingHorizontalIcon,
  AirplaySignalAirdropSquareIcon,
  TvCrossRemoveIcon,
  ItemSelectPlusAddIcon,
  MoonNightModeNotificationCircleIcon,
  SendHorizontalAngleBigIcon,
  TabsBookmarksPlusIcon,
  ChatMessageRemoveCrossIcon,
  StarStyleIcon,
  DownloadSquareIcon,
  UserModernSquareIcon,
  ImagePicturesDownloadArrowDownSquareIcon,
  ChatMessageStarShiningIcon,
  BinTrashStyleDownloadArrowDownIcon,
  LoadingReloadCircleIcon,
  BookmarkFavoriteSearchIcon,
  FileInfoIcon,
  WifiSignalCheckmarkIcon,
  MessageChatSmileysSadIcon,
  ItemEditPenTextDownRightSquareIcon,
  MonitorMinimalSquareIcon,
  SiriIcon,
  StarFavoriteSearchIcon,
  CursorSelectItemIcon,
  ImagePictureLeftArrowIcon,
  MenuLaodingItemIcon,
  CmdCommandKeyboardIcon,
  BookmarksLeftTopIcon,
  LoginSquareMinimalArrowRightIcon,
  DocumentRemoveGroupIcon,
  ClipCrossDeleteCircleIcon,
  TimeClockDeleteOffIcon,
  StatisticsAnalyticsArrowUpSquareIcon,
  HeartFavoriteAddPlusCircleIcon,
  TasklistLeftCentreArrowRightIcon,
  PowerTurnOnSquareIcon,
  BookmarksIcon,
  CopyItemRightIcon,
  LampLightShineRightIcon,
  ChatStyleCheckmarkIcon,
  PinLocationAddCircleIcon,
  ArrowDownIcon,
  MenuMoreEditCircleIcon,
  BookmarkCheckmarkCopyIcon,
  InfoInformationIcon,
  MarkerPinRefreshLoadingIcon,
  ClipMenuSquareIcon,
  MediaLibraryFolderCheckmarkIcon,
  UserMessageCentreTopIcon,
  StikerSmileEditIcon,
  CursorClickCircleCopyIcon,
  CockTimeIcon,
  LoginEnterIcon,
  ChartAnalyticsModernCircleIcon,
  ItemMessagePopUpPlusIcon,
  NewsIcon,
  SearchSelectIcon,
  LadingTimeSquareIcon,
  DoneCheckNotesIcon,
  ItemCopyPinIcon,
  WindowCircleIcon,
  BookmarksFavoriteLabelDeleteIcon,
  ChartAnalyticsSquareIcon,
  ZoomIcon,
  StatisticsAnalyticsSquareIcon,
  ItemCheckmarkMoreSquareIcon,
  BinModernTwoLineIcon,
  VoiceRecSquareCopyIcon,
  CursorFastMoveIcon,
  ZipModernCircleIcon,
  TextEditIcon,
  SearchLoapSquareIcon,
  DashnoardMinimalIcon,
  BellStyleBigNotificationIcon,
  MenuCursorSquareIcon,
  BookmarkFavoriteCrossDeleteCircleIcon,
  ClipArrowDownBigIcon,
  LoadingSquareCopyIcon,
  ImagePictureDownloadIcon,
  HeadphonesStyleBigIcon,
  StarsLightSquareIcon,
  BookmarkCheckmarkTopRightIcon,
  SelectDisableItemIcon,
  CheckListCrossDeleteIcon,
  MailboxLineUpIcon,
  ImagesPicturesCopyIcon,
  GridSquareIcon,
  QuestionRhombusIcon,
  RotateArrowsRefreshIcon,
  ItemSearchMinusDeleteIcon,
  HandCircleIcon,
  ChartPercentCircleIcon,
  ClockTimeStyleSquareIcon,
  ItemTitleTasklistLeftTopSquareIcon,
  BracketsCircleIcon,
  FlagLinesIcon,
  ItemTextMoreIcon,
  ChatMessageMoreDotsIcon,
  ItemTitleTasklistIcon,
  TrashDeleteBinSettingIcon,
  BookmarkLineSquareIcon,
  BookmarksFavoriteLabelAddIcon,
  TrashBinDAddPlusIcon,
  ClipIcon,
  ShareSignalCircleIcon,
  AlarmClockTimeFastIcon,
  HashtagAddPlusCircleIcon,
  ImagePictureEditStarsIcon,
  BrowserSeelctIcon,
  StikerSmileDeleteIcon,
  BinTrashModernIcon,
  HomeClassicIcon,
  CursrorClickSquareModernIcon,
  LockUnlockAddCircleIcon,
  SettingsFilterMenuIcon,
  SendShareIcon,
  CookiesNotificationCircleIcon,
  FlagPlusAddCircleIcon,
  QuoteIcon,
  AsteriskRotateArrowCircleIcon,
  SleepModeStarCircleIcon,
  FlagStyleIcon,
  MapMinimalSquareIcon,
  CompassLocationLoadingCircleIcon,
  FlagSquareSquareIcon,
  FilterSortFavouriteIcon,
  CircleIcon,
  ItemMenuRemoveDeleteIcon,
  AwardRatingCircleIcon,
  BellCrossDeleteIcon,
  BorderVerticalIcon,
  SignalRecStyleCircleIcon,
  AppApplicationIcon,
  NotebookLaptopCircleIcon,
  HeartFavoriteCircleCopyIcon,
  EarthHomeWorldSelectIcon,
  FloppyAddPlusIcon,
  RecCircleIcon,
  AlignLeftIcon,
  LoadingModernCircleIcon,
  BombIcon,
  EmailLetterSquareIcon,
  ChartAnalyticsModernCircleCopyIcon,
  MessageInputEditIcon,
  StarShiningCircleIcon,
  WifiInfoIcon,
  FireAlarmCircleIcon,
  MessageChatMinusIcon,
  LinksCircleIcon,
  RefreshLoadingCircleIcon,
  RefreshRotateRecycleIcon,
  LoadingSquareIcon,
  TimeClockCheckmarkIcon,
  UserCircleIcon,
  DisplayMonitorSquareIcon,
  BinTrashUploadIcon,
  SettingMinivalVerticalSquareIcon,
  HomeCheckmarkCopyIcon,
  MinimizeWindowIcon,
  QuestionNotificationCircleIcon,
  TimeModernSquareIcon,
  LeftArrowIcon,
  MouseScrollUpCopyIcon,
  SelectObjectAddPlusIcon,
  CursorLoadingWaitingIcon,
  CalendarScheduleWarningIcon,
  WifiCircleIcon,
  AlignLeftCircleIcon,
  LibraryUploadIcon,
  MessageChatMinusDeleteIcon,
  AttachmentLinkCheckmarkIcon,
  DownloadSaveCircleIcon,
  TasklistCopyCheckamrkIcon,
  MediaLibraryMiddleLinesFileIcon,
  SignalLineCircleIcon,
  NotificationIcon,
  TimerOffRemoveCrossBigIcon,
  TimerFastAddPlusIcon,
  FireHotSquareIcon,
  ShieldModernBlankIcon,
  QuestionHexagonIcon,
  LibraryModernMoreIcon,
  ListPapaerCrossDeleteIcon,
  BorderLeftIcon,
  BackwardRearwardBackRemoveIcon,
  SettingMinimalOctagonIcon,
  LockPasswordIcon,
  CursorCheckmarkDoneCheckIcon,
  SyncModernArrowCircleIcon,
  BorderHorizontalIcon,
  ClosedEyeInvisibleOctagonIcon,
  MicrophoneRecMicAddPlusSelectIcon,
  PinAngleCircleIcon,
  SearchItemIcon,
  SelectMenuIcon,
  CityTownBuildingsSquareIcon,
  WifiOffCrossSquareIcon,
  ClipSquareStyleVerticalIcon,
  EmailMailMinusIcon,
  DownloadArrowCircleCopyIcon,
  TimeClockSquareIcon,
  SearchAddPlusMiddleIcon,
  ChartAnalyticsNotificationCircleIcon,
  CursorSwipeUpIcon,
  MouseBigIcon,
  SelectDottedLineSquareCircleIcon,
  TaskListMenuIcon,
  ItemMenuCircleIcon,
  MessageChatShareArrowIcon,
  AnalyticsChartSquareIcon,
  CircleMaskCutIcon,
  TimerOffCrossRemoveIcon,
  LockStyleBlankCircleIcon,
  FloppysSaveIcon,
  EmailLetterStyleNotificationIcon,
  UsbCirceIcon,
  DoorHoleBigSquareIcon,
  SearchLoupeFavoriteHeartIcon,
  ArrowBackspaceCrossDeleteIcon,
  SearchShiningvIcon,
  ArrowDirectionShiningClickCircleIcon,
  ItemFavoriteStarIcon,
  SearchLoupeIcon,
  HeartCircleCrossDeleteIcon,
  GridLayoutDeliteIcon,
  BinTrashDSquareIcon,
  SendArrowSquareIcon,
  AsteriskSquareIcon,
  MailboxArchiveCollectionsDisableIcon,
  FingerprintCopyIcon,
  RepeatRotateSquareIcon,
  ItemLockWarningIcon,
  TimeClockAlarmAddPlusIcon,
  InfoInformaionSquareIcon,
  MessageChatTextAddPlusIcon,
  DocumentCopyAddPlusIcon,
  EyeCheckmarkSquareIcon,
  ImagePictureLeftSquareIcon,
  HomeModernMoreDotsIcon,
  RotateArrowCircleIcon,
  FloppySaveCrossDesableIcon,
  CheckboxBrokenCircleIcon,
  DropdownMenuItemDeleteIcon,
  HomeHouseSettingIcon,
  ZipArchiveCenterSquareIcon,
  TaskListCheckmarkIcon,
  TaskListMinusDeleteIcon,
  StikerSmileIcon,
  ClockTimeTimerAddTurnOnIcon,
  ChartArrowCircleIcon,
  EmailMailInfoCopyIcon,
  WifiOffDisabledIcon,
  ObjectArrowSquareIcon,
  LoadingCheckmarkCircleCopyIcon,
  ReloadRepeatArrowsLoadingIcon,
  ImagePictureCenterCircleIcon,
  WindowFinderAddPlusIcon,
  ObjectCursorIcon,
  ItemNotePinIcon,
  ClipBigArrowDownIcon,
  SettingMinimalStyleSquareIcon,
  ShareZoomInSqaureIcon,
  ResizeSelectIcon,
  ReceiptPlusIcon,
  PasswordLockProtectionDeclineIcon,
  CheckmarkReadOctagonIcon,
  WindowZoomOutPlusLoupeIcon,
  LoactionMapDirectionSquareIcon,
  VoiceRecIcon,
  EditPenBigSquareCopyIcon,
  SelectMoreSettingIcon,
  CalenadarRemoveCrossCircleIcon,
  CursorRightCircleCopyIcon,
  PluginsSquareIcon,
  MagnetVerticalCrossRemoveBigIcon,
  MailIcon,
  CheckboxBrokenSquareIcon,
  TrashDeleteRecicleIcon,
  ChartAnalyticsModernSquareIcon,
  HomeModernTwitterSquareIcon,
  ChartLineOctagonIcon,
  FlagNotificationStyleSquareIcon,
  PrinterPrintQuestionIcon,
  SearchMinusCopyIcon,
  RecordButtonCircleIcon,
  EventsListSquareIcon,
  WifiSignalOffRemoveCrossIcon,
  ShieldModernLineIcon,
  PazzlePluginMinimalRightIcon,
  MenuBoardSquareIcon,
  CalendarScheduleRefreshLadingIcon,
  PasswordLockAppSqaureIcon,
  SettingMenuCheckmarkIcon,
  PinCheckmarkSqaureIcon,
  BinTrashDCheckmarkIcon,
  CircleAttachmentLinkIcon,
  EditPenSquareIcon,
  PluginRoundCircleIcon,
  BackwardRearwardTargetIcon,
  TrashBinSquareIcon,
  CopyMinimalStyleSquareIcon,
  CalendarScheduleClockIcon,
  UmbrellaIcon,
  BinTrashStyleCrossAddPlusIcon,
  WifiNotificationIcon,
  MoonSleepNightModeIcon,
  UnlockCirleIcon,
  LetterMailCircleIcon,
  ImagePictureModernCircleIcon,
  MailboxArchiveLinkIcon,
  ResizeVerticalArrowsLeftRightCircleIcon,
  FileDocumentSquareIcon,
  InputPasswordProtectionIcon,
  StarFavoriteIcon,
  ImagePictureSelectIcon,
  BrokenLinkModernRightIcon,
  BarcodeScanSelectIcon,
  LibraryModernLineIcon,
  TimerClockFastIcon,
  BracketsSqaureCopyIcon,
  SearchTextLoupeIcon,
  PrivateIncognitoIcon,
  CalendarDateRightIcon,
  ClipVerticalSquareIcon,
  SettingVerticalSquareIcon,
  SearchCheckmarkMiddleIcon,
  IcoIconSqaureIcon,
  SearchBigIcon,
  CodeSearchIcon,
  TextItemCircleIcon,
  BrowserWebAddIcon,
  WifiCheckmarkSquareIcon,
  ScrollHorizontalSyncSquareIcon,
  CookiesCheckmarkIcon,
  ChatMessageCrossRemoveDeleteIcon,
  ItemCopyPlusAddIcon,
  TimerClockFastCopyIcon,
  AlarmTimeCrossRemoveIcon,
  AlarmTimerStyleIcon,
  CopyCircleCutIcon,
  ShareSignalSquareIcon,
  ChatMessageTextIcon,
  TimeClockIcon,
  ChartBarBargraphIcon,
  PenEditMediumSquareIcon,
  SliderWindowsHorizontalModernIcon,
  ItemTextCurvedCornerIcon,
  ChatModernTextIcon,
  TaskListAddPlusIcon,
  MessageChatAddPlusSquareIcon,
  HomeUndoArrowIcon,
  ItemSearchRightDownSquareIcon,
  InfoStyleCircleIcon,
  BookmarksFavoriteMinusIcon,
  DivideIcon,
  PatchIcon,
  StarStyleSquareIcon,
  PasswordLockProtectionSquareIcon,
  UserIcon,
  MenuGridCircleIcon,
  BookDocumentGroupIcon,
  PictureImageAddPlusIcon,
  CursorArrowAngleCircleIcon,
  SelectCopyFilesIcon,
  SearchZoomInArowRightIcon,
  CloudSignalSquareIcon,
  CalendarCopyTopRightIcon,
  ArrowLeftBackSquareIcon,
  SelectTargerIcon,
  ParagraphArrowCircleIcon,
  OpenBookCrossDeleteIcon,
  TasklistArrowLeftIcon,
  MailboxCollectionsArchiveMoreDotsIcon,
  CopySelectCutIcon,
  QuestionsCopyIcon,
  MessageChatReplyArrowIcon,
  TimeClockAlarmRemoveCrossIcon,
  LampLightShineLeftIcon,
  SettingsSwitchesArrowIcon,
  MessageSmileStyleIcon,
  TasklistCutLineIcon,
  GlobeEarthHomeWorldIcon,
  ShareSignalRightSquareIcon,
  StatusAddPlusIcon,
  ItemSearchInputSquareIcon,
  RecicleIcon,
  ClipStyleBigIcon,
  CalendarScheduleNextArrowIcon,
  StopMinusOctagonIcon,
  BrowserWindowShowSidebarIcon,
  DashboardMinimalStyleSquareIcon,
  WarningErrorAddIcon,
  MonitorDisplaySquareIcon,
  ReadCircleIcon,
  LoadingMinimalStyleSquareIcon,
  DashboardMinimalModernIcon,
  MediaLibraryFolderMoreDotsIcon,
  SendSqaureButtonIcon,
  GlobeAmericasIcon,
  ChartLineSquareIcon,
  StikerCheckmarkIcon,
  ShieldProtectedCheckmarkIcon,
  TimeClockInfoIcon,
  ItemDocumentAddPlusTopCentreIcon,
  ShieldProtectionSecurityMinusSquareIcon,
  LinkAngleRightIcon,
  DashboardVerticalCircleIcon,
  ItemCropDottedLinesIcon,
  TargetSelectIcon,
  BasketIcon,
  LabelTagCopyIcon,
  WifiSmallSquareIcon,
  CaledarDocumentGroupIcon,
  CalendarCheckmarkCopyIcon,
  AttachmentLinkCircleIcon,
  CalendarDateIcon,
  CalendarCutDottedLineIcon,
  DownloadArrowsLoadingIcon,
  FlagMinusIcon,
  PenCrossDeleteSquareIcon,
  MessageChatInfoIcon,
  CursorCutSelectDottedLineIcon,
  ShieldProtectionSquareIcon,
  MenuModernCircleCopyIcon,
  BoldIcon,
  UploadMediaDownSquareIcon,
  BookmarksStyleIcon,
  ClockTimeMenuLeftIcon,
  DocumentCrossIcon,
  SearchMoreDotsSqaureIcon,
  TrashBinDLineIcon,
  DownloadArrowSqaureIcon,
  BinTrashStyleLineTopIcon,
  BatteryChargeIcon,
  BellNotificationAlarmIcon,
  DirectionArrowClickDottedLineCircleIcon,
  MonitorSquareIcon,
  LightLampSelectIcon,
  BorderClearIcon,
  ChatMessageTrendLightningIcon,
  AdNotificationCircleIcon,
  EmailLetterStyleSquareIcon,
  FilterMinimalStyleCircleIcon,
  SignCheckmarkIcon,
  HourglassSquareIcon,
  ThumpsUpCircleIcon,
  TimeClockAlarmMinusIcon,
  StickerCopyIcon,
  CalendarAddCircleIcon,
  CopyObjectIcon,
  EditPenMenuSquareIcon,
  FiledownIcon,
  PictureImageStarsIcon,
  MessageChatStyleAddPlusIcon,
  CheckmarkBigCircleIcon,
  MessageChatAddPlusIcon,
  HornStyleBigIcon,
  RecMicrophoneMicOctagonIcon,
  FingerprintCrossIcon,
  ItemListCheckmarkIcon,
  SelectDotsVerticalMoreIcon,
  MessageChatWifiIcon,
  ArrowRightIcon,
  ItemTitleTasklistRightTopIcon,
  InfoHexagonIcon,
  TimeClockEditIcon,
  InfoSquareIcon,
  ChatMessagePasswordInputIcon,
  SubstractGroupCopyAddIcon,
  MessageEyeCopyIcon,
  ItemAddPinSqaureIcon,
  DownloadedCheckmarkSquareIcon,
  ClipCircleIcon,
  TrashBinDeleteCrossIcon,
  LoadingRotateArrowSquareIcon,
  ShieldProtectedDisabledRossIcon,
  TaskListChartLineIcon,
  ListTasksDeliteRemoveIcon,
  GlassesInvisibleIcon,
  CloudUploadCopyIcon,
  ItemTaskListMinusDeleteSquareIcon,
  PenEditRefreshIcon,
  MailboxInfoIcon,
  BlockCrossRemoveDeleteSquareIcon,
  TimeFastIcon,
  WarningAlarmSquareIcon,
  WarningErrorDeleteIcon,
  CopyItemLeftTopCopyIcon,
  TimerPauseClockCircleIcon,
  WifiPortableOffIcon,
  SelectObjectDeleteCrossIcon,
  CameraIcon,
  ImagePictureEditStarsCircleIcon,
  CalendarScheduleEditIcon,
  MessageChatClipIcon,
  UploadArrowUpIcon,
  ClockWatchIcon,
  TextInputCirleIcon,
  DoubleCheckmarkReadSquareIcon,
  ShieldModernDownloadArrowBigCenterIcon,
  CursorSelectCopyIcon,
  MessageChatLikeHeartIcon,
  MessageTextEditIcon,
  BookmarkOctagonIcon,
  LinkHorizontalSquareIcon,
  BookmarkLineOctagonIcon,
  MenuMoreEditSquareIcon,
  MailboxArchiveArowIcon,
  FilterStyleBigCircleIcon,
  CalendarScheduleAddPlusIcon,
  AttachmentLinkLeftArrowCircleIcon,
  TvChartStyleIcon,
  FileZipRarCopyIcon,
  PinVerticalIcon,
  FloppySaveSquareIcon,
  HomeEditIcon,
  CopyIcon,
  MouseCircleVerticalIcon,
  MessageChatStyleTextIcon,
  NewsPaperTextIcon,
  DocumentMoreIcon,
  BracketsSqaureIcon,
  ArrowUpDownIcon,
  BellNotificationOffCircleIcon,
  QuestionDottedLineSelectIcon,
  WindowResizeLeftIcon,
  ClockTimeAddTimerIcon,
  BrowserWebWindowsDeleteIcon,
  DoubleCheckmarkReadCircleIcon,
  CopyItemArrowIcon,
  CrownLineSquareIcon,
  CalendarCheduleCopyIcon,
  SearchRefreshIcon,
  CookiesSquareIcon,
  ClockTimeCircleCopyIcon,
  FingerprintNotificationSettingIcon,
  HomeMinimalLineCircleIcon,
  FeatherPenCircleEditSquareIcon,
  WifiSignalAddPlusIcon,
  PinDotsCircleIcon,
  PaperAddItemIcon,
  DocumentTextPinIcon,
  BookmarksFavoriteIcon,
  AnchorIcon,
  TasklIstIcon,
  BookOpenSquareIcon,
  SearchCrossRemoveIcon,
  ItemCopyDotsLineIcon,
  MailboxArchiveTextIcon,
  CirclesDeleteCrossIcon,
  TimeLoadingBackIcon,
  ImagePictureEditIcon,
  ImagePictureMoveLeftArrowIcon,
  MessageChatInfoCopyIcon,
  ObjectSearchSquareIcon,
  SendArrowCircleIcon,
  LoadingCheckmarkIcon,
  MenuCirlePlusAddIcon,
  FlagMoreMenuIcon,
  CursorInfoCopyIcon,
  StickerCheckmarkIcon,
  EyeCrossDeleteSquareIcon,
  CopyCrossErrorIcon,
  CursorLeftClickCircleIcon,
  AnalyticsChartAddPlusCircleIcon,
  BackspaceDeleteButtonCheckmarkIcon,
  SettingSwitchesIcon,
  MirrorHorizontalIcon,
  InboxMaleIncomingIcon,
  SelectCursorItemIcon,
  WifiAddSquareIcon,
  RecMicrophoneMicCrossRemoveOctagonIcon,
  ClockTimeCheckmarkCopyIcon,
  ClockTimeAlarmCheckmarkIcon,
  CertificateCheckmarkSquareIcon,
  ReadCheckmarkSquareIcon,
  TextResizeArrowIcon,
  ChatMessageDotsIcon,
  CursorSelecClickIcon,
  HandStopSignSquareIcon,
  AttachmentLinkCircleDeleteCrossIcon,
  ArrowDirectionCrossIcon,
  MailboxCheckmarkIcon,
  PhotoCameraAddCircleIcon,
  MediaLibraryAppPlusDownIcon,
  EditPenListSquareCopyIcon,
  UnderlineIcon,
  BellStyleRingIcon,
  AttachmentLinkFileAddIcon,
  BatteryChargingLightningIcon,
  CopyFilesSquareIcon,
  InfoSelectCopyIcon,
  DiamondBlankIcon,
  SelectScanMinimalSquareIcon,
  CursorLeftSquareIcon,
  RecycleCircleIcon,
  RotateModernCircleIcon,
  NotepadPlusAddIcon,
  StampCircleIcon,
  MicrophoneSquareIcon,
  BinTrashStyleDownloadLineBlankIcon,
  AdsAdvertismentPromotionIcon,
  ResizeArrowsSquareIcon,
  TrashDeleteBinSelectIcon,
  PenEditCircleIcon,
  DashboardRoundIcon,
  ChatMessageModernCopyIcon,
  MenuItemCircleIcon,
  LaptopMinimalSquareIcon,
  ShieldProtectedSquareIcon,
  MenuShareSquareIcon,
  EditPenTextRightDownCircleIcon,
  ImagePictureLinkIcon,
  CalendarArrowRightIcon,
  DashboardArrowRightCirceIcon,
  CursorClickSquareIcon,
  CameraSquareCopyIcon,
  LoupeCrossDeleteIcon,
  MenuHeartLikeSquareIcon,
  PinAddPlusCircleIcon,
  ClockTimerModernSquareIcon,
  GroupShareIcon,
  CopyRightTopArrowUpIcon,
  MenuIcon,
  LoadingCircleCopyIcon,
  SelectLinesIcon,
  InfoLoadingCircleIcon,
  GlobeEarthCheckmarkIcon,
  KeyboardShowIcon,
  EmailLetterStyleCheckmarkIcon,
  StickerAddPlusIcon,
  PrinterModernMinimalLineIcon,
  WifiSimpleSignalCircleIcon,
  GifButtonSquareIcon,
  WifiQuestionIcon,
  UnlockStyleBlankIcon,
  MenuArrowCircleIcon,
  LoginLogoutArrowIcon,
  InputTextSquareIcon,
  CursorStyleSquareIcon,
  EditPenMoreSquareIcon,
  ClockAlarmMoreDotsIcon,
  MonitorMinimalMinusBigIcon,
  TimerMinusRemoveIcon,
  MessageEditPenDottedLineIcon,
  PaperListDocumentLinkIcon,
  HomeLockIcon,
  MenuCrossDeleteIcon,
  MagicWandCircleIcon,
  EmailMailCheckmarkIcon,
  MediaLibraryMiddleLinesIcon,
  CopyDuplicateObjectAddPlusIcon,
  TextInputCircleIcon,
  MenuBarSpaceVerticalIcon,
  SearchAddPlusSquareIcon,
  ItemHeartFavoriteCircleIcon,
  SearchLoupeCircleIcon,
  BookmarkFavoriteCircleIcon,
  MailboxInboxArchiveIcon,
  CursorClickCopyIcon,
  BatteryChargingMaxIcon,
  TimerFastLeftIcon,
  ListRemoveIcon,
  LinkHorizontalLeftBigIcon,
  BluetoothCrossedOffSqureIcon,
  WarningMinimalWarningSquareIcon,
  BatteryChargingMinimalIcon,
  StrikeIcon,
  MapBigMinimalIcon,
  HeartAppIconIcon,
  ChartSmallSquareIcon,
  SmileyFaceMessageIcon,
  MessageChatSmileysIcon,
  UserProfileSquareIcon,
  CalendarEventIcon,
  TaskSheetCheckMarkIcon,
  MenuBurgeSqaureIcon,
  CopyArrowRightIcon,
  ArrowsScrollHorizontalCircleIcon,
  WifiDeleteCrossCircleIcon,
  SendEmailFastCircleIcon,
  ItemDocumentDeleteCrossTopCentreIcon,
  CalendarAddPlusLineIcon,
  BoxIcon,
  LeftRightArrowsCircleIcon,
  ResizeRemovecCrossShareIcon,
  ChartAnalyticsSearchIcon,
  SpaceSquareIcon,
  SendHorizontalTopBlankBigIcon,
  CameraCircleIcon,
  LinkAngleMinimalCircleIcon,
  SquareDIcon,
  BookmarkHeartDeleteCrossSquareIcon,
  CodeTextSquareIcon,
  TargetMinimalStyleBigIcon,
  SettingsGearSquareIcon,
  PenEditMinimalInputSquareIcon,
  SearchLoupeFastSquareIcon,
  ChartPercentSquareIcon,
  ShareCopyIcon,
  PinMenuSquareIcon,
  StampSquareIcon,
  LetterMailCheckmarkIcon,
  AttachmentLinkCircleMenuIcon,
  InputPasswordNumbersIcon,
  WifiAddPlusCopyIcon,
  CursorClickSelectSquareIcon,
  MediaLibraryFolderIcon,
  GridLayersAddIcon,
  QuoteRightBottomSquareIcon,
  OptionButtonSquareIcon,
  ObjectSquareCircleIcon,
  MailboxLoadingIcon,
  CopyItemSelectIcon,
  MenuAddSignalIcon,
  TaskListAddCheckmarkIcon,
  MailboxArchiveIcon,
  WindowsButtonIcon,
  PictureImageFavoriteHeartIcon,
  TasklistStyleLineIcon,
  CaledarScheduleListSearchIcon,
  FilterStyleCheckmarkCircleIcon,
  CipSquareIcon,
  ItemAddPlusPinCircleIcon,
  MenuCheckmarkCircleIcon,
  PrinterIcon,
  BookmarksCopyIcon,
  FingerprintMinusDeleteIcon,
  MenuDotsIcon,
  StickerCircleIcon,
  AddPlusDottedLineCircleIcon,
  PrinterPrintButtonCircleIcon,
  BorderStyleIcon,
  PenEditBigCircleIcon,
  MicrophoneMicOctagonIcon,
  ChartAnalyticsAddPlusIcon,
  CircleBrokenLineCutIcon,
  MessageChatModernBlankIcon,
  HomeLineModernIcon,
  BellAddPlusRingIcon,
  LockStyleMiddleSquareIcon,
  BokmarkSmallCricleIcon,
  ItemListArrowUpSquareIcon,
  ArrowsCircleSyncIcon,
  CheckboxChangeCheckmarkDeleteCrossIcon,
  CopyItemGroupIcon,
  UploadSpaceArrowUpIcon,
  WarningAddPlusSquareIcon,
  FloppySaveCrossMoreMenuIcon,
  CalendarCrossDeleteIcon,
  PinRemoveCrossCircleIcon,
  ChartCheckmarkCircleCopyIcon,
  AirdropShareIcon,
  AlarmClockSqaureIcon,
  CrownCircleIcon,
  AlendarIcon,
  CheckListAddPlusCopyIcon,
  StickerAddPlusCopyIcon,
  BrowserWebLinkIcon,
  TextImagePictureIcon,
  DownloadAllLineCircleIcon,
  BrowserWebWindowsFavoriteIcon,
  QuestionNotificationSquareIcon,
  BinTrashCircleIcon,
  SyncSynchronizeSquareIcon,
  WarningCheckmarkSquareCopyIcon,
  SettingStyleSquareIcon,
  BinBoxCheckmarkIcon,
  GroupCopyIcon,
  CheckmarkSquareIcon,
  LampSparkCopyIcon,
  MicrophoneMuteIcon,
  LinkAttachmentStyleSquareIcon,
  ArrowNavigationDirectionIcon,
  SettingEditFilterGearCircleIcon,
  ImagePictureClassicSquareIcon,
  LoadingCiecleIcon,
  MultiShotImageIcon,
  SearchCheckmarkSquareIcon,
  StarSquareIcon,
  SettingStyleIcon,
  CursorCircleIcon,
  WindowsHeartIcon,
  RecMicSquareIcon,
  SettingsFilterCircleIcon,
  UxCircleIcon,
  StickerSquareIcon,
  ArrowUturnUpSquareIcon,
  CaledarDateListRightAlignIcon,
  CursorSettingEditFilterIcon,
  ChatMessageEditIcon,
  CursorSwipeDownIcon,
  HeartFavoriteRemoveMoreDotsIcon,
  MenuSelectIcon,
  MonitorMinimalCircleIcon,
  EmailLetterStyleMinimalIcon,
  FilterStyleOctagonIcon,
  ItemCutCrossCircleIcon,
  WifiSpotSquareIcon,
  SignOutRightIcon,
  LoadingClassicCircleIcon,
  KeyLoginProtectionIcon,
  MessageChatStyleCheckmarkIcon,
  ObjectAddCircleIcon,
  FingerprintHexagonIcon,
  TvCheckmarkIcon,
  ItemInputSquareIcon,
  ScrollUpCircleIcon,
  VoiceRecSelectCopyIcon,
  DoubleRewindArrowUploadCircleIcon,
  ZipArchiveCitcleIcon,
  StarFavoriteCrossDeleteCircleIcon,
  DocumentAddPlusLeftIcon,
  SearchLoupeCheckmarkIcon,
  MoonNightModeCopyIcon,
  StickerRemoveMinusIcon,
  PinAngleCrossIcon,
  CursorSelectTextSquareIcon,
  LockCorssDeleteIcon,
  ItemListCrossRemoveCircleIcon,
  MediaLibraryTimeClockIcon,
  ChartCircleCopyIcon,
  LockMinimalOctagonIcon,
  ObjectCircleMoreDotsIcon,
  ChatMessageTextCrossChecklistIcon,
  MessageChatModernAddPlusIcon,
  LinkBigIcon,
  HeadphonesCircleIcon,
  LoginEnterArrowIcon,
  TasklistEditPenIcon,
  ShieldHeartIcon,
  PinLineCircleIcon,
  DownloadArrowsBigSquareIcon,
  LoadingMinimalCircleIcon,
  HomeMinimalCircleIcon,
  ItemCheckmarkEditPenCircleIcon,
  BellNotificationCrossOffSquareIcon,
  PrinterPrinWifiIcon,
  KeyboardMinimalCircleIcon,
  TrashDeleteBinSquareIcon,
  SearchLoupeMenuFilterIcon,
  EmailsLetterMailSquareIcon,
  TasklistArrowRightIcon,
  DocumentFileZipIcon,
  SettingsSwitchesDeleteCrossIcon,
  MouseScrollDownCircleIcon,
  ShieldProtectionWifiSignalModernIcon,
  TvMoreIcon,
  StopSignHexagonIcon,
  CompassMapSquareIcon,
  LockUnlockRemoveCircleIcon,
  CursorStyleClickSquareIcon,
  CalendarCutLineIcon,
  MenuCursorSelectCircleIcon,
  KeySignInIcon,
  RefreshArrowsSquareIcon,
  PowerOnIcon,
  MagnetVerticalDownBigIcon,
  ChartCheckmarkIcon,
  ShieldCrossIcon,
  MonitorMinimalCheckmarkBigIcon,
  ChecklistCircleIcon,
  CheckmarkBigSquareCopyIcon,
  SleepModeStarsIcon,
  ObjectTextTranslateIcon,
  UiDesignSqaureIcon,
  ListMenuItemIcon,
  BookmarkIcon,
  UserSquareIcon,
  ShareMinimalCircleIcon,
  ZipArchiveStyleSquareIcon,
  SignalHotspotSquareIcon,
  ImageCopyCrossIcon,
  TextItemInputSquareIcon,
  CalendarBokmarkIcon,
  LockStyleBlankIcon,
  FolderSquareIcon,
  ItemEnterArrowRightEditIcon,
  UxDesignSqaureIcon,
  DashboardStyleLeftCircleIcon,
  MenuBookmarkCircleIcon,
  ChatMessagesDeleteIcon,
  LockCheckmarkCircleIcon,
  DashboardUpCircleIcon,
  FilesLibraryMediaLibraryIcon,
  MessageChatEyeCopyIcon,
  ShieldCmodernCircleIcon,
  MessageChatEditPenIcon,
  FingerprintMoreDotsIcon,
  FloppySaveObjectItemIcon,
  PrinterSquareIcon,
  HomeIcon,
  EmailCheckmarkIcon,
  TimeClockMenuIcon,
  CursorMinimalLeftClickSquareIcon,
  CropSquareIcon,
  LetterOpenCircleIcon,
  EmailMailMinimalIrcleIcon,
  TimetClockAddPlusIcon,
  MessageChatTextClickIcon,
  SphereRotateArrowsDIcon,
  LockRefreshIcon,
  SelecursorVerticalClickCircleIcon,
  MediaLibraryFolderEditMoreIcon,
  TargetSpaceObjectSelectIcon,
  TasklistBookmarkIcon,
  ObjectCheckmarkIcon,
  SyncCheckmarkDeleteDottedLineIcon,
  RecMicStopOctagonIcon,
  BrowserCheckmarkSquareIcon,
  LinkCrossRemoveSquareIcon,
  MailboxArchiveTextCheckmarkIcon,
  PinAngleIcon,
  TasklistUndoArrowLeftIcon,
  FingerprintCrossDeleteCircleIcon,
  ResizeArrowIcon,
  MoonCircleIcon,
  InfinityLoopRepeatCircleIcon,
  FavoriteBookmarkLockIcon,
  GlobeWifiIcon,
  SearchFastItemmIcon,
  CursorDeleteIcon,
  AirplayStyleSquareIcon,
  MessageChatSendArrowBottomRightIcon,
  ClockTimeSquareIcon,
  EmailpMailTimeClockIcon,
  MessageChatLeftInputIcon,
  AirplaySignalAirdropCircleIcon,
  ReplyLeftDownIcon,
  InfoNotificationSquareIcon,
  CursorMenuIcon,
  UserNotificationCircleIcon,
  MagnetVerticalBigIcon,
  MessageChatModernIcon,
  PenLoadingIcon,
  AnalyticsMinimalCircleIcon,
  MessageChatSendArrowIcon,
  MessagesPostsStyleTextRightIcon,
  EarthGlobeAddPlusIcon,
  AttachmentLinkArrowsIcon,
  ObjectSearchZoomPlusIcon,
  ClockWatchNotificationIcon,
  CalendarPageIcon,
  CursorSelectStyleCircleIcon,
  PluginSqaureIcon,
  DoubleRewindArrowRightSquareIcon,
  LoadingLinesCircleIcon,
  CalendarScheduleDateIcon,
  ListDoListCheckmarkIcon,
  LoginMinimalArrowRightIcon,
  GroupElementsSquareIcon,
  DashboardDeleteIcon,
  DocumentCursorSelectIcon,
  WebFinderWindowIcon,
  HomeHouseSmileIcon,
  EditPenMinimalBigCircleIcon,
  MenuBoardSquareCircleIcon,
  CheckListAddPlusIcon,
  CalendarScheduleCheckmarkIcon,
  ResizeArrowsRightLeftSquareIcon,
  DirectionArrowClickCircleIcon,
  ItemSettingSquareIcon,
  PinCopyDottedLineIcon,
  MenuModernSquareCopyIcon,
  FloppySquareBigCopyIcon,
  CursorMinimalSquareIcon,
  AddCirlcePlusIcon,
  ClipArrowUpBigIcon,
  DownloadSaveSquareIcon,
  ItemArrowDownSquareIcon,
  PinSelectIcon,
  TextInputSmallSquareIcon,
  EditPenListSquareIcon,
  HornTrumpetCircleCopyIcon,
  ItemCheckmarkSquareCopyIcon,
  EditPenMinimalCircleIcon,
  BookCircleIcon,
  ConnectArrowsCircleIcon,
  FloppySaveCrossCheckmarkIcon,
  SendVerticalCircleIcon,
  MediaLibraryFolderDeleteCrossIcon,
  FinderWindowSqaureIcon,
  VoiceRecOctagonIcon,
  AlarmClockTimeAddIcon,
  ShieldProtectedAddIcon,
  ThunderLightningNotificationsIcon,
  LinkAttachmentCircleEditIcon,
  StickerRemoveCrossIcon,
  ClockTimerDotsLineIcon,
  TrashBinDeleteCopyIcon,
  MenuPlusAddSquareIcon,
  UserModernStyleCircleIcon,
  HomeMinimalOctagonIcon,
  BellCircleIcon,
  PenEditDeleteCrossCircleIcon,
  ParagraphSquareIcon,
  DashboardVerticalModernIcon,
  WallAdPaperIcon,
  FilterSortCircleIcon,
  ClipArrowIcon,
  TasklistNotesItemIcon,
  AirplayMediaVideoIcon,
  SearchZoomPlusIcon,
  DotsIcon,
  DocumentPlusAddIcon,
  CursorSwipeRightIcon,
  PasswordLockProtectionIcon,
  BuildingCityTownIcon,
  CheckmarkBigSquareIcon,
  TaskListMoreMenuIcon,
  EditPanChatMessageIcon,
  HeartSqaureIcon,
  MessageChatDocumentGroupIcon,
  AttentionFireIcon,
  CursorRightSquareIcon,
  ItemZoomLoapIcon,
  ZipCopyIcon,
  WindowsAppCheckmarkIcon,
  BasketCheckmakIcon,
  KeySquareCopyIcon,
  LaptopComputerIconSquareIcon,
  LockKeystoneSquadCircleIcon,
  CursorSelectSquareIcon,
  AdButtonIcon,
  CalendarScheduleCheckmarkCopyIcon,
  DoorHoleSquareIcon,
  QuestionAddPlusCircleCopyIcon,
  BookmarkClassicBigIcon,
  PictureImageIcon,
  ObjectAddIcon,
  LoadingItemDottedLineIcon,
  ClockTimeCopyIcon,
  HandSquareIcon,
  TaskListPrinIcon,
  CheckListTextCheckmarkIcon,
  CopyAddDottedLineIcon,
  EmailMailSelectSmallIcon,
  ImagePictureAddPlusSquareIcon,
  LockKeystoneSquadSquareIcon,
  ItemImagePictureSquareIcon,
  MenuSleepModeCircleIcon,
  PenEditAddPlusIcon,
  LampCrossCircleIcon,
  HeartStyleCricleIcon,
  NotebookRefreshIcon,
  DocumentCursorIcon,
  PrinterMinimalMinusIcon,
  NotesEditPenIcon,
  ItemPenTextSquareIcon,
  ItemSearchMoreEditIcon,
  HomeModernRemoveCrossDeleteIcon,
  ItemFilterSimpleSquareIcon,
  HomeHouseSquareIcon,
  SearchLoadingCircleIcon,
  AlarmClockSquareCopyIcon,
  BorderRightIcon,
  LockArrowUpIcon,
  GroupDotsSqaureIcon,
  TildaCircleIcon,
  TaskItemMenuMoreIcon,
  ImagePictureArrowIcon,
  ChartSquareCopyIcon,
  HashtagCircleIcon,
  EyePlusAddSqaureIcon,
  FloppySaveCheckMarkIcon,
  NightModeAddIcon,
  DownloadLoadingArrowDownIcon,
  LinkVericalSquareIcon,
  SearchOffCrossSqaureIcon,
  TextWidthIcon,
  ClipStyleSquareIcon,
  DisconnectCutSquareIcon,
  WifiSigmalMinimalSquareIcon,
  BookmarksSquareIcon,
  WifiAddPlusCircleIcon,
  ClockTimeArrowLeftDownIcon,
  EjectIcon,
  AlarmClockTimeArrowIcon,
  ImagePictureDeleteCrossIcon,
  TrashBinDeleteCheckmarkIcon,
  TextHexagonIcon,
  AlarmTimerModeDotsIcon,
  TrashDeleteRemoveIcon,
  CalendarStopCrossDeleteIcon,
  ArrowUturnUpIcon,
  UserProfileHeartIcon,
  CalendarCheduleListIcon,
  ClipCrossRemoveBigIcon,
  FingerprintEnterArrowRightIcon,
  AttachmentLinkMoreMenuIcon,
  VoiceRecMoreIcon,
  SelectCheckmarkIcon,
  BinTrashStyleWarningArrowDownIcon,
  ShieldHexagonCheckmarkIcon,
  CursorSelectCheckboxIcon,
  SendMailSquareIcon,
  CalendarEnterArrowRightIcon,
  CameraStyleLineIcon,
  ExitLoginIcon,
  WindowPopUpCircleIcon,
  CursorLeftDottedLineCircleIcon,
  DocumentEyeIcon,
  SettingMenuOctagonIcon,
  TextInputFormIcon,
  UserShareIcon,
  UiCircleIcon,
  MessageChatStyleRemoveCrossDeletedIcon,
  IgtvIcon,
  ItemProductAddPlusIcon,
  IdAccountCopyIcon,
  PrintOutputRemoveDeletePlusIcon,
  CursorLeftTopCircleIcon,
  AwardRatingIcon,
  DocumentFilterIcon,
  ImageWaveSquareIcon,
  FinderWindowSquareIcon,
  NightModeOffIcon,
  ClockTimeCircleIcon,
  ElementsObjectCopyIcon,
  AlarmClockTimeOffRmoveIcon,
  SettingMenuCircleIcon,
  CursorClickRightTopCircleIcon,
  PasswordInputSquareIcon,
  ItemCheckmarkCircleBigIcon,
  LinkVerticalRightBigIcon,
  TasklistItemAddPlusStyleSquareIcon,
  DoorLogOutInSquareIcon,
  AnalyticsCircleIcon,
  SendModernSquareIcon,
  ListBulletsSquareIcon,
  NotebookCheckmarkIcon,
  ChatMessageCrossSquareIcon,
  ShieldProtectionPenEditLineIcon,
  CaommandButtonIcon,
  BookmarksLabelTagDeleteCrossSquareIcon,
  DownloadArrowSquareIcon,
  PositionTargetSquareIcon,
  HeartFavoriteCrossDeleteIcon,
  MenuPlusAddIcon,
  CopyItemPlusAddRightTopIcon,
  PrinterAddPlusCircleIcon,
  CursorLeftClickDottedLineIcon,
  HomeModernDoorCircleIcon,
  FloppySaveEditIcon,
  CheckMarkSelectSquareIcon,
  StarShineSquareIcon,
  RefreshIcon,
  CopyCutBlockIcon,
  EditPenMinimalBigSquareIcon,
  CopyCutItemTextIcon,
  MenuCrossDeleteCircleIcon,
  ThunderLightningSqaureIcon,
  SyncSynchronizeSquareCopyIcon,
  ChangeSwapArrowIcon,
  LightOctagonIcon,
  SelectTargetIcon,
  CopyGridIcon,
  AdCircleIcon,
  TrashbinMoreIcon,
  BriefcaseIcon,
  CircleSearchBrokenLineIcon,
  NotesCheckmarkIcon,
  StickerAddIcon,
  AirplayStyleHorizontalIcon,
  CalendarMoreDotsIcon,
  CalendarChartAnalyticsIcon,
  InputMinimalSquareIcon,
  ItemSpaceSquareIcon,
  CheckmarkReadSquareIcon,
  DotsMenuIcon,
  TimerPauseClockIcon,
  EyeOctagonIcon,
  ItemLoadingCircleIcon,
  InfoStyleOctagonIcon,
  NewsBookmarkIcon,
  BrowserCheckmarkCircleIcon,
  CirclesArrowShareIcon,
  ObjectsAddPlusIcon,
  WindowFinderSidebarIcon,
  LibraryModernCheckmarkCenterIcon,
  ListBulletsMoreDotsIcon,
  BookSquareCopyIcon,
  PinAngleSquareIcon,
  BackwardRearwardBackLockIcon,
  PaperTextCodeIcon,
  ClockSleepIcon,
  PrinterMinimakCheckmarkIcon,
  BookSquareIcon,
  TimerClockAlarmMoreDotsIcon,
  SendFasrPaperPlaneIcon,
  MenuCircleCheckmarkIcon,
  BookmarkAddPlusIcon,
  ImagePictureLeftCircleIcon,
  SettingHorizontalBigIcon,
  FavoriteBookmarkCircleIcon,
  CursorClockIcon,
  PrivateIncognitoSquareIcon,
  HomeModernHeartLikeIcon,
  CloudDownlaodIcon,
  ChartAnalyticsOctagonIcon,
  HomeHouseCircleIcon,
  CalendarDatesIcon,
  GifCircleIcon,
  WarningMinimalWarningCurckeIcon,
  ItemAddPlusCopyIcon,
  FilterDeleteSquareIcon,
  BookOpenCircleIcon,
  MessageCheckmarkCopyIcon,
  BrowserInternetWebNetworkWindowAppIconIcon,
  ClipVerticalCircleCopyIcon,
  TasklistSearchIcon,
  HornTrumpetStyleCircleIcon,
  MediaLibraryCheckmarkIcon,
  SearchItemLoupeIcon,
  MailboxDownloadCircleIcon,
  ZipArchiveSquareCopyIcon,
  CopyAntalyticChartIcon,
  ArrowsUpDownMenuCircleIcon,
  CursorMoreDotsIcon,
  SlidesSquareIcon,
  SyncArrowsSquareIcon,
  TasklistDownloadIcon,
  ItemTxtSearchRightDownSquareIcon,
  ChecklistIcon,
  EditPenDottedLineCircleIcon,
  FlagDeleteCrossIcon,
  OpenBookAddPlusIcon,
  TriangleStatusRoundSquareIcon,
  NightModeIcon,
  FingerprintCrossDeleteIcon,
  LinkRemoveDeleteSquareIcon,
  TimeClockMoreMenuIcon,
  DropdownMenuTextIcon,
  CopyItemLeftBottomIcon,
  DasboardIcon,
  CopyAddPlusCopyIcon,
  MenuBoardCircleIcon,
  NightModeSwitchesIcon,
  AsteriskSquareCopyIcon,
  LinkAttachmentStyleCircleIcon,
  RefreshRotateSquareIcon,
  DashboardDottedLineIcon,
  FloppySaveCircleIcon,
  AdSquareIcon,
  PinCrossDeleteIcon,
  ItemMenuEnterArrowIcon,
  StarLightSparkleCircleIcon,
  PrinterPrintAddPlusIcon,
  MenuSquareIcon,
  MapMinimalOctagonIcon,
  ChartAnalyticsCheckmarkSquareIcon,
  FilterSortFavouriteEditIcon,
  StikerPlusAddIcon,
  InforamtionCrossDeleteCircleIcon,
  QuestionCheckmarkCircleCopyIcon,
  BellStyleCopyIcon,
  HeadphonesSignalIcon,
  BinTrashStyleDownloadFullBlankIcon,
  MessageChatEyeIcon,
  LogoutLeftArrowIcon,
  ThumbsUpLikeStyleBigIcon,
  LoadingCheckmarkCircleIcon,
  FavoriteBookmarkStarIcon,
  FingerprinWarningIcon,
  CalendarDownloadIcon,
  ClockTimeLeftSettingIcon,
  EditPenMiddleSquareIcon,
  SettingEditFilterGearIcon,
  SettingStyleHorizontalRoundIcon,
  TimerAlarmFastLeftIcon,
  AirplaySignalSquareIcon,
  ScannerBarCodeSeceltIcon,
  GridLayoutAddIcon,
  MailboxAddPlusIcon,
  SlidesWindowsCopyIcon,
  AddItemSearchCircleIcon,
  ClockTimeEditIcon,
  ChartAnalyticsEditPenSquareIcon,
  UserProfileIcon,
  CopyWindowsCircleIcon,
  TimerMinimalSquareIcon,
  CursorAddIcon,
  LinkBrokenModernDottedLineIcon,
  WarningCrossDeleteSquareIcon,
  MenuFilterCentreIcon,
  SearchButtonAddPlusIcon,
  CompassLocationMapOffCircleIcon,
  WarningRefreshSquareIcon,
  LinkArrowCircleIcon,
  TimerClockIcon,
  LaodingWaitingIcon,
  ItemEditSquareCopyIcon,
  SendRightCircleIcon,
  SubstractGroupIcon,
  TimerSmallCircleIcon,
  SearchLoupeAddPlusIcon,
  ListChekmarkCircleIcon,
  ChartModernCircleIcon,
  ClockTimeArrowDownIcon,
  ThunderLightningFastIcon,
  ChromcastCastAirplayIcon,
  BoxClassicLineIcon,
  EndlessSquareIcon,
  UploadArrowIcon,
  BookmarkPlusAddIcon,
  BellNotificationAddPlusIcon,
  LoadingReloadSquareIcon,
  CalendarCheduleIcon,
  InfoInformationCircleIcon,
  CompassMapDeleteCrossCircleIcon,
  SignInLoginInArrowIcon,
  PrinterMoreDotsIcon,
  FaceIdIcon,
  AttachmentLinkCircleDeleteIcon,
  DotsMenuCircleIcon,
  AirplayCircleIcon,
  MagnetVerticalAddPlusBigIcon,
  MicRecIcon,
  SendSquareIcon,
  MenuBarSpaceHorizontalIcon,
  FashboardMinimalLineCircleIcon,
  SerchCrossDeleteCircleIcon,
  TextEditAIcon,
  AirplaySignalAirdropIcon,
  TimeClockStarIcon,
  DocumetCopyIcon,
  LanguageTranslateCopyIcon,
  FilterStyleBigCirleIcon,
  MenuAddPlusIcon,
  MailboxNotificationIcon,
  FilterStyleNotificationCircleIcon,
  CheckListEnterInpuArrowRightIcon,
  InputEnterArrowRightIcon,
  UsersIcon,
  StikerSmileAddPlusIcon,
  DocumentStatusAddPlusIcon,
  ChatMessagesBubbleSquareIcon,
  ClipVerticalCircleIcon,
  ClipCopyIcon,
  MenuDotsSquareIcon,
  SettingEditFilterIcon,
  ShareMiddleSquareIcon,
  DirectionArrowNotificationCircleIcon,
  BookmarkFavoriteLabelMoreSettingIcon,
  HeartSyleIcon,
  BagShoppingSqaureIcon,
  MoonStarIcon,
  ShieldBlankIcon,
  MenuVerticalSquareIcon,
  TargerArrowLeftRightCircleIcon,
  DirectionArrowClickSquareIcon,
  FingerprintAnalysisLoadingIcon,
  RepeatRorateSquareIcon,
  CursorCheckmarkIcon,
  BrowserWebLoadingIcon,
  EdtiPenInputDotsIcon,
  MessageChatStyleDottedLineIcon,
  SelectObjectCopyIcon,
  LinkAttachmentSquareIcon,
  CalendarPinIcon,
  BorderOuterIcon,
  ItemSelectAddPlusIcon,
  MicrophoneMicRecCheckmarkIcon,
  EditPenMinimalStyleIcon,
  DashboardMinimalStyleCircleIcon,
  CalendarListIcon,
  SearchMoreVerticalMiddleIcon,
  MessageChatBlankRightIcon,
  ChartAnalyticStatisticsCrossDeleteCircleIcon,
  RotateMoreDotsIcon,
  SelectMenuItemIcon,
  FilterUpDownArrowSquareIcon,
  ArrowDirectionSquareIcon,
  CalendarScheduleTimeIcon,
  TasklistStyleLineDownIcon,
  ArrowsIcon,
  RotateSyncCircleArrowsIcon,
  ListPaperMoreIcon,
  ClipCrossDeleteIcon,
  UploadArrowUpSquareIcon,
  ChatMessageListIcon,
  WarningCrossDeleteCircleIcon,
  MicrophoneStyleRecMicCircleIcon,
  CursorClickStyleCircleIcon,
  CircleDeleteCrossIcon,
  HttpSquareIcon,
  SyncObjectsCheckmarkSquareIcon,
  ChecklistSearchIcon,
  MagnetHorizontalRightBigIcon,
  WindowMoreDotsIcon,
  SearchChartMiddleIcon,
  ChekmarkBigCircleIcon,
  InfoShareCircleIcon,
  LockSignalSquareIcon,
  QuestionCrossCircleIcon,
  PinCrossDeleteCircleIcon,
  DoorHoleModetnBigIcon,
  CalendarScheduleCopyIcon,
  TextDocumentMoreSettingIcon,
  LoadingDotsSquareIcon,
  ClockTimeTimerArrowIcon,
  GlobeSearchIcon,
  ItemCheckmarkClockIcon,
  ItemMenuHeartIcon,
  DropdownMenuIcon,
  FloppyCircleIcon,
  NotificationDocumentTextIcon,
  MessageChatRemoveCrossIcon,
  AlarmClockTimeCrossDeleteIcon,
  SearchLoupeSquareCopyIcon,
  ChartAnalyticsMinimalCircleIcon,
  MicrophoneRecMicRemoveDeleteCrossIcon,
  StopRemoveDeleteHorizontalSquareIcon,
  CircleMenuIcon,
  UserProfileRemoveSquareIcon,
  DeleteIcon,
  DashboardAngleDottedLineCircleIcon,
  EditPenCheckmarkIcon,
  MenuItemCheckmarkIcon,
  TimerClockSquareIcon,
  FlagAddPlusSquareIcon,
  ItemTextCircleIcon,
  FinderCircleIcon,
  BookOpenIcon,
  QyestionSquareIcon,
  ItemShareClickArrowIcon,
  CheckListEnterArrowRightIcon,
  LinkHorizontalDottedLineIcon,
  LoginModernArowCircleIcon,
  ClosedEyeSquareIcon,
  ObjectsTargetDotsIcon,
  ItemEditPenCircleIcon,
  BellNotificationCircleIcon,
  DownloadArrowIcon,
  WifiSignalWarningIcon,
  FilterMenuCircleIcon,
  TasklistEditPenCopyIcon,
  StarsLightCircleIcon,
  SettingsSwitchesCircleIcon,
  ListTasksCheckmarkIcon,
  ClipCrossDeleteCopyIcon,
  ListItemNotificationSquareIcon,
  BrowserWebSettingEditIcon,
  ChatBubleAddPlusIcon,
  CheckmarkLineSquareIcon,
  ObjectPictureTextEditIcon,
  GlobeCursorIcon,
  BackwardRearwardMoreDotsIcon,
  ShieldMoreSquareIcon,
  ClipArrowRightIcon,
  MessageCheckmarkIcon,
  DashboardVerticalMinimalCircleIcon,
  ClockWatchSquareIcon,
  ExitDoorLoginIcon,
  MailboxArchiveNewOneClickIcon,
  GridLayoutSquareIcon,
  QuoteRightBottomCircleIcon,
  FilterSettingsSortIcon,
  DocumentPinIcon,
  BackRefreshReloadLoadingCheckmarkIcon,
  TriangleSelectSquareIcon,
  BorderTopIcon,
  LabelTagCircleIcon,
  StartFavoriteAddPlusCircleIcon,
  ShareSquareCopyIcon,
  SettingStyleMenuSquareIcon,
  BookmarksLabelTagSquareIcon,
  BrowserSidebarRightTextIcon,
  FlagStyleSquareIcon,
  BookmarksLabelTagIcon,
  AlarmClockTimeLoadingIcon,
  PenItemEditIcon,
  CursorWarningIcon,
  FilterStyleAddPlusSquareIcon,
  DashboardLineIcon,
  QuestionAddCircleIcon,
  CmdKeyboardIcon,
  WifiArrowCircleIcon,
  AlarmClockTimeCrossIcon,
  MessageModernDotsIcon,
  ChartCheckamrkSquareIcon,
  SendIcon,
  FullScreenZoomSquareIcon,
  SmileysCheckmarkIcon,
  CalendarHeartIcon,
  SelectObjectCheckMarkIcon,
  UploadCircleIcon,
  PenEditCheckmarkIcon,
  WifiSignalModernCircleIcon,
  ThumbsUpLikeStyleSquareIcon,
  ChartNotificationIcon,
  SearchLoupeStarIcon,
  ListDeleteIcon,
  ZipCrossRemoveDeleteSquareIcon,
  MenuBurgerArrowCircleIcon,
  SettingModernSquareIcon,
  WindowFinderResizeArrowIcon,
  KeyArrowCircleIcon,
  TriangleWarningSquareIcon,
  SelectCheckmarkCopyIcon,
  CookiesCircleIcon,
  ImagePictureModernCrossRemoveDeleteIcon,
  PrinterPrintCheckmarkIcon,
  ClipCrossDeleteSqaureIcon,
  ObjectSearchMoreDotsIcon,
  AlarmTimerStyleRightIcon,
  CrownSquareIcon,
  HomeClassicSquareIcon,
  LampCheckamrkIcon,
  ItemSelectAddCrossIcon,
  AttachmentLinkPlusAddIcon,
  InputPasswordTextIcon,
  HeartFavoriteRemoveDeleteIcon,
  TasklistMinusIcon,
  DocumentStatusRejectedCrossIcon,
  SignalRssIcon,
  WindowScaleTransformIcon,
  MouseSqaureIcon,
  GroupSelectCopyDeleteIcon,
  ItemTextCheckmarkCurvedCornerIcon,
  TasklistStyleDottedLineIcon,
  LampLightSquareIcon,
  ClockWatchCircleOldIcon,
  HomeMenuMoreIcon,
  StatisticsAnalyticsArrowUpCircleIcon,
  CalendarDeleteRemoveHorizontalIcon,
  InfoMinimalCircleIcon,
  TextAlignArrowUpIcon,
  ReplyCircleIcon,
  TasklistMenuTextIcon,
  ClockTimeAddPlusIcon,
  LightBulbIdeaIcon,
  EarthGlobeModernMinimalIcon,
  GlobeEarthSphereModernIcon,
  ShieldDeletedPlusIcon,
  MessageChatPlusAddIcon,
  PenEditLeftTextItemSquareIcon,
  ShieldMorePasswordIcon,
  HeartIcon,
  DocumentInfoIcon,
  CheckmarkReadLineCircleIcon,
  CloudSquareIcon,
  ClockStopwatchIcon,
  LoadingDotsArrowCircleIcon,
  ImageCheckmarkIcon,
  MonitorDisplayCircleIcon,
  DocumentTextCheckmarkCentreRightIcon,
  RhombusSqureIcon,
  PinSignIcon,
  MedalAwardIcon,
  FavoriteHeartCheckmarkSquareIcon,
  BookmarksLabelTagDeleteCrossIcon,
  ItemTextSelectIcon,
  CompassMapCheckmarkCircleIcon,
  PictureImageSearchLoupeIcon,
  LoginMinimalRightIcon,
  WindowsCrossIcon,
  BrokenLinkModernRightCircleIcon,
  HomeModernDoorIcon,
  EyeSleepModeSquareIcon,
  CalendarGoBackIcon,
  CursorLeftSelectSquareIcon,
  TasklistAnalyticsIcon,
  LoapZoomOutCrossSquareIcon,
  PlusMinusCircleIcon,
  CopyGridPlusIcon,
  ObjectSelectCircleIcon,
  StarFavoriteCheckmarkIcon,
  WarningLoadingIcon,
  WindowRollUpCopyIcon,
  TrashBinDowloadBigIcon,
  MagicWandMiniIcon,
  ImagePictureArrowRightIcon,
  MessageCircleIcon,
  LaodingMinimalSquareIcon,
  CloudSqaureIcon,
  MenuModernLineIcon,
  WindowFinderMaximizeResizeIcon,
  HardDriveStorageIcon,
  HeartFavoriteCircleIcon,
  FloppyCheckmarkIcon,
  LockLineBigIcon,
  WindowFinderTwoColumnsIcon,
  CertificateMedalIcon,
  PinLineSquareIcon,
  HomeLineMinimalCircleIcon,
  CupCircleIcon,
  ThumbsUpLikeBigIcon,
  ObjectMagicWandIcon,
  WindowFinderMinimizeResizeIcon,
  PrinterPrinWarningInfoIcon,
  CursorMoveFastSquareIcon,
  EmailMailMenuIcon,
  ChatAnalyticsSearchSquareIcon,
  ChartAnalyticsMinimalSquareIcon,
  ChartCrossDeleteCircleIcon,
  RotateIcon,
  MessageChatCheckmarkSquareIcon,
  TimeAlarmClockMoreDotsIcon,
  ArrowDirectionNavigationPlusAddCircleIcon,
  MoveArrowRightLeftCircleIcon,
  SearchMediumSquareIcon,
  MailboxMinusIcon,
  SettingCircleCopyIcon,
  ThumbsUpIcon,
  ChartPlusAddIcon,
  PlusMinusSqaureIcon,
  MicrophoneMicRecSquareCopyIcon,
  TicketGroupCircleIcon,
  MailboxAddPlusCopyIcon,
  SettingHorizontalCircleCopyIcon,
  InboxDeleteRemoveSquareIcon,
  LoadingCheckmarkStatusCircleIcon,
  IcoIconCircleIcon,
  FingerprintInfoIcon,
  ChartModernSquareIcon,
  ShapesOblectTriangleSquareCircleIcon,
  ItemDocumentCheckmarkTopCentreIcon,
  MenuTextItemIcon,
  SearchSelectLoupeIcon,
  LightningCrossDeleteSquareIcon,
  MessageChatTextStyleUpIcon,
  LoadingPlusCircleAddIcon,
  BellIcon,
  MouseStyleBigIcon,
  DirectionArrowCheckmarkCircleIcon,
  StampCheckmarkCircleIcon,
  ItemCutRightIcon,
  SettingCheckMarkSqaureIcon,
  LockArrowAddPlusCircleIcon,
  MoviesVideoCircleIcon,
  TrashBinWarningIcon,
  MessageChatLightningModernIcon,
  EyeHideDownArrowIcon,
  CopyStyleRightIcon,
  ChartAddPlusCircleIcon,
  SearchCrossSquareCopyIcon,
  SettingsSwitchesSquareVolumeKnobIcon,
  CalendarDateLeftTopIcon,
  WifiSignalOctagonIcon,
  MonitorDisplayDeleteRossIcon,
  RefreshArrowsCircleIcon,
  PrinterMinimalCrossRemoveIcon,
  CursorBrokenLineSquareIcon,
  EyeDotsTargetIcon,
  HeartCircleIcon,
  PinAddPlusSquareIcon,
  WifiSignalStyleSquareIcon,
  ShieldProtectionSecurityAddPlusSquareIcon,
  CalendarLineCircleIcon,
  CalendarLineSquareIcon,
  SlidesImagesPapersRemoveDeleteIcon,
  ListTasksAddPlusIcon,
  RefreshArrowsSquareCopyIcon,
  ItemShineStarRightTopIcon,
  AlarmClockSquareIcon,
  KeyboardCircleIcon,
  LockDotsIcon,
  StikerSmileCircleIcon,
  BrowserSignalSquareIcon,
  FileZipRarIcon,
  ShiledProtectonDownloadArrowDownIcon,
  CopyCrossDeleteIcon,
  ShieldProtectedIcon,
  HeartFavoriteAddIcon,
  EditPenSmallSquarevIcon,
  LinkBrokenModernIcon,
  CopySquareDuplicateIcon,
  ChatMessageMoreIcon,
  ReloadSquareIcon,
  ArrowDirectionSquareCopyIcon,
  CheckListImageIcon,
  FilterStyleStopOctagonIcon,
  PinMenuCircleIcon,
  ClipAttachmentArrowLeftCircleIcon,
  MenuEditSquareIcon,
  TasklistItemAddPlusBigIcon,
  LinkAttachmentCircleArrowLeftIcon,
  ShieldRemoveDeleteCircleIcon,
  KeyboardButtonArrowsCircleIcon,
  EmailLetterStyleCrossRemoveIcon,
  EditPenIcon,
  ArtboardsSquareIcon,
  FilterSortCheckMarkIcon,
  EditPenLineStopOctagonIcon,
  EmailLetterStyleIcon,
  TimeClockAlarmSquareIcon,
  SearchLoadingDottedLineIcon,
  StickerMoreDotsIcon,
  CursorStyleIcon,
  BracketsMoreCircleIcon,
  ItemTextSquareIcon,
  IteamSearchRightTopSquareIcon,
  AttachmentLinkCirclePlusIcon,
  PrinterMinimalRemoveCrossIcon,
  ClosedEyeCircleIcon,
  VoiceRecCheckmarkIcon,
  CrownStyleSquareCopyIcon,
  LinkHorizontalCircleIcon,
  CursorLeftStyleIcon,
  HeartFavoriteLikeStoryIcon,
  TrianglestatusRoundCircleIcon,
  InfoStyleSquareIcon,
  TasklistItemRemoveCrossSquareIcon,
  StickerClipIcon,
  MicrophoneIcon,
  UploadBigCircleArrowUpIcon,
  BinTrashStyleCrossCheckmarkIcon,
  InputTextEditCircleIcon,
  MessageChatStyleIcon,
  SearchOffCrossCircleIcon,
  FullScreenCircleIcon,
  ArchiveDocumentsBoxBigIcon,
  BrokenLinkModernRightSquareIcon,
  MessageChatRightBlankIcon,
  ClockHourIcon,
  ShieldCheckmarkIcon,
  SettingStyleVerticalSquareIcon,
  HomeModernDoorSquareIcon,
  MagicWandCircleCopyIcon,
  BlankSpaceCircleIcon,
  ArrowUturnDownMoreMenuIcon,
  ItemTextFavoriteIcon,
  DownloadMinimalSquareIcon,
  SearchStopOctagonIcon,
  RotateItemIcon,
  EyeShineSquareIcon,
  BoxDeleteSpamCrossIcon,
  DocumentTextFloppyDiskIcon,
  CopyItemBigRightIcon,
  WifiRefreshCircleIcon,
  CopyItemShareMaximizeIcon,
  DoorWellMinimalLoginCircleIcon,
  ZipArchiveDeleteCrossSquareIcon,
  PazzlePluginLeftDownIcon,
  LoadingSqaureIcon,
  ItemAddClipSquareIcon,
  ChartCheckmarkSquareIcon,
  CopyItemRightTopMenuIcon,
  CircleHashIcon,
  LinkCheckmarkCopyIcon,
  MouseScrollUpCircleIcon,
  AlarmClockTimeTimerAddIcon,
  CookiesStyleIcon,
  ParagraphCircleIcon,
  ChartMinimalSignalSquareIcon,
  CursorMinusIcon,
  EditPenCircleCopyIcon,
  LanguageTranslateSquareIcon,
  SendRightSquareIcon,
  SearchArrowCircleIcon,
  CopyRightTopArrowLeftIcon,
  SettingEditFilterSwitchesSwitchCircleIcon,
  MoonOffCircleIcon,
  SettingFilterIcon,
  ArrowCircleButtonIcon,
  DownloadBigArrowDownIcon,
  MouseScrollUpIcon,
  HomeTwitterCircleIcon,
  CalendarTextNoteIcon,
  InputPasswordCircleIcon,
  HomeMoreIcon,
  KeyholeLockSquareIcon,
  StarDeleteIcon,
  VoiceRecDeleteCrossIcon,
  ObjectAddPlusSquareIcon,
  CalendarScheduleBackIcon,
  EmailMailDisableDeleteIcon,
  ItemDocumentsFilesIcon,
  PuzzlePlugInRemoveIcon,
  SignalBarsBargraphIcon,
  MessageSquareIcon,
  MonitorMinimalAddPlusBigIcon,
  ItemDocumentIcon,
  CursorClickCircleIcon,
  RotateDeleteArrowIcon,
  CalendarScheduleClipIcon,
  SpellingCheckIcon,
  EditPenBigCircleIcon,
  ReceiptIcon,
  SelectObjectMoreDotsIcon,
  CopyHorizontalCircleIcon,
  SearchShineLeftSquareIcon,
  ItemDocumentImageCheckmarkIcon,
  TaskListPinIcon,
  SignalWifiModernIcon,
  ChatMessageTextRightIcon,
  CopyFillIcon,
  SearchRemoveCrossCircleIcon,
  SearchBigLeftIcon,
  PrinterMinimalAddPlusIcon,
  SearchMinusIcon,
  ClipArrowLeftIcon,
  PasswordInputIcon,
  ItemTextCimpleCircleIcon,
  ItemMinimizeWindowIcon,
  ImagePicturesMinusSquareIcon,
  NotebookAddPlusIcon,
  AttachmentLinkLeftIcon,
  FilterArrowUpDownCircleIcon,
  TasklistRemoveCrossIcon,
  ClipArrowLeftBigIcon,
  ClipVerticalStyleIcon,
  MouseScrollDownIcon,
  CursorSquarePositionAppIcon,
  SignalRightTopSquareIcon,
  FilterCheckmarkCircleIcon,
  ItemTextNotificationSquareIcon,
  DocumentCheckmarkLeftIcon,
  BookmarkShieldIcon,
  SearchLoupeSelectIcon,
  TimerClockAddSquareIcon,
  ChartStyleIcon,
  MessageChatRecMicDownIcon,
  SearchSquareIcon,
  SettingCheckmarkIcon,
  ShieldModernIcon,
  LockStyleLineBlankIcon,
  FilleDocumentSquareIcon,
  ChatMessageInfoIcon,
  FileZipRarCircleIcon,
  CheckListEditIcon,
  BinTrashModernLineIcon,
  DashboardModernStyleCircleIcon,
  CropArrowSyncIcon,
  PenEditSquareCopyIcon,
  IdCircleIcon,
  CmdCommandKeyboardCircleIcon,
  ItemSelectIcon,
  SendStyleSelectIcon,
  CloudArrowCircleIcon,
  CircleCutIcon,
  CommandMinimalCircleIcon,
  LinkCheckmarkIcon,
  CopyItemShareArrowIcon,
  LoginOgoutIcon,
  ItemBlockCrossSquareIcon,
  StopMinusIcon,
  TranslateAddCircleIcon,
  SearchShineSquareIcon,
  LinkMinimalCircleIcon,
  BookmarksLabelTagCopyIcon,
  MessageChatEditPenCommentsIcon,
  DashboardLeftCircleIcon,
  FinderBrowserSidebarLeftTextIcon,
  BinBoxCircleIcon,
  SearchRightSquareIcon,
  BluetoothOffIcon,
  ChatMessageStickerSmileIcon,
  LinkEditPenIcon,
  GroupElementsMoveIcon,
  GlobeSmallSelectIcon,
  MonitorMaximizeWindowIcon,
  CheckListWarningIcon,
  MailEmailLetterAllMailboxesIcon,
  SearchLoapRefreshReloadIcon,
  CopyItemMinusRightTopIcon,
  EmailMailInfoIcon,
  KeyboardCircleCopyIcon,
  HomeHouseModernIcon,
  FingerprintWarningIcon,
  MenuItemButtonSquareIcon,
  ItemFavoriteHeartCircleIcon,
  CopyCircleCopyIcon,
  ClockTimeTimerDeleteTurnOffIcon,
  ItemMenuAddPlusCircleIcon,
  ChartStyleBigCopyIcon,
  MessagesTalkLeftIcon,
  BorderBottomIcon,
  BookTitleIcon,
  CameraChatIcon,
  TextEditPenTIcon,
  BookmarkCrossDeleteSquareIcon,
  ItemListDownloadArrowDownCornerIcon,
  CheckmarkTextIcon,
  CopyrightIcon,
  KeyholeOctagonIcon,
  EditPenBigSquareIcon,
  SleepTurnOffSquareIcon,
  BookmarkLinesCopyIcon,
  MediaLibrarySqaureIcon,
  SendCircleIcon,
  RotateArrowSquareIcon,
  EditPenMoreDottedLineCircleIcon,
  MailboxArchiveReplyIcon,
  WindowsMoreCopyIcon,
  MenuArrowSquareIcon,
  FavoriteBookmarkMenuIcon,
  TimeClockNotificationIcon,
  ItemHeartFavoriteSquareIcon,
  EmailMailTargetIcon,
  CopySmallVerticlaSquareIcon,
  BookmarkAddPlusTopRightIcon,
  ChecklistClockIcon,
  LinkBrokernModernCircleIcon,
  ClockTimeMoreSettingIcon,
  PenEditLineSquareIcon,
  MarkerPinAddPlusIcon,
  ClipSquareIcon,
  MagnetSqaureIcon,
  WarningCheckmarkSquareIcon,
  ItemLikeHeartSquareIcon,
  AlarmClockTimeWarningIcon,
  TasklistCurvedCornerBottomRightIcon,
  CheckMarkSquareSelectIcon,
  InputCursorIcon,
  EditPenLineCircleIcon,
  MailboxInboxShiningCircleIcon,
  UserSingleSelectIcon,
  ChartAnalyticsCopyIcon,
  FlagStopOctagonIcon,
  PhotoCameraAddIcon,
  InputASquareIcon,
  WifiAddPlusIcon,
  CopyItemIcon,
  ChatMessageModernCheckmarkIcon,
  DocumentPinCopyIcon,
  MessagesWarningInformationIcon,
  WifiSignalCheckmarkCircleIcon,
  TimeClockMinutesIcon,
  ChartGraphIcon,
  NotificationDocumentSelectIcon,
  StickerMoreIcon,
  ItemListCheckmarkSquareIcon,
  CursorRefreshReloadLoadingIcon,
  CopyClipDocumentIcon,
  CopyItemCrossRemoveRightTopIcon,
  ItemArrowDownCircleIcon,
  NightModeCircleIcon,
  CursorRightCircleIcon,
  CursorMoveFastCirclIcon,
  HomeCircleCopyIcon,
  ThunderLightningIcon,
  SlidesImagesPapersBlankIcon,
  BrowserWebCheckmarkIcon,
  InfoCiecleSquareIcon,
  LoginLogoutCrossDeleteIcon,
  PenEditUndoIcon,
  ChartAnalyticsStyleIcon,
  StickerAddRemoveIcon,
  MessageChatIcon,
  LaptopComputerSelectIcon,
  AttachmentLinkCrossDeleteIcon,
  CheckboxChekmarkEqualIcon,
  CameraRecSquareIcon,
  PlayVideoCopyIcon,
  DownloadCircleCopyIcon,
  UnderlineTextSquareIcon,
  TrashBinDeleteEnterArrowRightIcon,
  SleepModeSquareIcon,
  FullSignalNetworkSignalSmartPhoneStrengthTelephoneIcon,
  ObjectHeartTextEditIcon,
  ItemTextSelectCopyIcon,
  DocumetEditPenIcon,
  BrowserInternetWebNetworkWindowsIcon,
  FavoriteBookmarkCheckmarkIcon,
  SignalRssNetworkSquareIcon,
  BellWarningIcon,
  PlusNotificationCircleIcon,
  ShareStarSquareIcon,
  DownloadButtonSqaureIcon,
  CloudSettingIcon,
  ClosedCaptioningIcon,
  EditPenRemoveCrossSquareIcon,
  StarIcon,
  NotesCutIcon,
  BinTrashStyleUploadArrowUpIcon,
  CalendarEditPenStyleIcon,
  LoadingDeleteCrossIcon,
  MediaLibraryFavoriteHeartIcon,
  CheckListTaskWarningIcon,
  EyeShineCircleIcon,
  SettingsFilterSqureIcon,
  FileupMailIcon,
  SearchCrossRemoveOctagonIcon,
  BinTrashStyleCrossRemoveIcon,
  TimeClockFloppyDiskSaveIcon,
  CurvedBoxIcon,
  MenuPinIcon,
  LoadingStyleSquareIcon,
  CipIcon,
  LoadingClassicSquareIcon
};
