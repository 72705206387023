import { Modal, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type SelectedCardStylesType } from './ManageMembersModal.types';

export const SubTitle = styled.h3`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  margin-bottom: ${tokens.spacingXxl};
`;

export const CardContainer = styled.div<
  AddPrefixToType<SelectedCardStylesType>
>`
  display: flex;
  flex-direction: column;
  width: 35.6rem;
  height: 27.6rem;
  padding: ${tokens.spacingXs};
  align-items: center;
  justify-content: end;
  gap: ${tokens.spacingXs};
  border: 2px solid transparent;
  transition: border-color 200ms ease-in-out;
  border-radius: ${tokens.borderRadiusMd};
  background: ${tokens.neutralColorHighLight};
  box-shadow: 0 8px 16px 0 rgba(170 170 170 / 16%);

  > svg {
    width: 13rem;
    height: 13rem;
    margin: 0 auto;
    color: ${tokens.brandColorSecondaryPure};
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingNano};
  }

  &:hover {
    cursor: pointer;
    border-color: ${tokens.brandColorSecondaryPure};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: ${tokens.brandColorSecondaryPure};
    `}

  ${({ $isSelectTravelersDisabled }) =>
    $isSelectTravelersDisabled &&
    css`
      pointer-events: none;
      box-shadow: none;

      * {
        color: ${tokens.neutralColorLowLight} !important;
      }

      > svg path {
        stroke: ${tokens.neutralColorHighDarkest} !important;
      }
    `}
`;

export const CardTitle = styled.h4`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  text-align: left;
  width: 100%;
`;

export const CardDescription = styled.h4`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 1.82rem;
  letter-spacing: 0.75px;
`;

export const CardItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${tokens.spacingXxs};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    overflow: auto;
    max-height: 30rem;
    justify-content: unset;
    flex-direction: column;
    gap: ${tokens.spacingXxs};
  }
`;

export const ButtonsContainer = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${tokens.spacingXxl};
`;
