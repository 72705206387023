import { Modal, tokens } from 'ds';
import styled from 'styled-components';

import { SideModal } from 'presentation/components/global/Modal';

export const Container = styled(SideModal)``;

export const Content = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-content: flex-start;
  row-gap: ${tokens.spacingLg};
`;

export const Subtitle = styled.p`
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};

  a {
    color: ${tokens.brandColorSecondaryPure};
  }
`;

export const FormContent = styled(Modal.Form)`
  flex: 1 0 0;
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  row-gap: ${tokens.spacingLg};
  padding-top: ${tokens.spacingNano};
  padding-right: ${tokens.spacingNano};

  > div:has(> input) {
    justify-content: flex-start;
  }

  .react-multiSelect-custom__control {
    min-width: unset;
    max-width: unset;
    min-height: unset;

    > div:first-of-type {
      max-width: unset;
      width: calc(100% - 8.1rem);
      flex: 1 0 0;
    }

    &:has(.react-multiSelect-custom__value-container--has-value) {
      padding-top: 0 !important;
    }
  }
`;

export const FormContentDefault = styled.div`
  display: flex;
  flex: 1 0 0;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  gap: ${tokens.spacingLg};
  padding-top: ${tokens.spacingNano};
  padding-right: ${tokens.spacingNano};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-top: -${tokens.spacingQuarck};
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${tokens.spacingXs};

  > div {
    display: flex;
  }

  button,
  div {
    flex: 1 0 0;
    white-space: nowrap;
  }
`;
