import { tokens } from 'ds';
import styled from 'styled-components';

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${tokens.spacingXxs};

  svg {
    color: ${tokens.brandColorPrimaryMedium};
    width: 5.6rem;
    height: 5.6rem;

    path,
    line {
      stroke-width: 0.7;
    }
  }

  p {
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    line-height: 140%;
  }
`;

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingXxs};

  svg {
    color: ${tokens.brandColorPrimaryMedium};
    width: 3.2rem;
    height: 3.2rem;

    path,
    line {
      stroke-width: 1;
    }
  }

  p {
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    line-height: 132%;
  }
`;
