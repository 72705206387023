import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  padding: 1.6rem 3.2rem;
  background: ${tokens.deepColorWhite};
`;
