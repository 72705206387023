import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const ModalTitleWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  gap: ${tokens.spacingNano};
`;

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  p {
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.075rem;
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamilyPoppins};
    font-weight: ${tokens.fontWeightRegular};
  }
`;

export const ModalImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ModalImage = styled.img`
  width: 12.45rem;
  height: 12.45rem;
  object-fit: contain;
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-wrap: wrap;

  button {
    flex: 1 0 0;
  }
`;
