import { tokens } from 'ds';
import styled from 'styled-components';

export const Text = styled.p`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
`;

export const Bold = styled.p`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightMedium};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${tokens.spacingInsetXxs};
`;
