import Container from 'react-modal';

import { useSideModal } from './useSideModal';

import { type ISideModalProps } from './SideModal.types';

import {
  CloseButton,
  ContentContainer,
  ContentWrapper,
  HeaderContainer,
  ModalTitle
} from './SideModal.styles';

Container.setAppElement('#root');

export function SideModal({
  children,
  title,
  isOpen,
  side = 'right',
  onAfterClose,
  className
}: ISideModalProps): JSX.Element {
  const { modalAnimationState, handleCloseModalWithAnimation } = useSideModal({
    onAfterClose
  });

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={handleCloseModalWithAnimation}
      overlayClassName={`default-modal-overlay side-modal-backdrop-${modalAnimationState}-animation`}
      className={`side-modal-content side-modal-${side} side-modal-${side}-${modalAnimationState}-animation ${className}`}
    >
      <ContentWrapper>
        <HeaderContainer>
          <ModalTitle>{title}</ModalTitle>

          <CloseButton onClick={handleCloseModalWithAnimation} />
        </HeaderContainer>

        <ContentContainer>{children}</ContentContainer>
      </ContentWrapper>
    </Container>
  );
}
