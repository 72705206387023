import { tokens } from 'ds';
import styled from 'styled-components';

import { AlertModal } from 'presentation/components/global/AlertModal';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${tokens.spacingXs};
  align-self: stretch;
`;

export const WarningIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: ${tokens.borderRadiusCircular};
  background: ${tokens.feedbackColorWarningLightest};
`;

export const WarningIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  background: ${tokens.feedbackColorWarningPure};
  border-radius: ${tokens.borderRadiusCircular};

  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${tokens.neutralColorHighPure};
  }
`;

export const Text = styled.div`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledAlertModal = styled(AlertModal)`
  #footer {
    button {
      white-space: nowrap;
    }
  }
`;
