import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding-top: ${tokens.spacingXs};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.spacingXs};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    align-items: end;
  }
`;

export const Message = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  line-height: 160%;
  letter-spacing: 0.75px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighLight};
  padding: ${tokens.spacingXxs};
  border-radius: ${tokens.borderRadiusMd};
`;

export const NameNotFoundBannerWrapper = styled.div`
  padding-bottom: ${tokens.spacingMd};
`;
