import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${tokens.deepColorWhite};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h1`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 1.8rem;
  letter-spacing: 0.075rem;
  margin-bottom: ${tokens.spacingNano};
`;

export const Days = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${tokens.spacingQuarck};
`;

export const Fees = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 1.8rem;
  letter-spacing: 0.075rem;
`;

export const Value = styled.span`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 1.8rem;
  letter-spacing: 0.075rem;
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelTotal = styled.span`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightMedium};
  line-height: 1.6rem;
  letter-spacing: 0.075rem;
`;

export const ValueTotal = styled.span`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightBold};
  line-height: 1.6rem;
  letter-spacing: 0.075rem;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${tokens.neutralColorHighDark};
  padding-bottom: ${tokens.spacingXs};
  margin-bottom: ${tokens.spacingXs};
`;
