import { tokens } from 'ds';
import styled from 'styled-components';

import { AlertModal } from 'presentation/components/global/AlertModal';

export const WarningIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: ${tokens.borderRadiusCircular};
  background: ${tokens.feedbackColorWarningLightest};
`;

export const WarningIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  width: 4.8rem;
  background: ${tokens.feedbackColorWarningPure};
  border-radius: ${tokens.borderRadiusCircular};

  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${tokens.neutralColorHighPure};
  }
`;

export const Text = styled.div`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
`;

export const StyledAlertModal = styled(AlertModal)`
  #footer {
    button {
      flex-basis: 200px;
      flex-grow: 1;
    }
  }
`;
