import { DefaultButton, Modal, SecondaryButton } from 'ds';

import { useLangContext, useModalContext } from 'data/contexts';

import { type IAlertModalProps } from './AlertModal.types';

import { Container, Content, Footer, Header } from './AlertModal.styles';

export function AlertModal({
  className,
  children,
  cancelButtonProps,
  confirmButtonProps,
  onClose,
  title,
  icon,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick
}: IAlertModalProps): JSX.Element {
  const { handleCloseModal } = useModalContext();
  const { lang, currentLangKey } = useLangContext();

  return (
    <Modal.Container
      className={className}
      variant='small'
      onClose={onClose ?? handleCloseModal}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Container>
        <div>
          <Modal.Header id='header'>
            <Modal.IconClose onClick={onClose ?? handleCloseModal} />
          </Modal.Header>

          <Header>
            {icon && icon}

            {title && <Modal.Title id='title'>{title}</Modal.Title>}
          </Header>

          <Modal.Content id='content'>
            <Content>{children}</Content>
          </Modal.Content>
        </div>

        {(cancelButtonProps?.onCancel || confirmButtonProps?.onConfirm) && (
          <Modal.Footer id='footer'>
            <Footer>
              {cancelButtonProps?.onCancel && (
                <SecondaryButton
                  size='small'
                  onClick={cancelButtonProps.onCancel}
                  disabled={cancelButtonProps.disabled}
                >
                  {cancelButtonProps.label ??
                    lang.global.cancel[currentLangKey]}
                </SecondaryButton>
              )}

              {confirmButtonProps?.onConfirm && (
                <DefaultButton
                  size='small'
                  onClick={confirmButtonProps.onConfirm}
                  disabled={confirmButtonProps.disabled}
                >
                  {confirmButtonProps.label ??
                    lang.global.confirm[currentLangKey]}
                </DefaultButton>
              )}
            </Footer>
          </Modal.Footer>
        )}
      </Container>
    </Modal.Container>
  );
}
