import { create } from 'zustand';

import { type IUseAuthContext } from './useAuthContext.types';

export const useAuthContext = create<IUseAuthContext>()((set, get) => ({
  company: null,
  isAuthenticated: false,
  user: null,
  outsourcedUserWithoutCard: false,
  isAuthenticating: false,
  outsourcedUser: null,
  hasErrorOnAuthenticate: false,

  setAuthenticationData: data => {
    set({
      company: data.company || null,
      user: data.user || null,
      isAuthenticated: data.isAuthenticated,
      outsourcedUserWithoutCard: data.outsourcedUserWithoutCard || false,
      outsourcedUser: data.outsourcedUser || null,
      hasErrorOnAuthenticate: data.hasErrorOnAuthenticate,
      isAuthenticating: data.isAuthenticating
    });
  }
}));
