export const featureFlagsKeys = {
  cardGroupsFlag: 'card_groups',
  analyticsPageFlag: 'analytics_page',
  redesignReportsFlag: 'reports_page',
  teamMembersFlag: 'team_members',
  fuelDashboard: 'admin_fuel_dashboard',
  redesignCompanyOnboarding: 'redesign_onboarding_kyb',
  redesignUserOnboarding: 'redesign_onboarding_kyc',
  newVersionTravelApprovalFlowsFlag: 'new_version_travel_approval_flows',
  spendingLimitsFlag: 'spending_limits',
  travelPolicyFlag: 'travel_policy',
  travelProductSearchFlag: 'travel_product_search',
  travelReportFlag: 'travel_report'
};
