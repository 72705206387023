import { tokens } from 'ds';
import styled from 'styled-components';

export const SuccessIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: ${tokens.borderRadiusCircular};
  background: ${tokens.feedbackColorSuccessLightest};
`;

export const SuccessIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  width: 4.8rem;
  background: ${tokens.feedbackColorSuccessPure};
  border-radius: ${tokens.borderRadiusCircular};

  svg {
    width: 2.5rem;
    height: 2.5rem;
    color: ${tokens.neutralColorHighPure};
  }
`;

export const Text = styled.p`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
`;

export const Title = styled.p`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightMedium};
`;

export const TitleMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetXxs};
`;
