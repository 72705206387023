import { useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { vexDomain } from 'data/config';
import { useAuthContext } from 'data/contexts';
import {
  type IUser,
  useGetAuthCardsCompany,
  useGetAuthCardsUser,
  useGetAuthTravelUser,
  useGetAuthUser,
  useGetOutsourcedUser,
  useGetOutsourcedUsersAndPermissions
} from 'data/modules/auth';

import { SplashScreen } from 'presentation/components/global/SplashScreen';

import { useGa4 } from 'shared/hooks/global';
import { CustomCookies } from 'shared/utils/global';

export function AuthenticationMiddleware(): JSX.Element | null {
  const [outsourcedUserWithoutCard, setOutsourcedUserWithoutCard] =
    useState(false);

  const { sendUser } = useGa4();

  const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';

  const [setAuthenticationData, isAuthenticating] = useAuthContext(
    useShallow(state => [state.setAuthenticationData, state.isAuthenticating])
  );

  /** Request para usuário autenticado no VExpenses */
  const {
    user,
    company,
    isFetchingAuthUser,
    isErrorAuthUser,
    isSuccessAuthUser
  } = useGetAuthUser();

  /* Engajamento do usuario set datalayer */
  if (user && company) {
    sendUser({
      userId: user.id,
      companyId: company.id,
      companyUuId: company.uuid
    });
  }

  /** Request para usuário autenticado em Viagens */
  const { travelUser, isFetchingTravelUser, isErrorTravelUser } =
    useGetAuthTravelUser({
      enabled: !!company?.parameters.usesTravels && isSuccessAuthUser
    });

  const isGetOutsourcedUserRequestEnabled =
    !!outsourcingUserUuid && !!user?.parameters.hasOutsourcingAccess;

  /** Request para usuário terceirizado */
  const { outsourcedUser, isFetchingOutsourcedUser } = useGetOutsourcedUser({
    userId: outsourcingUserUuid,
    enabled: isGetOutsourcedUserRequestEnabled && isSuccessAuthUser
  });

  /** Request para empresa autenticada em cartões */
  const { cardsCompany, cardsCompanyError, isFetchingCardsCompany } =
    useGetAuthCardsCompany({
      enabled:
        !isFetchingAuthUser &&
        !!company?.parameters.usesCards &&
        isSuccessAuthUser
    });

  /** Request para usuário autenticado em cartões */
  const { cardsUser, cardsUserError, isFetchingCardsUser } =
    useGetAuthCardsUser({
      enabled:
        !isFetchingAuthUser &&
        !!company?.parameters.usesCards &&
        !!cardsCompany?.isOnboardingFinished &&
        isSuccessAuthUser
    });

  /** Request permissionamento de usuário terceirizado */
  const { isFetchingOutsourcedUsersAndPermissions } =
    useGetOutsourcedUsersAndPermissions({
      enabled:
        (!isAuthenticating && user?.parameters.hasOutsourcingAccess) ?? false
    });

  const isError =
    isErrorAuthUser || cardsUserError || cardsCompanyError || isErrorTravelUser;

  const isLoading =
    isFetchingAuthUser ||
    isFetchingTravelUser ||
    isFetchingOutsourcedUser ||
    isFetchingCardsCompany ||
    isFetchingCardsUser ||
    isFetchingOutsourcedUsersAndPermissions;

  /** Seta dados de usuário autenticado */
  useEffect(() => {
    if (isSuccessAuthUser) {
      setAuthenticationData({
        company: company ? { ...company, cards: cardsCompany || null } : null,
        user: user
          ? { ...user, cards: cardsUser || null, travel: travelUser || null }
          : null,
        isAuthenticated: true,
        outsourcedUserWithoutCard,
        outsourcedUser: outsourcedUser || null,
        hasErrorOnAuthenticate: isError,
        isAuthenticating: isLoading
      });
    }
  }, [
    cardsCompany,
    cardsUser,
    company,
    isError,
    outsourcedUser,
    isSuccessAuthUser,
    outsourcedUserWithoutCard,
    travelUser,
    user,
    isLoading,
    setAuthenticationData
  ]);

  /** Remove usuário terceirizado quando necessário */
  useEffect(() => {
    if (
      !isFetchingAuthUser &&
      !isGetOutsourcedUserRequestEnabled &&
      !outsourcedUser &&
      !user?.parameters.usesCards &&
      !user?.parameters.cardsUserType
    ) {
      CustomCookies.remove('outsourcing');
      sessionStorage.removeItem('idUsuarioTerceirizado');

      setOutsourcedUserWithoutCard(true);
    }
  }, [
    isFetchingAuthUser,
    isGetOutsourcedUserRequestEnabled,
    outsourcedUser,
    user
  ]);

  /** Atualiza dados de parâmetros para terceirização */
  useEffect(() => {
    if (
      !isAuthenticating &&
      !!CustomCookies.get('outsourcing') &&
      !!user?.parameters.hasOutsourcingAccess &&
      outsourcedUser
    ) {
      const newOutsourcingParameters: Partial<IUser['parameters']> = {
        usesCards:
          outsourcedUser.user.parameters.usesCards ??
          outsourcedUser.user.parameters.sharedAccountsCards ??
          false
      };

      setAuthenticationData({
        company: company ? { ...company, cards: cardsCompany || null } : null,
        isAuthenticated: true,
        outsourcedUserWithoutCard,
        outsourcedUser: outsourcedUser || null,
        hasErrorOnAuthenticate: isError,
        isAuthenticating: isLoading,
        user: {
          ...user,
          parameters: {
            ...user.parameters,
            ...newOutsourcingParameters
          }
        }
      });
    }
  }, [
    cardsCompany,
    company,
    isAuthenticating,
    isError,
    isLoading,
    outsourcedUser,
    outsourcedUserWithoutCard,
    user,
    setAuthenticationData
  ]);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (isError) {
    window.location.href = `${vexDomain}/login`;

    return null;
  }

  return <Outlet />;
}
