import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  ul {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingXs};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: ${tokens.spacingNano};
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background: ${tokens.brandColorSecondaryLightest};

  svg {
    width: 2rem;
    height: 2rem;
    color: ${tokens.brandColorSecondaryPure};
  }
`;

export const Title = styled.h2`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 100%;
  letter-spacing: 0.75px;
`;

export const RuleWrapper = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingQuarck};
`;

export const RuleName = styled.h3`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
`;

export const RuleDescription = styled.p`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  line-height: 140%;
`;
