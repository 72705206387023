import { Modal, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type BookingValueStylesType } from './EmissionErrorModal.types';
import { type AddPrefixToType } from 'ds/types';

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${tokens.spacingXs};
  align-self: stretch;
  padding-right: 0;

  @media screen and (max-width: 480px) {
    width: 75vw;
  }
`;

export const WarningIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: ${tokens.borderRadiusCircular};
  background: ${tokens.feedbackColorErrorLightest};
`;

export const WarningIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  background: ${tokens.feedbackColorErrorPure};
  border-radius: ${tokens.borderRadiusCircular};

  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${tokens.neutralColorHighPure};
  }
`;

export const Text = styled.div`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
`;

export const BookingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingXs};
  width: 100%;
  max-height: 20rem;
  overflow: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }
`;

export const Booking = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BookingTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  svg {
    flex: none;
  }
`;

export const BookingTextContainer = styled.div`
  max-width: 75%;
  display: flex;
  flex-direction: row;
  column-gap: ${tokens.spacingNano};
  align-items: center;
  color: ${tokens.neutralColorLowDark};

  > svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const BookingValue = styled.div<AddPrefixToType<BookingValueStylesType>>`
  max-width: 25%;
  color: ${tokens.neutralColorLowMedium};
  text-align: right;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightMedium};
  line-height: 140%;
  letter-spacing: 0.75px;
  margin-left: 0.5rem;
  text-decoration: ${({ $lineThrough }) =>
    $lineThrough ? 'line-through' : 'none'};

  ${({ $boldText }) =>
    $boldText &&
    css`
      color: ${tokens.neutralColorLowDark};
      font-weight: ${tokens.fontWeightBold};
    `}
`;

export const BookingSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.8rem;
  width: 100%;
`;

export const BookingStatus = styled.span`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 140%;
  letter-spacing: 1px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: ${tokens.spacingNano};
`;
