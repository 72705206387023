import { tokens } from 'ds';

import { Icons } from 'shared/constants/global';

import { type IHotelTitleProps } from './HotelTitle.types';

import { Container, Text } from './HotelTitle.styles';

export function HotelTitle({
  withIcon = true,
  text,
  className
}: IHotelTitleProps): JSX.Element {
  return (
    <Container className={className}>
      {withIcon && (
        <Icons.SingleBedIcon
          className='bed-icon'
          color={tokens.neutralColorLowMediumLight}
        />
      )}
      <Text
        className='text'
        title={text}
      >
        {text}
      </Text>
    </Container>
  );
}
