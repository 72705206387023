import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingXs};

  .stepperContainer {
    > div div:last-child span {
      width: 34rem;
    }

    > div:first-child > div:first-child {
      &::before {
        flex-basis: calc(17rem - 1.2rem - 0.8rem);
      }
    }

    > div:last-child > div:first-child {
      &::after {
        flex-basis: calc(17rem - 1.2rem - 0.8rem);
      }
    }
  }
`;
