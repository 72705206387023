import { DefaultTable, tokens } from 'ds';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import {
  type PolicyTextStylesType,
  type TableRowStylesType
} from './TableContent.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  overflow-x: auto;

  table {
    th {
      white-space: nowrap;
      height: 6rem;
    }

    td {
      height: 5.6rem;
    }

    @media screen and (max-width: 1360px) {
      table-layout: auto;
    }
  }
`;

export const TableRow = styled(DefaultTable.Tr)<
  AddPrefixToType<TableRowStylesType>
>`
  &:hover {
    cursor: pointer;
  }

  ${({ $aboutToExpire }) =>
    $aboutToExpire &&
    css`
      td {
        background: ${tokens.feedbackColorWarningLightest};
      }

      &:hover {
        td {
          background: ${() =>
            darken(0.05, tokens.feedbackColorWarningLightest)};
        }
      }
    `}

  ${({ $expired }) =>
    $expired &&
    css`
      td {
        background: ${tokens.feedbackColorErrorLightest};
      }

      &:hover {
        td {
          background: ${() => darken(0.03, tokens.feedbackColorErrorLightest)};
        }
      }
    `}

  .tooltipContainer {
    white-space: pre-wrap;
  }
`;

export const DateWithIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${tokens.spacingInsetNano};
  align-items: center;

  svg {
    margin-top: 0.4rem;
  }
`;

export const PolicyText = styled.div<AddPrefixToType<PolicyTextStylesType>>`
  color: ${({ $outOfPolicy }) =>
    $outOfPolicy
      ? tokens.feedbackColorErrorMedium
      : tokens.feedbackColorSuccessMedium};
  font-weight: ${tokens.fontWeightRegular};
`;

export const BookingTypeIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: ${tokens.spacingInsetNano};
`;

export const EmptyTableContainer = styled.tr`
  width: 100%;

  td > div {
    height: 30rem;
    display: flex;
    flex-direction: column;
    row-gap: ${tokens.spacingInsetMdd};
    justify-content: center;
    align-items: center;
    align-self: auto;
  }
`;

export const EmptyTableText = styled.h2`
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  color: ${tokens.neutralColorLowMedium};
`;

export const TableSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingInsetXxs};
  width: 100%;
  padding: ${tokens.spacingInsetXxs};
  background: ${tokens.neutralColorHighPure};
  border-radius: 0 0 ${tokens.borderRadiusXs} ${tokens.borderRadiusXs};
`;
