import { lazy } from 'react';

const TravelDatesCalendarIcon = lazy(
  async () =>
    await import('./travel_dates_calendar.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneGlobeIcon = lazy(
  async () =>
    await import('./plane_globe.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplanePassengerRemoveCrossIcon = lazy(
  async () =>
    await import('./airplane_passenger_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageBagsIcon = lazy(
  async () =>
    await import('./baggage_bags.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaneRightSquareIcon = lazy(
  async () =>
    await import('./airplane_right_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaneRightCircleIcon = lazy(
  async () =>
    await import('./airplane_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlainAirportRoadIcon = lazy(
  async () =>
    await import('./plain_airport_road.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneTakeOffTimeIcon = lazy(
  async () =>
    await import('./plane_take_off_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PassportPagesIcon = lazy(
  async () =>
    await import('./passport_pages.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneTakeOffIcon = lazy(
  async () =>
    await import('./plane_take_off.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TravelCompassIcon = lazy(
  async () =>
    await import('./travel_compass.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneCheckTravelIcon = lazy(
  async () =>
    await import('./plane_check_travel.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BeachParasolUmbrellaSquareIcon = lazy(
  async () =>
    await import('./beach_parasol_umbrella_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PassportBoardingPassIcon = lazy(
  async () =>
    await import('./passport_boarding_pass.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaneSquareCopyIcon = lazy(
  async () =>
    await import('./airplane_square_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplanePlaneInsuranceRightShieldIcon = lazy(
  async () =>
    await import('./airplane_plane_insurance_right_shield.svg').then(
      module => ({
        default: module.ReactComponent
      })
    )
);
const BaggageIcon = lazy(
  async () =>
    await import('./baggage.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SunSeaSunriseIcon = lazy(
  async () =>
    await import('./sun_sea_sunrise.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirlineTicketsIcon = lazy(
  async () =>
    await import('./airline_tickets.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneIcon = lazy(
  async () =>
    await import('./plane.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PassportTravelOpenIcon = lazy(
  async () =>
    await import('./passport_travel_open.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageVerticalBigIcon = lazy(
  async () =>
    await import('./baggage_vertical_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobePinIcon = lazy(
  async () =>
    await import('./globe_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneTripInternationalIcon = lazy(
  async () =>
    await import('./plane_trip_international.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirlineTicketHandIcon = lazy(
  async () =>
    await import('./airline_ticket_hand.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PassengerAddPlusIcon = lazy(
  async () =>
    await import('./passenger_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneBoardingPassIcon = lazy(
  async () =>
    await import('./plane_boarding_pass.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneFlightCheckmarkIcon = lazy(
  async () =>
    await import('./plane_flight_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const IslandTravelUmbrellaIcon = lazy(
  async () =>
    await import('./island_travel_umbrella.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneSelectIcon = lazy(
  async () =>
    await import('./plane_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PassportGlobeTravelIcon = lazy(
  async () =>
    await import('./passport_globe_travel.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LocationSelectPinHandIcon = lazy(
  async () =>
    await import('./location_select_pin_hand.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDepartureDateIcon = lazy(
  async () =>
    await import('./calendar_departure_date.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PhotosTravelIcon = lazy(
  async () =>
    await import('./photos_travel.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplanePlaneBigIcon = lazy(
  async () =>
    await import('./airplane_plane_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageLineRightIcon = lazy(
  async () =>
    await import('./baggage_line_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapPointPinIcon = lazy(
  async () =>
    await import('./map_point_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaneCircleIcon = lazy(
  async () =>
    await import('./airplane_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PassportTicketTravelIcon = lazy(
  async () =>
    await import('./passport_ticket_travel.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapOpenLocationAddPlusIcon = lazy(
  async () =>
    await import('./map_open_location_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeLocationPinIcon = lazy(
  async () =>
    await import('./globe_location_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneClockIcon = lazy(
  async () =>
    await import('./plane_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SunBeachSquareIcon = lazy(
  async () =>
    await import('./sun_beach_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplanePlaneTripTravelIcon = lazy(
  async () =>
    await import('./airplane_plane_trip_travel.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneTakeOffClockIcon = lazy(
  async () =>
    await import('./plane_take_off_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AToBIcon = lazy(
  async () =>
    await import('./a_to_b.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaneSquareIcon = lazy(
  async () =>
    await import('./airplane_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TravelVacationHolidayUmbrellaIcon = lazy(
  async () =>
    await import('./travel_vacation_holiday_umbrella.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TravelEarthIcon = lazy(
  async () =>
    await import('./travel_earth.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaneSkyFlightIcon = lazy(
  async () =>
    await import('./airplane_sky_flight.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageCheckamrkSelectIcon = lazy(
  async () =>
    await import('./baggage_checkamrk_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapCompassIcon = lazy(
  async () =>
    await import('./map_compass.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneFlightCanceledIcon = lazy(
  async () =>
    await import('./plane_flight_canceled.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MobileAirplaneIcon = lazy(
  async () =>
    await import('./mobile_airplane.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BeachUmbrellaSquareIcon = lazy(
  async () =>
    await import('./beach_umbrella_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageCheckmarkSquareIcon = lazy(
  async () =>
    await import('./baggage_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplanePinLocationIcon = lazy(
  async () =>
    await import('./airplane_pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirlineTicketIcon = lazy(
  async () =>
    await import('./airline_ticket.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageSelectMinusIcon = lazy(
  async () =>
    await import('./baggage_select_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneBoardGateRightArrowIcon = lazy(
  async () =>
    await import('./plane_board_gate_right_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageCartIcon = lazy(
  async () =>
    await import('./baggage_cart.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const AirplaneCirleIcon = lazy(
  async () =>
    await import('./airplane_cirle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TravelEarthWorldDirectionIcon = lazy(
  async () =>
    await import('./travel_earth_world_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggageAddPlusIcon = lazy(
  async () =>
    await import('./baggage_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneInformationBoardIcon = lazy(
  async () =>
    await import('./plane_information_board.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlaneLandIcon = lazy(
  async () =>
    await import('./plane_land.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BrowserIcon = lazy(
  async () =>
    await import('./browser.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCursorLoactionDirectionIcon = lazy(
  async () =>
    await import('./pin_cursor_loaction_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BaggagePinIcon = lazy(
  async () =>
    await import('./baggage_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SecurityOfficerPassportIcon = lazy(
  async () =>
    await import('./security_officer_passport.svg').then(module => ({
      default: module.ReactComponent
    }))
);

export const Travel = {
  TravelDatesCalendarIcon,
  PlaneGlobeIcon,
  AirplanePassengerRemoveCrossIcon,
  BaggageBagsIcon,
  AirplaneRightSquareIcon,
  AirplaneRightCircleIcon,
  PlainAirportRoadIcon,
  PlaneTakeOffTimeIcon,
  PassportPagesIcon,
  PlaneTakeOffIcon,
  TravelCompassIcon,
  PlaneCheckTravelIcon,
  BeachParasolUmbrellaSquareIcon,
  PassportBoardingPassIcon,
  AirplaneSquareCopyIcon,
  AirplanePlaneInsuranceRightShieldIcon,
  BaggageIcon,
  SunSeaSunriseIcon,
  AirlineTicketsIcon,
  PlaneIcon,
  PassportTravelOpenIcon,
  BaggageVerticalBigIcon,
  GlobePinIcon,
  PlaneTripInternationalIcon,
  AirlineTicketHandIcon,
  PassengerAddPlusIcon,
  PlaneBoardingPassIcon,
  PlaneFlightCheckmarkIcon,
  IslandTravelUmbrellaIcon,
  PlaneSelectIcon,
  PassportGlobeTravelIcon,
  LocationSelectPinHandIcon,
  CalendarDepartureDateIcon,
  PhotosTravelIcon,
  AirplanePlaneBigIcon,
  BaggageLineRightIcon,
  MapPointPinIcon,
  AirplaneCircleIcon,
  PassportTicketTravelIcon,
  MapOpenLocationAddPlusIcon,
  GlobeLocationPinIcon,
  PlaneClockIcon,
  SunBeachSquareIcon,
  AirplanePlaneTripTravelIcon,
  PlaneTakeOffClockIcon,
  AToBIcon,
  AirplaneSquareIcon,
  TravelVacationHolidayUmbrellaIcon,
  TravelEarthIcon,
  AirplaneSkyFlightIcon,
  BaggageCheckamrkSelectIcon,
  MapCompassIcon,
  PlaneFlightCanceledIcon,
  MobileAirplaneIcon,
  BeachUmbrellaSquareIcon,
  BaggageCheckmarkSquareIcon,
  AirplanePinLocationIcon,
  AirlineTicketIcon,
  BaggageSelectMinusIcon,
  PlaneBoardGateRightArrowIcon,
  BaggageCartIcon,
  AirplaneCirleIcon,
  TravelEarthWorldDirectionIcon,
  BaggageAddPlusIcon,
  PlaneInformationBoardIcon,
  PlaneLandIcon,
  BrowserIcon,
  PinCursorLoactionDirectionIcon,
  BaggagePinIcon,
  SecurityOfficerPassportIcon
};
