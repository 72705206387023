import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type HeaderStylesType } from './TravelNewVersionApprovalFlowsMembers.types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding-top: ${tokens.spacingXs};
`;

export const Header = styled.div<AddPrefixToType<HeaderStylesType>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.spacingXs};

  button {
    white-space: nowrap;
  }

  ${({ $isEdditingName }) =>
    $isEdditingName &&
    css`
      align-items: start;
    `}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mid}px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};

  > div {
    display: flex;
    align-items: center;
    gap: ${tokens.spacingNano};

    > div > svg {
      color: ${tokens.brandColorPrimaryMedium};
      width: 2.8rem;
      height: 2.8rem;
      cursor: pointer;
    }
  }
`;

export const Message = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  line-height: 160%;
  letter-spacing: 0.75px;
`;

export const Title = styled.h2`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXs};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighLight};
  padding: ${tokens.spacingXxs};
  border-radius: ${tokens.borderRadiusMd};

  > nav {
    margin-bottom: 0.5rem;
  }
`;

export const NameNotFoundBannerWrapper = styled.div`
  padding-bottom: ${tokens.spacingMd};
`;
