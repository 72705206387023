import { type IOverviewProps } from './Overview.types';

import { Container } from './Overview.styles';

export function Overview({ children }: IOverviewProps): JSX.Element {
  return (
    <>
      <Container>{children}</Container>
    </>
  );
}
