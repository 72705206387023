import { tokens } from 'ds';
import styled from 'styled-components';

import { type TextStylesType } from './FlightPath.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.spacingQuarck};
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Line = styled.div`
  width: 100%;
  min-width: 0;
  border-top: 1px solid ${tokens.neutralColorHighDark};
  margin: 0 ${tokens.spacingNano};

  & + div {
    white-space: nowrap;
  }
`;

export const Text = styled.div<AddPrefixToType<TextStylesType>>`
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${({ $semibold }) =>
    $semibold ? tokens.fontWeightSemibold : tokens.fontWeightRegular};
  color: ${({ $lightColor }) =>
    $lightColor ? tokens.neutralColorLowMedium : tokens.neutralColorLowDark};
  line-height: 140%;
`;
