import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingQuarck};
  background: ${tokens.neutralColorHighPure};
  border-radius: ${tokens.borderRadiusSm};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighMedium};
`;

export const Title = styled.h3`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  color: ${tokens.neutralColorLowDark};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
`;

export const Text = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  color: ${tokens.neutralColorLowDark};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 135%;
  white-space: pre-line;
`;
