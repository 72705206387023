import { useState } from 'react';

import { useDsGa4 } from 'ds/hooks/globals';

import { CustomDate } from 'shared/utils/custom';

import {
  type DateRangeType,
  type DateType,
  type IUseControlledDatePicker,
  type IUseControlledDatePickerProps
} from './ControlledDatePicker.types';

export function useControlledDatePicker({
  selectsRange,
  onChangeValue,
  onChangeDateRange
}: IUseControlledDatePickerProps): IUseControlledDatePicker {
  const { sendDsGaEvent } = useDsGa4();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function handleChangeDate(
    value: DateType | DateRangeType,
    onChange?: (...event: unknown[]) => void
  ): void {
    if (!selectsRange) {
      const date = value as DateType;
      const dateValue = date ? CustomDate.formatDateToIso(date) : undefined;

      sendGaEvent(dateValue);
      onChangeValue?.(dateValue);

      // função onChange do react-hook-form. Caso seja undefined, passa string vazia.
      // Deve ser assim, porque o react-hook-form se perde com o valor undefined.
      onChange?.(dateValue === undefined ? '' : dateValue);
    }

    if (selectsRange) {
      const [start, end] = value as DateRangeType;
      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        const startDate = CustomDate.formatDateToIso(start);
        const endDate = CustomDate.formatDateToIso(end);

        sendGaEvent(`${startDate} - ${endDate}`);
        onChangeDateRange?.([startDate, endDate]);
      }

      if (!start || !end) {
        sendGaEvent(undefined);
        onChangeDateRange?.(undefined);
      }
    }
  }

  function sendGaEvent(value: string | undefined): void {
    sendDsGaEvent('components', 'date_picker', {
      description: 'click no dia',
      eventAction: 'date_picker_day',
      eventLabel: 'action_date_picker_day',
      eventValue: value
    });
  }

  function clearDateRange(): void {
    setStartDate(null);
    setEndDate(null);
    onChangeDateRange?.(undefined);
  }

  return {
    startDate,
    endDate,
    handleChangeDate,
    clearDateRange
  };
}
