import React from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import { env } from 'data/config';

import 'data/config/i18n.config';
import './main.scss';
import 'driver.js/dist/driver.css';

import App from './App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 1.0, //  Capturar 100% das transações
    // Setar 'tracePropagationTargets' para controlar para quais URLs a rastreabilidade distribuída deve ser habilitada
    tracePropagationTargets: [/api\.(stg\.)?vexpenses\.com/],
    // Replays de sessão
    replaysSessionSampleRate: 0.1, // Amostrar 10% das sessões
    replaysOnErrorSampleRate: 1.0 // Capturar 100% dos erros
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
