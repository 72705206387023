import { create } from 'zustand';

import { type IUseHotelSearchContext } from './useHotelSearchContext.types';

export const useHotelSearchContext = create<IUseHotelSearchContext>()(set => ({
  filters: {
    destination: null,
    checkIn: undefined,
    checkOut: undefined,
    breakfastIncluded: false
  },
  handleChangeFilters: newFilters => {
    set(state => ({
      filters: {
        ...state.filters,
        ...newFilters
      }
    }));
  },
  virtualFilters: {
    hotelsWithinPolicy: false,
    minPrice: null,
    maxPrice: null,
    orderBy: 'lowest_price',
    stars: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false
    }
  },
  handleChangeVirtualFilters: newFilters => {
    set(state => ({
      virtualFilters: {
        ...state.virtualFilters,
        ...newFilters
      }
    }));
  }
}));
