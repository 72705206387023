import { tokens } from 'ds/tokens';
import MaskedTextInput from 'react-text-mask';
import styled, { css } from 'styled-components';

import { type ControlledDatePickerStylesType } from './ControlledDatePicker.types';
import { type AddPrefixToType } from 'ds/types';

export const Legend = styled.legend`
  opacity: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
  max-width: 0.001rem;
  white-space: nowrap;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  letter-spacing: 0.075rem;

  p {
    opacity: 0;
    visibility: visible;
  }
`;

export const Wrapper = styled.div<
  AddPrefixToType<ControlledDatePickerStylesType>
>`
  width: 100%;
  position: relative;
`;

export const Fieldset = styled.fieldset`
  text-align: left;
  position: absolute;
  inset: -5px 0 0;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;
  padding: 0 ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusXs};
  border-color: ${tokens.neutralColorLowLight};
  border-width: ${tokens.borderWidthHairline};
  border-style: solid;
  top: -1rem;
  transition: border 100ms ease-in-out;
`;

export const DatePickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: ${tokens.spacingXxs};
  }
`;

export const DatePickerMonth = styled.h4`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontWeightBold};
  font-size: ${tokens.fontSizeXxs};
  line-height: 3.24rem;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
`;

export const DatePickerYear = styled.h4`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeXxs};
  line-height: 3.24rem;
  letter-spacing: 0.1rem;
`;

export const Container = styled.div<
  AddPrefixToType<ControlledDatePickerStylesType>
>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border 100ms ease-in-out;
  gap: ${tokens.spacingInsetNano};
  padding: ${tokens.spacingNano} ${tokens.spacingXs};
  min-width: 25.2rem;
  width: 100%;
  background-color: ${tokens.neutralColorHighPure};
  min-height: 5.2rem;
  max-height: 5.2rem;

  .react-datepicker-popper {
    z-index: 4;
  }

  * {
    letter-spacing: 0.075rem;
  }

  svg {
    width: 2.2rem;
    height: 2.2rem;
    opacity: 0.3;
    transition: opacity 100ms ease-in-out;
  }

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:focus-within {
    background-color: ${tokens.neutralColorHighPure};
    border-color: ${tokens.brandColorSecondaryPure};
    border-width: ${tokens.borderWidthThin};

    span {
      color: ${tokens.brandColorSecondaryPure};
      opacity: 1;
    }

    input {
      color: ${tokens.neutralColorLowDarkest};
    }

    svg {
      opacity: 1;
    }

    ~ ${Fieldset} {
      border-color: ${tokens.brandColorSecondaryPure};
      border-width: ${tokens.borderWidthThin};

      ${Legend} {
        opacity: 1;
        visibility: visible;
        max-width: unset;
        overflow: visible;
        padding: 0 ${tokens.spacingQuarck};
      }
    }
  }

  ${({ $isTouched }) =>
    $isTouched &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowMedium};
        border-width: ${tokens.borderWidthHairline};

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      input {
        color: ${tokens.neutralColorLowDarkest};
      }

      span,
      svg {
        opacity: 1;
      }
    `}

  ${({ $variant }) =>
    $variant === 'large' &&
    css`
      padding-top: ${tokens.spacingXxs} !important;
      padding-bottom: ${tokens.spacingXxs} !important;
      min-width: 25.2rem;

      input {
        font-size: ${tokens.fontSizeXs} !important;
      }

      span {
        font-size: ${tokens.fontSizeXxs};
      }

      ${DatePickerHeader} h4 {
        font-size: ${tokens.fontSizeSm};
      }

      min-height: 6.4rem;
      max-height: 6.4rem;
    `}

  ${({ $variant }) =>
    $variant === 'small' &&
    `
      min-height: 4.1rem;
      max-height: 4.1rem;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${tokens.neutralColorHighLight} !important;

      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowLightest} !important;
        border-width: ${tokens.borderWidthHairline} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      * {
        color: ${tokens.neutralColorLowMediumLight} !important;
      }

      span {
        opacity: 1;
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.feedbackColorErrorPure} !important;
        border-width: ${tokens.borderWidthThin} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      span,
      svg {
        color: ${tokens.feedbackColorErrorPure} !important;
        opacity: 1;
      }
    `}


  .customDatePicker {
    > button {
      line-height: 2.7rem;
      border-width: 7.2px;
    }

    border-radius: ${tokens.borderRadiusXs};
    border: 2px solid ${tokens.neutralColorHighDark};
    font-family: ${tokens.fontFamilyPoppins};

    .react-datepicker__header {
      border: none;
      background-color: ${tokens.deepColorWhite} !important;
      padding: 0;
      padding-top: ${tokens.spacingNano};
    }

    .react-datepicker__day--selected {
      color: ${tokens.brandColorPrimaryMedium} !important;
      background-color: ${tokens.brandColorSecondaryLightest};
      border: 1px solid ${tokens.brandColorPrimaryPure};
    }

    .react-datepicker__day--today {
      color: ${tokens.baseColorWhite} !important;
      background-color: ${tokens.brandColorPrimaryPure};
    }

    .react-datepicker__day {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 3.6rem;
      height: 3.6rem;
      color: ${tokens.neutralColorLowDark};
      font-size: ${tokens.fontSizeXxs};
      font-weight: ${tokens.fontWeightSemibold};
      font-family: ${tokens.fontFamilyPoppins};
      letter-spacing: 0.025rem;
      border-radius: ${tokens.borderRadiusCircular};
      padding: ${tokens.spacingInsetNano};
      text-align: center;

      ${({ $variant }) =>
        $variant === 'small' &&
        css`
          width: 3.2rem;
          height: 3.2rem;
          font-size: ${tokens.fontSizeXxss};
        `}

      ${({ $variant }) =>
        $variant === 'large' &&
        css`
          width: 4.4rem;
          height: 4.4rem;
          font-size: ${tokens.fontSizeXs};
        `}
    }

    .react-datepicker__day:hover {
      background-color: ${tokens.neutralColorHighMedium};
      border: 1px solid ${tokens.neutralColorLowLight};
      color: ${tokens.neutralColorLowDark} !important;
    }

    .react-datepicker__day--disabled {
      color: ${tokens.neutralColorHighDark};
    }

    .react-datepicker__day--disabled:hover {
      color: ${tokens.neutralColorHighDark} !important;
      position: relative;
      background: none !important;
      border: none !important;
    }

    .react-datepicker__day--disabled:hover::after {
      content: ${({ $tooltipDisabledDayMessage }) =>
        `'${$tooltipDisabledDayMessage}'`};
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333;
      color: #fff;
      padding: 0.5rem;
      border-radius: 4px;
      white-space: nowrap;
      font-size: 1.2rem;
      z-index: 1000;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range {
      background: ${tokens.brandColorSecondaryLightest};
      border: 1px solid ${tokens.brandColorPrimaryPure};
      color: ${tokens.brandColorPrimaryMedium} !important;

      &:hover {
        background: ${tokens.brandColorSecondaryLightest};
        color: ${tokens.brandColorSecondaryMedium} !important;
        border: 1px solid ${tokens.brandColorSecondaryMedium};
      }

      &:active {
        background: ${tokens.brandColorSecondaryLight};
        border: 1px solid ${tokens.brandColorSecondaryPure};
      }
    }

    .react-datepicker__day--in-range.react-datepicker__day--today,
    .react-datepicker__day--in-selecting-range.react-datepicker__day--today {
      color: ${tokens.brandColorSecondaryMedium} !important;
      border: 1px solid ${tokens.brandColorSecondaryMedium};
    }

    .react-datepicker__day--selected.react-datepicker__day--today {
      background: ${tokens.brandColorSecondaryLightest} !important;
      color: ${tokens.brandColorSecondaryMedium} !important;
      border: 1px solid ${tokens.brandColorSecondaryMedium} !important;
    }

    .react-datepicker__day-names {
      display: flex;
      box-sizing: border-box;
      width: 95%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;

      > div {
        width: 3.2rem;
        font-size: ${tokens.fontSizeXxss};
        line-height: 2.7rem;
        font-family: ${tokens.fontFamilyPoppins};
      }
    }

    .react-datepicker__current-month {
      font-size: ${tokens.fontSizeXxs};
      font-weight: ${tokens.fontWeightBold};
      font-family: ${tokens.fontFamilyPoppins};
    }

    .react-datepicker-time__caption {
      font-size: 1.1rem;
      font-family: ${tokens.fontFamilyPoppins};
    }

    ${({ $variant }) =>
      $variant === 'large' &&
      css`
        .react-datepicker__day-names {
          & > div {
            font-size: ${tokens.fontSizeXs} !important;
          }
        }
      `}

    ${({ $variant }) =>
      $variant === 'small' &&
      css`
        .react-datepicker__day-names {
          & > div {
            font-size: ${tokens.fontSizeXxss} !important;
          }
        }
      `}
  }

  .clearIconButton:hover {
    cursor: pointer;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const InputComponent = styled(MaskedTextInput)<
  AddPrefixToType<ControlledDatePickerStylesType>
>`
  border: none !important;
  outline: none !important;
  width: 100%;
  background-color: transparent !important;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  padding: ${tokens.spacingNano};
  line-height: ${tokens.spacingInsetMd};
  font-size: ${tokens.fontSizeXxs};

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      font-size: ${tokens.fontSizeXxss};
    `}
`;

export const Label = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  top: -1.5rem;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  padding: ${tokens.spacingQuarck};
`;
export const Caption = styled.span<
  AddPrefixToType<ControlledDatePickerStylesType>
>`
  display: inline-block;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${tokens.feedbackColorErrorPure} !important;
    `}
`;
