import { tokens } from 'ds';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 4fr;
  gap: ${tokens.spacingInsetMd};
  padding: ${tokens.spacingInsetXs} ${tokens.spacingInsetLg};
  background: ${tokens.deepColorWhite};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: minmax(0, 1fr);
  }
`;
