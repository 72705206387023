import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import {
  BookingStatuses,
  ProcessingStatuses
} from 'data/modules/travel/global';

import {
  type BadgeTagStylesType,
  type BookingCardStylesType,
  type TagStylesType
} from './BookingCard.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<BookingCardStylesType>>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${tokens.spacingNano};
  width: 100%;
  background: ${tokens.deepColorWhite};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};

  > div {
    width: 100%;
  }

  &:hover {
    cursor: pointer;

    input[type='checkbox'] ~ div {
      border-color: ${tokens.brandColorSecondaryPure};
    }
  }

  .bookingCardCheckbox {
    padding: 0;

    input[type='checkbox'] ~ div {
      border-width: 2px;
      padding: 1.1rem;
      border-radius: 6px;
    }

    input[type='checkbox']:checked ~ div {
      padding: 0.6rem;

      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      background: ${tokens.neutralColorHighLight};

      &:hover {
        cursor: default;
      }
    `}

  /* ${({ $status }) =>
    $status === BookingStatuses.APROVADA && // ABOUT_TO_EXPIRE
    css`
      outline: 1px solid ${tokens.feedbackColorWarningLight};
      border-color: ${tokens.feedbackColorWarningLight};
    `} */

  /* ${({ $status }) =>
    $status === BookingStatuses.APROVADA && // NEEDS_REPRICING
    css`
      outline: 1px solid ${tokens.feedbackColorErrorLight};
      border-color: ${tokens.feedbackColorErrorLight};

      &:hover {
        cursor: default;
      }
    `} */

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      outline: 2px solid ${tokens.brandColorPrimaryPure};
      border-color: ${tokens.brandColorPrimaryPure};
    `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.h3`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
  letter-spacing: 0.075rem;
`;

export const CheckboxBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${tokens.spacingNano};
  align-items: center;

  /* > div:nth-child(2) {
    padding: 0;
  } */
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingNano};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
  border-bottom: 1px solid ${tokens.neutralColorHighDark};
  padding-bottom: ${tokens.spacingXxs};

  > div {
    display: flex;
    align-items: center;
    column-gap: ${tokens.spacingQuarck};
  }
`;

export const InfoTitle = styled.div`
  flex-wrap: wrap;
  row-gap: ${tokens.spacingMini};
  color: ${tokens.neutralColorLowDarkest};
  text-overflow: ellipsis;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
  letter-spacing: 0.01rem;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 0.3rem;
  }

  > div {
    display: flex;
    align-items: center;
    column-gap: ${tokens.spacingQuarck};
  }
`;

export const InfoSearchFilters = styled.div`
  > div {
    display: flex;
  }

  .tooltipContainer {
    white-space: pre-wrap;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${tokens.spacingNano};
  row-gap: ${tokens.spacingQuarck};
  padding: ${tokens.spacingNano} 0;
`;

export const Tag = styled.div<AddPrefixToType<TagStylesType>>`
  display: flex;
  flex-direction: row;
  column-gap: ${tokens.spacingQuarck};
  align-items: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 140%;
  letter-spacing: 0.025rem;

  > svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  ${({ $type }) =>
    $type === 'OUT_OF_POLICY' &&
    css`
      color: ${tokens.feedbackColorErrorMedium};
    `}

  ${({ $type }) =>
    $type === 'WITHIN_POLICY' &&
    css`
      color: ${tokens.feedbackColorSuccessMedium};
    `}

  ${({ $type }) =>
    $type === 'BEST_PRICE' &&
    css`
      color: ${tokens.supportsColorLowDark};
    `}
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingQuarck};
  border-top: 1px solid ${tokens.neutralColorHighDark};
  padding-top: ${tokens.spacingXxs};
  padding-bottom: ${tokens.spacingNano};

  h3 {
    color: ${tokens.neutralColorLowDarkest};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxs};
    font-weight: ${tokens.fontWeightSemibold};
    line-height: 140%;
    letter-spacing: 0.01rem;
  }

  p {
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamiliesPoppins};
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightRegular};
    line-height: 1.8rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

export const PriceText = styled.div`
  text-align: right;
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightBold};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;

  span {
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightRegular};
    line-height: 140%;
  }
`;

export const BadgeTag = styled.div<AddPrefixToType<BadgeTagStylesType>>`
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2.2rem;
  letter-spacing: 0.25px;
  padding: 0.1rem ${tokens.spacingNano};
  border-radius: ${tokens.borderRadiusXs};

  ${({ $status }) =>
    $status === BookingStatuses.AGUARDANDO_APROVACAO &&
    css`
      display: none;
    `}

  /* ${({ $status }) =>
    $status === BookingStatuses.APROVADA && // ABOUT_TO_EXPIRE
    css`
      color: ${tokens.feedbackColorWarningMedium};
      border: 2px solid ${tokens.feedbackColorWarningPure};
    `}

  ${({ $status }) =>
    $status === BookingStatuses.APROVADA && // NEEDS_REPRICING
    css`
      color: ${tokens.feedbackColorErrorMedium};
      border: 2px solid ${tokens.feedbackColorErrorPure};
    `} */

  ${({ $status, $processingStatus }) =>
    ($status === BookingStatuses.EXPIRADA ||
      $status === BookingStatuses.REPROVADA ||
      $status === BookingStatuses.FALHA ||
      $processingStatus === ProcessingStatuses.AGUARDANDO_PAGAMENTO) &&
    css`
      color: ${tokens.feedbackColorErrorMedium};
      border: 2px solid ${tokens.feedbackColorErrorPure};
    `}


  ${({ $status, $processingStatus }) =>
    ($processingStatus === ProcessingStatuses.EMITIDO_SUCESSO ||
      $status === BookingStatuses.APROVADA) &&
    css`
      color: ${tokens.feedbackColorSuccessPure};
      border: 2px solid ${tokens.feedbackColorSuccessPure};
    `}
`;

export const ExpirationDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${tokens.spacingXxs} 0;
  border-top: 1px solid ${tokens.neutralColorHighDark};

  :first-child {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightSemibold};
    color: ${tokens.neutralColorLowDark};
    line-height: 140%;
    letter-spacing: 1px;
  }

  :last-child {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightMedium};
    color: ${tokens.feedbackColorErrorMedium};
  }
`;
