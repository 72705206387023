export enum RoutesPrefix {
  EXPENSES = '/despesas',
  CARDS = '/cartoes',
  ANALYSIS = '/analises',
  STORE = '/store',
  SUPPORT = '/suporte',
  REPORTS = '/relatorios',
  SETTINGS = '/settings',
  ADMIN = '/admin',
  TRAVELS = '/viagens'
}

export enum ExpensesRoutes {
  EXPENSES = RoutesPrefix.EXPENSES,
  EXPENSES_ID = '/:id'
}

export enum CardsRoutes {
  CARDS_WEBVIEW = '/webview/cartoes/*',
  CARDS_USER_ONBOARDING = 'onboarding/usuario',
  COMPANY_ONBOARDING = 'onboarding/empresa',
  COMPANY_ONBOARDING_BEGIN = 'onboarding/empresa/inicio',
  COMPANY_ONBOARDING_1 = 'onboarding/empresa/1',
  COMPANY_ONBOARDING_2 = 'onboarding/empresa/2',
  COMPANY_ONBOARDING_3 = 'onboarding/empresa/3',
  COMPANY_ONBOARDING_3_AUTHORIZATION = 'onboarding/empresa/3/autorizacao',
  COMPANY_ONBOARDING_3_REPRESENTATIVES = 'onboarding/empresa/3/representantes',
  COMPANY_ONBOARDING_3_APPROVAL_REPRESENTATIVES = 'onboarding/empresa/3/aprovacao-representantes',
  COMPANY_ONBOARDING_4 = 'onboarding/empresa/4',
  COMPANY_ONBOARDING_5 = 'onboarding/empresa/5',
  COMPANY_ONBOARDING_6 = 'onboarding/empresa/6',
  COMPANY_ONBOARDING_7 = 'onboarding/empresa/7',
  COMPANY_ONBOARDING_7_REPRESENTATIVE_TYPE = 'onboarding/empresa/7/tipo-representante',
  COMPANY_ONBOARDING_7_FP_CLASSIFICATION = 'onboarding/empresa/7/classificacao-pessoa-fisica',
  COMPANY_ONBOARDING_7_FP_DATA = 'onboarding/empresa/7/dados-pessoa-fisica',
  COMPANY_ONBOARDING_7_JP_DATA = 'onboarding/empresa/7/dados-pessoa-juridica',
  COMPANY_ONBOARDING_8 = 'onboarding/empresa/8',
  COMPANY_ONBOARDING_9 = 'onboarding/empresa/9',
  COMPANY_ONBOARDING_10 = 'onboarding/empresa/10',
  COMPANY_ONBOARDING_11 = 'onboarding/empresa/11',
  COMPANY_ONBOARDING_12 = 'onboarding/empresa/12',
  COMPANY_ONBOARDING_13 = 'onboarding/empresa/13',
  COMPANY_ONBOARDING_FINAL = 'onboarding/empresa/final',
  COMPANY_ONBOARDING_IN_PROGRESS = 'onboarding/empresa/em-progresso',
  COMPANY_ONBOARDING_KYC = 'onboarding/empresa/kyc',
  COMPANY_ONBOARDING_APPROVE = 'onboarding/empresa/approve',
  ONBOARDING_APPROVE = 'onboarding/approve',
  ONBOARDING_REDIRECT = 'redirect/onboarding',
  USER_ONBOARDING = 'onboarding/usuario',
  USER_ONBOARDING_BEGIN = 'onboarding/usuario/inicio',
  USER_ONBOARDING_1 = 'onboarding/usuario/1',
  USER_ONBOARDING_2 = 'onboarding/usuario/2',
  USER_ONBOARDING_3 = 'onboarding/usuario/3',
  USER_ONBOARDING_FINAL = 'onboarding/usuario/final',
  USER_ONBOARDING_KYC = 'onboarding/usuario/kyc',
  CARDS_MANAGEMENT = 'gestao',
  CARDS_CARD_GROUPS = 'grupos-de-cartoes',
  CARDS_CARD_GROUPS_GROUP = 'gestao-de-grupos/:groupId',
  CARDS_CARD_GROUPS_VALUE_ALLOCATION = 'gestao-de-grupos/:groupId/alocacao-de-valores',
  CARDS_CARD_GROUPS_VALUE_SOLICITATION = 'gestao-de-grupos/:groupId/solicitacoes-de-valores',
  CARDS_CARD_GROUPS_MOVEMENTS = 'gestao-de-grupos/:groupId/movimentacoes',
  CARDS_CARD_GROUPS_STATEMENT = 'gestao-de-grupos/:groupId/movimentacoes/:userId',
  CARDS_VALUE_SOLICITATION = 'solicitacoes-de-valores',
  CARDS_BALANCE_SOLICITATION = 'solicitacoes-de-saldo',
  CARDS_VALUE_ALLOCATION = 'alocacao-de-valores',
  CARDS_BALANCE_ALLOCATION = 'alocacao-de-saldo',
  CARDS_MOVEMENTS = 'movimentacoes',
  CARDS_MOVEMENTS_BALANCE_ID = 'movements/:balanceId',
  CARDS_MY_CARD = 'meu-cartao',
  CARDS_MY_CARD_MY_MOVEMENTS = 'meu-cartao/minhas-movimentacoes/:userId',
  CARDS_MY_CARD_MY_SOLICITATIONS = 'meu-cartao/minhas-solicitacoes',
  CARDS_BEGIN = 'inicio',
  CARDS_REDIRECT = `${RoutesPrefix.CARDS}`
}

export enum AnalysisRoutes {
  ANALYSIS = `${RoutesPrefix.ANALYSIS}`,
  ANALYSIS_RESUME = 'resumo',
  ANALYSIS_SUGGESTION = 'sugestao',
  ANALYSIS_EXPENSES_PER_USER = 'despesas-por-usuario',
  ANALYSIS_EXPENSES_PER_PROJECT = 'despesas-por-projeto'
}

export enum StoreRoutes {
  STORE = `${RoutesPrefix.STORE}`,
  STORE_CARDS = 'cards',
  STORE_CARDS_PJ = 'cards-pj',
  STORE_HORUS = 'horus',
  STORE_CONCILIATION = 'conciliation',
  STORE_OUTSOURCING = 'outsourcing',
  STORE_ACTIVE_DIRECTORY = 'active-directory',
  STORE_FUEL = 'fuel'
}

export enum SupportRoutes {
  SUPPORT = `${RoutesPrefix.SUPPORT}`
}

export enum ReportsRoutes {
  REPORTS = `${RoutesPrefix.REPORTS}`,
  REPORTS_REPORT = ':id'
}

export enum SettingRoutes {
  SETTINGS_EMAIL_PREFERENCES = 'preferencias-de-email',
  SETTINGS_TEAM_MEMBERS = 'membros-de-equipe',
  SETTINGS_CARDS_PJ = 'cartoes/cartoes-empresariais',
  SETTINGS_CARDS_PJ_ACCOUNT_ID = 'cartoes/cartoes-empresariais/:accountId',
  SETTINGS_CARDS_SHAREHOLDERS = 'cartoes/portadores-pj',
  SETTINGS_CARDS_TARIFFS = 'cartoes/tarifas',
  SETTINGS_CARDS_TERMS_OF_USE = 'cartoes/termos-de-uso',
  SETTINGS_FUEL_VEHICLES = 'combustivel/veiculos',
  SETTINGS_FUEL_VEHICLES_ID = 'combustivel/veiculos/:id',
  SETTINGS_FUEL_POLICIES = 'combustivel/politicas'
}

export enum AdminRoutes {
  FUEL_DASHBOARD = 'dashboard-combustivel',
  FUEL_DASHBOARD_BLOCKED_USERS = 'dashboard-combustivel/usuarios-bloqueados',
  FUEL_DASHBOARD_USERS_LESS_COMPLIENT = 'dashboard-combustivel/usuarios-menos-conformes',
  FUEL_DASHBOARD_EXPENSES_WITH_ALERT = 'dashboard-combustivel/despesas-com-alerta',
  TRAVEL_REPORT = 'relatorio-de-viagem'
}
