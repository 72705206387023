import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  width: 100%;
  min-width: 0;
  padding: ${tokens.spacingInsetXs};
  background: ${tokens.deepColorWhite};
  border-radius: ${tokens.borderRadiusMd};
  border: 1px solid ${tokens.neutralColorHighDark};

  &:hover {
    cursor: pointer;
    border-color: ${tokens.brandColorSecondaryPure};
  }
`;

export const Title = styled.h3`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: ${tokens.spacingNano};
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingNano};
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: ${tokens.spacingNano};
  overflow-x: auto;
  padding-top: 0.3rem;
  margin-top: -0.3rem;
`;

export const PeriodText = styled.div`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  line-height: 140%;
`;

export const CountdownText = styled.div`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  line-height: 140%;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-self: end;
`;
