import { tokens } from 'ds';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingInsetXs};
  margin-top: ${tokens.spacingInsetMdd};
`;

export const LoadingMessage = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  color: ${tokens.neutralColorLowDark};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  animation: ${animation} 5s linear infinite;
`;

export const SpinnerContainer = styled.div`
  flex-shrink: 0;
`;
