import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  font-family: ${tokens.fontFamilyPoppins};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: ${tokens.deepColorWhite};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};
`;

export const Title = styled.h2`
  color: ${tokens.neutralColorLowDarkest};
  text-overflow: ellipsis;
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
  letter-spacing: 1px;

  > span {
    display: block;
    color: ${tokens.neutralColorLowDark};
    text-overflow: ellipsis;
    font-size: ${tokens.fontSizeXxss};
    font-weight: ${tokens.fontWeightMedium};
    line-height: 140%;
    letter-spacing: 1px;
    margin-top: ${tokens.spacingNano};
  }
`;

export const BookingList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
  width: 100%;
  margin-top: ${tokens.spacingXs};
  margin-bottom: ${tokens.spacingNano};
  padding-bottom: ${tokens.spacingNano};
  border-bottom: 1px solid ${tokens.neutralColorHighDark};
`;

export const BookingRow = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${tokens.neutralColorLowDark};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  letter-spacing: 1px;

  > span {
    color: ${tokens.neutralColorLowMedium};
    font-weight: ${tokens.fontWeightMedium};
    letter-spacing: 0.75px;
    margin-left: ${tokens.spacingNano};
  }
`;

export const BookingRowAndLoadingContainer = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetMini};
`;

export const TotalValue = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${tokens.neutralColorLowDark};
  font-size: ${tokens.fontSizeXxss};
  line-height: 140%;
  font-weight: ${tokens.fontWeightSemibold};

  > span {
    color: ${tokens.neutralColorLowDarkest};
    font-size: ${tokens.fontSizeXxs};
    letter-spacing: 0.75px;
    margin-left: ${tokens.spacingNano};
  }
`;
