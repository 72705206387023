import { tokens } from 'ds';
import styled from 'styled-components';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingNano};

  > svg {
    min-width: 2.4rem;
    min-height: 2.4rem;
  }
`;

export const Title = styled.h1`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightBold};
  line-height: 140%;
  letter-spacing: 0.075rem;
  color: ${tokens.neutralColorLowDarkest};
`;

export const JustifyText = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  letter-spacing: 0.25px;
  color: ${tokens.neutralColorLowDarkest};
`;
