import { DSIcons, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${tokens.deepColorWhite};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.spacingNano};
  padding-bottom: ${tokens.spacingNano};

  > svg {
    min-width: 2.4rem;
    min-height: 2.4rem;
  }
`;

export const StyledWarningOutlineIcon = styled(DSIcons.WarningOutlineIcon)`
  width: 2rem;
  color: ${tokens.feedbackColorErrorMedium};
`;

export const Title = styled.h1`
  color: ${tokens.feedbackColorErrorMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 184%;
  letter-spacing: 0.075rem;
`;

export const Text = styled.p`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightMedium};
`;
