import { Alert, Modal, tokens } from 'ds';
import styled from 'styled-components';

export const StyledModalContainer = styled(Modal.Container)`
  @media screen and (max-width: 1300px) {
    max-width: 80rem;
    width: 80rem;
  }

  @media screen and (max-width: 850px) {
    width: 50rem;
    max-width: 50rem;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const StyledModalHeader = styled(Modal.Header)`
  margin-bottom: ${tokens.spacingMd};
`;

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingMd};
  padding-right: 0;
  max-height: 68vh;

  > div {
    justify-content: start;
  }

  .stepper-container {
    > .first-stepper-item {
      > .number-container::before {
        display: none;
      }

      > .label-container {
        > span {
          width: fit-content;
        }
      }
    }

    > .second-stepper-item {
      > .number-container::after {
        display: none;
      }

      > .label-container {
        > span {
          width: fit-content;
        }
      }
    }
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${tokens.spacingMd};
`;

export const StyledAlert = styled(Alert)`
  > div > div {
    align-items: start;
  }

  svg {
    flex-shrink: 0;
  }
`;
