import { Overview as OverviewContainer } from './Overview.component';

import {
  HeaderActionButtonsContainer,
  HeaderCardsContainer,
  HeaderContainer
} from './Overview.styles';

export const Overview = {
  Container: OverviewContainer,
  Header: HeaderContainer,
  HeaderCards: HeaderCardsContainer,
  HeaderButtons: HeaderActionButtonsContainer
};
