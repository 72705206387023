import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${tokens.spacingMd};
  gap: ${tokens.spacingXs};
  width: min(80vw, 73.6rem);
`;

export const SelectAllContainer = styled.div<{ $disabled?: boolean }>`
  align-self: flex-start;
  display: flex;
  align-items: center;
  min-width: 0;

  > span {
    color: ${({ $disabled }) =>
      $disabled
        ? tokens.neutralColorHighMedium
        : tokens.neutralColorLowLightest};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    line-height: 132%;
    letter-spacing: 0.25px;
  }
`;

export const UsersListContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 35vh;
  overflow-y: scroll;

  > li {
    display: flex;
    justify-content: start;
    overflow: hidden;

    > div {
      min-width: 0;
    }

    > div > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @media screen and (max-height: 700px) {
    max-height: 25vh;
  }
`;

export const SkeletonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${tokens.spacingNano};
`;
