import { type PeriodType } from 'data/modules/travel/bookingDetails';

import { type FlightSegment } from './Flight.types';
import { type TravelProducts } from './TravelProducts.types';

export enum BookingStatuses {
  CRIADA = 'CRIADA',
  AGUARDANDO_APROVACAO = 'AGUARDANDO_APROVACAO',
  APROVADA = 'APROVADA',
  FALHA = 'FALHA',
  EXPIRADA = 'EXPIRADA',
  REPROVADA = 'REPROVADA',
  CANCELADA = 'CANCELADA'
}

export enum ProcessingStatuses {
  AGUARDANDO_APROVACAO = 'AGUARDANDO_APROVACAO',
  AGUARDANDO_PAGAMENTO = 'AGUARDANDO_PAGAMENTO',
  EMITIDO_SUCESSO = 'EMITIDO_SUCESSO',
  FALHA_PAGAMENTO = 'FALHA_PAGAMENTO',
  FALHA_PROCESSAMENTO = 'FALHA_PROCESSAMENTO',
  FALHA_CANCELAMENTO = 'FALHA_CANCELAMENTO',
  AGUARDANDO_CANCELAMENTO = 'AGUARDANDO_CANCELAMENTO',
  CANCELADO = 'CANCELADO',
  EXPIRADO = 'EXPIRADO',
  CONFIRMADO = 'CONFIRMADO',
  PRECO_ATUALIZADO = 'PRECO_ATUALIZADO'
}

export interface IBooking {
  id: string;
  product: TravelProducts;
  status: BookingStatuses;
  processingStatus: ProcessingStatuses;
  totalPrice: number;
  flagBestPrice: boolean;
  flagPolicyRules: boolean;
  filters: string[] | null;
  isAboutToExpire: boolean;
}

export interface IBookingHotel extends IBooking {
  product: TravelProducts.HOTEL;
  hotel: string;
  checkIn: Date;
  checkOut: Date;
  days: number;
  averagePerDay: number;
  expirationDate: Date | null;
}

export interface IBookingFlight extends IBooking {
  product: TravelProducts.AEREO;
  origin: string;
  destination: string;
  departureDate: Date;
  departureStopovers: number;
  arrivalDate: Date | null;
  arrivalStopovers: number | null;
  segmentType: FlightSegment;
}

export interface IPersistenceBooking {
  id: string;
  product: TravelProducts;
  status: BookingStatuses;
  processing_status: ProcessingStatuses;
  total_price: number;
  search_filters: IPersistenceBookingFilters;
  flag_best_price: boolean;
  flag_policy_rules: boolean;
}

export interface IPersistenceBookingHotel extends IPersistenceBooking {
  product: TravelProducts.HOTEL;
  hotel: string;
  check_in: string;
  check_out: string;
  daily_count: number;
  price_day: number;
  cancelation_date_limit?: string;
}

export interface IPersistenceBookingFlight extends IPersistenceBooking {
  product: TravelProducts.AEREO;
  origin: string;
  destination: string;
  departure_date: string;
  arrival_date: string;
  departure_stop_amount: number;
  return_stop_amount: number;
  segment_type: FlightSegment;
}

export interface IPersistenceBookingFilters {
  origin?: string;
  destination?: string;
  departure_date?: string;
  return_date?: string;
  departure_period?: PeriodType;
  return_period?: null;
  direct_flight?: boolean;
  with_luggage?: boolean;
  checkIn?: string;
  checkOut?: string;
  onlyWithBreakfest?: boolean;
  distance?: number;
}
