import {
  type MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { vexDomain } from 'data/config';
import { useAuthContext, useFlagsContext } from 'data/contexts';
import { useGetOutsourcedUsersAndPermissions } from 'data/modules/auth';

import { cookiesKeys, Icons } from 'shared/constants/global';
import { sidebarRoutes } from 'shared/constants/global/sidebarRoutes.constant';
import { CustomCookies } from 'shared/utils/global';

import {
  type IRoutes,
  type ISidebarContainerProps,
  type IUseSidebarContainer
} from './SidebarContainer.types';

export function useSidebarContainer({
  isCollapsed,
  onSetIsCollapsed
}: ISidebarContainerProps): IUseSidebarContainer {
  const { pathname: route } = useLocation();
  const [user, company, outsourcedUser, isAuthenticating] = useAuthContext(
    useShallow(state => [
      state.user,
      state.company,
      state.outsourcedUser,
      state.isAuthenticating
    ])
  );
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { redesignReportsFlag } = useFlagsContext();
  const { travelReportFlag } = useFlagsContext();

  const firstRender = useRef(true);

  const accountManager = user?.parameters.cardsUserType === 'ACCOUNT_MANAGER';
  const isAdmin = user?.userType === 'ADMINISTRADOR';
  const isUser = user?.userType === 'USUARIO';
  const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';
  const userHasCompanies = !!(
    user?.userCompanies && user?.userCompanies?.length > 0
  );

  const {
    outsourcedUsersAndPermissions,
    isFetchingOutsourcedUsersAndPermissions
  } = useGetOutsourcedUsersAndPermissions({
    enabled: user?.parameters.hasOutsourcingAccess
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  const createAllowedRoutes = (routes: (IRoutes | null)[]): IRoutes[] => {
    return routes.filter(route => route !== null) as IRoutes[];
  };

  const checkPermission = (
    permissions: string[],
    requiredPermission: string
  ): boolean => permissions.includes(requiredPermission);

  const getRoute = (
    condition: boolean,
    routeObject: IRoutes,
    alternativeRoute?: IRoutes
  ): IRoutes | null => {
    return condition ? routeObject : alternativeRoute || null;
  };

  const expenseRoute: IRoutes = useMemo(() => {
    const showOldExpensesPage = CustomCookies.get(
      cookiesKeys.redesignExpensesOldVersion
    );

    const usesRedesignExpenses = company?.parameters.usesRedesignExpenses;

    const isExpensesOldVersionAllowed =
      company?.parameters.isExpensesOldVersionAllowed;

    if (
      usesRedesignExpenses === null ||
      (isExpensesOldVersionAllowed && showOldExpensesPage === 'true')
    ) {
      return {
        name: 'expenses',
        icon: Icons.ExpensesIcon,
        route: `${vexDomain}/despesas`,
        isReactRoute: false
      };
    }

    return {
      name: 'expenses',
      icon: Icons.ExpensesIcon,
      route: '/despesas',
      isReactRoute: true
    };
  }, [
    company?.parameters.usesRedesignExpenses,
    company?.parameters.isExpensesOldVersionAllowed
  ]);

  const availableRoutes = useMemo(() => {
    const isUserApprover = !!user?.userApproves;
    const isTravelApprover = !!user?.travel?.isApprover;

    if (outsourcingUserUuid) {
      if (
        outsourcedUser &&
        !isFetchingOutsourcedUsersAndPermissions &&
        outsourcedUsersAndPermissions
      ) {
        const outsourcedPermissions =
          outsourcedUsersAndPermissions?.find(
            ({ directorUuid }) => directorUuid === outsourcedUser?.user.uuid
          )?.permissions || [];

        const conditionToRedirectToCardsReactRoute =
          (outsourcedPermissions.includes('CARDS') &&
            company?.parameters.usesCards) ||
          user?.cards?.isCardGroupManagerWithoutAccount;

        const routes = [
          getRoute(
            checkPermission(outsourcedPermissions, 'EXPENSES'),
            expenseRoute
          ),
          getRoute(
            checkPermission(outsourcedPermissions, 'REPORTS') ||
              checkPermission(
                outsourcedPermissions,
                'SEND_REPORT_FOR_APPROVAL'
              ),
            {
              ...sidebarRoutes.reports,
              route: redesignReportsFlag
                ? sidebarRoutes.reports.route
                : `${vexDomain}/relatorios`,
              isReactRoute: redesignReportsFlag
            }
          ),
          getRoute(
            checkPermission(outsourcedPermissions, 'CONCILIATION') &&
              !!company?.parameters.usesInvoiceConciliation,
            sidebarRoutes.conciliations
          ),
          getRoute(
            checkPermission(outsourcedPermissions, 'WITHDRAWALS_ADVANCE') &&
              !!company?.parameters.usesCheckingAccount,
            sidebarRoutes.withdraw
          ),
          getRoute(isAdmin || !!conditionToRedirectToCardsReactRoute, {
            ...sidebarRoutes.cards,
            route: conditionToRedirectToCardsReactRoute
              ? sidebarRoutes.cards.route
              : `${vexDomain}/cards-preview-index`,
            isReactRoute: conditionToRedirectToCardsReactRoute
              ? sidebarRoutes.cards.isReactRoute
              : false,
            showPreviewIndicator: !conditionToRedirectToCardsReactRoute
          })
        ];

        return createAllowedRoutes(routes.filter(Boolean));
      }
      return [];
    }

    const conditionToRedirectToCardsReactRoute =
      (company?.parameters.usesCards &&
        (!!user?.parameters.usesCards ||
          (user && user?.cards?.type !== 'USER') ||
          !!user?.parameters.cardsUserType)) ||
      user?.cards?.isCardGroupManagerWithoutAccount ||
      accountManager;

    function showApprovalSubmenu(): boolean {
      let count = 0;

      if (isAdmin || isUserApprover) {
        count++;
      }

      if (accountManager) {
        count++;
      }

      if (isTravelApprover) {
        count++;
      }

      return count > 1;
    }

    function getApprovalRoute(): string {
      if (isAdmin || isUserApprover) return sidebarRoutes.approvals.route;

      if (accountManager) return sidebarRoutes.approvalsRequest.route;

      if (isTravelApprover) return sidebarRoutes.travel.route;

      return '';
    }

    const routes = [
      getRoute(isAdmin, sidebarRoutes.home),
      getRoute(true, expenseRoute),
      getRoute(true, {
        ...sidebarRoutes.reports,
        route: redesignReportsFlag
          ? sidebarRoutes.reports.route
          : `${vexDomain}/relatorios`,
        isReactRoute: redesignReportsFlag
      }),
      getRoute(
        !!company?.parameters.usesInvoiceConciliation,
        sidebarRoutes.conciliations
      ),
      getRoute(
        isAdmin || isUserApprover || accountManager || isTravelApprover,
        {
          ...sidebarRoutes.approvals,
          route: getApprovalRoute(),
          subRouteActive: 'APROVACOES',
          subRouteCondition: showApprovalSubmenu(),
          isReactRoute:
            (accountManager || isTravelApprover) && !!getApprovalRoute(),
          subRoute: [
            {
              ...sidebarRoutes.reports,
              route: sidebarRoutes.approvals.route,
              active: false,
              condition: isAdmin || isUserApprover,
              isReactRoute: sidebarRoutes.approvals.isReactRoute
            },
            {
              ...sidebarRoutes.approvalsRequest,
              active: route === sidebarRoutes.approvalsRequest.route,
              condition: accountManager,
              isReactRoute: true
            },
            {
              ...sidebarRoutes.travel,
              active: route.includes(sidebarRoutes.travel.route),
              condition: isTravelApprover,
              isReactRoute: true
            }
          ]
        }
      ),
      getRoute(!(isAdmin || user?.userApproves) && accountManager, {
        ...sidebarRoutes.approvalsRequest,
        name: sidebarRoutes.approvals.name
      }),
      getRoute(
        !!company?.parameters.usesCheckingAccount,
        sidebarRoutes.withdraw
      ),
      getRoute(isAdmin || conditionToRedirectToCardsReactRoute, {
        ...sidebarRoutes.cards,
        route: conditionToRedirectToCardsReactRoute
          ? sidebarRoutes.cards.route
          : `${vexDomain}/cards-preview-index`,
        isReactRoute: conditionToRedirectToCardsReactRoute
          ? sidebarRoutes.cards.isReactRoute
          : false,
        showPreviewIndicator: !conditionToRedirectToCardsReactRoute
      }),
      getRoute(isAdmin || !!user?.parameters.allowsManagementAccess, {
        ...sidebarRoutes.management,
        subRouteActive:
          route === sidebarRoutes.fuelDashboard.route
            ? sidebarRoutes.fuelDashboard.route
            : sidebarRoutes.travelReport.route,
        subRouteCondition:
          company?.parameters.usesFuelsModule ||
          (company?.parameters.usesTravels && travelReportFlag),
        subRoute: [
          {
            ...sidebarRoutes.adminGraphicsDashboard,
            active: false
          },
          {
            ...sidebarRoutes.fuelDashboard,
            condition: company?.parameters.usesFuelsModule,
            active: route === sidebarRoutes.fuelDashboard.route
          },
          {
            ...sidebarRoutes.travelReport,
            condition: company?.parameters.usesTravels && travelReportFlag,
            active: route === sidebarRoutes.travelReport.route
          }
        ]
      })
    ];

    return createAllowedRoutes(routes.filter(Boolean));
  }, [
    accountManager,
    company,
    expenseRoute,
    isFetchingOutsourcedUsersAndPermissions,
    outsourcedUser,
    outsourcedUsersAndPermissions,
    outsourcingUserUuid,
    redesignReportsFlag,
    route,
    user,
    isAdmin,
    travelReportFlag
  ]);

  // se o número de empresas for maior ou igual a 3, o menu de empresas deve ter 19.3rem de altura
  // se for menor, deve ter 5rem de altura por empresa + 5rem por conta da primeira opção
  const animatedMulticompanyMenuHeight =
    user?.userCompanies && user?.userCompanies?.length > 0
      ? user.userCompanies.length >= 3
        ? 19.3
        : user.userCompanies.length * 5 + 5
      : 0;

  const [subItemActive, setSubItemActive] = useState<string>(
    availableRoutes.find(({ subRoute }) =>
      subRoute?.some(({ active }) => active)
    )?.subRouteActive || route
  );

  /**
   * Salva o novo estado da sidebar nos cookies
   */
  function handleCollapseSidebar(): void {
    CustomCookies.set('sidebar', !isCollapsed ? 'collapsed' : '', {
      daysToExpire: 2
    });

    onSetIsCollapsed(!isCollapsed);
  }

  /**
   * Collapsa a sidebar caso seja mobile
   */
  function handleCollapseSidebarMobile(target: EventTarget): void {
    if (window.innerWidth >= 1280) return;

    if (target === (sidebarRef as MutableRefObject<HTMLDivElement>)?.current) {
      onSetIsCollapsed(!isCollapsed);
    }
  }

  const isOutsourcing = outsourcingUserUuid !== '';

  return {
    handleCollapseSidebar,
    availableRoutes,
    route,
    isOutsourcing,
    isFetchingData: isFetchingOutsourcedUsersAndPermissions || isAuthenticating,
    sidebarRef,
    company: company!,
    user: user!,
    subItemActive,
    setSubItemActive,
    handleCollapseSidebarMobile,
    isDropdownOpen,
    setIsDropdownOpen,
    firstRender: firstRender.current,
    isAdmin,
    isUser,
    animatedMulticompanyMenuHeight,
    userHasCompanies
  };
}
