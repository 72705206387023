import { DSIcons, tokens } from 'ds';
import styled from 'styled-components';

import { type TotalValueStylesType } from './SummaryCard.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  color: ${tokens.neutralColorLowDarkest};
  background: ${tokens.neutralColorHighPure};
  padding: ${tokens.spacingXs};
  gap: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const Title = styled.h2`
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
  letter-spacing: 0.1rem;
`;

export const SubTitle = styled.p`
  color: ${tokens.neutralColorLowDark};
`;

export const SummaryContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: ${tokens.fontFamilyPoppins};
  background: ${tokens.deepColorWhite};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};
`;

export const ListSummary = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${tokens.spacingXxs};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};

  > span {
    font-weight: ${tokens.fontWeightMedium};
    margin-left: ${tokens.spacingNano};
  }
`;

export const TotalValue = styled.div<AddPrefixToType<TotalValueStylesType>>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({ $isReproving }) =>
    $isReproving
      ? tokens.feedbackColorErrorMedium
      : tokens.feedbackColorSuccessMedium};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightMedium};
  margin-top: ${tokens.spacingXxs};

  > span {
    font-size: ${tokens.fontSizeXs};
    font-weight: ${tokens.fontWeightBold};
    margin-left: ${tokens.spacingNano};
  }
`;

export const Line = styled.span`
  width: 100%;
  border-bottom: 1px solid ${tokens.neutralColorHighDarkest};
`;

export const Info = styled.p`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 140%;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @media screen and (max-width: 560px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: ${tokens.spacingXs};
  }
`;

export const StyledCloseIcon = styled(DSIcons.CloseMediumIcon)`
  width: initial !important;
  height: initial !important;
`;

export const StyledCheckMarkIcon = styled(DSIcons.CheckMarkMediumIcon)`
  width: initial !important;
  height: initial !important;
`;
