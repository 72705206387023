import { useState } from 'react';

import { useDsGa4 } from 'ds/hooks/globals';

import { CustomDate } from 'shared/utils/custom';

import {
  type DateRangeType,
  type DateType,
  type IUseDefaultDatePicker,
  type IUseDefaultDatePickerProps
} from './DefaultDatePicker.types';

export function useDefaultDatePicker({
  onChangeValue,
  onChangeDateRange,
  selectsRange = false
}: IUseDefaultDatePickerProps): IUseDefaultDatePicker {
  const { sendDsGaEvent } = useDsGa4();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function handleChangeDate(value: DateType | DateRangeType): void {
    if (!selectsRange) {
      const date = value as DateType;

      sendGaEvent(date ? CustomDate.formatDateToIso(date) : undefined);
      onChangeValue?.(date ? CustomDate.formatDateToIso(date) : undefined);
    }

    if (selectsRange) {
      const [start, end] = value as DateRangeType;
      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        const startDate = CustomDate.formatDateToIso(start);
        const endDate = CustomDate.formatDateToIso(end);

        sendGaEvent(`${startDate} - ${endDate}`);
        onChangeDateRange?.([startDate, endDate]);
      }

      if (start && !end) {
        const startDate = CustomDate.formatDateToIso(start);
        onChangeDateRange?.([startDate, null]);
      }

      if (!start && !end) {
        sendGaEvent(undefined);
        onChangeDateRange?.(undefined);
      }
    }
  }

  function sendGaEvent(value: string | undefined): void {
    sendDsGaEvent('components', 'date_picker', {
      description: 'click no dia',
      eventAction: 'date_picker_day',
      eventLabel: 'action_date_picker_day',
      eventValue: value
    });
  }

  function clearDateRange(): void {
    setStartDate(null);
    setEndDate(null);
    onChangeDateRange?.(undefined);
  }

  return {
    handleChangeDate,
    startDate,
    endDate,
    clearDateRange
  };
}
