import { tokens } from 'ds';
import styled from 'styled-components';

import { FlightTitle } from 'presentation/pages/travel/components';

export const Container = styled.div`
  background: ${tokens.deepColorWhite};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};
  width: 100%;
`;

export const SearchSection = styled.div`
  margin-bottom: ${tokens.spacingSm};
  gap: ${tokens.spacingNano};
`;

export const FilterSection = styled.div`
  gap: ${tokens.spacingNano};
`;

export const Title = styled.h2`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 184%;
  letter-spacing: 0.075rem;
  padding-bottom: ${tokens.spacingNano};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};

  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 169%;
  letter-spacing: 0.025rem;

  svg {
    width: 2rem;
    height: 2rem;
  }

  > div {
    display: flex;
    align-items: center;
    column-gap: ${tokens.spacingNano};
  }
`;

export const WrapperRoundTrip = styled.div`
  white-space: nowrap;

  > svg {
    min-width: 2rem;
    min-height: 2rem;
  }
`;

export const StyledFlightTitle = styled(FlightTitle)`
  line-height: normal;

  .arrow-icon {
    width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
    min-height: 1.2rem;
    margin: 0 ${tokens.spacingNano};
  }
`;
