import { useShallow } from 'zustand/react/shallow';

import { useTourContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';

import { type IUseTourAction } from './TourAction.types';

export function useTourAction(): IUseTourAction {
  const [handleReset] = useTourContext(
    useShallow(state => [state.handleReset])
  );

  function identifyTourByUrl(): string | string[] {
    const urlStringArray = window.location.pathname.split('/');
    const url = urlStringArray.pop();

    switch (url !== '' ? url : urlStringArray.pop()) {
      case 'alocacao-de-valores':
        return localStorageKeys.chargeCardsTour;
      case 'despesas':
        return [
          localStorageKeys.routeByMapModalTour,
          localStorageKeys.viewExpensesModalTour,
          localStorageKeys.manualExpenseModalTour,
          localStorageKeys.reportSelectionTour,
          localStorageKeys.fuelExpenseModalTour,
          localStorageKeys.fuelExpenseModalIsNotAFuelExpenseTour,
          localStorageKeys.manualExpenseModalIsAFuelExpenseTour,
          localStorageKeys.expensesPageTour
        ];
      case 'veiculos':
        return [
          localStorageKeys.viewVehicleModalTour,
          localStorageKeys.createVehicleModalTour,
          localStorageKeys.linkMemberToVehicleModalTour,
          localStorageKeys.vehicleListPageTour
        ];
      case 'politicas':
        return localStorageKeys.fuelPolicyPageTour;
      case 'solicitacoes-de-valores':
        return localStorageKeys.valueSolicitationTour;
      case 'gestao':
        return localStorageKeys.cardsTour;
      default:
        return '';
    }
  }

  function handleResetTour(): void {
    const tourToReset = identifyTourByUrl();
    const defaultToursToReset = [localStorageKeys.zoomTour];

    defaultToursToReset.forEach((item): void => {
      handleReset(item);
      localStorage.setItem(item, 'false');
    });

    if (Array.isArray(tourToReset)) {
      tourToReset.forEach((item): void => {
        handleReset(item);
        localStorage.setItem(item, 'false');
      });

      return;
    }

    handleReset(tourToReset);
    localStorage.setItem(tourToReset, 'false');
  }

  return {
    handleResetTour
  };
}
