import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};

  section {
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingNano};
    padding-bottom: ${tokens.spacingNano};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.spacingXs};
`;

export const Title = styled.h2`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 140%;
`;

export const SectionTitle = styled.section`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 186.667%;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${tokens.spacingMd};
`;
