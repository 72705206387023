import styled from 'styled-components';

import { CardsContainer } from './components/BookingCardsContainer/BookingCardsContainer.styles';

export const SkeletonContainer = styled(CardsContainer)`
  span {
    width: 100%;
    height: 30rem;
  }
`;
