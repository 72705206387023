import { tokens } from 'ds';
import styled from 'styled-components';

import { SideModal } from 'presentation/components/global/Modal';

export const StyledSideModal = styled(SideModal)`
  > div > div:last-child {
    padding: 0;
    padding-top: ${tokens.spacingLg};
    align-items: start;
  }
`;
