import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${tokens.spacingXs};
  align-self: stretch;

  @media screen and (max-width: 480px) {
    width: 75vw;
  }
`;

export const WarningIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: ${tokens.borderRadiusCircular};
  background: ${tokens.feedbackColorErrorLightest};
`;

export const WarningIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  background: ${tokens.feedbackColorErrorPure};
  border-radius: ${tokens.borderRadiusCircular};

  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${tokens.neutralColorHighPure};
  }
`;

export const Text = styled.div`
  color: ${tokens.neutralColorLowDark};
  text-align: center;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
`;

export const UsersList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingNano};
  width: 100%;
  list-style-type: circle !important;
  overflow-y: auto;
  max-height: 15rem;

  li {
    color: ${tokens.neutralColorLowDark};
    text-overflow: ellipsis;
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    font-style: normal;
    font-weight: ${tokens.fontWeightRegular};
    line-height: 140%;
    letter-spacing: 0.25px;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: ${tokens.spacingNano};
`;
