import { tokens } from 'ds';
import styled from 'styled-components';

import {
  type IStyledHeaderActionButtonsContainerProps,
  type IStyledHeaderCardsContainerProps
} from './Overview.types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingXs};
  background: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
  padding: ${tokens.spacingXxs};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${tokens.spacingXs};
  row-gap: ${tokens.spacingXs};
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const HeaderCardsContainer = styled.div<IStyledHeaderCardsContainerProps>`
  display: flex;
  flex-direction: row;
  column-gap: ${tokens.spacingXs};
  row-gap: ${tokens.spacingXs};

  > div {
    min-width: 28rem;
  }

  @media screen and (max-width: 1280px) {
    > div {
      min-width: 25rem;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;

    > div {
      width: 49%;
    }
  }

  @media screen and (max-width: 834px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

export const HeaderActionButtonsContainer = styled.div<IStyledHeaderActionButtonsContainerProps>`
  display: flex;
  flex-direction: column;
  column-gap: ${tokens.spacingNano};
  row-gap: ${tokens.spacingNano};

  > div {
    min-width: 25rem;
    flex: 1;
  }

  button {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: row;

    > div {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    button > svg {
      width: 1.1rem;
      height: 1.1rem;
    }
  }

  button > svg {
    width: 1.2rem;
    height: 1rem;
  }
`;
