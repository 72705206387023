import { ReactComponent as ActionsMinusIcon } from './svg/actions_minus.svg';
import { ReactComponent as ActionsPlusIcon } from './svg/actions_plus.svg';
import { ReactComponent as AddCircleIcon } from './svg/add_circle.svg';
import { ReactComponent as AddExpenseIcon } from './svg/add_expense.svg';
import { ReactComponent as ArrowDiagonalIcon } from './svg/arrow_diagonal.svg';
import { ReactComponent as ArrowDownIcon } from './svg/arrow_down.svg';
import { ReactComponent as ArrowDownloadIcon } from './svg/arrow_download.svg';
import { ReactComponent as ArrowGoBackIcon } from './svg/arrow_go_back.svg';
import { ReactComponent as ArrowLeftIcon } from './svg/arrow_left.svg';
import { ReactComponent as ArrowRepeatRotateIcon } from './svg/arrow_repeat_rotate.svg';
import { ReactComponent as ArrowRightIcon } from './svg/arrow_right.svg';
import { ReactComponent as ArrowUpIcon } from './svg/arrow_up.svg';
import { ReactComponent as ArrowUpDropdown } from './svg/arrow_up_dropdown.svg';
import { ReactComponent as ArrowsIcon } from './svg/arrows.svg';
import { ReactComponent as AtmMoneyBanknoteIcon } from './svg/atm_money_banknote.svg';
import { ReactComponent as BackpackIcon } from './svg/backpack.svg';
import { ReactComponent as BarcodeScanSelectIcon } from './svg/barcode_scan_select.svg';
import { ReactComponent as BlockIcon } from './svg/block.svg';
import { ReactComponent as BuildingIcon } from './svg/building.svg';
import { ReactComponent as CalendarScheduleIcon } from './svg/calendar_schedule.svg';
import { ReactComponent as CalendarDateIcon } from './svg/calendar-date.svg';
import { ReactComponent as CarIcon } from './svg/car.svg';
import { ReactComponent as CarGasStationIcon } from './svg/car_gas_station.svg';
import { ReactComponent as CarTaxiIcon } from './svg/car_taxi.svg';
import { ReactComponent as CardIcon } from './svg/card_icon.svg';
import { ReactComponent as CardsIcon } from './svg/cards_icon.svg';
import { ReactComponent as CashBanknoteCheckmarkIcon } from './svg/cash_banknote_checkmark.svg';
import { ReactComponent as ChartSquareIcon } from './svg/chart_square.svg';
import { ReactComponent as ChatMessageIcon } from './svg/chat_message.svg';
import { ReactComponent as ChatMessageBubbleIcon } from './svg/chat_message_bubble.svg';
import { ReactComponent as CheckIcon } from './svg/check.svg';
import { ReactComponent as CheckmarkBigIcon } from './svg/checkmark_big.svg';
import { ReactComponent as CheckMarkMediumIcon } from './svg/checkmark_medium.svg';
import { ReactComponent as CheckmarkSmallCircleIcon } from './svg/checkmark_small_circle.svg';
import { ReactComponent as ChevronIcon } from './svg/chevron.svg';
import { ReactComponent as CircleOutlined } from './svg/circle_outlined.svg';
import { ReactComponent as ClearIcon } from './svg/clear.svg';
import { ReactComponent as ClipIcon } from './svg/clip.svg';
import { ReactComponent as ClipLineIcon } from './svg/clip_line.svg';
import { ReactComponent as ClockSmallCircleIcon } from './svg/clock_small_circle.svg';
import { ReactComponent as CloseIcon } from './svg/close.svg';
import { ReactComponent as CloseBigIcon } from './svg/close_big.svg';
import { ReactComponent as CloseLargerIcon } from './svg/close_larger.svg';
import { ReactComponent as CloseMediumIcon } from './svg/close_medium.svg';
import { ReactComponent as CloseSmallCircleIcon } from './svg/close_small_circle.svg';
import { ReactComponent as CogIcon } from './svg/cog.svg';
import { ReactComponent as ConcialiatedIcon } from './svg/conciliated_icon.svg';
import { ReactComponent as CopyItemRightIcon } from './svg/copy_item_right.svg';
import { ReactComponent as CreditCardIcon } from './svg/credit_card.svg';
import { ReactComponent as CreditCardClientIcon } from './svg/credit_card_client.svg';
import { ReactComponent as CreditCardInfoIcon } from './svg/credit_card_info.svg';
import { ReactComponent as CreditCardInvoiceListIcon } from './svg/credit_card_invoice_list.svg';
import { ReactComponent as CreditCardsIcon } from './svg/credit_cards_icon.svg';
import { ReactComponent as CreditCardUserGroupIcon } from './svg/credit-card-user-group.svg';
import { ReactComponent as CurrencyDollarMediunCircleIcon } from './svg/currency_dollar_medium_circle.svg';
import { ReactComponent as DiagramsArrow } from './svg/diagrams_arrow.svg';
import { ReactComponent as DocumentIcon } from './svg/document.svg';
import { ReactComponent as DocumentFileAddPlusCenter } from './svg/document_file_add_plus_center.svg';
import { ReactComponent as DotsActionIcon } from './svg/dots_action.svg';
import { ReactComponent as DotsVerticalIcon } from './svg/dots_vertical.svg';
import { ReactComponent as DownloadIcon } from './svg/download.svg';
import { ReactComponent as DownloadArrowIcon } from './svg/download_arrow.svg';
import { ReactComponent as DragInputIcon } from './svg/drag_input.svg';
import { ReactComponent as EditIcon } from './svg/edit.svg';
import { ReactComponent as EditPenIcon } from './svg/edit_pen.svg';
import { ReactComponent as EditReportIcon } from './svg/edit_report.svg';
import { ReactComponent as EmptyFolderIcon } from './svg/empty_folder.svg';
import { ReactComponent as ErrorIcon } from './svg/error.svg';
import { ReactComponent as ErrorConfirmationIcon } from './svg/error_action.svg';
import { ReactComponent as EssentialFlagIcon } from './svg/essential_flag.svg';
import { ReactComponent as EyeHiddenIcon } from './svg/eye_hidden.svg';
import { ReactComponent as EyeShowIcon } from './svg/eye_show.svg';
import { ReactComponent as FastFoodIcon } from './svg/fast_food.svg';
import { ReactComponent as FileIcon } from './svg/file.svg';
import { ReactComponent as FileCopyIcon } from './svg/file_copy.svg';
import { ReactComponent as FileDocumentIcon } from './svg/file_document.svg';
import { ReactComponent as FileExcelIcon } from './svg/file_excel.svg';
import { ReactComponent as FilePdfIcon } from './svg/file_pdf.svg';
import { ReactComponent as FileBlankExchangeCrossDeleteIcon } from './svg/file-blank-exchange-cross-delete.svg';
import { ReactComponent as FilterSortIcon } from './svg/filter-sort.svg';
import { ReactComponent as FolderFileCopyIcon } from './svg/folder_file_copy.svg';
import { ReactComponent as FullArrowRightIcon } from './svg/full_arrow_right.svg';
import { ReactComponent as GpsCardIcon } from './svg/gps_card.svg';
import { ReactComponent as GraduateHatIcon } from './svg/graduate_hat.svg';
import { ReactComponent as GraphArrowDownIcon } from './svg/graph_arrow_down.svg';
import { ReactComponent as GraphArrowUpIcon } from './svg/graph_arrow_up.svg';
import { ReactComponent as GridLayoutIcon } from './svg/grid_layout.svg';
import { ReactComponent as HappyEmojiFilledIcon } from './svg/happy_emoji_filled.svg';
import { ReactComponent as HappyEmojiOutlinedIcon } from './svg/happy_emoji_outlined.svg';
import { ReactComponent as ImagePictureIcon } from './svg/image_picture.svg';
import { ReactComponent as ImportedExpenseIcon } from './svg/imported_expense.svg';
import { ReactComponent as IndeterminatedIcon } from './svg/indeterminated.svg';
import { ReactComponent as InfoIcon } from './svg/info.svg';
import { ReactComponent as InfoVariantIcon } from './svg/info_variant.svg';
import { ReactComponent as InvoiceAddPlusIcon } from './svg/invoice_add_plus.svg';
import { ReactComponent as InvoiceCheckmarkIcon } from './svg/invoice_checkmark.svg';
import { ReactComponent as InvoiceMinusIcon } from './svg/invoice_minus.svg';
import { ReactComponent as InvoiceMore } from './svg/invoice_more.svg';
import { ReactComponent as InvoiceRemoveCrossIcon } from './svg/invoice_remove_cross.svg';
import { ReactComponent as ListLayoutIcon } from './svg/list_layout.svg';
import { ReactComponent as MapFinalIcon } from './svg/map_final.svg';
import { ReactComponent as MapInitialIcon } from './svg/map_initial.svg';
import { ReactComponent as MapWaypointIcon } from './svg/map_waypoint.svg';
import { ReactComponent as MenuBurger } from './svg/menu_burger.svg';
import { ReactComponent as MenuDotsIcon } from './svg/menu_dots.svg';
import { ReactComponent as MessageQuestionIcon } from './svg/message_question.svg';
import { ReactComponent as MobileLockIcon } from './svg/mobile_lock.svg';
import { ReactComponent as MoneyBanknoteExchangeIcon } from './svg/money_banknote_exchange.svg';
import { ReactComponent as MoneyWalletAdd } from './svg/money_wallet_add.svg';
import { ReactComponent as MoneyWalletCheckMark } from './svg/money_wallet_checkmark.svg';
import { ReactComponent as NeutralEmojiFilledIcon } from './svg/neutral_emoji_filled.svg';
import { ReactComponent as NeutralEmojiOutlinedIcon } from './svg/neutral_emoji_outlined.svg';
import { ReactComponent as NotConcialiatedIcon } from './svg/not_conciliated.svg';
import { ReactComponent as NotificationBellIcon } from './svg/notification_bell.svg';
import { ReactComponent as PaperClipAttachIcon } from './svg/paper_clip_attach.svg';
import { ReactComponent as PartiallyConciliatedIcon } from './svg/partially_conciliated.svg';
import { ReactComponent as PaymentsFinanceIcon } from './svg/payments_finance.svg';
import { ReactComponent as PdfRedIcon } from './svg/pdf_icon.svg';
import { ReactComponent as PdfLinkIcon } from './svg/pdf_link.svg';
import { ReactComponent as PinLocationIcon } from './svg/pin_location.svg';
import { ReactComponent as PinLocationAddPlusCircleIcon } from './svg/pin_location_add_plus_circle.svg';
import { ReactComponent as PinLocationMapIcon } from './svg/pin_location_map.svg';
import { ReactComponent as PixIcon } from './svg/pix.svg';
import { ReactComponent as PlusSmallIcon } from './svg/plus-small.svg';
import { ReactComponent as QuestionIcon } from './svg/question.svg';
import { ReactComponent as ReceiptCurrencyDollarIcon } from './svg/receipt_currency_dollar.svg';
import { ReactComponent as RemoveIcon } from './svg/remove_icon.svg';
import { ReactComponent as ReportTimeClockIcon } from './svg/report_time_clock.svg';
import { ReactComponent as RocketIcon } from './svg/rocket.svg';
import { ReactComponent as RouteIcon } from './svg/route.svg';
import { ReactComponent as SadEmojiFilledIcon } from './svg/sad_emoji_filled.svg';
import { ReactComponent as SadEmojiOutlinedIcon } from './svg/sad_emoji_outlined.svg';
import { ReactComponent as SearchIcon } from './svg/search.svg';
import { ReactComponent as SearchBigIcon } from './svg/search_big.svg';
import { ReactComponent as SearchZoomPlusIcon } from './svg/search_zoom_plus.svg';
import { ReactComponent as SecondaryPointIcon } from './svg/secondary_route.svg';
import { ReactComponent as SendEmailLetterOpenEmailIcon } from './svg/send_email_letter_open_mail.svg';
import { ReactComponent as SendMessageIcon } from './svg/send_message.svg';
import { ReactComponent as SendMoneyPersonIcon } from './svg/send_money_person.svg';
import { ReactComponent as SendToApproval } from './svg/send_to_approval.svg';
import { ReactComponent as SendEmailFastIcon } from './svg/send-email-fast.svg';
import { ReactComponent as SeriusEmojiFilledIcon } from './svg/serius_emoji_filled.svg';
import { ReactComponent as SeriusEmojiOutlinedIcon } from './svg/serius_emoji_outlined.svg';
import { ReactComponent as SettingsIcon } from './svg/settings.svg';
import { ReactComponent as ShoppingIcon } from './svg/shopping.svg';
import { ReactComponent as ShoppingCartIcon } from './svg/shopping_cart.svg';
import { ReactComponent as ShoppingListReceipt3Icon } from './svg/shopping-list-receipt-3.svg';
import { ReactComponent as SingleBedIcon } from './svg/single_bed.svg';
import { ReactComponent as SingleUserCheckmarkIcon } from './svg/single_user_checkmark.svg';
import { ReactComponent as SmileEmojiFilledIcon } from './svg/smile_emoji_filled.svg';
import { ReactComponent as SmileEmojiOutlinedIcon } from './svg/smile_emoji_outlined.svg';
import { ReactComponent as SortAscIcon } from './svg/sort_asc.svg';
import { ReactComponent as SortDescIcon } from './svg/sort_desc.svg';
import { ReactComponent as SortableIcon } from './svg/sortable.svg';
import { ReactComponent as StarIcon } from './svg/star.svg';
import { ReactComponent as StartingPointIcon } from './svg/starting_point.svg';
import { ReactComponent as StickerCopyIcon } from './svg/stickers_copy.svg';
import { ReactComponent as SuccessIcon } from './svg/success.svg';
import { ReactComponent as SuccessConfirmantionIcon } from './svg/success_action.svg';
import { ReactComponent as TextCheckmarkIcon } from './svg/text_checkmark.svg';
import { ReactComponent as TimeClockIcon } from './svg/time_clock.svg';
import { ReactComponent as TotalKmLiterIcon } from './svg/total_km_liter.svg';
import { ReactComponent as TotalKmUsedIcon } from './svg/total_km_used.svg';
import { ReactComponent as TotalValueIcon } from './svg/total_value_card.svg';
import { ReactComponent as TransportationBusIcon } from './svg/transportation_bus.svg';
import { ReactComponent as TransportationCarIcon } from './svg/transportation_car.svg';
import { ReactComponent as TrashDeleteBinIcon } from './svg/trash_delete_bin.svg';
import { ReactComponent as TrashDeleteIcon } from './svg/trash-delete.svg';
import { ReactComponent as UserIcon } from './svg/user.svg';
import { ReactComponent as UserCrossDeleteIcon } from './svg/user_cross_delete.svg';
import { ReactComponent as UserDeleteCrossIcon } from './svg/user_delete_cross.svg';
import { ReactComponent as UserEditIcon } from './svg/user_edit.svg';
import { ReactComponent as UserKeyProtectionIcon } from './svg/user_key_protection.svg';
import { ReactComponent as UserMenuIcon } from './svg/user_menu.svg';
import { ReactComponent as UserProfileIcon } from './svg/user_profile.svg';
import { ReactComponent as UserProfileRefreshIcon } from './svg/user_profile_refresh.svg';
import { ReactComponent as UserSingleIcon } from './svg/user_single.svg';
import { ReactComponent as UserSingleAlertIcon } from './svg/user_single_alert.svg';
import { ReactComponent as UsersIcon } from './svg/users.svg';
import { ReactComponent as WarningIcon } from './svg/warning.svg';
import { ReactComponent as WarningConfirmationIcon } from './svg/warning_confirmation.svg';
import { ReactComponent as WarningEssentialIcon } from './svg/warning_essential.svg';
import { ReactComponent as WarningOutlineIcon } from './svg/warning_outline.svg';
import { ReactComponent as ZoomMinusIcon } from './svg/zoom_minus.svg';
import { ReactComponent as ZoomPlusIcon } from './svg/zoom_plus.svg';

export const DSIcons = {
  ArrowUpIcon,
  AtmMoneyBanknoteIcon,
  CarTaxiIcon,
  MenuDotsIcon,
  FastFoodIcon,
  MoneyBanknoteExchangeIcon,
  CreditCardInvoiceListIcon,
  StickerCopyIcon,
  DownloadArrowIcon,
  UserProfileRefreshIcon,
  ShoppingIcon,
  FullArrowRightIcon,
  InvoiceAddPlusIcon,
  SadEmojiFilledIcon,
  SadEmojiOutlinedIcon,
  HappyEmojiFilledIcon,
  HappyEmojiOutlinedIcon,
  SeriusEmojiFilledIcon,
  SeriusEmojiOutlinedIcon,
  SmileEmojiFilledIcon,
  SmileEmojiOutlinedIcon,
  NeutralEmojiFilledIcon,
  NeutralEmojiOutlinedIcon,
  PartiallyConciliatedIcon,
  InvoiceCheckmarkIcon,
  EmptyFolderIcon,
  QuestionIcon,
  InvoiceRemoveCrossIcon,
  InvoiceMinusIcon,
  ArrowDownloadIcon,
  InfoIcon,
  ActionsPlusIcon,
  ArrowsIcon,
  ActionsMinusIcon,
  InvoiceMore,
  ErrorIcon,
  SuccessIcon,
  WarningIcon,
  CloseLargerIcon,
  PaperClipAttachIcon,
  CarGasStationIcon,
  CheckmarkBigIcon,
  ChatMessageBubbleIcon,
  CloseIcon,
  CloseBigIcon,
  ChartSquareIcon,
  CurrencyDollarMediunCircleIcon,
  ShoppingListReceipt3Icon,
  PinLocationAddPlusCircleIcon,
  CalendarDateIcon,
  CheckIcon,
  FileIcon,
  IndeterminatedIcon,
  ChevronIcon,
  ClipIcon,
  FolderFileCopyIcon,
  ClearIcon,
  TrashDeleteBinIcon,
  TrashDeleteIcon,
  EssentialFlagIcon,
  SendMessageIcon,
  SendEmailLetterOpenEmailIcon,
  BarcodeScanSelectIcon,
  PinLocationMapIcon,
  ArrowRepeatRotateIcon,
  FileBlankExchangeCrossDeleteIcon,
  CogIcon,
  ImagePictureIcon,
  SearchZoomPlusIcon,
  SortableIcon,
  PaymentsFinanceIcon,
  ChatMessageIcon,
  ArrowLeftIcon,
  TimeClockIcon,
  ArrowRightIcon,
  SortDescIcon,
  SortAscIcon,
  ArrowGoBackIcon,
  MoneyWalletCheckMark,
  MoneyWalletAdd,
  MapFinalIcon,
  DocumentFileAddPlusCenter,
  MapInitialIcon,
  MapWaypointIcon,
  DragInputIcon,
  StartingPointIcon,
  AddCircleIcon,
  DotsVerticalIcon,
  RemoveIcon,
  SecondaryPointIcon,
  SearchIcon,
  ImportedExpenseIcon,
  NotConcialiatedIcon,
  CardIcon,
  ConcialiatedIcon,
  ArrowDownIcon,
  CalendarScheduleIcon,
  SearchBigIcon,
  WarningConfirmationIcon,
  ListLayoutIcon,
  GridLayoutIcon,
  PixIcon,
  FileCopyIcon,
  CardsIcon,
  CreditCardInfoIcon,
  CreditCardsIcon,
  CashBanknoteCheckmarkIcon,
  CreditCardClientIcon,
  SendMoneyPersonIcon,
  RocketIcon,
  NotificationBellIcon,
  RouteIcon,
  SuccessConfirmantionIcon,
  ErrorConfirmationIcon,
  DiagramsArrow,
  MenuBurger,
  GraduateHatIcon,
  UsersIcon,
  UserIcon,
  ZoomMinusIcon,
  ZoomPlusIcon,
  SettingsIcon,
  EditIcon,
  PlusSmallIcon,
  CreditCardUserGroupIcon,
  WarningEssentialIcon,
  ReportTimeClockIcon,
  UserEditIcon,
  WarningOutlineIcon,
  ArrowUpDropdown,
  DotsActionIcon,
  SendToApproval,
  AddExpenseIcon,
  EditReportIcon,
  DownloadIcon,
  FileExcelIcon,
  PdfLinkIcon,
  FilePdfIcon,
  CloseMediumIcon,
  CheckMarkMediumIcon,
  FilterSortIcon,
  ReceiptCurrencyDollarIcon,
  CreditCardIcon,
  SingleUserCheckmarkIcon,
  UserDeleteCrossIcon,
  EditPenIcon,
  BlockIcon,
  EyeHiddenIcon,
  EyeShowIcon,
  UserCrossDeleteIcon,
  GraphArrowDownIcon,
  GraphArrowUpIcon,
  TotalKmLiterIcon,
  TotalValueIcon,
  TotalKmUsedIcon,
  GpsCardIcon,
  CarIcon,
  InfoVariantIcon,
  ClipLineIcon,
  ArrowDiagonalIcon,
  FileDocumentIcon,
  DocumentIcon,
  UserProfileIcon,
  BuildingIcon,
  SendEmailFastIcon,
  UserKeyProtectionIcon,
  UserMenuIcon,
  UserSingleIcon,
  MessageQuestionIcon,
  MobileLockIcon,
  TextCheckmarkIcon,
  UserSingleAlertIcon,
  CircleOutlined,
  CopyItemRightIcon,
  PinLocationIcon,
  BackpackIcon,
  StarIcon,
  PdfRedIcon,
  ShoppingCartIcon,
  SingleBedIcon,
  TransportationCarIcon,
  TransportationBusIcon,
  CheckmarkSmallCircleIcon,
  ClockSmallCircleIcon,
  CloseSmallCircleIcon
};
