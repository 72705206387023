import { tokens } from 'ds';

import { Icons } from 'shared/constants/global';

import { type IFlightTitleProps } from './FlightTitle.types';

import { Container, Text, TextWrapper } from './FlightTitle.styles';

export function FlightTitle({
  withIcon = true,
  departure,
  destination,
  isRoundTrip,
  className
}: IFlightTitleProps): JSX.Element {
  const title = `${departure} ${isRoundTrip ? '⇆' : '→'} ${destination}`;

  return (
    <Container className={className}>
      {withIcon && (
        <Icons.AirplaneIcon
          className='plane-icon'
          color={tokens.neutralColorLowMediumLight}
        />
      )}
      <TextWrapper title={title}>
        <Text className='text'>{departure}</Text>
        {isRoundTrip ? (
          <Icons.ArrowArrangeIcon
            className='arrow-icon -arrange'
            color={tokens.neutralColorLowMediumLight}
          />
        ) : (
          <Icons.ArrowRightIcon
            className='arrow-icon -right'
            color={tokens.neutralColorLowMediumLight}
          />
        )}
        <Text className='text'>{destination}</Text>
      </TextWrapper>
    </Container>
  );
}
