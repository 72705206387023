import { tokens } from 'ds';
import styled from 'styled-components';

export const Title = styled.h1`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  margin-bottom: ${tokens.spacingNano};
`;

export const CardsContainer = styled.div`
  display: grid;
  column-gap: ${tokens.spacingXs};
  row-gap: ${tokens.spacingXs};

  @media screen and (min-width: 1921px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1920px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`;
