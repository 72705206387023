import { faker } from '@faker-js/faker';

import {
  BookingStatuses,
  FlightSegment,
  type IBooking,
  type IBookingFlight,
  type IBookingHotel,
  ProcessingStatuses,
  TravelProducts,
  TripStatuses
} from 'data/modules/travel/global';
import { type ITripDetails } from 'data/modules/travel/tripDetails';

import { MockApi } from 'shared/utils/global';
import { Booking } from 'shared/utils/travels';

export async function getTripDetailsMock(): Promise<ITripDetails | null> {
  await MockApi.sleep();

  if (faker.datatype.boolean(0.1)) {
    return null;
  }

  return {
    id: faker.string.uuid(),
    userName: faker.person.fullName(),
    status: faker.helpers.arrayElement(Object.values(TripStatuses)),
    description: faker.lorem.words({
      min: 3,
      max: 8
    }),
    observation: faker.lorem.text(),
    requestedDate: faker.date.recent(),
    totalPrice: faker.number.float({
      min: 1000,
      max: 5000,
      fractionDigits: 2
    }),
    bookings: makeBookingListMock(faker.number.int({ min: 8, max: 16 })),
    startDate: faker.date.recent(),
    endDate: faker.date.soon(),
    averagePerDay: faker.number.float({
      min: 100,
      max: 2000,
      fractionDigits: 2
    }),
    days: faker.number.int({ min: 0, max: 10 })
  };
}

export function makeBookingListMock(
  quantity = 1
): (IBookingFlight | IBookingHotel)[] {
  const bookings: (IBookingFlight | IBookingHotel)[] = [];

  for (let i = 0; i < quantity; i++) {
    bookings.push(
      faker.helpers.maybe(() => makeBookingFlightMock()) ||
        makeBookingHotelMock()
    );
  }

  return bookings;
}

const bookingStatusesArray: BookingStatuses[] = [
  BookingStatuses.AGUARDANDO_APROVACAO,
  BookingStatuses.EXPIRADA,
  BookingStatuses.APROVADA,
  BookingStatuses.REPROVADA,
  BookingStatuses.CANCELADA,
  BookingStatuses.FALHA
];

const processingStatusesArray: ProcessingStatuses[] = [
  ProcessingStatuses.AGUARDANDO_APROVACAO,
  ProcessingStatuses.AGUARDANDO_PAGAMENTO,
  ProcessingStatuses.EMITIDO_SUCESSO,
  ProcessingStatuses.FALHA_PAGAMENTO,
  ProcessingStatuses.FALHA_PROCESSAMENTO,
  ProcessingStatuses.AGUARDANDO_CANCELAMENTO,
  ProcessingStatuses.CANCELADO,
  ProcessingStatuses.EXPIRADO,
  ProcessingStatuses.CONFIRMADO,
  ProcessingStatuses.FALHA_CANCELAMENTO
];

const searchFiltersArray = [
  faker.lorem.sentence({ min: 3, max: 6 }),
  faker.lorem.sentence({ min: 3, max: 6 }),
  faker.lorem.sentence({ min: 3, max: 6 }),
  faker.lorem.sentence({ min: 3, max: 6 }),
  faker.lorem.sentence({ min: 3, max: 6 }),
  faker.lorem.sentence({ min: 3, max: 6 })
];

export function makeBookingMock(bookingType: TravelProducts): IBooking {
  return {
    id: faker.string.uuid(),
    product: bookingType,
    status: faker.helpers.arrayElement(bookingStatusesArray),
    processingStatus: faker.helpers.arrayElement(processingStatusesArray),
    flagBestPrice: faker.datatype.boolean(),
    flagPolicyRules: faker.datatype.boolean(),
    totalPrice: faker.number.float({
      min: 100,
      max: 5000,
      fractionDigits: 2
    }),
    filters:
      faker.helpers.maybe(
        () => faker.helpers.arrayElements(searchFiltersArray),
        { probability: 0.6 }
      ) || null,
    isAboutToExpire: false
  };
}

export function makeBookingHotelMock(): IBookingHotel {
  const bookingMock = makeBookingMock(TravelProducts.HOTEL);

  return {
    ...bookingMock,
    product: TravelProducts.HOTEL,
    hotel: faker.lorem.words({ min: 4, max: 8 }),
    checkIn: faker.date.soon(),
    checkOut: faker.date.recent(),
    days: faker.number.int({ min: 1, max: 5 }),
    averagePerDay: faker.number.float({
      min: 100,
      max: 2000,
      fractionDigits: 2
    }),
    expirationDate: faker.datatype.boolean()
      ? Booking.getHotelExpirationDate(
          faker.date.soon({ days: 10 }).toString(),
          bookingMock.status
        )
      : null
  };
}

export function makeBookingFlightMock(): IBookingFlight {
  return {
    ...makeBookingMock(TravelProducts.AEREO),
    product: TravelProducts.AEREO,
    origin: faker.location.city(),
    destination: faker.location.city(),
    departureDate: faker.date.soon(),
    departureStopovers: faker.number.int({ min: 0, max: 4 }),
    arrivalDate: faker.date.recent(),
    arrivalStopovers: faker.number.int({ min: 0, max: 4 }),
    segmentType: faker.helpers.arrayElement(Object.values(FlightSegment))
  };
}
