import { tokens } from 'ds';
import styled from 'styled-components';

export const Wrapper = styled.div`
  /* TODO: deve ser removido */
  border: 1px solid red;

  display: flex;
  gap: ${tokens.spacingMd};

  > div {
    width: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

export const DetailColumn = styled.div``;

export const HistoryColumn = styled.div``;
