import { useQueryCache } from 'data/cache';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetAuthTravelUser,
  type IUseGetAuthTravelUserParams
} from 'data/modules/auth';

export function useGetAuthTravelUser({
  enabled
}: IUseGetAuthTravelUserParams): IUseGetAuthTravelUser {
  const {
    data: travelUser,
    isFetching: isFetchingTravelUser,
    isError
  } = useQueryCache({
    queryKey: [AuthQueryKeys.AUTH_TRAVEL_USER],
    queryFn: async () => await AuthService.getAuthTravelUser(),
    staleTime: Infinity,
    enabled
  });

  return {
    travelUser,
    isFetchingTravelUser,
    isErrorTravelUser: isError
  };
}
