import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .product-icon {
    width: 2rem;
    height: 2rem;
    color: ${tokens.neutralColorLowMediumLight};
  }

  .status-icon {
    position: absolute;

    width: 1rem;
    height: 1rem;
    top: 0;
    right: 0;
    transform: translate(45%, -20%);
  }
`;
