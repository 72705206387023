import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacingLg};
  padding: ${tokens.spacingMd};
  font-family: ${tokens.fontFamilyPoppins};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${tokens.spacingXs};

  > img {
    width: 21.7rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TextBig = styled.h2`
  font-size: ${tokens.fontSizeMd};
  font-weight: ${tokens.fontWeightBold};
  line-height: 140%;
  letter-spacing: 1px;
`;

export const TextMedium = styled.p`
  font-size: ${tokens.fontSizeSm};
  line-height: 140%;
  letter-spacing: 1px;
`;

export const TextSmall = styled.p`
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 160%;
  letter-spacing: 0.75px;
`;
