import { SecondaryButton, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingMd};
  padding: ${tokens.spacingMd} ${tokens.spacingLg} 0;
`;

export const FilterSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  padding: ${tokens.spacingMd};
  background: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusMd};
`;

export const FilterSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.spacingMd};

  h2 {
    color: ${tokens.neutralColorLowDark};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxs};
    font-weight: ${tokens.fontWeightSemibold};
  }
`;

export const CartButton = styled(SecondaryButton)`
  width: 15rem;

  svg {
    min-width: 1.6rem;
    min-height: 1.6rem;
    stroke-width: 2;
  }
`;
