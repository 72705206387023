import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type ChoiceStatementStylesType } from './SearchHistoryAnalysisCard.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${tokens.deepColorWhite};
  padding: ${tokens.spacingXs};
  border: 1px solid ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusMd};
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingNano};
`;

export const BarContainer = styled.div`
  display: flex;
  margin: 1rem 0;
`;

export const Title = styled.h2`
  display: flex;
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 184%;
  letter-spacing: 0.075rem;
  padding-bottom: ${tokens.spacingNano};
`;

export const ChoiceStatement = styled.div<
  AddPrefixToType<ChoiceStatementStylesType>
>`
  display: flex;
  color: ${tokens.feedbackColorWarningMediumDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightBold};
  line-height: 140%;
  letter-spacing: 0.1rem;

  ${({ $isCheapest }) =>
    $isCheapest &&
    css`
      color: ${tokens.brandColorSecondaryMedium};
    `}
`;

export const RegularText = styled.div`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-style: normal;
  font-weight: ${tokens.fontWeightRegular};
  line-height: 140%;
  letter-spacing: 0.1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${tokens.spacingXs};

  button {
    width: 100%;
  }
`;
