import { create } from 'zustand';

import { type IUseEditApproversContext } from './useEditTravelApproversContext.types';

export const useEditTravelApproversContext = create<IUseEditApproversContext>()(
  set => ({
    step: 1,
    travelUsers: [],
    usersSelection: [],
    approversEditDiff: {
      addingUsers: [],
      removingUsers: []
    },
    search: '',
    setStep: step => {
      set({ step });
    },
    setTravelUsers: travelUsers => {
      set({ travelUsers });
    },
    setUsersSelection: usersSelection => {
      set({ usersSelection });
    },
    setApproverEditDiff: approversEditDiff => {
      set({ approversEditDiff });
    },
    setSearch: search => {
      set({ search });
    },
    resetState: () => {
      set({
        step: 1,
        travelUsers: [],
        usersSelection: [],
        approversEditDiff: {
          addingUsers: [],
          removingUsers: []
        },
        search: ''
      });
    }
  })
);
