import { Modal, tokens } from 'ds';
import styled from 'styled-components';

export const ModalTitle = styled(Modal.Title)`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.075rem;
  text-align: left;
`;

export const ModalHeader = styled(Modal.Header)`
  align-items: start;
  margin-bottom: 0.8rem;
`;

export const PleaseTextContent = styled.p`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.075rem;
`;

export const ModalContent = styled(Modal.Content)`
  padding-right: 0;
`;

export const NotesContainer = styled.div`
  margin-top: 4.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  > div:first-child {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .option-selected {
      background-color: ${tokens.brandColorSecondaryMedium} !important;
      border-color: ${tokens.brandColorSecondaryMedium} !important;
      color: ${tokens.neutralColorHighPure};
    }
  }

  > div:last-child {
    display: flex;
    justify-content: space-between;

    > p {
      color: ${tokens.neutralColorLowDark};
      font-family: ${tokens.fontFamilyPoppins};
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2.2rem;
      letter-spacing: 0.025rem;
    }
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  margin-top: 4.8rem;
  width: 100%;
  align-items: center;
  gap: 1.6rem;

  > p {
    color: ${tokens.neutralColorLowLightest};
    font-family: ${tokens.fontFamilyPoppins};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.2rem;
    letter-spacing: 0.075rem;
  }
`;
