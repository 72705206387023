import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingLg};
`;

export const TabsContainer = styled.div`
  nav ul li {
    white-space: nowrap;
  }

  @media screen and (max-width: 480px) {
    nav ul {
      flex-wrap: wrap;
      column-gap: 1rem;

      li {
        max-width: 50%;
      }
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${tokens.spacingInsetXs};
  row-gap: ${tokens.spacingInsetXs};

  > div {
    flex: 1;
    max-width: calc(33.33%);
  }

  @media screen and (max-width: 1366px) {
    > div {
      max-width: calc(19%);
    }
  }

  @media screen and (max-width: 834px) {
    > div {
      max-width: calc(50%);
      min-width: calc(30%);
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    > div {
      max-width: 100%;
    }
  }

  .react-select-custom__control,
  .react-multiSelect-custom__control {
    min-width: auto;
    max-width: unset;
  }
`;
