import { Modal } from 'ds';
import styled from 'styled-components';

export const StyledModalContainer = styled(Modal.Container)`
  width: fit-content;
  max-width: fit-content;
`;

export const StyledModalContent = styled(Modal.Content)`
  max-height: unset;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
