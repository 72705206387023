import { tokens } from 'ds';
import { DSIcons } from 'ds/icons';
import styled from 'styled-components';

import { type DotStylesType } from './SavingsBar.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${tokens.borderRadiusMd};
  background: linear-gradient(
    90deg,
    ${tokens.feedbackColorSuccessPure} 0%,
    ${tokens.feedbackColorWarningPure} 46.5%,
    ${tokens.feedbackColorErrorMediumLight} 100%
  );
  position: relative;
  height: 0.4rem;
`;

export const Dot = styled(DSIcons.CircleOutlined)<
  AddPrefixToType<DotStylesType>
>`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: ${({ $percentage }) => $percentage}%;
  transition: left 0.3s ease;

  circle {
    stroke: ${tokens.brandColorPrimaryPure};
    fill: ${tokens.brandColorPrimaryLightest};
  }
`;
