import { useQueryCache } from 'data/cache';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetAuthUser,
  type IUseGetAuthUserParams
} from 'data/modules/auth';

export function useGetAuthUser(
  { enabled = true }: IUseGetAuthUserParams = {
    enabled: true
  }
): IUseGetAuthUser {
  const {
    data: authUserData,
    isFetching: isFetchingAuthUser,
    isSuccess,
    isError
  } = useQueryCache({
    queryKey: [AuthQueryKeys.AUTH_USER],
    queryFn: async () => await AuthService.getAuthUser(),
    staleTime: Infinity,
    enabled
  });

  return {
    user: authUserData?.user,
    company: authUserData?.company,
    isFetchingAuthUser,
    isSuccessAuthUser: isSuccess,
    isErrorAuthUser: isError
  };
}
