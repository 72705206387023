import { DefaultTable, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: ${tokens.borderRadiusMd};
`;

export const StyledTh = styled(DefaultTable.Th)`
  svg {
    flex-shrink: 0;
  }
`;

export const ActionColumnTh = styled(DefaultTable.Th)`
  width: 10rem;

  > div {
    display: flex;
    justify-content: center;
  }
`;

export const ActionColumnTd = styled(DefaultTable.Td)`
  display: flex;
  justify-content: center;
`;

export const RemoveButton = styled.button`
  all: unset;
  cursor: pointer;
`;
