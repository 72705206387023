import { Checkbox, tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingXs};
  width: 100%;
`;

export const PolicyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: ${tokens.spacingInsetMdd};
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckbox = styled(Checkbox.Default)`
  > div {
    border-width: 2px;
    padding: 0.75rem;
  }
`;
